import * as React from 'react';
import { WHITE } from '@utils/colors';
import Svg, { Circle } from 'react-native-svg';
const SelectedCircle = (props) => {
    const { style, ...otherProps } = props;
    const { height = 24, width = 24, color = WHITE } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 24 24", fill: "none", ...otherProps },
        React.createElement(Circle, { cx: "12", cy: "12", r: "11.5", stroke: color }),
        React.createElement(Circle, { cx: "12", cy: "12", r: "6", fill: color })));
};
export default SelectedCircle;
