// @ts-nocheck
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { Dimensions, ScrollView, View, StyleSheet } from 'react-native';
import { s } from 'react-native-size-matters';
import { GREEN } from '../../utils/colors';
import TextButton from '../baseComponents/textButton';
const { height } = Dimensions.get('screen');
const width = s(360);
export default class QuotesView extends Component {
    static defaultProps = {
        horizontal: true,
        pagingEnabled: true,
        showsHorizontalScrollIndicator: false,
        showsVerticalScrollIndicator: false,
        bounces: false,
        scrollsToTop: false,
        removeClippedSubviews: true,
        automaticallyAdjustContentInsets: false,
        index: 0,
    };
    state = this.initState(this.props);
    initState(props) {
        const total = props.children ? props.children.length || 1 : 0;
        const index = total > 1 ? Math.min(props.index, total - 1) : 0;
        const offset = width * index;
        const state = {
            total,
            index,
            offset,
            width,
            height,
        };
        this.internals = {
            isScrolling: false,
            offset,
        };
        return state;
    }
    onScrollBegin = () => {
        this.internals.isScrolling = true;
    };
    onScrollEnd = (e) => {
        this.internals.isScrolling = false;
        this.updateIndex(e.nativeEvent.contentOffset
            ? e.nativeEvent.contentOffset.x
            : e.nativeEvent.position * this.state.width);
    };
    onScrollEndDrag = (e) => {
        const { contentOffset: { x: newOffset }, } = e.nativeEvent;
        const { children } = this.props;
        const { index } = this.state;
        const { offset } = this.internals;
        if (offset === newOffset && (index === 0 || index === children.length - 1)) {
            this.internals.isScrolling = false;
        }
    };
    updateIndex = (offset) => {
        const { state } = this;
        const diff = offset - this.internals.offset;
        const step = state.width;
        let { index } = state;
        if (!diff) {
            return;
        }
        index = parseInt(index + Math.round(diff / step), 10);
        this.internals.offset = offset;
        this.setState({
            index,
        });
    };
    swipe = (type) => {
        if (this.state.total < 2) {
            return;
        }
        if (this.state.index === this.state.total - 1) {
            return;
        }
        const newIndex = type === 'next' ? this.state.index + 1 : this.state.index - 1;
        const { state } = this;
        const x = newIndex * state.width;
        const y = 0;
        this.scrollView && this.scrollView.scrollTo({ x, y, animated: true });
        this.internals.isScrolling = true;
        this.setState({ index: newIndex });
    };
    renderScrollView = (pages) => {
        return (React.createElement(ScrollView, { ref: (component) => {
                this.scrollView = component;
            }, ...this.props, onScrollBeginDrag: this.onScrollBegin, onMomentumScrollEnd: this.onScrollEnd, onScrollEndDrag: this.onScrollEndDrag }, pages.map((page, i) => (React.createElement(View, { style: [styles.container, { paddingHorizontal: s(2) }], key: i.toString() }, page)))));
    };
    renderButton = () => {
        return (React.createElement(View, { style: styles.buttonView },
            this.state.index > 0 && (React.createElement(TextButton, { title: "PREV", onPress: () => this.swipe('prev'), buttonStyle: [styles.button, { marginRight: 6 }] })),
            this.state.index !== this.state.total - 1 && (React.createElement(TextButton, { title: "NEXT", onPress: () => this.swipe('next'), buttonStyle: [styles.button, { marginLeft: 6 }] }))));
    };
    render = ({ children, index } = this.props) => {
        return (React.createElement(View, null,
            React.createElement(View, { style: styles.container }, this.renderScrollView(children)),
            React.createElement(View, null, this.renderButton(index))));
    };
}
export const styles = StyleSheet.create({
    container: {
        width: s(360),
        alignSelf: 'center',
    },
    buttonView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 24,
    },
    button: {
        height: 40,
        width: 80,
        backgroundColor: GREEN,
    },
});
