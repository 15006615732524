import { merge } from 'lodash';
import { RECEIVE_USER, AUTH_FAIL, AUTH_LOAD, LOGOUT, saveUser, removeUser, } from '../actions/userActions';
import { coerceStateToDataFormat } from '../utils';
import { FAILED_STATUS, LOADING_STATUS } from '../utils/constants';
const getInitialState = () => {
    return {
        _persist: { rehydrated: false },
    };
};
const UserReducer = (state = getInitialState(), action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_USER:
            // eslint-disable-next-line no-case-declarations
            const user = merge(newState, action.user);
            saveUser(user); // Anti-pattern
            return user;
        case AUTH_FAIL:
            return { data: { ...newState.data, authStatus: FAILED_STATUS } };
        case AUTH_LOAD:
            return { data: { ...newState.data, authStatus: LOADING_STATUS } };
        case LOGOUT:
            removeUser(); // Anti-pattern
            return getInitialState();
        default:
            return newState;
    }
};
export default UserReducer;
