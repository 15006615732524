import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function QuotesIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 129", fill: "none", ...otherProps },
        React.createElement(Path, { d: "M45.1563 128.443C70.6915 128.443 90.8539 107.739 90.8539 82.2037C90.8539 56.6685 69.8819 35.965 44.3467 35.965C44.0912 35.965 43.7059 35.9996 43.4505 36.0039C53.5468 27.9641 66.2278 23.1194 80.1081 23.1194C86.4941 23.1194 91.6332 17.9414 91.6332 11.5597C91.6332 5.17808 87.5245 3.05176e-05 81.1385 3.05176e-05C35.8133 0.00436401 -0.000114441 36.8785 -0.000114441 82.2037C-0.000114441 82.2124 -0.000114441 82.2211 -0.000114441 82.234C-0.000114441 107.756 19.6297 128.443 45.1563 128.443Z", fill: color }),
        React.createElement(Path, { d: "M153.458 128.443C178.994 128.443 199.191 107.739 199.191 82.2037C199.191 56.6685 178.236 35.965 152.696 35.965C152.441 35.965 152.064 35.9996 151.809 36.0039C161.905 27.9641 174.59 23.1194 188.471 23.1194C194.857 23.1194 200 17.9414 200 11.5597C200 5.17808 195.827 3.05176e-05 189.441 3.05176e-05C144.115 0.00436401 108.237 36.8785 108.237 82.2037C108.237 82.2124 108.237 82.2211 108.237 82.234C108.237 107.756 127.932 128.443 153.458 128.443Z", fill: color })));
}
QuotesIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default QuotesIcon;
