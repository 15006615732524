import { useState, useEffect, useRef, } from 'react';
import { useNavigation } from '@react-navigation/native';
import TrackPlayer, { Capability } from '@utils/libraries/trackPlayer';
import { useStore } from 'react-redux';
import { Alert } from 'react-native';
import Analytics from '@utils/analytics';
import { receiveContinueMeditation } from '@actions/continuePlayingActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { round } from 'lodash';
import { DEFAULT_IMAGE } from '@utils/constants';
import RNFS from '../utils/libraries/fs';
import { filepathToSpacesUrl, imageToSpacesUrl } from '../utils/request';
import { STOP_SAVING_POS_PERCENTAGE } from './useBackgroundTimer';
const Capabilities = [
    Capability.Play,
    Capability.Pause,
    Capability.Stop,
    Capability.JumpBackward,
    Capability.JumpForward,
];
// should this be composed into different hooks?
const useAudio = ({ alertIfEmptyMeditation, analyticsIfEmptyMeditation, autoplay, meditation, playlist, user, }) => {
    const store = useStore();
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [restoredTrack, setRestoredTrack] = useState(false);
    const [seekedToSaved, setSeekedToSaved] = useState(false);
    const [trackStartDate, setTrackStartDate] = useState(null);
    const [trackPositionKey, setTrackPositionKey] = useState('');
    const [playbackRate, setPlaybackRate] = useState(1);
    const [playbackPosition, setPlaybackPosition] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    // sliding
    const [sliderPosition, setSliderPosition] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [initiatedBySlider, setInitiatedBySlider] = useState(false);
    const addTrackToTrackPlayer = async () => {
        if (!meditation)
            return;
        const duration = meditation.length_sec;
        const { filepath } = meditation;
        const track = {
            artist: 'Stoa',
            id: meditation.id.toString(),
            title: meditation.title,
            duration,
            artwork: imageToSpacesUrl(meditation.image || playlist?.image || DEFAULT_IMAGE),
            url: '',
        };
        const localFilepath = `${RNFS.DocumentDirectoryPath}/${filepath}`;
        const exists = await RNFS.exists(localFilepath);
        if (exists) {
            track.url = `file:///${localFilepath}`;
        }
        else {
            track.url = filepathToSpacesUrl(filepath);
        }
        await TrackPlayer.add([track]);
    };
    // Load meditation audio
    useEffect(() => {
        if (!meditation) {
            if (analyticsIfEmptyMeditation) {
                Analytics.recordError(Analytics.Events.LOADED_EMPTY_MEDITATION, {
                    playlist: playlist?.id || '',
                });
            }
            if (alertIfEmptyMeditation) {
                const navigation = useNavigation();
                Alert.alert('Unable to load meditation.', 'It may have expired or updated.', [
                    {
                        text: 'Ok',
                        onPress: () => navigation.goBack(),
                    },
                ]);
            }
        }
        else {
            const loadMeditationAsync = async () => {
                setRestoredTrack(false);
                await TrackPlayer.reset();
                updatePlaybackPosition(0);
                setIsLoadingAudio(true);
                // Should this be here?
                await TrackPlayer.setupPlayer().then(() => {
                    TrackPlayer.updateOptions({
                        capabilities: Capabilities,
                        backwardJumpInterval: 30,
                        forwardJumpInterval: 30,
                        notificationCapabilities: Capabilities,
                        compactCapabilities: Capabilities,
                        stopWithApp: true,
                    });
                });
                await addTrackToTrackPlayer();
                setIsLoadingAudio(false);
                setTrackStartDate(new Date().toISOString());
                setTrackPositionKey(`${user.id}-meditation-position-${meditation.id}`);
            };
            loadMeditationAsync();
        }
        return () => {
            TrackPlayer.reset();
        };
    }, [meditation?.id]);
    // Set continue playing
    useEffect(() => {
        if (meditation?.id) {
            const { dispatch } = store;
            dispatch(receiveContinueMeditation(meditation.id));
        }
    }, [meditation?.id]);
    useEffect(() => {
        const restoreSavedPositionAsync = async () => {
            if (!meditation || !trackPositionKey || restoredTrack || isLoadingAudio)
                return;
            const positionStr = await AsyncStorage.getItem(trackPositionKey);
            const position = parseInt(positionStr || '0', 10);
            if ((position >= (meditation.length_sec * STOP_SAVING_POS_PERCENTAGE))) {
                await AsyncStorage.removeItem(trackPositionKey);
            }
            else if (position) {
                setPlaybackPosition(position);
                setSliderPosition(position / meditation.length_sec);
                Analytics.event(Analytics.Events.MEDITATION_RESTORE, {
                    meditation_id: meditation.id,
                    position,
                });
            }
            if (autoplay && playlist?.is_theory) {
                setTimeout(play, 1000); // TODO: fix this
            }
            setRestoredTrack(true);
        };
        restoreSavedPositionAsync();
    }, [meditation?.id, trackPositionKey, restoredTrack, isLoadingAudio]);
    const prevIsPlayingRef = useRef(isPlaying);
    useEffect(() => {
        prevIsPlayingRef.current = isPlaying;
    }, [isPlaying]);
    const updatePlaybackPosition = (newPlaybackPosition) => {
        if (!meditation)
            return;
        setPlaybackPosition(newPlaybackPosition);
        setSliderPosition(newPlaybackPosition / meditation.length_sec);
    };
    const seekToSaved = async () => {
        // TrackPlayer doesn't support seeking on iOS w/o starting the track.
        if (!seekedToSaved) {
            const positionStr = await AsyncStorage.getItem(trackPositionKey);
            const position = parseInt(positionStr || '0', 10);
            if (position) {
                await TrackPlayer.seekTo(position);
            }
            setSeekedToSaved(true);
        }
    };
    const play = async () => {
        await TrackPlayer.play();
        await seekToSaved();
        setIsPlaying(true);
    };
    const pause = async () => {
        await TrackPlayer.pause();
        setIsPlaying(false);
    };
    // Add analytic
    const togglePlay = async () => {
        setInitiatedBySlider(false);
        if (TrackPlayer) {
            if (isPlaying) {
                await pause();
            }
            else {
                await play();
            }
        }
    };
    const replay = async (amount) => {
        if (!meditation)
            return;
        const jumpAmount = amount || (meditation.length_sec > 60 * 15 ? 30 : 10);
        const newPlaybackPosition = playbackPosition - jumpAmount < 0
            ? 0 : playbackPosition - jumpAmount;
        if (TrackPlayer) {
            await TrackPlayer.seekTo(newPlaybackPosition);
            updatePlaybackPosition(newPlaybackPosition);
        }
    };
    const forward = async (amount) => {
        if (!meditation)
            return;
        const jumpAmount = amount || (meditation.length_sec > 60 * 15 ? 30 : 10);
        const newPlaybackPosition = playbackPosition + jumpAmount > meditation.length_sec
            ? playbackPosition
            : playbackPosition + jumpAmount;
        if (TrackPlayer) {
            await TrackPlayer.seekTo(newPlaybackPosition);
            updatePlaybackPosition(newPlaybackPosition);
        }
    };
    const incrementPlaybackRate = () => {
        const newRate = playbackRate === 2 ? 0.5 : round(playbackRate + 0.1, 1);
        if (TrackPlayer) {
            TrackPlayer.setRate(newRate);
            setPlaybackRate(newRate);
        }
    };
    const handleSlidingStart = () => {
        setIsSliding(true);
        pause();
    };
    const handleSliderValueChange = (value) => {
        if (!meditation)
            return;
        const newPosition = Math.trunc(value * meditation.length_sec);
        setPlaybackPosition(newPosition);
    };
    const handleSlidingComplete = () => {
        setTimeout(() => {
            setIsSliding(false);
            // is this necessary?
            setInitiatedBySlider(true);
            TrackPlayer.seekTo(playbackPosition);
            prevIsPlayingRef.current && play();
        }, 200);
    };
    return {
        forward,
        handleSliderValueChange,
        handleSlidingComplete,
        handleSlidingStart,
        incrementPlaybackRate,
        initiatedBySlider,
        isLoadingAudio,
        prevIsPlaying: prevIsPlayingRef.current,
        playbackRate,
        isPlaying,
        isSliding,
        pause,
        play,
        playbackPosition,
        replay,
        sliderPosition,
        togglePlay,
        trackPositionKey,
        trackStartDate,
        updatePlaybackPosition,
    };
};
export default useAudio;
