import * as React from 'react';
import { WHITE } from '@utils/colors';
import Svg, { Path } from 'react-native-svg';
const MeaninglessnessIcon = (props) => {
    const { style, ...otherProps } = props;
    const { height = 24, width = 24, color = WHITE } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 24 24", fill: "none", ...otherProps },
        React.createElement(Path, { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M12.021 0.0617104C11.1175 0.226926 10.5023 0.562564 9.71353 1.32062C8.94407 2.06015 8.9261 2.06766 7.8223 2.10964C6.89302 2.14501 6.7474 2.16978 6.17723 2.38965C5.16636 2.77946 4.27555 3.67023 3.88571 4.68104C3.6662 5.25024 3.6412 5.39603 3.60381 6.32602C3.55934 7.43294 3.55089 7.45325 2.81493 8.21891C2.43719 8.61187 2.19911 8.90609 2.06002 9.15176C1.30825 10.4796 1.30825 12.0235 2.06002 13.3513C2.19911 13.597 2.43719 13.8912 2.81493 14.2841C3.55089 15.0498 3.55934 15.0701 3.60381 16.177C3.6412 17.107 3.6662 17.2528 3.88571 17.822C4.27555 18.8328 5.16636 19.7236 6.17723 20.1134C6.74646 20.3329 6.89227 20.3579 7.8223 20.3953C8.92929 20.4398 8.94961 20.4482 9.71531 21.1841C10.1083 21.5618 10.4025 21.7999 10.6482 21.939C11.9761 22.6907 13.5201 22.6907 14.848 21.939C15.0939 21.7998 15.388 21.5616 15.7827 21.1824C16.5515 20.4435 16.5693 20.436 17.6739 20.3942C18.5903 20.3593 18.9206 20.2964 19.4759 20.0509C20.4194 19.6337 21.2438 18.7728 21.6105 17.822C21.83 17.2528 21.855 17.107 21.8924 16.177C21.9369 15.0701 21.9453 15.0498 22.6813 14.2841C23.059 13.8912 23.2971 13.597 23.4362 13.3513C24.1879 12.0235 24.1879 10.4796 23.4362 9.15176C23.2971 8.90609 23.059 8.61187 22.6813 8.21891C21.9453 7.45325 21.9369 7.43294 21.8924 6.32602C21.855 5.39603 21.83 5.25024 21.6105 4.68104C21.2206 3.67023 20.3298 2.77946 19.319 2.38965C18.7547 2.17203 18.6078 2.14712 17.6739 2.11025C16.5692 2.06663 16.5509 2.05893 15.7827 1.32062C15.1585 0.720743 14.7512 0.452139 14.1464 0.241609C13.458 0.0019946 12.6991 -0.0622716 12.021 0.0617104ZM1.68186 19.8398C0.616294 20.049 -0.150863 21.146 0.0251989 22.2086C0.177711 23.1288 0.870136 23.8212 1.79042 23.9737C3.14693 24.1984 4.39757 22.9479 4.17281 21.5914C3.9747 20.3962 2.85575 19.6093 1.68186 19.8398Z", fill: color })));
};
export default MeaninglessnessIcon;
