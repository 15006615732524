import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function LogoutIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 21 21", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M20.8923 9.27246C20.8477 9.16591 20.784 9.06897 20.7028 8.98775L18.0838 6.36873C17.7423 6.02815 17.1904 6.02815 16.8489 6.36873C16.5075 6.71018 16.5075 7.26297 16.8489 7.60356L17.9781 8.73273H13.0998C12.6169 8.73273 12.2266 9.12395 12.2266 9.60601C12.2266 10.0881 12.6169 10.4793 13.0998 10.4793H17.9781L16.8489 11.6085C16.5075 11.9499 16.5075 12.5027 16.8489 12.8433C17.0192 13.0144 17.2427 13.0992 17.4663 13.0992C17.6899 13.0992 17.9135 13.0145 18.0838 12.8433L20.7028 10.2243C20.784 10.1439 20.8477 10.047 20.8923 9.93955C20.9805 9.72656 20.9805 9.48553 20.8923 9.27246Z", fill: color }),
        React.createElement(Path, { d: "M14.8465 12.2261C14.3635 12.2261 13.9732 12.6174 13.9732 13.0994V17.4659H10.48V3.49316C10.48 3.10804 10.2268 2.76746 9.85735 2.65656L6.82438 1.7466H13.9732V6.11309C13.9732 6.59515 14.3635 6.98637 14.8465 6.98637C15.3294 6.98637 15.7197 6.59515 15.7197 6.11309V0.873322C15.7197 0.391222 15.3294 0 14.8465 0H0.873769C0.84233 0 0.814371 0.0130994 0.783833 0.0165789C0.742775 0.0209591 0.705237 0.0279181 0.665939 0.037538C0.574243 0.061117 0.491266 0.0969357 0.41357 0.145813C0.394371 0.158053 0.370792 0.158912 0.352453 0.172871C0.345412 0.178152 0.342792 0.187772 0.335792 0.193012C0.240617 0.268088 0.161161 0.359784 0.103523 0.468959C0.0912836 0.492538 0.0886637 0.517877 0.0790848 0.542316C0.0511257 0.608672 0.0205877 0.67331 0.0101082 0.746667C0.00572805 0.772865 0.0135877 0.797304 0.012728 0.822643C0.0118684 0.840123 0.000488281 0.855842 0.000488281 0.873281V18.3392C0.000488281 18.7557 0.294775 19.1138 0.702617 19.195L9.43555 20.9416C9.49232 20.9539 9.54996 20.9591 9.6067 20.9591C9.80667 20.9591 10.0032 20.8901 10.1604 20.7609C10.3621 20.5949 10.48 20.3478 10.48 20.0858V19.2125H14.8465C15.3294 19.2125 15.7197 18.8213 15.7197 18.3392V13.0994C15.7197 12.6174 15.3294 12.2261 14.8465 12.2261Z", fill: color })));
}
LogoutIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default LogoutIcon;
