import React from 'react';
import { useSelector } from 'react-redux';
import { isLoggedIn } from '../utils/auth';
import { stripPersist } from '../utils';
import RootNavigator from './RootNavigator';
import AuthNavigator from './AuthNavigator';
const AppAuth = () => {
    const user = useSelector((state) => stripPersist(state.user));
    console.log('u', user);
    if (isLoggedIn(user)) {
        return React.createElement(RootNavigator, null);
    }
    console.log('an');
    return React.createElement(AuthNavigator, null);
};
export default AppAuth;
