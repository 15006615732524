// @ts-nocheck
import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import DrawerMenu from '../components/drawerMenu';
import { DRAWER_MENU, HOME_SCREEN } from '../utils/constants';
import HomeScreen from '../components/homeScreen';
const Drawer = createDrawerNavigator();
const DrawerNavigator = () => {
    return (React.createElement(Drawer.Navigator, { drawerContent: (props) => {
            return React.createElement(DrawerMenu, { ...props });
        }, drawerBackgroundColor: "transparent", initialRouteName: HOME_SCREEN, headerMode: "none", drawerType: "back" },
        React.createElement(Drawer.Screen, { name: HOME_SCREEN, component: HomeScreen }),
        React.createElement(Drawer.Screen, { name: DRAWER_MENU, component: DrawerMenu })));
};
export default DrawerNavigator;
