import { purchaseSubscription, unsubscribeUser } from '@actions/userActions';
import Analytics from '@utils/analytics';
import { IS_DEV } from '@utils/constants';
import { ANNUAL_PACKAGE_TYPE, DEFAULT_OFFERINGS_WITH_TRIAL_ID, DevPackages, FULL_SUBSCRIPTION_ENTITLEMENT, } from '@utils/subscription';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Purchases from 'react-native-purchases';
import { useStore } from 'react-redux';
const PurchaseService = () => {
    const checkSubscriptionStatus = async () => {
        try {
            const purchaserInfo = await Purchases.getPurchaserInfo();
            const entitlementInfo = purchaserInfo.entitlements.active[FULL_SUBSCRIPTION_ENTITLEMENT];
            const isSubscribed = typeof entitlementInfo !== 'undefined';
            const hasAnyPurchases = !isEmpty(purchaserInfo.entitlements.all);
            return {
                isSubscribed,
                hasAnyPurchases,
                entitlementInfo,
                purchaserInfo,
            };
        }
        catch (e) {
            // Error fetching purchaser info
            Analytics.recordError(e);
        }
    };
    const useSubscriptionStatus = ({ refresh = 0 }) => {
        const [isSubscribed, setIsSubscribed] = useState(false);
        useEffect(() => {
            const check = async () => {
                const status = await checkSubscriptionStatus();
                setIsSubscribed(status?.isSubscribed ?? false);
            };
            check();
        }, [refresh]);
        return {
            isSubscribed,
        };
    };
    const useUpdateSubscriptionStatus = ({ user }) => {
        const { dispatch } = useStore();
        useEffect(() => {
            const checkAndUpdate = async () => {
                const status = await checkSubscriptionStatus();
                if (!status)
                    return;
                const { isSubscribed, hasAnyPurchases, entitlementInfo, } = status;
                // TODO: add subscription expiring soon warning
                if (isSubscribed && !user.is_subscribed) {
                    purchaseSubscription(user, entitlementInfo)(dispatch);
                }
                else if (!isSubscribed && user.is_subscribed && hasAnyPurchases) {
                    unsubscribeUser(user)(dispatch);
                }
            };
            checkAndUpdate();
        }, []);
    };
    const useProductPackages = () => {
        const [productPackages, setProductPackages] = useState(null);
        const [selectedPackage, setSelectedPackage] = useState(null);
        useEffect(() => {
            const getProducts = async () => {
                if (IS_DEV) {
                    setProductPackages(DevPackages);
                    setSelectedPackage(DevPackages[ANNUAL_PACKAGE_TYPE]);
                    return;
                }
                try {
                    const offerings = await Purchases.getOfferings();
                    if (offerings.all[DEFAULT_OFFERINGS_WITH_TRIAL_ID] !== null) {
                        const newProductPackages = {};
                        offerings.all[DEFAULT_OFFERINGS_WITH_TRIAL_ID].availablePackages.forEach((productPackage) => {
                            newProductPackages[productPackage.packageType] = productPackage;
                        });
                        setProductPackages(newProductPackages);
                        setSelectedPackage(newProductPackages[ANNUAL_PACKAGE_TYPE]);
                    }
                }
                catch (err) {
                    console.log(err);
                }
            };
            getProducts();
        }, []);
        return {
            productPackages,
            selectedPackage,
            setSelectedPackage,
        };
    };
    return {
        useProductPackages,
        useSubscriptionStatus,
        useUpdateSubscriptionStatus,
    };
};
export default PurchaseService;
