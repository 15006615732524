import React from 'react';
import { Linking, StyleSheet, TouchableOpacity, View, } from 'react-native';
import BaseText from '@components/baseComponents/baseText';
import { COLORS, TERTIARY_TEXT } from '../../utils/colors';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    learnMore: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    learnMoreText: {
        fontSize: Fonts.size.l,
        alignItems: 'center',
        opacity: 0.6,
    },
});
const MeditationLink = ({ mode, meditation }) => {
    const { link, detail } = meditation;
    if (!detail)
        return null;
    const onPress = link ? () => Linking.openURL(link) : () => { };
    const textStyle = [styles.learnMoreText, { color: COLORS[TERTIARY_TEXT][mode] }];
    return (React.createElement(View, { style: styles.learnMore }, onPress ? (React.createElement(TouchableOpacity, { onPress: onPress },
        React.createElement(BaseText, { style: textStyle }, detail))) : (React.createElement(BaseText, { style: textStyle }, detail))));
};
export default MeditationLink;
