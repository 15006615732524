import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function AppleIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 244", ...otherProps },
        React.createElement(Path, { d: "M66.3085 243.836C30.0185 243.627 0 169.546 0 131.819C0 70.1914 46.2311 56.6994 64.0483 56.6994C72.0777 56.6994 80.6515 59.8526 88.2137 62.6429C93.5019 64.5893 98.9711 66.5986 102.013 66.5986C103.834 66.5986 108.124 64.8894 111.912 63.3897C119.99 60.1736 130.043 56.1765 141.749 56.1765C141.77 56.1765 141.798 56.1765 141.819 56.1765C150.559 56.1765 177.062 58.0948 192.996 82.0231L196.728 87.6318L191.357 91.6851C183.683 97.4752 169.682 108.037 169.682 128.959C169.682 153.738 185.538 163.268 193.156 167.851C196.519 169.874 200 171.96 200 176.523C200 179.502 176.225 243.466 141.7 243.466C133.252 243.466 127.28 240.927 122.013 238.688C116.683 236.42 112.086 234.467 104.489 234.467C100.638 234.467 95.7684 236.288 90.613 238.22C83.568 240.85 75.5938 243.836 66.5457 243.836H66.3085Z", fill: color }),
        React.createElement(Path, { d: "M145.407 0C146.306 32.4206 123.12 54.9126 99.9612 53.5019C96.1451 27.6292 123.117 0 145.407 0Z", fill: color })));
}
AppleIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default AppleIcon;
