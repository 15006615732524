import { ANGER_ANSWER, ANXIETY_ANSWER, GRIEF_ANSWER, IRRATIONALITY_ANSWER, MEANINGLESSNESS_ANSWER, VICE_ANSWER, } from '@components/onboarding_ii/QuizIIScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { QuizIAnswerKey, QuizIIAnswerKey } from '@utils/asyncKeys';
import { arrToIdObj, idObjToArr } from '@utils/index';
import { isEmpty, flatten, sample } from 'lodash';
const HOME_SCREEN_LENGTH = 10;
const EVENING_ID = 12;
const CONVERSATIONS_ID = 18;
const MORNING_ID = 13;
export const TIMER_ID = 24;
const FREE_THEORY_IDS = [32, 33, 34, 36];
const ACCEPTANCE_ID = 30;
const STOA_PURPOSE_ID = 32;
const ANGER_ID = 39;
const CALM_ID = 37;
const RATIONALITY = 42;
const MEANING_THEORY_ID = 348;
export const INTRO_IDS = [6, 26];
export const FREE_PLAYLIST_IDS = [
    ...INTRO_IDS,
    EVENING_ID,
    MORNING_ID,
    TIMER_ID,
    ...FREE_THEORY_IDS,
];
const CONVERSATIONS_NAME = 'conversations';
const EVENING_NAME = 'evening routine';
const MORNING_NAME = 'morning routine';
const DAILY_NAME = 'daily meditation';
export const playlistsToSortedArray = (playlists) => {
    if (isEmpty(playlists))
        return [];
    return idObjToArr(playlists).sort((a, b) => a.index - b.index);
};
export const sortedNextPlaylistsArray = (playlists) => {
    if (isEmpty(playlists))
        return [];
    return idObjToArr(playlists)
        .filter((playlist) => playlist.is_eligible_for_next)
        .sort((a, b) => a.index - b.index);
};
export const sortedTheoryPlaylistsArray = (playlists) => sortedNextPlaylistsArray(playlists).filter((playlist) => playlist.is_theory);
export const sortedEligiblePlaylistsArray = (playlists) => sortedNextPlaylistsArray(playlists).filter((playlist) => !playlist.is_theory);
export const getDailyMeditation = (playlists) => {
    const dailyPlaylist = idObjToArr(playlists).filter((playlist) => playlist.title.toLowerCase().includes(DAILY_NAME))[0];
    if (!dailyPlaylist) {
        return null;
    }
    const meditationId = Object.keys(dailyPlaylist.meditations)[0];
    const meditation = dailyPlaylist.meditations[meditationId];
    if (meditation && !meditation.is_listened)
        return meditation;
    return null;
};
export const getNextMeditation = async (playlists, listenHistory) => {
    if (isEmpty(playlists)) {
        return null;
    }
    let nextMeditationByListenHistory = null;
    const answer = await AsyncStorage.getItem(QuizIAnswerKey);
    if (!isEmpty(listenHistory)) {
        const meditations = getMeditationsObj(playlists);
        let lastMeditation = null;
        let i = 0;
        while (lastMeditation === null && listenHistory && i < listenHistory?.length) {
            const record = listenHistory[i];
            if (!record)
                continue;
            const meditation = meditations[record.meditation_id];
            const playlist = meditation ? playlists[meditation.playlist_id] : null;
            if (!meditation?.is_listened
                && (meditation?.is_eligible_for_next || playlist?.is_eligible_for_next)
                && !(answer === 'Advanced' && INTRO_IDS.includes(playlist?.id || 0))) {
                lastMeditation = meditation;
            }
            i++;
        }
        if (lastMeditation) {
            const playlist = playlists[lastMeditation.playlist_id];
            const playlistMeditations = getMeditationsFromPlaylist(playlist);
            const lastIndex = playlistMeditations.findIndex((m) => m.id === lastMeditation?.id);
            if (lastIndex !== -1 && lastIndex !== undefined && lastIndex !== null) {
                nextMeditationByListenHistory = playlistMeditations[lastIndex + 1];
            }
        }
    }
    const playlistsArr = sortedEligiblePlaylistsArray(playlists);
    let nextMeditation = null;
    for (const playlist of playlistsArr) {
        if (playlist.is_daily)
            continue;
        const meditations = getMeditationsFromPlaylist(playlist);
        for (const meditation of meditations) {
            if (!meditation.is_listened && nextMeditation === null)
                nextMeditation = meditation;
        }
    }
    const randomMeditation = getRandomMeditation(playlistsArr);
    return nextMeditationByListenHistory || nextMeditation || randomMeditation;
};
let randomMeditation = null;
export const getRandomMeditation = (playlistsArr) => {
    if (randomMeditation)
        return randomMeditation;
    const randomPlaylist = sample(playlistsArr) || null;
    if (randomPlaylist) {
        const meditations = getMeditationsFromPlaylist(randomPlaylist);
        randomMeditation = sample(meditations) || null;
    }
    return randomMeditation;
};
export const getMeditationsFromPlaylist = (playlist) => {
    if (isEmpty(playlist))
        return [];
    return idObjToArr(playlist.meditations);
};
export const getMeditations = (playlists) => {
    const playlistsArr = sortedEligiblePlaylistsArray(playlists);
    return flatten(playlistsArr.map((playlist) => getMeditationsFromPlaylist(playlist)));
};
export const getMeditationsObj = (playlists) => {
    const meditationsObj = {};
    const meditations = getAllMeditations(playlists);
    meditations.forEach((meditation) => {
        meditationsObj[meditation.id] = meditation;
    });
    return meditationsObj;
};
export const getAllMeditations = (playlists) => {
    if (isEmpty(playlists))
        return [];
    const meditationsArr = [];
    idObjToArr(playlists).forEach((playlist) => {
        const { meditations } = playlist;
        Object.keys(meditations).forEach((id) => {
            const meditation = meditations[id];
            meditationsArr.push(meditation);
        });
    });
    return meditationsArr;
};
export const getFavoriteMeditations = (playlists) => {
    const meditations = getAllMeditations(playlists);
    return meditations.filter((meditation) => meditation.is_favorite);
};
export const getDownloadedMeditations = (playlists) => {
    const meditations = getAllMeditations(playlists);
    return meditations.filter((meditation) => meditation.is_downloaded);
};
export const getListenAgain = (playlists) => {
    const meditations = getMeditations(playlists).filter((meditation) => meditation.id !== 10 && meditation.listens > 0);
    return meditations.sort((a, b) => b.listens - a.listens).slice(0, HOME_SCREEN_LENGTH);
};
const getPlaylistByIdOrName = (playlists, id, name) => {
    if (isEmpty(playlists))
        return null;
    let playlist = playlists[id];
    if (!playlist) {
        playlist = getPlaylistByName(playlists, name);
    }
    // not found
    if (!playlist) {
        return null;
    }
    return playlist;
};
const getPlaylistByName = (playlists, name) => {
    const playlistsArr = playlistsToSortedArray(playlists);
    return playlistsArr.filter((playlist) => playlist.title.toLowerCase() === name)[0];
};
export const getEveningMeditations = (playlists) => {
    const playlist = getPlaylistByIdOrName(playlists, EVENING_ID, EVENING_NAME);
    const meditations = playlist
        ? getMeditationsFromPlaylist(playlist).filter((conversation) => !conversation.is_listened)
        : [];
    return sortMeditationsByUnlistened(meditations).slice(0, HOME_SCREEN_LENGTH);
};
export const getMorningMeditations = (playlists) => {
    const playlist = getPlaylistByIdOrName(playlists, MORNING_ID, MORNING_NAME);
    const meditations = playlist
        ? getMeditationsFromPlaylist(playlist).filter((conversation) => !conversation.is_listened)
        : [];
    return sortMeditationsByUnlistened(meditations).slice(0, HOME_SCREEN_LENGTH);
};
const sortMeditationsByUnlistened = (meditations) => {
    if (isEmpty(meditations))
        return [];
    meditations.sort((a, b) => (a.is_listened ? 1 : -1) - (b.is_listened ? 1 : -1));
    meditations.sort((a, b) => a.id - b.id);
    return meditations;
};
export const getRecommendedConversations = (playlists) => {
    let playlist = playlists[CONVERSATIONS_ID];
    if (!playlist) {
        playlist = getPlaylistByName(playlists, CONVERSATIONS_NAME);
    }
    // Conversations not found
    if (!playlist) {
        return [];
    }
    const conversations = getMeditationsFromPlaylist(playlist).filter((conversation) => !conversation.is_listened);
    conversations.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    return conversations.slice(0, HOME_SCREEN_LENGTH);
};
export const getHomeScreenPlaylists = (playlists) => {
    const playlistsArr = sortedTheoryPlaylistsArray(playlists);
    const unlistenedPlaylists = getUnlistenedPlaylists(playlistsArr);
    if (unlistenedPlaylists.length > 0) {
        return unlistenedPlaylists.slice(0, HOME_SCREEN_LENGTH);
    }
    return playlistsArr.slice(0, HOME_SCREEN_LENGTH);
};
export const getUnlistenedPlaylists = (playlistsArr) => {
    const unlistenedPlaylistsArr = [];
    playlistsArr.forEach((playlist) => {
        for (const meditation of getMeditationsFromPlaylist(playlist)) {
            if (!meditation.is_listened) {
                unlistenedPlaylistsArr.push(playlist);
                return;
            }
        }
    });
    return unlistenedPlaylistsArr;
};
const meditationsHasUnlistened = (meditations) => !isEmpty(idObjToArr(meditations).filter((m) => !m.is_listened));
export const getSuggestedMeditationsFromOnboarding = async (playlists) => {
    const answersJSON = await AsyncStorage.getItem(QuizIIAnswerKey);
    const answers = answersJSON ? answersJSON.replace(/\]|\[/ig, '').split(',') : null;
    if (!answers)
        return null;
    let meditations;
    let sortBy;
    for (const answer of answers) {
        switch (answer) {
            case ANXIETY_ANSWER:
                meditations = playlists[CALM_ID]?.meditations;
                break;
            case IRRATIONALITY_ANSWER:
                meditations = playlists[RATIONALITY]?.meditations;
                break;
            case ANGER_ANSWER:
                meditations = playlists[ANGER_ID]?.meditations;
                break;
            case VICE_ANSWER:
                meditations = arrToIdObj(getAllMeditations(playlists).filter((m) => m.title.toLowerCase().includes('virtue')));
                break;
            case MEANINGLESSNESS_ANSWER:
                meditations = {
                    ...{ [MEANING_THEORY_ID]: playlists[STOA_PURPOSE_ID]?.meditations?.[MEANING_THEORY_ID] },
                    ...(playlists[MORNING_ID]?.meditations || {}),
                };
                sortBy = (a) => (a.title === 'Meaning' ? -1 : 0);
                break;
            case GRIEF_ANSWER:
                meditations = playlists[ACCEPTANCE_ID]?.meditations;
                break;
            default:
                break;
        }
        if (meditations && meditationsHasUnlistened(meditations)) {
            const meditationsArr = sortMeditationsByUnlistened(idObjToArr(meditations));
            if (sortBy) {
                meditationsArr.sort(sortBy);
            }
            return meditationsArr;
        }
    }
    return null;
};
