import React from 'react';
import { CHAPTER_SCREEN } from '@utils/constants';
import Analytics from '@utils/analytics';
import Row from './Row';
const ChapterRow = ({ book, chapter, mode, navigation, index, }) => {
    const handleOnPress = () => {
        navigation.navigate(CHAPTER_SCREEN, {
            chapterId: chapter.id,
            book,
        });
        Analytics.event(Analytics.Events.CHAPTER_ROW_TOUCH, { bookName: chapter.title, bookId: chapter.id });
    };
    return (React.createElement(Row, { handleOnPress: handleOnPress, index: index, key: chapter.id, mode: mode, subtitle: chapter.subtitle, title: chapter.title }));
};
export default ChapterRow;
