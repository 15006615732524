import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function PlayIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 25 42", fill: color, ...otherProps },
        React.createElement(Path, { d: "M36 21L-1.9576e-06 41.7846L-1.40549e-07 0.21539L36 21Z", fill: color })));
}
PlayIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default PlayIcon;
