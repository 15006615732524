import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function HomeIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 19 18", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M18.5681 7.99986L10.0949 0.279112C9.72299 -0.0598263 9.16215 -0.0597894 8.79038 0.279075L0.317141 7.9999C0.0192188 8.27137 -0.079191 8.68965 0.0663582 9.06547C0.211944 9.4413 0.566411 9.68411 0.969457 9.68411H2.32278V17.4197C2.32278 17.7264 2.57146 17.9751 2.87816 17.9751H7.52253C7.82923 17.9751 8.07791 17.7265 8.07791 17.4197V12.7229H10.8075V17.4198C10.8075 17.7265 11.0562 17.9751 11.3629 17.9751H16.007C16.3137 17.9751 16.5624 17.7265 16.5624 17.4198V9.68411H17.916C18.319 9.68411 18.6735 9.44126 18.8191 9.06547C18.9644 8.68961 18.866 8.27137 18.5681 7.99986Z", fill: color }),
        React.createElement(Path, { d: "M16.4173 1.13348H12.6875L16.9727 5.02989V1.68883C16.9727 1.38213 16.724 1.13348 16.4173 1.13348Z", fill: color })));
}
HomeIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default HomeIcon;
