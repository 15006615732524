import { StyleSheet } from 'react-native';
import BaseStyles from './baseStyles';
import { Fonts } from './fonts';
import { CARD_IMAGE_HEIGHT } from './styles';
const WebBaseStyles = StyleSheet.create({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: Fonts.size.xxl,
        paddingTop: 2,
        paddingBottom: 10,
    },
    homeSectionContainer: {
        flex: 1,
        marginTop: 0,
        paddingTop: 2,
        paddingBottom: 20,
        paddingRight: 5,
        paddingLeft: 5,
        minHeight: 150,
        maxHeight: 300,
        flexBasis: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
    },
    homeCarousel: {
        minHeight: CARD_IMAGE_HEIGHT,
    },
});
const MergedBaseStyles = {
    ...BaseStyles,
    ...WebBaseStyles,
};
export default MergedBaseStyles;
