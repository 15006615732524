import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Image, StyleSheet, View, } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import Header from '@components/baseComponents/header';
import { COLORS, LIGHT_GRAY, SECONDARY_TEXT, WHITE, } from '../../utils/colors';
import { PHILOSOPHY_SCREEN } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
        textAlign: 'center',
    },
    quotesContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
        marginBottom: 20,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    padding: {
        marginTop: 279 / 1.25,
    },
    screenshotContainer: {
        position: 'absolute',
        top: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    screenshotStyle: {
        width: 229 / 1.25,
        height: 279 / 1.25,
    },
    pitchContainer: {
        marginTop: 225 / 1.25,
    },
});
const PitchScreen = ({ mode, navigation, }) => {
    useEffect(() => {
        Analytics.screenHit('Welcome', 'PitchScreen');
    }, []);
    useHandleLoggedInOrLoadedUser();
    return (React.createElement(OnboardingBackground, null,
        React.createElement(View, { style: styles.screenshotContainer },
            React.createElement(Image, { source: require('@assets/images/screenshot.png'), style: styles.screenshotStyle })),
        React.createElement(Header, { onPressCallback: () => navigation.goBack(), title: "", mode: mode, color: WHITE }),
        React.createElement(View, { style: styles.pitchContainer },
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Not just another wellness and meditation app.")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Stoa has a comprehensive focus.")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Other apps focus on techniques and lifehacks, Stoa provides theory and practice for a philosophy of life."))),
        React.createElement(ContinueFooter, { nextScreenName: PHILOSOPHY_SCREEN, index: 1 })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(PitchScreen);
