import merge from 'lodash/merge';
import { RECEIVE_QUOTES, RECEIVE_QUOTE } from '../actions/quoteActions';
import { LOGOUT } from '../actions/userActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {
    data: [],
};
const QuotesReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_QUOTES:
            return {
                data: action.quotes,
            };
        case RECEIVE_QUOTE:
            if (action.quote) {
                newState.data[action.quote.id] = action.quote;
            }
            return newState;
        case LOGOUT:
            return newState;
        default:
            return newState;
    }
};
export default QuotesReducer;
