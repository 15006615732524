import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, TouchableOpacity, View, Platform, } from 'react-native';
import FastImage from '../../utils/libraries/fastImage';
import BaseStyles from '../../utils/baseStyles';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { PLAYLIST_NAV_CONFIG } from '../../utils/constants';
import { BLACK, COLORS, SECONDARY_TEXT, BLUE, TRANSPARENT_BACKGROUND, TRANSPARENT, } from '../../utils/colors';
import { setPlaylistId } from '../../actions/playlistActions';
import { imageToSpacesUrl } from '../../utils/request';
import { hitSlop, stripPersist } from '../../utils';
import { CARD_IMAGE_HEIGHT } from '../../utils/styles';
import Analytics from '../../utils/analytics';
import { ChevronRightIcon } from '../../assets/svg';
import BaseText from './baseText';
import { Fonts } from '../../utils/fonts';
import BaseOverlay from './BaseOverlay';
const styles = StyleSheet.create({
    container: {
        borderRadius: IMAGE_BORDER_RADIUS,
        height: 166,
        width: 263,
        overflow: 'hidden',
        marginRight: Platform.OS === 'web' ? 6 : 0,
    },
    bgImage: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flex: 1,
        backgroundColor: BLUE,
    },
    bgImageStyle: {
        borderRadius: IMAGE_BORDER_RADIUS,
        opacity: 0.8,
    },
    titleContainer: {
        padding: 6,
        alignSelf: 'flex-start',
    },
    descriptionContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingHorizontal: 8,
        paddingVertical: 6,
    },
    descriptionText: {
        padding: 5,
        fontWeight: 'bold',
    },
    titleText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    subtitleText: {
        fontSize: Fonts.size.s,
        fontWeight: '400',
        paddingTop: 3,
        alignSelf: 'flex-start',
        textAlign: 'left',
    },
    contentContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 166,
        width: 263,
        zIndex: 50,
        padding: 8,
    },
    overlay: {
        opacity: 0.4,
        backgroundColor: BLACK,
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: IMAGE_BORDER_RADIUS,
        height: 166,
        width: 263,
    },
    topRow: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 6,
        alignItems: 'center',
        zIndex: 50,
    },
});
const PlaylistCard = (props) => {
    const navigationConfig = props.isTheory
        ? PLAYLIST_NAV_CONFIG.theory
        : PLAYLIST_NAV_CONFIG.meditation;
    return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: [BaseStyles.flexCol, styles.container, { minHeight: CARD_IMAGE_HEIGHT }], onPress: () => {
            props.setPlaylistId(props.playlist.id);
            props.navigation.navigate(navigationConfig.navigator, {
                screen: navigationConfig.screen,
                params: { isSourceHome: true },
            });
            Analytics.event(Analytics.Events.PLAYLIST_CARD_TOUCH, {
                playlist_id: props.playlist.id,
                playlist_title: props.playlist.title,
            });
        } },
        React.createElement(View, { style: styles.container },
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(props.playlist.image) }, style: styles.bgImage, 
                // @ts-ignore
                imageStyle: styles.bgImageStyle },
                React.createElement(BaseOverlay, null),
                React.createElement(View, { style: styles.overlay }),
                React.createElement(View, { style: styles.contentContainer },
                    React.createElement(View, { style: styles.topRow },
                        React.createElement(View, { style: [
                                styles.descriptionContainer,
                                {
                                    backgroundColor: props.description
                                        ? COLORS[TRANSPARENT_BACKGROUND][props.mode]
                                        : TRANSPARENT,
                                },
                            ] },
                            React.createElement(BaseText, { style: { color: COLORS[SECONDARY_TEXT][props.mode] } }, props.description)),
                        React.createElement(ChevronRightIcon, { style: { color: COLORS[SECONDARY_TEXT][props.mode], height: 16, width: 16 } })),
                    React.createElement(View, { style: styles.titleContainer },
                        React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, props.playlist.title),
                        React.createElement(BaseText, { style: [styles.subtitleText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, props.playlist.subtitle)))))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    setPlaylistId: (id) => dispatch(setPlaylistId(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistCard);
