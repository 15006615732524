import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
function DownloadIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 212", ...otherProps },
        React.createElement(Path, { d: "M199.6 151.971C199.821 151.971 200 152.15 200 152.371V202.848C200 207.703 196.083 211.62 191.228 211.62H8.77193C3.92566 211.62 0 207.697 0 202.848V152.371C0 152.15 0.179086 151.971 0.4 151.971H17.1439C17.3648 151.971 17.5439 152.15 17.5439 152.371V194.076H182.456V152.371C182.456 152.15 182.635 151.971 182.856 151.971L199.6 151.971Z", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M58.2669 100.724L98.0532 140.511C100.314 142.772 103.98 142.772 106.241 140.511L146.027 100.724C148.288 98.4635 148.288 94.7978 146.027 92.5369L140.422 86.9321C138.161 84.6712 134.496 84.6712 132.235 86.9321L111.895 107.272L111.9 5.78946C111.9 2.91177 109.8 0.524445 107.049 0.0757599L106.11 -1.52588e-05H98.1837C94.9863 -1.52588e-05 92.3943 2.59203 92.3943 5.78946L92.3895 107.272L72.0593 86.9321C70.0495 84.9224 66.9299 84.6991 64.6735 86.2622L63.8717 86.9321L58.2669 92.5369C56.006 94.7978 56.006 98.4635 58.2669 100.724Z", fill: color })));
}
export default DownloadIcon;
