import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState, BackHandler, Platform, StyleSheet, View, } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isEmpty, merge } from 'lodash';
import { augmentMeditation, getMeditationAfter, hasMultipleFiles } from '@selectors/meditations';
import TrackPlayer from '@utils/libraries/trackPlayer';
import { receiveContinueMeditation, removeContinueMeditation, } from '@actions/continuePlayingActions';
import useAuthors from '@services/useAuthors';
import MeditationService from '@services/MeditationService';
import useAudio from '@services/useAudio';
import useBackgroundTimer, { REQ_PERCENTAGE_COMPLETE } from '@services/useBackgroundTimer';
import QuoteService from '../../services/QuoteService';
import { recordListen, setAndGoToMeditation } from '../../actions/meditationActions';
import { receiveListensAction, uploadListens } from '../../actions/listensActions';
import { getDeviceOrientation } from '../../actions/dimensionsActions';
import { receiveNotification } from '../../actions/statsActions';
import Header from '../baseComponents/header';
import Modal from '../baseComponents/modal';
import { stripPersist } from '../../utils';
import { HOME_SCREEN, ORIENTATION } from '../../utils/constants';
import { BACKGROUND, COLORS, BLUE } from '../../utils/colors';
import { saveMindfulSession, healthKitPermissions } from '../../utils/healthKit';
import MeditationModal from '../baseComponents/meditationModal';
import Analytics from '../../utils/analytics';
import { isMeditationDownloadedAndUpdate } from '../../actions/playlistsActions';
import { Fonts } from '../../utils/fonts';
import MeditationContentPortrait from './MeditationContentPortrait';
import MeditationContentLandscape from './MeditationContentLandscape';
const styles = StyleSheet.create({
    container: {
        backgroundColor: BLUE,
        flex: 1,
    },
    bg: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    learnMore: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    learnMoreText: {
        fontSize: Fonts.size.l,
        alignItems: 'center',
        opacity: 0.4,
    },
    blankSection: {
        flex: 1,
        zIndex: 1,
    },
    title: {
        paddingBottom: 20,
        minHeight: 33,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    titleText: {
        fontSize: 26,
        fontWeight: '700',
    },
});
const MeditationScreenV2 = (props) => {
    const [isEnded, setIsEnded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const { useFetchAndUpdateQuotes, useMeditationQuote } = QuoteService();
    useFetchAndUpdateQuotes({ user: props.user, quotes: props.quotes });
    useMeditationQuote({
        meditation: props.meditation,
        quotes: props.quotes,
        quote: props.quote,
    });
    useAuthors({ user: props.user, authors: props.authors });
    const { useIsMeditationDownloaded } = MeditationService();
    useIsMeditationDownloaded({ meditation: props.meditation });
    const { forward, handleSliderValueChange, handleSlidingComplete, handleSlidingStart, incrementPlaybackRate, initiatedBySlider, isLoadingAudio, isPlaying, isSliding, playbackPosition, playbackRate, prevIsPlaying, replay, sliderPosition, togglePlay, trackPositionKey, trackStartDate, updatePlaybackPosition, } = useAudio({
        alertIfEmptyMeditation: true,
        autoplay: props.autoplay,
        analyticsIfEmptyMeditation: true,
        meditation: props.meditation,
        playlist: props.playlist,
        user: props.user,
    });
    const handleReplayPress = () => {
        replay();
        Analytics.event(Analytics.Events.MEDITATION_REPLAY_PRESS, {
            meditation_id: props.meditation.id,
            meditation_title: props.meditation.title,
            // position: newPlaybackPosition,
        });
    };
    const handleForwardPress = () => {
        forward();
        Analytics.event(Analytics.Events.MEDITATION_FORWARD_PRESS, {
            meditation_id: props.meditation.id,
            meditation_title: props.meditation.title,
        });
    };
    const handleBackPress = () => {
        props.navigation.goBack();
        Analytics.event(Analytics.Events.MEDITATION_BACKPRESS, {
            is_playing: isPlaying,
            meditation_id: props.meditation.id,
            meditation_title: props.meditation.title,
            position: playbackPosition,
        });
        return true;
    };
    const handleAppStateChange = async (state) => {
        const percentageComplete = playbackPosition / props.meditation.length_sec;
        if (state === 'active' && percentageComplete > REQ_PERCENTAGE_COMPLETE && !isModalOpen) {
            handleMeditationEnd();
        }
    };
    useEffect(() => {
        Analytics.screenHit('Meditation', 'MeditationScreen');
        BackHandler.addEventListener('hardwareBackPress', handleBackPress);
        if (TrackPlayer) {
            TrackPlayer.setRate(1);
        }
        if (Platform.OS !== 'web') {
            AppState.addEventListener('change', handleAppStateChange);
        }
        // loadMeditation
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
            if (Platform.OS !== 'web') {
                AppState.removeEventListener('change', handleAppStateChange);
            }
        };
    }, []);
    //   Handle new meditation
    useEffect(() => {
        if (props.autoplay && props.meditation.is_theory) {
            if (isEnded) {
                setIsEnded(false);
            }
        }
    }, [props.meditation?.id]);
    const handleMeditationEnd = async () => {
        if (!isEnded) {
            setIsEnded(true);
            // Buffer ending
            setTimeout(() => {
                if (Platform.OS === 'ios') {
                    healthKitPermissions();
                    saveMindfulSession(trackStartDate, new Date().toISOString());
                }
                Analytics.event(Analytics.Events.MEDITATION_END, {
                    meditation_id: props.meditation.id,
                    meditation_title: props.meditation.title,
                });
                const nextMeditation = getMeditationAfter(props.meditation.id, props.playlist?.meditations);
                if (props.autoplay && nextMeditation && props.playlist?.is_theory) {
                    setIsEnded(false);
                    props.setAndGoToMeditation(nextMeditation, props.navigation, props.playlist.is_theory);
                }
                else {
                    setIsModalOpen(true);
                }
                AsyncStorage.removeItem(trackPositionKey);
            }, 3000);
        }
    };
    //   This should potentially be moved to a hook or service
    const handleRecordListen = (meditation) => {
        const newMeditation = merge({}, meditation);
        newMeditation.is_listened = true;
        props.receiveListensAction(meditation.id);
        props.recordListen(newMeditation);
        props.statsNotification();
        // TODO: this should create user mediations at the correct time
        const listenedMeditationIds = props.listenedMeditationIds.slice();
        if (!listenedMeditationIds.includes(meditation.id)) {
            listenedMeditationIds.push(meditation.id);
        }
        props.uploadListens(props.user, listenedMeditationIds);
        Analytics.event(Analytics.Events.MEDITATION_RECORD_LISTEN, {
            meditation_id: meditation.id,
            meditation_title: meditation.title,
            autoplay: props.autoplay,
            meditation_len: props.meditationLen,
            length_sec: meditation.length_sec,
        });
    };
    const handleRecordedEnd = async (meditation) => {
        handleRecordListen(meditation);
        props.removeContinuePlaying();
        AsyncStorage.removeItem(`${props.user.id}-meditation-position-${meditation.id}`);
    };
    useBackgroundTimer({
        positionKey: trackPositionKey,
        meditationLengthSec: props.meditation.length_sec,
        updatePlaybackPosition,
        handleMeditationEnd,
        handleRecordedEnd,
        isSliding,
        isPlaying,
        meditation: props.meditation,
    });
    if (isEmpty(props.meditation))
        return null;
    const orientation = getDeviceOrientation();
    const { deviceHeight, deviceWidth } = props.deviceDimensions;
    const size = orientation === ORIENTATION.PORTRAIT
        ? Math.floor((deviceHeight * 0.35 + deviceWidth * 0.9) / 2)
        : 100;
    return (React.createElement(View, { style: [
            styles.container,
            {
                backgroundColor: COLORS[BACKGROUND][props.mode],
            },
        ] },
        React.createElement(Header, { title: `${props.meditation.title}`, onPressCallback: handleBackPress, secondaryOnPressCallback: () => setIsActionModalOpen(true), hasVerticalDots: true, mode: props.mode }),
        React.createElement(View, { style: styles.bg },
            orientation === ORIENTATION.PORTRAIT ? (React.createElement(MeditationContentPortrait, { mode: props.mode, meditation: props.meditation, size: size, handleForwardPress: handleForwardPress, incrementPlaybackRate: incrementPlaybackRate, playbackRate: playbackRate, prevIsPlaying: prevIsPlaying, handleSliderValueChange: handleSliderValueChange, handleSlidingStart: handleSlidingStart, handleSlidingComplete: handleSlidingComplete, isLoadingAudio: isLoadingAudio, isPlaying: isPlaying, isSliding: isSliding, togglePlay: togglePlay, initiatedBySlider: initiatedBySlider, playbackPosition: playbackPosition, sliderPosition: sliderPosition, handleReplayPress: handleReplayPress, meditationHasMultipleFiles: props.meditationHasMultipleFiles, meditationLen: props.meditationLen, playlist: props.playlist })) : (React.createElement(MeditationContentLandscape, { mode: props.mode, meditation: props.meditation, handleForwardPress: handleForwardPress, incrementPlaybackRate: incrementPlaybackRate, playbackRate: playbackRate, prevIsPlaying: prevIsPlaying, handleSliderValueChange: handleSliderValueChange, handleSlidingStart: handleSlidingStart, handleSlidingComplete: handleSlidingComplete, isLoadingAudio: isLoadingAudio, isPlaying: isPlaying, isSliding: isSliding, togglePlay: togglePlay, initiatedBySlider: initiatedBySlider, playbackPosition: playbackPosition, sliderPosition: sliderPosition, handleReplayPress: handleReplayPress, meditationHasMultipleFiles: props.meditationHasMultipleFiles, meditationLen: props.meditationLen, playlist: props.playlist })),
            React.createElement(Modal, { buttonText: MODAL_BUTTON_TEXT, isModalOpen: isModalOpen, closeModal: () => {
                    props.navigation.navigate(HOME_SCREEN);
                }, quote: props.quotes[props.meditation.quote_id], meditation: props.meditation, playlist: props.playlist }),
            React.createElement(MeditationModal, { playlist: props.playlist, meditation: props.meditation, isOpen: isActionModalOpen, closeModal: () => setIsActionModalOpen(false) }))));
};
const MODAL_BUTTON_TEXT = 'Finish';
const mapStateToProps = (state, withProps) => {
    const playlistId = withProps.route.params.playlistId || state.playlist.id;
    const playlist = stripPersist(state.playlists)[playlistId];
    const { meditationLen } = state.settings;
    const meditationId = withProps.route.params.meditationId || state.meditation.id;
    const unaugmentedMeditation = playlist?.meditations?.[meditationId]
        || withProps.route.params.meditation;
    const meditation = augmentMeditation(unaugmentedMeditation, meditationLen);
    const meditationHasMultipleFiles = hasMultipleFiles(meditation);
    return {
        authors: stripPersist(state.authors),
        autoplay: state.settings.autoplay,
        deviceDimensions: state.dimensions.deviceDimensions,
        isConnected: state.connection.isConnected,
        listenedMeditationIds: state.listens.meditationIds,
        meditation,
        meditationLen,
        meditationHasMultipleFiles,
        mode: stripPersist(state.colors).mode,
        playlist,
        quotes: stripPersist(state.quotes),
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    removeContinuePlaying: () => dispatch(removeContinueMeditation()),
    setContinuePlaying: (id) => dispatch(receiveContinueMeditation(id)),
    recordListen: (meditation) => dispatch(recordListen(meditation)),
    receiveListensAction: (meditationId) => dispatch(receiveListensAction(meditationId)),
    statsNotification: () => dispatch(receiveNotification('meditation')),
    isMeditationDownloadedAndUpdate: (meditation) => (dispatch(isMeditationDownloadedAndUpdate(meditation))),
    setAndGoToMeditation: (meditation, navigation, isTheory) => (dispatch(setAndGoToMeditation(meditation, navigation, isTheory))),
    uploadListens: (user, meditationIds) => (dispatch(uploadListens(user, meditationIds))),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeditationScreenV2);
