import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function Replay10Icon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 48 51", ...otherProps },
        React.createElement(Path, { d: "M17.9386 11.7649C21.4314 10.4936 25.2377 10.3762 28.7868 11.4302C32.3359 12.4843 35.4365 14.653 37.6239 17.6113C39.8113 20.5696 40.9676 24.1581 40.9195 27.8388C40.8713 31.5195 39.6213 35.0941 37.3568 38.0267C35.0923 40.9593 31.9353 43.0919 28.3592 44.1048C24.7831 45.1177 20.9804 44.9563 17.5214 43.6448C14.0623 42.3334 11.1332 39.9425 9.17308 36.8306", stroke: color, strokeWidth: "2", fill: "transparent" }),
        React.createElement(Path, { d: "M10.4728 14.244L19.8287 16.4047L16.251 6.5749L10.4728 14.244Z", fill: color }),
        React.createElement(Path, { d: "M20.2341 32.8192H18.9861V26.8782H16.9451V25.9812C17.5171 25.9725 17.9981 25.8252 18.3881 25.5392C18.7781 25.2445 19.0208 24.8632 19.1161 24.3952H20.2341V32.8192ZM23.9186 25.8772C23.4246 26.3972 23.1776 27.3072 23.1776 28.6072C23.1776 29.9072 23.4246 30.8172 23.9186 31.3372C24.2653 31.7012 24.7116 31.8832 25.2576 31.8832C25.8036 31.8832 26.2499 31.7012 26.5966 31.3372C27.0906 30.8172 27.3376 29.9072 27.3376 28.6072C27.3376 27.3072 27.0906 26.3972 26.5966 25.8772C26.2499 25.5132 25.8036 25.3312 25.2576 25.3312C24.7116 25.3312 24.2653 25.5132 23.9186 25.8772ZM22.6706 31.6622C22.1679 30.9169 21.9166 29.8985 21.9166 28.6072C21.9166 27.3159 22.1679 26.2975 22.6706 25.5522C23.2686 24.6509 24.1309 24.2002 25.2576 24.2002C26.3843 24.2002 27.2466 24.6509 27.8446 25.5522C28.3473 26.2975 28.5986 27.3159 28.5986 28.6072C28.5986 29.8985 28.3473 30.9169 27.8446 31.6622C27.2466 32.5635 26.3843 33.0142 25.2576 33.0142C24.1309 33.0142 23.2686 32.5635 22.6706 31.6622Z", fill: color })));
}
Replay10Icon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default Replay10Icon;
