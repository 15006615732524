import { idObjToArr } from '@utils/index';
import { isEmpty, shuffle, maxBy, merge, minBy, } from 'lodash';
export const GOAL_THEORY_MEDITATION_MAP = {
    1: 349,
    2: 350,
    3: 352,
    4: 351,
    5: 348,
};
let meditationGoals = null;
export const onboardingTheoryMeditation = (meditations, user) => {
    if (isEmpty(meditations) || isEmpty(user))
        return null;
    meditationGoals = meditationGoals || shuffle(user.meditation_goal_ids);
    const meditationGoalId = meditationGoals[0];
    if (meditationGoalId === undefined || meditationGoalId === null)
        return null;
    const meditationId = GOAL_THEORY_MEDITATION_MAP[meditationGoalId];
    if (meditationId === undefined || meditationId === null)
        return null;
    return meditations[meditationId];
};
export const augmentMeditation = (meditation, meditationLen) => {
    if (['10', '20'].includes(meditationLen)
        && meditation?.meditation_files
        && meditation?.meditation_files.length > 1) {
        const sortFunc = meditationLen === '20' ? maxBy : minBy;
        const meditationFile = sortFunc(meditation.meditation_files, (files) => files.length_sec);
        const { length_sec, filepath } = meditationFile;
        return merge({}, meditation, { length_sec, filepath, meditation_file_id: meditationFile.id });
    }
    return meditation;
};
export const hasMultipleFiles = (meditation) => meditation?.meditation_files && meditation.meditation_files.length > 1;
export const getMeditationAfter = (meditationId, meditations) => {
    const meditationsArr = idObjToArr(meditations);
    const index = meditationsArr.map((m) => m.id).indexOf(meditationId);
    if (index === -1)
        return null;
    const nextMeditation = meditationsArr[index + 1]; // Assumes sorted
    return nextMeditation || null;
};
