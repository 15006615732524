import { merge } from 'lodash';
import { NULL_ORIENTATION, CHANGE_ORIENTATION, setOrientation } from '../actions/dimensionsActions';
const defaultState = setOrientation();
const DimensionsReducer = (state = defaultState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case CHANGE_ORIENTATION:
            return {
                orientation: action.orientation,
                deviceDimensions: action.deviceDimensions,
            };
        case NULL_ORIENTATION:
            return newState;
        default:
            return newState;
    }
};
export default DimensionsReducer;
