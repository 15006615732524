import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function CheckMarkIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: height, height: width, viewBox: "0 0 200 200", fill: "none" },
        React.createElement(Circle, { cx: "100", cy: "100", r: "95", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M86.8843 133.724L146.398 74.2668C147.866 72.7994 147.868 70.4191 146.4 68.9502L143.34 65.9327C141.87 64.4826 139.505 64.4901 138.044 65.9496L84.7553 119.176L83.6923 119.176L61.8961 97.38C60.428 95.9118 58.0477 95.9118 56.5795 97.38L53.5918 100.389C52.1334 101.857 52.1365 104.229 53.5986 105.694L81.5696 133.718C82.857 135.01 84.8798 135.184 86.3087 134.2L86.8843 133.724Z", fill: "white" })));
}
CheckMarkIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default CheckMarkIcon;
