const Handler = {
    get() {
        return () => { };
    },
    apply() {
        return () => { };
    },
};
const dumbWebHandler = (library) => {
    return new Proxy(library, Handler);
};
export default dumbWebHandler;
