import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function JournalIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 23 23", ...otherProps },
        React.createElement(Path, { d: "M12.9619 5.27209L17.7259 10.0361L7.73689 20.025L2.97293 15.2611L12.9619 5.27209Z", fill: color }),
        React.createElement(Path, { d: "M17.2502 0.985137L15.8208 2.41455L20.5855 7.17923L22.0149 5.74982C23.3284 4.4363 23.3284 2.29865 22.0149 0.985137C20.7014 -0.328379 18.5637 -0.328379 17.2502 0.985137Z", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M14.8672 3.36539L19.6312 8.12935L18.6784 9.08211L13.9144 4.31815L14.8672 3.36539Z", fill: color }),
        React.createElement(Path, { d: "M2.20041 16.3987L0 23L6.60122 20.7996L2.20041 16.3987Z", fill: color })));
}
JournalIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default JournalIcon;
