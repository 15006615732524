import { merge } from 'lodash';
import { RECIEVE_ENTRIES, RECIEVE_ENTRY, REMOVE_ENTRY } from '../actions/entryActions';
import { LOGOUT } from '../actions/userActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {
    data: {},
};
const EntriesReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECIEVE_ENTRIES:
            return {
                data: { ...newState.data, ...action.entries },
            };
        case RECIEVE_ENTRY:
            newState.data[action.entry.id] = action.entry;
            return newState;
        case REMOVE_ENTRY:
            delete newState?.data[action.entryId];
            return newState;
        case LOGOUT:
            return {};
        default:
            return newState;
    }
};
export default EntriesReducer;
