import { escapeEmail } from '../utils/email';
import { host } from '../utils/constants';
export const RECEIVE_LISTENS = 'RECEIVE_LISTENS';
export const CLEAR_LISTENS = 'CLEAR_LISTENS';
export const receiveListensAction = (meditationId) => ({
    type: RECEIVE_LISTENS,
    meditationId,
});
export const clearListensAction = () => ({
    type: CLEAR_LISTENS,
});
export const uploadListens = (user, meditationIds) => (dispatch) => {
    fetch(`${host}/api/user_meditations/mass_update?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_id: user.id,
            meditation_ids: meditationIds,
        }),
    })
        .then((response) => {
        if (response.status === 200) {
            dispatch(clearListensAction());
        }
    })
        .catch((error) => console.log('Upload Error:', error));
};
