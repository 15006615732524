import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function GoogleIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 200", ...otherProps },
        React.createElement(Path, { d: "M100 80V120H156.58C148.32 143.28 126.08 160 100 160C66.92 160 40 133.08 40 100C40 66.92 66.92 40 100 40C114.34 40 128.14 45.14 138.86 54.48L165.14 24.32C147.14 8.64 124.02 0 100 0C44.86 0 0 44.86 0 100C0 155.14 44.86 200 100 200C155.14 200 200 155.14 200 100V80H100Z", fill: color })));
}
GoogleIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default GoogleIcon;
