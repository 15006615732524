import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function Forward10Icon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 48 51", ...otherProps },
        React.createElement(Path, { d: "M18.3738 32.8192H17.1258V26.8782H15.0848V25.9812C15.6568 25.9725 16.1378 25.8252 16.5278 25.5392C16.9178 25.2445 17.1604 24.8632 17.2558 24.3952H18.3738V32.8192ZM22.0583 25.8772C21.5643 26.3972 21.3172 27.3072 21.3172 28.6072C21.3172 29.9072 21.5643 30.8172 22.0583 31.3372C22.4049 31.7012 22.8513 31.8832 23.3973 31.8832C23.9433 31.8832 24.3896 31.7012 24.7363 31.3372C25.2303 30.8172 25.4773 29.9072 25.4773 28.6072C25.4773 27.3072 25.2303 26.3972 24.7363 25.8772C24.3896 25.5132 23.9433 25.3312 23.3973 25.3312C22.8513 25.3312 22.4049 25.5132 22.0583 25.8772ZM20.8103 31.6622C20.3076 30.9169 20.0563 29.8985 20.0563 28.6072C20.0563 27.3159 20.3076 26.2975 20.8103 25.5522C21.4083 24.6509 22.2706 24.2002 23.3973 24.2002C24.5239 24.2002 25.3863 24.6509 25.9843 25.5522C26.4869 26.2975 26.7383 27.3159 26.7383 28.6072C26.7383 29.8985 26.4869 30.9169 25.9843 31.6622C25.3863 32.5635 24.5239 33.0142 23.3973 33.0142C22.2706 33.0142 21.4083 32.5635 20.8103 31.6622Z", fill: color }),
        React.createElement(Path, { d: "M27.7821 11.7649C24.2893 10.4936 20.483 10.3762 16.9339 11.4302C13.3848 12.4843 10.2842 14.653 8.09676 17.6113C5.90936 20.5696 4.75307 24.1581 4.80123 27.8388C4.8494 31.5195 6.09943 35.0941 8.36392 38.0267C10.6284 40.9593 13.7854 43.0919 17.3615 44.1048C20.9376 45.1177 24.7403 44.9563 28.1993 43.6448C31.6584 42.3334 34.5875 39.9425 36.5476 36.8306", stroke: color, strokeWidth: "2", fill: "transparent" }),
        React.createElement(Path, { d: "M35.2479 14.244L25.892 16.4047L29.4697 6.5749L35.2479 14.244Z", fill: color })));
}
Forward10Icon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default Forward10Icon;
