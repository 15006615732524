import BaseText from '@components/baseComponents/baseText';
import { stripPersist } from '@utils/index';
import { BANNER_TEXT, BLACK, BRAND, COLORS, GREEN, LIGHT_GRAY, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { fetchGet } from '@utils/request';
import { BUTTON_RADIUS } from '@utils/styles';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import Analytics from '@utils/analytics';
const styles = StyleSheet.create({
    container: {},
    scrollContainer: {
        flexGrow: 1,
        paddingLeft: 5,
    },
    content: {
        flexDirection: 'row',
        padding: 5,
    },
    tag: {
        borderRadius: BUTTON_RADIUS,
        backgroundColor: LIGHT_GRAY,
        borderColor: LIGHT_GRAY,
        borderWidth: 1,
        justifyContent: 'center',
        textAlign: 'center',
        marginHorizontal: 2,
        marginVertical: 2,
    },
    tagText: {
        fontSize: Fonts.size.m,
        padding: 10,
    },
});
const removeEmptyTags = (tags) => {
    const fullTags = {};
    Object.values(tags).forEach((tag) => {
        if (!isEmpty(tag?.meditation_ids)) {
            fullTags[tag.id] = tag;
        }
    });
    return fullTags;
};
const Tags = ({ user, currentTag, setCurrentTag, mode, }) => {
    const [tags, setTags] = useState({});
    useEffect(() => {
        const fetchTags = async () => {
            const fetchedTags = await fetchGet(user, '/api/tags');
            if (fetchedTags) {
                setTags(removeEmptyTags(fetchedTags));
            }
        };
        fetchTags();
    }, []);
    if (isEmpty(tags))
        return null;
    return (React.createElement(View, { style: styles.container },
        React.createElement(ScrollView, { contentContainerStyle: styles.scrollContainer, style: styles.content, horizontal: true }, Object.entries(tags).map(([, tag]) => {
            const onPress = () => {
                const val = currentTag?.id === tag.id ? null : tag;
                setCurrentTag(val);
                if (val) {
                    Analytics.event(Analytics.Events.TAG_PRESS, { tag });
                }
            };
            const selectedTag = currentTag?.id === tag.id
                ? { backgroundColor: COLORS[BRAND][mode], borderColor: GREEN }
                : {};
            const selectedTagText = currentTag?.id === tag.id ? { color: COLORS[BANNER_TEXT][mode] } : { color: BLACK };
            return (React.createElement(TouchableOpacity, { key: `tag-${tag.name}`, onPress: onPress },
                React.createElement(View, { style: [styles.tag, selectedTag] },
                    React.createElement(BaseText, { style: [styles.tagText, selectedTagText] }, tag.name))));
        }))));
};
const mapStateToProps = (state) => ({
    user: stripPersist(state.user),
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(Tags);
