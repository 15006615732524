import Slider from '@react-native-community/slider';
import { BRAND, COLORS, LIGHT_GRAY } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import React from 'react';
import { StyleSheet, View, Image, } from 'react-native';
import { Circle } from '@assets/index';
import BaseText from '@components/baseComponents/baseText';
const styles = StyleSheet.create({
    progressContainer: {
        paddingTop: 20,
    },
    slider: {
        height: 30,
        width: '100%',
    },
    timeContainer: {
        width: '100%',
        minHeight: 13,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    timeText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
        textAlign: 'center',
        color: LIGHT_GRAY,
    },
});
const MeditationProgress = (props) => {
    const thumbImage = Image.resolveAssetSource(Circle).uri;
    return (React.createElement(View, { style: styles.progressContainer },
        React.createElement(Slider, { maximumTrackTintColor: LIGHT_GRAY, maximumValue: 1, minimumTrackTintColor: COLORS[BRAND][props.mode], minimumValue: 0, onSlidingComplete: props.handleSlidingComplete, onSlidingStart: props.handleSlidingStart, onValueChange: props.handleSliderValueChange, style: styles.slider, 
            // @ts-ignore
            thumbImage: thumbImage, thumbTintColor: COLORS[BRAND][props.mode], value: props.sliderPosition }),
        React.createElement(Time, { mode: props.mode, length_sec: props.length_sec, playbackPosition: props.playbackPosition })));
};
const Time = (props) => {
    const formatTime = (isDynamic = false) => {
        const position = isDynamic ? props.playbackPosition : props.length_sec;
        const [minutes] = (position / 60).toFixed(2).split('.');
        const seconds = position % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };
    return (React.createElement(View, { style: styles.timeContainer },
        React.createElement(BaseText, { style: [styles.timeText, { color: COLORS[BRAND][props.mode] }] }, formatTime(true)),
        React.createElement(BaseText, { style: styles.timeText }, formatTime())));
};
export default MeditationProgress;
