import { merge } from 'lodash';
import { RECEIVE_LISTENS, CLEAR_LISTENS } from '../actions/listensActions';
const initialState = {
    meditationIds: [],
};
const ListensReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_LISTENS:
            return {
                meditationIds: [...newState.meditationIds, action.meditationId],
            };
        case CLEAR_LISTENS:
            return { meditationIds: [] };
        default:
            return newState;
    }
};
export default ListensReducer;
