import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
function BugsnagWeb() {
    return (component) => component;
}
BugsnagWeb.start = () => {
    Bugsnag.start({
        apiKey: '01b47ccf3d91711508a39ce3e583bc81',
        // @ts-ignore
        plugins: [new BugsnagPluginReact(React)],
    });
};
BugsnagWeb.notify = (error, properties) => {
    Bugsnag.notify(error, properties);
};
BugsnagWeb.setUser = (userId, email) => {
    Bugsnag.setUser(userId, email);
};
export default BugsnagWeb;
