import React from 'react';
import { connect } from 'react-redux';
import { Alert, BackHandler, FlatList, ScrollView, StyleSheet, TouchableOpacity, View, } from 'react-native';
import moment from 'moment';
import RandomPopups from '../baseComponents/RandomPopups';
import { stripPersist, hitSlop } from '../../utils';
import { deleteEntry, fetchEntries, setEntry, updateEntries, } from '../../actions/entryActions';
import CreateEntryButton from './createEntryButton';
import Header from '../baseComponents/header';
import { HOME_SCREEN, ENTRY_SCREEN } from '../../utils/constants';
import { GRAY, LIGHT_BORDER, SUBTITLE_TEXT, BRAND, ENTRY_BACKGROUND, COLORS, PRIMARY_TEXT, BACKGROUND, } from '../../utils/colors';
import BaseStyles from '../../utils/baseStyles';
import Analytics from '../../utils/analytics';
import { TrashIcon, ChevronRightIcon, VerticalDotsIcon } from '../../assets/svg';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        justifyContent: 'space-between',
    },
    delete: {
        width: 25,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    entryCard: {
        flex: 4,
        padding: 6,
        marginRight: 6,
        marginLeft: 6,
        marginBottom: 3,
        marginTop: 3,
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopWidth: 1,
    },
    entryCardEnd: {
        borderTopWidth: 1,
        marginRight: 6,
        marginLeft: 6,
        marginBottom: 3,
        marginTop: 3,
    },
    entriesContainer: {
        flex: 4,
    },
    entryTextContainer: {
        backgroundColor: 'transparent',
        width: '90%',
    },
    subtitle: {
        textShadowOffset: { width: 1, height: 1 },
        fontSize: Fonts.size.m,
        marginRight: 1,
    },
    dateAndTime: {
        paddingTop: 1,
        paddingBottom: 1,
        textShadowOffset: { width: 1, height: 1 },
        flexDirection: 'row',
        alignItems: 'center',
    },
    dateString: {
        fontSize: Fonts.size.xl,
    },
    timeString: {},
    topGradient: {
        opacity: 0,
        top: 111,
        height: 30,
    },
    bottomGradient: {
        bottom: 0,
        height: 0,
    },
});
class EntriesScreen extends React.Component {
    unsubscribe;
    constructor(props) {
        super(props);
        this.state = {
            didFetchEntries: false,
            deleteEntryId: null,
            lastSeenMode: props.mode,
            refresh: 0,
        };
    }
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        // Fetch series
        // Check local storage and then make a request
        BackHandler.addEventListener('hardwareBackPress', this.navigateHome);
    }
    async componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('focus', () => {
            this.setState({ deleteEntryId: null });
        });
        this.props.navigation.addListener('focus', this.fetchEntries);
        await this.props.updateEntries(this.props.user, this.props.entries);
        this.fetchEntries();
        Analytics.screenHit('entries_screen', 'EntriesScreen');
        BackHandler.addEventListener('hardwareBackPress', this.navigateHome);
        this.props.navigation.addListener('focus', this.handleFocusChange);
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.navigateHome);
        this.unsubscribe();
        this.props.navigation.removeListener('focus', this.fetchEntries);
        this.props.navigation.removeListener('focus', this.handleFocusChange);
    }
    handleFocusChange = () => {
        if (this.state.lastSeenMode !== this.props.mode) {
            this.setState((prevState) => ({ refresh: prevState.refresh + 1, lastSeenMode: this.props.mode }));
        }
    };
    fetchEntries = () => {
        if (!this.props.isConnected && !this.state.didFetchEntries) {
            this.setState({ didFetchEntries: true }); // Todo add offline message
        }
        this.props.fetchEntries(this.props.user).then(() => {
            this.setState({ didFetchEntries: true });
        });
    };
    navigateHome = () => {
        this.props.navigation.navigate(HOME_SCREEN);
        return true;
    };
    renderDelete = (entry) => {
        if (this.state.deleteEntryId !== entry.id) {
            return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => this.setState({ deleteEntryId: entry.id }), style: styles.delete },
                React.createElement(VerticalDotsIcon, { style: { color: GRAY, width: 20, height: 20 } })));
        }
        const date = this.formatDateString(entry?.created_at);
        const time = this.formatTimeString(entry?.created_at);
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => {
                Alert.alert('Are you sure?', `Do you really want to delete the journal entry from ${date} at ${time}?`, [
                    {
                        text: 'Cancel',
                        onPress: () => { },
                        style: 'cancel',
                    },
                    {
                        text: 'Yes',
                        onPress: () => {
                            this.props.deleteEntry(this.props.user, entry.id.toString());
                            this.setState({ deleteEntryId: null });
                        },
                        style: 'destructive',
                    },
                ]);
            }, style: styles.delete },
            React.createElement(TrashIcon, { style: { color: GRAY, width: 20, height: 20 } })));
    };
    renderEntry = (entryId, i) => {
        const entry = this.props.entries[entryId];
        if (!entry) {
            return null;
        }
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, key: `${i}-${entryId}`, onPress: () => {
                this.props.setEntry(entryId);
                Analytics.event(Analytics.Events.ENTRY_ROW_TOUCH, { entry_id: entryId });
                this.props.navigation.navigate(ENTRY_SCREEN);
            } },
            React.createElement(View, { style: [
                    styles.entryCard,
                    {
                        borderTopColor: COLORS[LIGHT_BORDER][this.props.mode],
                        backgroundColor: COLORS[ENTRY_BACKGROUND][this.props.mode],
                    },
                ] },
                this.renderDelete(entry),
                React.createElement(View, { style: styles.entryTextContainer },
                    React.createElement(View, { style: styles.dateAndTime },
                        React.createElement(BaseText, { style: [styles.dateString, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, this.formatDateString(entry.created_at)),
                        React.createElement(BaseText, { style: [styles.timeString, { color: COLORS[SUBTITLE_TEXT][this.props.mode] }] }, this.formatTimeString(entry.created_at))),
                    React.createElement(BaseText, { style: [styles.subtitle, { color: COLORS[SUBTITLE_TEXT][this.props.mode] }] },
                        entry.content && entry.content.slice(0, 50),
                        entry.content && entry.content.length > 50 && '...')),
                React.createElement(ChevronRightIcon, { style: { color: COLORS[BRAND][this.props.mode], height: 20, width: 20 } }))));
    };
    formatDateString = (dateString) => {
        if (!dateString)
            return '';
        const date = new Date(dateString);
        return date.toLocaleDateString().split('/').slice(0, 2).join('/');
    };
    formatTimeString = (dateString) => {
        const date = new Date(dateString);
        return ` ${moment(date).format('h:mm a')}`; // Refactor to use moment format
    };
    renderEntries() {
        const entryIds = Object.keys(this.props.entries).sort((id1, id2) => {
            const [entry1, entry2] = [this.props.entries[id1], this.props.entries[id2]];
            if (new Date(entry1.created_at) > new Date(entry2.created_at)) {
                return -1;
            }
            if (new Date(entry1.created_at) < new Date(entry2.created_at)) {
                return 1;
            }
            return 0;
        });
        return (React.createElement(FlatList, { data: entryIds, renderItem: ({ item, index }) => this.renderEntry(item, index), keyExtractor: (item, index) => `${item.slice(0, 5)}-${index}`, extraData: { mode: this.props.mode, refresh: this.state.refresh } }));
    }
    render() {
        console.count && console.count('EntriesScreen');
        return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(Header, { onPressCallback: this.navigateHome, title: "Journal", mode: this.props.mode }),
            React.createElement(View, { style: styles.entriesContainer },
                React.createElement(ScrollView, null,
                    this.renderEntries(),
                    React.createElement(View, { style: [
                            styles.entryCardEnd,
                            { borderTopColor: COLORS[LIGHT_BORDER][this.props.mode] },
                        ] }))),
            React.createElement(RandomPopups, { navigation: this.props.navigation }),
            React.createElement(View, { style: BaseStyles.flexRow },
                React.createElement(CreateEntryButton, { navigation: this.props.navigation, recordClick: () => Analytics.event(Analytics.Events.CREATE_ENTRY, { source: 'entries' }) }))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    isConnected: state.connection.isConnected,
    entries: stripPersist(state.entries),
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    deleteEntry: (user, entryId) => dispatch(deleteEntry(user, entryId)),
    fetchEntries: (user) => dispatch(fetchEntries(user)),
    setEntry: (id) => dispatch(setEntry(id)),
    updateEntries: (user, entries) => dispatch(updateEntries(user, entries)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EntriesScreen);
