import { loadUser } from '@actions/userActions';
import { useNavigation } from '@react-navigation/native';
import { stripPersist } from '@utils/index';
import { isLoggedOut, redirectIfLoggedIn } from '@utils/auth';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
const useHandleLoggedInOrLoadedUser = () => {
    const { getState, dispatch } = useStore();
    const { user: unstrippedUser } = getState();
    const user = stripPersist(unstrippedUser);
    const navigation = useNavigation();
    useEffect(() => {
        redirectIfLoggedIn(user, navigation);
        if (isLoggedOut(user)) {
            loadUser(dispatch);
        }
    }, []);
};
export default useHandleLoggedInOrLoadedUser;
