import { fetchPost } from '@utils/request';
import { isEqual, merge } from 'lodash';
import { getRubyTimezoneOffset } from '../utils';
import Analytics from '../utils/analytics';
import { host } from '../utils/constants';
import { downloadMeditation, downloadPlaylist, isMeditationDownloaded, isPlaylistDownloaded, removeAllPlaylistDownloads, removeMeditationDownload, removePlaylistDownload, } from '../utils/download';
import { escapeEmail } from '../utils/email';
export const FETCH_PLAYLISTS = 'FETCH_PLAYLISTS';
export const REQUEST_PLAYLISTS = 'REQUEST_PLAYLISTS';
export const RECEIVE_PLAYLISTS = 'RECEIVE_PLAYLISTS';
export const RECEIVE_PLAYLIST = 'RECEIVE_PLAYLIST';
export const RECEIVE_MEDITATION = 'RECEIVE_MEDITATION';
export const receivePlaylist = (playlist) => ({
    type: RECEIVE_PLAYLIST,
    playlist,
});
export const receivePlaylists = (playlists) => ({
    type: RECEIVE_PLAYLISTS,
    loading: false,
    playlists,
});
export const requestPlaylists = () => ({
    type: REQUEST_PLAYLISTS,
    loading: true,
});
export const receiveMeditation = (meditation) => ({
    type: RECEIVE_MEDITATION,
    meditation,
});
export function fetchPlaylists(user, playlists) {
    return function (dispatch) {
        return fetch(`${host}/v2/playlists/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}&timezone_offset=${getRubyTimezoneOffset()}`)
            .then((response) => response.json())
            .then((newPlaylists) => {
            const mergedPlaylists = mergePlaylists(newPlaylists, playlists);
            if (!isEqual(playlists, mergedPlaylists)) {
                dispatch(receivePlaylists(mergedPlaylists));
            }
        })
            .catch((error) => console.log(error));
    };
}
// Default new playlist to prevPlaylists device specific values (is_download etc)
const mergePlaylists = (newPlaylists, prevPlaylists) => {
    const mergedPlaylists = merge({}, newPlaylists);
    Object.entries(mergedPlaylists).forEach(([playlistId, playlist]) => {
        Object.entries(playlist.meditations).forEach(([meditationId, meditation]) => {
            const prevMeditation = prevPlaylists[playlistId]?.meditations[meditationId];
            if (prevMeditation && prevMeditation.is_downloaded !== meditation.is_downloaded) {
                const mergedMeditation = merge({}, meditation);
                mergedMeditation.is_downloaded = prevMeditation.is_downloaded;
                mergedPlaylists[mergedMeditation.playlist_id].meditations[mergedMeditation.id] = mergedMeditation;
            }
        });
        const prevPlaylist = prevPlaylists[playlistId];
        if (prevPlaylist && prevPlaylist.is_downloaded !== playlist.is_downloaded) {
            const mergedPlaylist = merge({}, playlist);
            mergedPlaylist.is_downloaded = prevPlaylist.is_downloaded;
            mergedPlaylists[mergedPlaylist.id] = mergedPlaylist;
        }
    });
    return mergedPlaylists;
};
// Downloads
export const downloadAndUpdateMeditation = (meditation) => async (dispatch) => {
    const downloadSuccessCallback = () => {
        const newMeditation = merge({}, meditation, { is_downloaded: true, is_downloading: false });
        dispatch(receiveMeditation(newMeditation));
        Analytics.event(Analytics.Events.DOWNLOADED_MEDITATION, {
            meditation_id: newMeditation.id,
            meditation_title: newMeditation.title,
        });
    };
    const newMeditation = merge({}, meditation, { is_downloading: true });
    dispatch(receiveMeditation(newMeditation));
    await downloadMeditation(meditation, downloadSuccessCallback);
};
export const removeDownloadAndUpdateMeditation = (meditation) => async (dispatch) => {
    await removeMeditationDownload(meditation);
    const newMeditation = merge({}, meditation, { is_downloaded: false });
    dispatch(receiveMeditation(newMeditation));
    Analytics.event(Analytics.Events.REMOVE_MEDITATION, {
        meditation_id: newMeditation.id,
        meditation_title: newMeditation.title,
    });
};
export const downloadPlaylistAndUpdate = (playlist) => async (dispatch) => {
    const result = await downloadPlaylist(playlist);
    await Promise.resolve(result);
    const newPlaylist = merge({}, playlist);
    const { meditations } = playlist;
    const newMeditations = {};
    Object.keys(meditations).forEach((meditationId) => {
        const meditation = meditations[meditationId];
        const newMeditation = merge({}, meditation, { is_downloaded: true });
        newMeditations[newMeditation.id] = newMeditation;
    });
    newPlaylist.is_downloaded = true;
    newPlaylist.meditations = newMeditations;
    dispatch(receivePlaylist(newPlaylist));
};
export const removePlaylistDownloadAndUpdate = (playlist) => async (dispatch) => {
    await removePlaylistDownload(playlist);
    const newPlaylist = merge({}, playlist);
    const { meditations } = playlist;
    const newMeditations = {};
    Object.keys(meditations).forEach((meditationId) => {
        const meditation = meditations[meditationId];
        const newMeditation = merge({}, meditation, { is_downloaded: false });
        newMeditations[newMeditation.id] = newMeditation;
    });
    newPlaylist.is_downloaded = false;
    newPlaylist.meditations = newMeditations;
    dispatch(receivePlaylist(newPlaylist));
};
export const removeAllPlaylistDownloadsAndUpdate = (playlists) => async (dispatch) => {
    await removeAllPlaylistDownloads(playlists);
    const newPlaylists = {};
    Object.keys(playlists).forEach((id) => {
        const playlist = playlists[id];
        const newPlaylist = merge({}, playlist);
        const { meditations } = playlist;
        const newMeditations = {};
        Object.keys(meditations).forEach((meditationId) => {
            const meditation = meditations[meditationId];
            const newMeditation = merge({}, meditation, { is_downloaded: false });
            newMeditations[newMeditation.id] = newMeditation;
        });
        newPlaylist.is_downloaded = false;
        newPlaylist.meditations = newMeditations;
        newPlaylists[newPlaylist.id] = newPlaylist;
    });
    dispatch(receivePlaylists(newPlaylists));
};
export const isPlaylistDownloadedAndUpdate = (playlist) => async (dispatch) => {
    // check and update store
    const isDownloaded = await isPlaylistDownloaded(playlist);
    const newPlaylist = merge({}, playlist);
    newPlaylist.is_downloaded = isDownloaded;
    if (isDownloaded) {
        const { meditations } = playlist;
        const newMeditations = {};
        Object.keys(meditations).forEach((meditationId) => {
            const meditation = meditations[meditationId];
            const newMeditation = merge({}, meditation, { is_downloaded: true });
            newMeditations[newMeditation.id] = newMeditation;
        });
        newPlaylist.meditations = newMeditations;
    }
    dispatch(receivePlaylist(newPlaylist));
};
export const isMeditationDownloadedAndUpdate = (meditation) => async (dispatch) => {
    const isDownloaded = await isMeditationDownloaded(meditation);
    const newMeditation = merge({}, meditation, { is_downloaded: isDownloaded });
    dispatch(receiveMeditation(newMeditation));
};
export const updateAllPlaylistsDownloadStatus = (playlists) => (dispatch) => {
    if (!playlists) {
        Analytics.recordDiagnostic('Playlists is falsey', {
            method: 'updateAllPlaylistsDownloadStatus',
        });
        return;
    }
    Object.keys(playlists).map(async (playlistId) => {
        const playlist = playlists[playlistId];
        if (!playlist) {
            Analytics.recordDiagnostic('Unable to find playlistId', { playlistId, playlists });
            return;
        }
        const playlistIsDownloaded = await isPlaylistDownloaded(playlist);
        const newPlaylist = merge({}, playlist);
        newPlaylist.is_downloaded = playlistIsDownloaded;
        const playlistIsStoredAsDownloaded = !!playlist.is_downloaded;
        if (playlistIsDownloaded || playlistIsDownloaded !== playlistIsStoredAsDownloaded) {
            let shouldDispatch = playlistIsDownloaded !== playlistIsStoredAsDownloaded;
            if (playlistIsDownloaded) {
                const { meditations } = playlist;
                const newMeditations = {};
                Object.keys(meditations).forEach((meditationId) => {
                    const meditation = meditations[meditationId];
                    if (meditation.is_downloaded !== playlistIsDownloaded)
                        shouldDispatch = true;
                    const newMeditation = merge({}, meditation, { is_downloaded: playlistIsDownloaded });
                    newMeditations[newMeditation.id] = newMeditation;
                });
                newPlaylist.meditations = newMeditations;
            }
            if (shouldDispatch) {
                dispatch(receivePlaylist(newPlaylist));
            }
        }
        if (!playlistIsDownloaded) {
            Object.keys(playlist.meditations).map(async (meditationId) => {
                const meditation = playlist.meditations[meditationId];
                if (!playlist) {
                    Analytics.recordDiagnostic('Unable to find meditationId', {
                        playlistId,
                        meditations: playlist.meditations,
                    });
                    return;
                }
                const meditationIsDownloaded = await isMeditationDownloaded(meditation);
                const meditationIsStoredAsDownloaded = !!meditation.is_downloaded;
                if (meditationIsDownloaded !== meditationIsStoredAsDownloaded) {
                    const newMeditation = merge({}, meditation, { is_downloaded: meditationIsDownloaded });
                    newPlaylist.meditations[meditationId] = newMeditation;
                    dispatch(receiveMeditation(newMeditation));
                }
            });
        }
    });
};
export const markPlaylistAsListened = (user, playlist) => (dispatch) => {
    // Optimistic update
    const newPlaylist = merge({}, playlist, { is_listened: true });
    const newMeditations = {};
    Object.entries(playlist.meditations).forEach(([, meditation]) => {
        const newMeditation = merge({}, meditation, { is_listened: true });
        newMeditations[newMeditation.id] = newMeditation;
    });
    newPlaylist.meditations = newMeditations;
    dispatch(receivePlaylist(newPlaylist));
    Object.entries(playlist.meditations).forEach(([, meditation]) => {
        fetchPost(user, '/api/user_meditation_listen_statuses/listen', {
            meditation_id: meditation.id.toString(),
            user_id: user.id.toString(),
        });
    });
};
export const markPlaylistAsUnlistened = (user, playlist) => (dispatch) => {
    // Optimistic update
    const newPlaylist = merge({}, playlist, { is_listened: false });
    const newMeditations = {};
    Object.entries(playlist.meditations).forEach(([, meditation]) => {
        const newMeditation = merge({}, meditation, { is_listened: false });
        newMeditations[newMeditation.id] = newMeditation;
    });
    newPlaylist.meditations = newMeditations;
    dispatch(receivePlaylist(newPlaylist));
    Object.entries(playlist.meditations).forEach(([, meditation]) => {
        fetchPost(user, '/api/user_meditation_listen_statuses/unlisten', {
            meditation_id: meditation.id.toString(),
            user_id: user.id.toString(),
        });
    });
};
