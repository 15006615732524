import React from 'react';
import { View } from 'react-native';
import Carousel from '../../utils/libraries/carousel';
import BaseStyles from '../../utils/baseStyles';
import MeditationCarouselCard from './meditationCarouselCard';
import SectionTitle from './sectionTitle';
const MeditationCarousel = (props) => {
    const renderItem = ({ item, index }) => (React.createElement(MeditationCarouselCard, { key: index, meditation: item, playlist: props.playlists[item.playlist_id], navigation: props.navigation, carouselTitle: props.title }));
    return props.meditations.length > 0 ? (React.createElement(View, { style: BaseStyles.homeSectionContainer },
        React.createElement(SectionTitle, { title: props.title }),
        React.createElement(Carousel, { activeSlideAlignment: "start", data: props.meditations, renderItem: renderItem, sliderWidth: props.deviceWidth, itemWidth: 136, containerCustomStyle: BaseStyles.homeCarousel }))) : null;
};
export default MeditationCarousel;
