import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, TouchableOpacity, ActivityIndicator, } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ScrollView } from 'react-native-gesture-handler';
import { capitalize, shuffle, isEmpty } from 'lodash';
import { MeditationGoalIdsKey, MeditationReminderAtKey } from '@utils/asyncKeys';
import { checkPermissionAndAlertIfNone } from '@utils/firebase.web';
import { s } from 'react-native-size-matters';
import { SIGNUP_SCREEN } from '../../utils/constants';
import TextButton from '../baseComponents/textButton';
import { COLORS, BACKGROUND, PRIMARY_TEXT, LIGHT_GREEN, GREEN, LIGHT_GRAY, BLACK, LIGHT_BLACK, } from '../../utils/colors';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import Header from '../baseComponents/header';
import { BUTTON_RADIUS } from '../../utils/styles';
import { fetchMeditationGoals } from '../../actions/meditationGoalActions';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    largeText: {
        fontSize: Fonts.size.xxl,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    smallText: {
        fontSize: Fonts.size.l,
        textAlign: 'center',
        padding: 30,
    },
    loadingGoals: {
        padding: 10,
        color: 'transparent',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
    },
    goals: {
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexGrow: 1,
        paddingHorizontal: s(12),
    },
    goal: {
        padding: 10,
        borderRadius: BUTTON_RADIUS,
        backgroundColor: LIGHT_GRAY,
        borderColor: LIGHT_GRAY,
        borderWidth: 1,
        justifyContent: 'center',
        textAlign: 'center',
        marginHorizontal: 4,
        marginVertical: 4,
    },
    selectedGoal: {
        padding: 10,
        borderRadius: BUTTON_RADIUS,
        backgroundColor: LIGHT_GREEN,
        borderColor: GREEN,
        borderWidth: 1,
        justifyContent: 'center',
        textAlign: 'center',
        marginVertical: 2,
    },
    sectionContainer: {
        paddingVertical: 5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
class ReminderScreen extends React.Component {
    meditationGoals;
    constructor(props) {
        super(props);
        this.state = { isTimePicker: false, selectedGoalIds: [], time: '' };
    }
    componentDidMount = () => {
        Analytics.screenHit('Reminder Welcome', 'ReminderScreen');
        if (isEmpty(this.props.meditationGoals)) {
            this.props.fetchMeditationGoals();
        }
    };
    handleBackPress = () => {
        this.props.navigation.goBack();
        Analytics.event(Analytics.Events.REMINDER_WELCOME_BACKPRESS, {});
        return true;
    };
    handleSetReminderPress = async () => {
        this.setState({ isTimePicker: true });
        await checkPermissionAndAlertIfNone();
        Analytics.event(Analytics.Events.REMINDER_SAVE);
    };
    setTime = (time) => {
        AsyncStorage.setItem(MeditationReminderAtKey, JSON.stringify(time));
        this.setState({
            time,
        });
    };
    handleContinue = () => {
        AsyncStorage.setItem(MeditationGoalIdsKey, JSON.stringify(this.state.selectedGoalIds));
        this.props.navigation.navigate(SIGNUP_SCREEN);
        Analytics.event(Analytics.Events.REMINDERS_CONTINUE_TOUCH);
    };
    handleConfirm = (time) => {
        // bad taste?
        if (time) {
            this.setTime(time);
        }
        this.props.navigation.navigate(SIGNUP_SCREEN);
    };
    shuffledMeditationGoals = () => {
        if (this.meditationGoals)
            return this.meditationGoals;
        this.meditationGoals = shuffle(Object.keys(this.props.meditationGoals).map((goalId) => this.props.meditationGoals[goalId]));
        return this.meditationGoals;
    };
    renderGoals = () => {
        let goals;
        if (isEmpty(this.props.meditationGoals)) {
            goals = (React.createElement(View, { style: styles.loadingGoals },
                React.createElement(ActivityIndicator, { size: "small" })));
        }
        else {
            goals = this.shuffledMeditationGoals().map((goal) => {
                const buttonStyle = this.state.selectedGoalIds.includes(goal.id)
                    ? styles.selectedGoal
                    : styles.goal;
                const textColor = this.state.selectedGoalIds.includes(goal.id) ? BLACK : LIGHT_BLACK;
                return (React.createElement(TouchableOpacity, { style: buttonStyle, onPress: () => this.toggleGoal(goal.id), key: goal.id },
                    React.createElement(BaseText, { style: { color: textColor } }, capitalize(goal.title))));
            });
        }
        return React.createElement(View, { style: styles.goals }, goals);
    };
    toggleGoal = (toggledGoalId) => {
        let newGoals = [];
        if (this.state.selectedGoalIds.includes(toggledGoalId)) {
            newGoals = this.state.selectedGoalIds.filter((goalId) => goalId !== toggledGoalId);
            Analytics.event(Analytics.Events.REMOVE_MEDITATION_GOAL, { meditation_goal_id: toggledGoalId });
        }
        else {
            newGoals = this.state.selectedGoalIds.slice();
            newGoals.push(toggledGoalId);
            Analytics.event(Analytics.Events.ADD_MEDITATION_GOAL, { meditation_goal_id: toggledGoalId });
        }
        this.setState({ selectedGoalIds: newGoals });
    };
    renderGoalSection = () => (React.createElement(View, { style: styles.sectionContainer },
        React.createElement(BaseText, { style: [
                styles.smallText,
                { color: COLORS[PRIMARY_TEXT][this.props.mode], padding: 0, paddingTop: 30 },
            ] }, "What do you want from using Stoa?"),
        React.createElement(BaseText, { style: [styles.smallText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Set a purpose."),
        this.renderGoals()));
    render() {
        return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(Header, { title: "Get Started", onPressCallback: this.handleBackPress, mode: this.props.mode }),
            React.createElement(ScrollView, null,
                this.renderGoalSection(),
                React.createElement(View, { style: styles.sectionContainer }),
                React.createElement(View, { style: styles.sectionContainer },
                    React.createElement(TextButton, { onPress: this.handleContinue, title: "Continue", testID: "continue-button" })))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    meditationGoals: stripPersist(state.meditationGoals),
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    fetchMeditationGoals: () => dispatch(fetchMeditationGoals()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReminderScreen);
