import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function ChevronUpIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 173 100", fill: "none" },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M170.399 80.719C171.883 82.201 172.697 84.1724 172.697 86.2861C172.697 88.3998 171.883 90.3712 170.399 91.8538L164.626 97.6284C161.553 100.697 156.557 100.697 153.49 97.6291L87.6344 31.7738H85.1354L19.2075 97.7017C17.7226 99.1854 15.7515 100 13.6409 100C11.5276 100 9.5562 99.1856 8.07074 97.7025L2.29784 91.9267C0.81395 90.4428 3.05176e-05 88.4724 3.05176e-05 86.3592C3.05176e-05 84.2455 0.81424 82.274 2.29785 80.7916L80.792 2.29455C82.2796 0.809319 84.2599 -0.0046463 86.3845 2.28882e-05C88.51 -0.00465393 90.489 0.808281 91.9754 2.29355L170.399 80.719Z", fill: color })));
}
ChevronUpIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default ChevronUpIcon;
