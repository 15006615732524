import * as React from 'react';
import Svg, { Rect } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function PauseIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 16 27", ...otherProps },
        React.createElement(Rect, { width: "3", height: "27", rx: "1.5", fill: color }),
        React.createElement(Rect, { x: "13", width: "3", height: "27", rx: "1.5", fill: color })));
}
PauseIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default PauseIcon;
