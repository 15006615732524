import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function CheckIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 148", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M71.4504 146.201L198.237 19.535C200.586 17.1886 200.588 13.3824 198.241 11.0336L188.809 1.73121C186.457 -0.587564 182.676 -0.575498 180.34 1.75823L68.046 113.921L66.3463 113.92L19.5019 67.0765C17.1543 64.7289 13.348 64.729 11.0004 67.0766L1.74578 76.3965C-0.586167 78.7449 -0.581303 82.5365 1.75667 84.8789L62.952 146.191C65.0106 148.257 68.2452 148.535 70.53 146.962L71.4504 146.201Z", fill: color })));
}
CheckIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default CheckIcon;
