import React from 'react';
import { BRAND, COLORS, SECONDARY_TEXT, } from '@utils/colors';
import { StyleSheet, View, } from 'react-native';
import BaseText from '@components/baseComponents/baseText';
import BaseStyles from '@utils/baseStyles';
import { CheckIcon } from '@assets/svg';
const styles = StyleSheet.create({
    checkIcon: {
        width: 20,
        height: 20,
    },
    listEl: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
    },
    textPadding: {
        paddingLeft: 10,
    },
});
const SubscriptionListEl = (props) => (React.createElement(View, { style: styles.listEl },
    React.createElement(CheckIcon, { style: { ...styles.checkIcon, ...{ color: COLORS[BRAND][props.mode] } } }),
    React.createElement(BaseText, { style: [
            BaseStyles.standardText,
            styles.textPadding,
            props.textStyle ? props.textStyle : { color: COLORS[SECONDARY_TEXT][props.mode], opacity: 0.7 },
        ] }, props.text)));
export default SubscriptionListEl;
