// @ts-nocheck
import { Audio } from 'expo-av';
class TrackPlayerWeb {
    static sound = null;
    static useProgress = async () => {
        const status = await this.sound?.getStatusAsync();
        if (!status)
            return { position: 0, duration: 0 };
        return { position: this.position || 0, duration: this.durationMillis || 0 };
    };
    static add = async (tracks) => {
        this.sound = this.sound || new Audio.Sound();
        const track = tracks[0];
        this.sound?.setOnPlaybackStatusUpdate((playbackStatus) => {
            if (playbackStatus.isLoaded) {
                this.position = playbackStatus.positionMillis / 1000;
                this.durationMillis = playbackStatus.durationMillis / 1000;
            }
        });
        return this.sound?.loadAsync(track.url);
    };
    static getPosition = async () => {
        const status = await this.sound?.getStatusAsync();
        if (!status)
            return 0;
        return status.positionMillis / 1000;
    };
    static getQueue = async () => {
        return new Promise();
    };
    static pause = async () => {
        return await this.sound?.pauseAsync();
    };
    static play = async () => {
        return await this.sound?.playAsync();
    };
    static destroy = async () => {
        if (this.sound) {
            await this.sound?.unloadAsync();
        }
        this.sound = null;
    };
    static reset = async () => {
        if (this.sound) {
            this.sound.unloadAsync();
        }
        this.sound = null;
    };
    static seekTo = async (position) => {
        const positionMillis = position * 1000;
        return await this.sound?.setPositionAsync(positionMillis);
    };
    static setupPlayer = async () => {
        this.sound = new Audio.Sound();
        return Promise;
    };
    static updateOptions = async () => {
        return Promise;
    };
    static addEventListener = async () => {
        return Promise;
    };
    static setRate = async (newRate) => {
        if (this.sound) {
            return this.sound?.setRateAsync(newRate, true);
        }
        return Promise;
    };
    static getState = async () => {
        if (this.sound?.isPlaying) {
            return 'playing';
        }
        return '';
    };
}
export default TrackPlayerWeb;
const State = () => { };
const Capability = () => { };
const Event = () => { };
const { useProgress } = TrackPlayerWeb;
export { State, Capability, Event, useProgress };
