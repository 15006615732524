import React from 'react';
import { COLORS, BANNER_GRAY, BLACK, BACKGROUND, BANNER_BACKGROUND, MODAL_TEXT, SECONDARY_TEXT, BRAND, } from '@utils/colors';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import FastImage from 'react-native-fast-image';
import { StyleSheet, View, Pressable, } from 'react-native';
import { secondsToStr, stripPersist } from '@utils/index';
import { CheckMarkIcon, ChevronRightIcon, MeditationIcon, QuotationIcon, } from '@assets/svg';
import TargetIcon from '@assets/svg/target/TargetIcon';
import BaseText from '@components/baseComponents/baseText';
import { connect } from 'react-redux';
import { imageToSpacesUrl } from '@utils/request';
import { vs } from 'react-native-size-matters';
import { WPM } from '@utils/constants';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    bgImage: {
        borderTopEndRadius: IMAGE_BORDER_RADIUS,
        borderTopStartRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'row',
        height: vs(50),
        width: '100%',
        maxWidth: 450,
    },
    titleContainer: {
        flex: 0.8,
        justifyContent: 'flex-start',
        paddingLeft: 10,
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    pairContainer: {
        flex: 0.4,
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    titleText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        alignItems: 'center',
        padding: 10,
    },
    lengthText: {
        fontSize: Fonts.size.s,
        fontWeight: '400',
        alignItems: 'center',
    },
    arrow: {
        flex: 0.2,
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        borderTopRightRadius: IMAGE_BORDER_RADIUS,
        borderBottomRightRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
const RoutineHeader = ({ step, mode, isActive, handleOnPressStep, index, }) => {
    const bgBackgroundColor = isActive
        ? { backgroundColor: COLORS[BANNER_GRAY][mode] }
        : { backgroundColor: COLORS[BANNER_BACKGROUND][mode] };
    const Icon = step.type === 'meditation'
        ? MeditationIcon
        : step.type === 'quote'
            ? QuotationIcon
            : TargetIcon;
    const imageUri = step.type === 'meditation'
        ? imageToSpacesUrl(step.exercise.image || step.playlist?.image || '')
        : '';
    const imageBorderRadius = isActive
        ? {}
        : { borderRadius: IMAGE_BORDER_RADIUS };
    const textColor = isActive
        ? { color: BLACK }
        : step.is_finished
            ? { color: COLORS[MODAL_TEXT][mode] }
            : { color: COLORS[SECONDARY_TEXT][mode] };
    const title = step.type === 'meditation'
        ? step.exercise?.is_theory
            ? 'Theory'
            : 'Practice'
        : step.type === 'quote'
            ? 'Quote'
            : 'Action';
    const lengthSec = step.type === 'meditation'
        ? step.exercise.length_sec
        : step.type === 'quote'
            ? Math.round(step.exercise.text.length / WPM)
            : Math.round(step.description.length / WPM);
    const lengthStr = lengthSec < 10 ? '0:10' : secondsToStr(lengthSec);
    const iconColor = step.is_finished
        ? COLORS[MODAL_TEXT][mode]
        : COLORS[BRAND][mode];
    return (React.createElement(Pressable, { onPress: () => handleOnPressStep(index, step) },
        React.createElement(FastImage, { source: { uri: imageUri }, style: [styles.bgImage, bgBackgroundColor, imageBorderRadius], tintColor: COLORS[BACKGROUND][mode] },
            React.createElement(View, { style: styles.titleContainer },
                React.createElement(Icon, { style: {
                        color: iconColor,
                        height: 20,
                        width: 20,
                    } }),
                React.createElement(BaseText, { style: [styles.titleText, textColor] }, title)),
            React.createElement(View, { style: styles.pairContainer },
                React.createElement(BaseText, { style: [styles.lengthText, textColor] }, lengthStr),
                !isActive ? (React.createElement(View, { style: styles.arrow }, step.is_finished ? (React.createElement(CheckMarkIcon, { style: {
                        color: COLORS[BRAND][mode],
                        height: 20,
                        width: 20,
                    } })) : (React.createElement(ChevronRightIcon, { style: {
                        color: COLORS[SECONDARY_TEXT][mode],
                        height: 15,
                        width: 15,
                    } })))) : null))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(RoutineHeader);
