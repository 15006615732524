import moment from 'moment';
import { Alert } from 'react-native';
import { FREE_PLAYLIST_IDS } from '../selectors/playlists';
import { ROOT_SIGNUP_SCREEN, SUBSCRIPTION_SCREEN_HOME } from './constants';
export const redirectIfRestricted = (user, navigation, defaultCallback) => {
    if (isUserPseudo(user)) {
        Alert.alert('Please create an account', 'Create an account to access this content. You will not be charged.');
        navigation.navigate(ROOT_SIGNUP_SCREEN);
    }
    else if (isUserExpired(user)) {
        navigation.navigate(SUBSCRIPTION_SCREEN_HOME);
    }
    else {
        defaultCallback();
    }
};
export const redirectIfMeditationRestricted = (meditation, user, navigation, defaultCallback) => {
    if (FREE_PLAYLIST_IDS.includes(meditation.playlist_id)) {
        return defaultCallback();
    }
    return redirectIfRestricted(user, navigation, defaultCallback);
};
export const isUserSubscribedOrFree = (user) => user.is_subscribed || user.is_free;
export const isUserPseudo = (user) => user.is_pseudo;
export const isUserRestricted = (user) => isUserPseudo(user) || isUserExpired(user);
export const isUserExpired = (user) => {
    const unsubscribedGrace = getUnsubscribedGrace(user);
    return (isTrialExpired(user)
        && !user.is_subscribed
        && !user.is_free
        && !user.referral_success_at
        && (!unsubscribedGrace || unsubscribedGrace.isBefore(moment())));
};
export const isTrialExpired = (user) => {
    if (user.trial_expires_at) {
        return moment(user.trial_expires_at).isBefore(moment());
    }
    const trialLength = getDefaultTrialLength(user);
    return moment(user.created_at).isBefore(trialLength);
};
export const isMeditationRestricted = (meditation, user) => !FREE_PLAYLIST_IDS.includes(meditation.playlist_id) && isUserRestricted(user);
export const isPlaylistRestricted = (playlist, user) => !playlist.is_free && isUserRestricted(user);
export const UNSUBSCRIBE_GRACE_DAYS = 3;
const getUnsubscribedGrace = (user) => {
    if (user.unsubscribed_at) {
        return moment(user.unsubscribed_at).add(UNSUBSCRIBE_GRACE_DAYS, 'days');
    }
};
export const trialDaysLeft = (user) => {
    if (user.trial_expires_at) {
        const trialExpiresAt = moment(user.trial_expires_at);
        if (trialExpiresAt.isBefore(moment()))
            return 0;
        return trialExpiresAt.diff(moment(), 'days');
    }
    const trialLength = getDefaultTrialLength(user);
    if (moment(user.created_at).isBefore(trialLength)) {
        return 0;
    }
    return moment(user.created_at).diff(trialLength, 'days') + 1;
};
const getDefaultTrialLength = (user) => (moment(user.created_at).isBefore(moment('11-20-2019'))
    ? moment().subtract(2, 'weeks')
    : moment().subtract(1, 'weeks'));
