import { merge } from 'lodash';
import { UPDATE_TRACK_PLAYER_STATUS, INITIALIZE_TRACK_PLAYER, TRACK_PLAYER_STATUSES, } from '../actions/trackPlayerActions';
const initialState = {
    status: TRACK_PLAYER_STATUSES.UNINITIALIZED,
};
const TrackPlayerReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case UPDATE_TRACK_PLAYER_STATUS:
            return { ...newState, status: action.status };
        case INITIALIZE_TRACK_PLAYER:
            return { player: action.player, status: action.status };
        default:
            return newState;
    }
};
export default TrackPlayerReducer;
