// @ts-nocheck
import React from 'react';
import { ScrollView } from 'react-native';
// import Swiper from 'react-native-web-swiper';
const SwiperWrapper = (props) => {
    const renderItems = () => {
        const items = [];
        for (let i = 0; i < props.data.length; i++) {
            const data = props.data[i];
            items.push(props.renderItem({ item: data, index: i }));
        }
        return items;
    };
    return React.createElement(ScrollView, { horizontal: true }, renderItems());
};
export default SwiperWrapper;
