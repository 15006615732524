import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function Replay30Icon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 277 299", ...otherProps },
        React.createElement(Path, { d: "M105.519 69.2053C126.065 61.727 148.455 61.0363 169.332 67.2366C190.209 73.437 208.448 86.1943 221.315 103.596C234.182 120.998 240.984 142.107 240.701 163.758C240.418 185.409 233.064 206.436 219.744 223.686C206.423 240.937 187.853 253.482 166.817 259.44C145.781 265.398 123.412 264.449 103.065 256.734C82.7175 249.02 65.4873 234.956 53.9574 216.651", stroke: color, strokeWidth: "12", fill: "transparent" }),
        React.createElement(Path, { d: "M61.6048 83.788L116.639 96.4983L95.5937 38.6758L61.6048 83.788Z", fill: color }),
        React.createElement(Path, { d: "M118.75 165.93L115.39 160.26L128.76 147.94H107.06V141.64H137.86V147.8L124.91 159.77C128.597 159.77 131.84 160.983 134.64 163.41C137.487 165.837 138.91 169.22 138.91 173.56C138.91 177.573 137.44 181.003 134.5 183.85C131.56 186.697 127.547 188.12 122.46 188.12C117.467 188.12 113.453 186.743 110.42 183.99C107.433 181.19 105.823 177.807 105.59 173.84L112.31 172.3C112.45 175.24 113.453 177.62 115.32 179.44C117.233 181.213 119.59 182.1 122.39 182.1C125.377 182.1 127.71 181.307 129.39 179.72C131.117 178.087 131.98 176.08 131.98 173.7C131.98 170.947 131.07 168.847 129.25 167.4C127.43 165.953 125.26 165.23 122.74 165.23C121.387 165.23 120.057 165.463 118.75 165.93ZM155.971 149.62C153.311 152.42 151.981 157.32 151.981 164.32C151.981 171.32 153.311 176.22 155.971 179.02C157.838 180.98 160.241 181.96 163.181 181.96C166.121 181.96 168.525 180.98 170.391 179.02C173.051 176.22 174.381 171.32 174.381 164.32C174.381 157.32 173.051 152.42 170.391 149.62C168.525 147.66 166.121 146.68 163.181 146.68C160.241 146.68 157.838 147.66 155.971 149.62ZM149.251 180.77C146.545 176.757 145.191 171.273 145.191 164.32C145.191 157.367 146.545 151.883 149.251 147.87C152.471 143.017 157.115 140.59 163.181 140.59C169.248 140.59 173.891 143.017 177.111 147.87C179.818 151.883 181.171 157.367 181.171 164.32C181.171 171.273 179.818 176.757 177.111 180.77C173.891 185.623 169.248 188.05 163.181 188.05C157.115 188.05 152.471 185.623 149.251 180.77Z", fill: color })));
}
Replay30Icon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default Replay30Icon;
