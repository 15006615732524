import React from 'react';
import { connect } from 'react-redux';
import { BackHandler, StyleSheet, ScrollView, View, Alert, Platform, Linking, } from 'react-native';
import { getVersion } from 'react-native-device-info';
import CodePush from '@utils/libraries/codepush';
import { merge } from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RoutinesFeedbackKey } from '@utils/asyncKeys';
import RNFS from '../../utils/libraries/fs';
import Header from '../baseComponents/header';
import TextButton from '../baseComponents/textButton';
import { getMeditations } from '../../selectors/playlists';
import { SECONDARY_BUTTON, COLORS, PRIMARY_TEXT, BACKGROUND, BRAND, WARNING_RED, GRAYS, } from '../../utils/colors';
import { trialDaysLeft } from '../../utils/enforcement';
import { HOME_SCREEN, SUBSCRIPTION_SCREEN_HOME, ROOT_SIGNUP_SCREEN } from '../../utils/constants';
import { deleteUser, logout, updateUser } from '../../actions/userActions';
import { stripPersist } from '../../utils';
import { meditationDownloadCount } from '../../utils/download';
import { removeAllPlaylistDownloadsAndUpdate } from '../../actions/playlistsActions';
import BaseStyles from '../../utils/baseStyles';
import Analytics from '../../utils/analytics';
import FLAGS from '../../utils/flags';
import BaseText from '../baseComponents/baseText';
import ReminderButtons from './ReminderButtons';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    body: {
        padding: 20,
    },
    currentContainer: {},
    center: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        marginVertical: '3%',
    },
});
class Account extends React.Component {
    static navigationOptions = {
        title: 'Account',
    };
    constructor(props) {
        super(props);
        this.state = {
            downloadedCount: null,
            isRemoved: false,
            codepushLabel: '',
        };
    }
    async componentDidMount() {
        Analytics.screenHit('Account', 'AccountScreen');
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        const downloadedCount = await meditationDownloadCount(this.props.playlists);
        this.setState({ downloadedCount });
        const codePushMetadata = await CodePush.getUpdateMetadata();
        this.setState({ codepushLabel: codePushMetadata?.label ?? '' });
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
    removeMeditations = async () => {
        this.setState({ isRemoved: false });
        await this.props.removeAllPlaylistDownloadsAndUpdate(this.props.playlists);
        this.setState({ isRemoved: true });
        const downloadedCount = await meditationDownloadCount(this.props.playlists);
        this.setState({ downloadedCount });
        Analytics.event(Analytics.Events.ACCOUNT_REMOVE_MEDITATIONS_TOUCH);
    };
    removeMeditation = async (meditation) => {
        const localFilepath = `${RNFS.DocumentDirectoryPath}/${meditation.filepath}`;
        await RNFS.exists(localFilepath).then((exists) => {
            if (exists) {
                RNFS.unlink(localFilepath);
            }
        });
        const localFilepath20 = `${RNFS.DocumentDirectoryPath}/${meditation['20_filepath']}`;
        await RNFS.exists(localFilepath20).then((exists) => {
            if (exists) {
                RNFS.unlink(localFilepath20);
            }
        });
    };
    handleRoutinesOptOut = () => {
        Alert.alert('Do you want to opt out of Beta Routines?', 'If you opt out, you will return to the default Stoa experience. You will not be able to rejoin from the app. \nEmail us at stoa@stoameditation.com for any feedback', [
            {
                text: 'Cancel',
                onPress: () => console.log('User routine opt-out canceled'),
            },
            {
                text: 'Yes',
                onPress: async () => {
                    const newUser = merge({}, this.props.user, { is_beta: false });
                    this.props.updateUser(newUser);
                    Analytics.event(Analytics.Events.ROUTINES_OPT_OUT, {
                        userId: this.props.user.id,
                        location: 'AccountScreen',
                    });
                    await AsyncStorage.removeItem(RoutinesFeedbackKey);
                },
                style: 'destructive',
            }
        ]);
    };
    handleBackPress = () => {
        this.props.navigation.navigate(HOME_SCREEN);
        Analytics.event(Analytics.Events.ACCOUNT_BACKPRESS, {});
        return true;
    };
    arePlaylistsDownloaded = () => {
        Object.keys(this.props.playlists).map((playlistId) => {
            const playlist = this.props.playlists[playlistId];
            if (!playlist.is_downloaded) {
                return false;
            }
            return true;
        });
        return true;
    };
    renderReminders = () => {
        if (!FLAGS.REMINDERS)
            return null;
        return (React.createElement(View, { style: BaseStyles.paddingTopContainer },
            React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Reminders:"),
            React.createElement(ReminderButtons, null)));
    };
    renderVersion = () => {
        if (!FLAGS.VERSION)
            return null;
        return (React.createElement(View, { style: styles.center },
            React.createElement(BaseText, { style: [BaseStyles.disclaimerText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] },
                "Version",
                ' ',
                getVersion(),
                this.state.codepushLabel ? `-${this.state.codepushLabel}` : null)));
    };
    renderDownload = () => {
        if (!FLAGS.DOWNLOAD)
            return null;
        return (React.createElement(View, { style: BaseStyles.paddingTopContainer },
            React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Downloads:"),
            this.state.downloadedCount !== null ? (React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, `You have ${this.state.downloadedCount} downloaded meditations`)) : null,
            this.state.isRemoved ? null : this.arePlaylistsDownloaded() ? (React.createElement(View, { style: styles.center },
                React.createElement(TextButton, { buttonStyle: { backgroundColor: COLORS[SECONDARY_BUTTON][this.props.mode] }, buttonTextStyle: { color: COLORS[BRAND][this.props.mode] }, title: "Remove All", onPress: this.removeMeditations }))) : null));
    };
    renderUserRoutines = () => {
        if (!this.props.user.is_beta)
            return null;
        return (React.createElement(View, { style: BaseStyles.paddingTopContainer },
            React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "User Routines:"),
            React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "You are currently enrolled in the experimental Routines feature"),
            React.createElement(View, { style: styles.center },
                React.createElement(TextButton, { buttonStyle: { backgroundColor: COLORS[SECONDARY_BUTTON][this.props.mode] }, buttonTextStyle: { color: COLORS[BRAND][this.props.mode] }, title: "Opt Out", onPress: this.handleRoutinesOptOut }))));
    };
    renderSubscription = () => (React.createElement(React.Fragment, null,
        React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Subscription:"),
        React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, this.props.user.is_pseudo
            ? 'You are not subscribed'
            : `You signed in with the email: ${this.props.user.email}`),
        React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, this.props.user.is_pseudo
            ? 'You need to sign in with an email to subscribe'
            : this.props.user.is_subscribed
                ? 'You are subscribed'
                : this.props.user.is_free
                    ? 'You have free account'
                    : `You do not have a subscription \u2014 your free trial has ${trialDaysLeft(this.props.user)} days left`),
        this.props.user.is_pseudo ? (React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: "Sign in", onPress: () => {
                    this.props.navigation.navigate(ROOT_SIGNUP_SCREEN);
                    Analytics.event(Analytics.Events.PSEUDO_SIGN_IN_TOUCH);
                } }))) : !this.props.user.is_subscribed ? (React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: "Subscribe", onPress: () => {
                    this.props.navigation.navigate(SUBSCRIPTION_SCREEN_HOME);
                    Analytics.event(Analytics.Events.ACCOUNT_SUBSCRIPTION_TOUCH);
                } }))) : null));
    handleConfirmDelete = () => {
        Alert.alert('Confirm that you want your account deleted', 'Double checking to ensure that you do not delete your account on accident.\n\nPress "Yes, delete" to delete your account.\n\nYour account will not be recoverable.', [
            { text: 'No, do not delete', onPress: () => console.log('Delete canceled') },
            { text: 'Yes, delete', onPress: () => this.props.deleteUser(this.props.user), style: 'destructive' }
        ]);
    };
    handleDelete = () => {
        Alert.alert('Are you sure you want to delete your account and its associated data?', 'Your data will not be recoverable.\n Note: this action does not alter subscription status.', [
            { text: 'No thanks', onPress: () => console.log('Delete canceled') },
            { text: 'Delete', onPress: this.handleConfirmDelete, style: 'destructive' }
        ]);
    };
    renderAccountManagement = () => (React.createElement(View, { style: BaseStyles.paddingTopContainer },
        React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Account Management:"),
        React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: "View Subscriptions", onPress: () => (Platform.OS === 'ios'
                    ? Linking.openURL('https://apps.apple.com/account/subscriptions')
                    : Linking.openURL('https://play.google.com/store/account/subscriptions')) })),
        React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: "Logout", onPress: this.props.logout, buttonStyle: { backgroundColor: COLORS[SECONDARY_BUTTON][this.props.mode] }, buttonTextStyle: { color: COLORS[GRAYS][this.props.mode] } })),
        React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: "Delete", onPress: this.handleDelete, buttonStyle: { backgroundColor: COLORS[SECONDARY_BUTTON][this.props.mode] }, buttonTextStyle: { color: WARNING_RED } }))));
    render() {
        return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(ScrollView, { contentContainerStyle: styles.currentContainer },
                React.createElement(Header, { onPressCallback: this.handleBackPress, title: "Account", mode: this.props.mode }),
                React.createElement(View, { style: styles.body },
                    this.renderSubscription(),
                    this.renderReminders(),
                    this.renderDownload(),
                    this.renderUserRoutines(),
                    this.renderAccountManagement())),
            this.renderVersion()));
    }
}
const mapStateToProps = (state) => {
    const meditations = getMeditations(stripPersist(state.playlists));
    return {
        meditations,
        mode: stripPersist(state.colors).mode,
        playlists: stripPersist(state.playlists),
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    deleteUser: (user) => dispatch(deleteUser(user)),
    updateUser: (user) => dispatch(updateUser(user)),
    logout: () => dispatch(logout()),
    removeAllPlaylistDownloadsAndUpdate: (playlists) => dispatch(removeAllPlaylistDownloadsAndUpdate(playlists)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Account);
