import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function TheoryIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 26 20", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M3.97718 0.000137329C3.97648 0.000137329 3.97569 0.000137329 3.97499 0.000137329C3.72908 0.000137329 3.4978 0.0959962 3.32315 0.270206C3.14658 0.446341 3.04932 0.680867 3.04932 0.930538V14.8817C3.04932 15.3933 3.46716 15.8106 3.98086 15.8119C6.14648 15.817 9.77476 16.2684 12.2778 18.8878V4.28744C12.2778 4.11402 12.2335 3.9511 12.1499 3.81629C10.0955 0.507883 6.14771 0.00521479 3.97718 0.000137329Z", fill: color }),
        React.createElement(Path, { d: "M22.951 14.8817V0.9304C22.951 0.68073 22.8538 0.446204 22.6772 0.270068C22.5025 0.0958588 22.2711 0 22.0254 0C22.0246 0 22.0239 0 22.0232 0C19.8527 0.005165 15.9049 0.507833 13.8505 3.81624C13.7669 3.95105 13.7227 4.11397 13.7227 4.28739V18.8876C16.2257 16.2683 19.8539 15.8169 22.0196 15.8117C22.5332 15.8104 22.951 15.3932 22.951 14.8817Z", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M25.0696 3.21739H24.395V14.8816C24.395 16.1878 23.3307 17.253 22.0225 17.2562C20.1856 17.2606 17.1568 17.6198 15.0117 19.65C18.7216 18.7416 22.6325 19.3321 24.8613 19.84C25.1396 19.9034 25.4272 19.8379 25.6501 19.6602C25.8723 19.4829 25.9998 19.218 25.9998 18.9335V4.14762C25.9999 3.63471 25.5825 3.21739 25.0696 3.21739Z", fill: color }),
        React.createElement(Path, { d: "M1.60483 14.8816V3.21739H0.930225C0.417402 3.21739 0 3.63471 0 4.14762V18.9333C0 19.2178 0.127462 19.4827 0.349644 19.66C0.57244 19.8376 0.859754 19.9033 1.13849 19.8398C3.36732 19.3318 7.27827 18.7414 10.988 19.6497C8.84301 17.6196 5.81422 17.2605 3.97731 17.2561C2.66917 17.253 1.60483 16.1878 1.60483 14.8816Z", fill: color })));
}
TheoryIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default TheoryIcon;
