import React, { useState } from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import useDimensions from '@services/useDimensions';
import { BACKGROUND, BLACK, COLORS } from '@utils/colors';
import { stripPersist } from '@utils/index';
import { connect } from 'react-redux';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        backgroundColor: 'transparent',
    },
    bg: {
        flex: 1,
        top: 0,
        left: 0,
        flexDirection: 'column',
    },
});
const OnboardingBackground = ({ children, mode }) => {
    const { height, width } = useDimensions();
    const [loading, setLoading] = useState(true);
    return (React.createElement(ImageBackground, { style: [styles.bgImage, { height, width, backgroundColor: loading ? 'transparent' : COLORS[BACKGROUND][mode] }], source: require('@assets/images/background.png'), resizeMode: "cover", defaultSource: require('@assets/images/background.png'), onLoadEnd: () => setLoading(true) },
        React.createElement(BaseOverlay, { style: [styles.bg, { backgroundColor: BLACK, opacity: 0.85 }] }),
        children));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(OnboardingBackground);
