import Amplitude from 'amplitude-js';
import { AppEventsLogger } from 'react-native-fbsdk-next';
import Purchases from 'react-native-purchases';
import appsFlyer from 'react-native-appsflyer';
import { adapty } from 'react-native-adapty';
import firebaseCrashlytics from './libraries/firebaseCrashlytics';
import firebaseAnalytics from './libraries/firebaseAnalytics';
import { IS_DEV } from './constants';
import Bugsnag from './libraries/bugsnag';
import AnalyticsEvents from './AnalyticsEvents';
const Crashlytics = firebaseCrashlytics.crashlytics();
const FirebaseAnalytics = firebaseAnalytics();
class Analytics {
    static Events = AnalyticsEvents;
    static async setUser(user) {
        if (IS_DEV) {
            console.log(`[DEV] set user: ${user.id}`);
            return;
        }
        if (!user.id)
            return;
        const userIdStr = user.id ? user.id.toString() : '';
        const emailStr = user.email ? user.email.toString() : '';
        if (FirebaseAnalytics.setUserId) {
            FirebaseAnalytics.setUserId(userIdStr);
        }
        Amplitude.getInstance().setUserId(user.id);
        Bugsnag.setUser(userIdStr, emailStr);
        await Purchases.logIn(userIdStr);
        if (emailStr) {
            Purchases.setEmail(emailStr);
        }
        appsFlyer.setCustomerUserId(user.id.toString());
        await adapty.profile.identify(user.id.toString());
    }
    static screenHit(page, component, properties) {
        if (IS_DEV) {
            console.log(`[DEV] page hit for page: ${page}`);
        }
        else {
            FirebaseAnalytics.logScreenView({ screen_name: page, screen_class: component });
            Amplitude.getInstance().logEvent(`${page} visit`, {
                page,
                component,
                ...properties,
            });
        }
    }
    static event(eventName, params = {}) {
        if (IS_DEV) {
            console.log(`[DEV] event for: ${eventName}`);
            if (params)
                console.log(`[DEV] params: ${JSON.stringify(params)}`);
        }
        else {
            try {
                FirebaseAnalytics.logEvent(Analytics.formatFirebaseName(eventName), params);
            }
            catch (error) {
                console.log(error);
            }
            Amplitude.getInstance().logEvent(eventName, params);
        }
    }
    static recordDiagnostic(name, metadata = {}) {
        Bugsnag.notify(new Error(name), (event) => {
            event.severity = 'info';
            event.addMetadata('metadata', metadata);
        });
    }
    static recordError(error, metadata = {}) {
        if (IS_DEV) {
            console.log(`[DEV] error: ${error}`);
        }
        else {
            if (error instanceof Error) {
                Crashlytics.recordError(error);
            }
            else if (typeof error !== 'string' && 'message' in error) {
                Analytics.event(error.message);
                Crashlytics.recordError(error);
            }
            Bugsnag.notify(error, (event) => {
                event.addMetadata('metadata', metadata);
            });
        }
    }
    static start() {
        if (IS_DEV) {
            console.log('[DEV] psuedo analytics start');
        }
        else {
            Bugsnag.start();
            Amplitude.getInstance().init('807ae7b01f4863f728c5001eeb3e0347');
            FirebaseAnalytics.setAnalyticsCollectionEnabled(true);
            Crashlytics.setCrashlyticsCollectionEnabled(true);
        }
    }
    static async revenue(purchaseMade) {
        if (IS_DEV)
            return;
        const offerings = await Purchases.getOfferings();
        const products = offerings?.current?.availablePackages.map((productPackage) => productPackage.product);
        const product = products?.filter((sku) => sku.identifier === purchaseMade.product_id)[0];
        const price = purchaseMade.price || product?.price;
        try {
            const revenue = new Amplitude.Revenue().setProductId(purchaseMade.product_id).setPrice(price);
            Amplitude.getInstance().logRevenueV2(revenue);
        }
        catch (e) {
            console.log(e);
        }
        try {
            if (product?.currency_code) {
                AppEventsLogger.logPurchase(price, product?.currency_code, { param: 'value' });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    static formatFirebaseName(name) {
        return name.split(' ').join('_').toLowerCase().substring(0, 40);
    }
}
export default Analytics;
