import AsyncStorage from '@react-native-async-storage/async-storage';
import Analytics from '@utils/analytics';
import { useProgress } from '@utils/libraries/trackPlayer';
import { useEffect, useState } from 'react';
import { AppState } from 'react-native';
const REQ_PERCENTAGE_RECORDED = 0.66;
export const REQ_PERCENTAGE_COMPLETE = 0.999;
export const STOP_SAVING_POS_PERCENTAGE = 0.99;
const useBackgroundTimer = ({ positionKey, meditationLengthSec, updatePlaybackPosition, handleMeditationEnd, // TODO: should this be moved?
handleRecordedEnd, // TODO: should this be moved?
isSliding, isPlaying, meditation, }) => {
    const [recordedEnd, setRecordedEnd] = useState(false);
    const [appState, setAppState] = useState('active');
    const isLongAudio = meditationLengthSec > 60 * 20;
    const { position, duration, buffered } = useProgress(appState === 'active' ? 500 : isLongAudio ? 60 * 1000 : 5000);
    const percentageComplete = position / meditationLengthSec;
    const appStateChange = (state) => setAppState(state);
    useEffect(() => {
        if (meditation) {
            setRecordedEnd(false);
        }
    }, [meditation?.id]);
    useEffect(() => {
        AppState.addEventListener('change', appStateChange);
        return () => AppState.removeEventListener('change', appStateChange);
    }, []);
    useEffect(() => {
        const onPositionChange = async () => {
            if (!isSliding && position && isPlaying) {
                updatePlaybackPosition(Math.round(position), percentageComplete);
            }
            Analytics.event(Analytics.Events.MEDITATION_COUNT, {
                position,
                meditationId: meditation.id,
                duration,
                buffered,
            });
            const shouldSaveProgress = appState === 'active' ? Math.round(position) % 2 === 0 : true;
            if (position && shouldSaveProgress && percentageComplete < STOP_SAVING_POS_PERCENTAGE && positionKey) {
                await AsyncStorage.setItem(positionKey, position.toString(), (e) => {
                    if (e) {
                        Analytics.recordError(e);
                    }
                });
            }
        };
        if (!recordedEnd && percentageComplete > REQ_PERCENTAGE_RECORDED) {
            handleRecordedEnd(meditation);
            setRecordedEnd(true);
        }
        if (percentageComplete > REQ_PERCENTAGE_COMPLETE || (duration !== 0 && position >= duration)) {
            handleMeditationEnd();
        }
        onPositionChange();
    }, [position]);
};
export default useBackgroundTimer;
