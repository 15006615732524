import SectionTitle from '@components/baseComponents/sectionTitle';
import { stripPersist } from '@utils/index';
import Accordion from 'react-native-collapsible/Accordion';
import React from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, } from 'react-native';
import { connect } from 'react-redux';
import { vs } from 'react-native-size-matters';
import useRoutine from './useRoutines';
import RoutineStep from './RoutineStep';
import RoutineHeader from './RoutineHeader';
const styles = StyleSheet.create({
    accordionContainer: {
        alignSelf: 'center',
        width: '85%',
    },
    sectionContainer: {
        marginVertical: vs(5),
    },
});
const DailyRoutineManager = ({ user }) => {
    const { error, routine, isLoading, finishRoutineStep, activeIndex, setActiveIndex, quoteModal, toggleQuoteModal, } = useRoutine({ user });
    const handleOnPressStep = (index, step) => {
        if (step.type === 'quote') {
            setActiveIndex([index]);
            toggleQuoteModal(true);
        }
        else {
            setActiveIndex([index]);
        }
    };
    const renderSteps = () => {
        if (!routine?.steps)
            return null;
        const steps = routine?.steps
            ? Object.keys(routine?.steps).map((key) => ({
                ...routine?.steps[key],
            }))
            : [];
        return (React.createElement(Accordion, { activeSections: activeIndex, sections: steps, renderHeader: (content, index, isActive) => (React.createElement(RoutineHeader, { step: content, isActive: isActive, index: index, handleOnPressStep: handleOnPressStep })), onChange: () => { }, containerStyle: styles.accordionContainer, sectionContainerStyle: styles.sectionContainer, renderContent: (content, index, isActive) => (React.createElement(RoutineStep, { key: content.id, step: content, isActive: isActive, handleFinish: finishRoutineStep, toggleQuoteModal: toggleQuoteModal, quoteModal: quoteModal })) }));
    };
    if (error) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionTitle, { title: "Daily Routine" }),
        React.createElement(ScrollView, null, isLoading ? React.createElement(ActivityIndicator, { size: "large" }) : renderSteps())));
};
const mapStateToProps = (state) => ({
    user: stripPersist(state.user),
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(DailyRoutineManager);
