import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Image, StyleSheet, View, ActivityIndicator, } from 'react-native';
import { QuotationIcon } from '@assets/svg';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import { s } from 'react-native-size-matters';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import { requestTrackingPermission } from 'react-native-tracking-transparency';
import appsFlyer from 'react-native-appsflyer';
import { Settings } from 'react-native-fbsdk-next';
import { adapty } from 'react-native-adapty';
import { COLORS, BRAND, LIGHT_GRAY, WHITE, SECONDARY_TEXT, } from '../../utils/colors';
import { isLoggedIn } from '../../utils/auth';
import { PITCH_SCREEN } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    largeText: {
        paddingTop: 20,
        fontSize: Fonts.size.huge,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
        textAlign: 'center',
    },
    authorText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
    },
    quotesContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    divider: {
        width: 34,
        backgroundColor: WHITE,
        opacity: 0.3,
        height: 1,
    },
    icon: {
        width: 28, height: 28, paddingVertical: 12,
    },
    padding: {
        paddingVertical: 12,
    },
    welcomeContainer: {
        paddingTop: s(50),
        paddingBottom: s(100),
        flexDirection: 'column',
        alignItems: 'center',
    },
});
const onInstallConversionDataCanceller = appsFlyer.onInstallConversionData((installData) => {
    // It's important to include the network user ID
    appsFlyer.getAppsFlyerUID((error, uid) => {
        if (!error) {
            adapty.updateAttribution(uid, installData, 'AppsFlyer');
        }
        else {
            Analytics.recordError(error);
        }
    });
});
const WelcomeScreen = ({ user, mode, unstrippedUser, }) => {
    useEffect(() => {
        if (!isLoggedIn(user)) {
            Analytics.screenHit('Welcome', 'WelcomeScreen');
        }
    }, []);
    useHandleLoggedInOrLoadedUser();
    useEffect(() => {
        const handleTrackingPermission = async () => {
            const trackingStatus = await requestTrackingPermission();
            if (trackingStatus === 'authorized') {
                Settings.setAdvertiserTrackingEnabled(true);
            }
            if (!(trackingStatus === 'authorized' || trackingStatus === 'unavailable')) {
                appsFlyer.anonymizeUser(true);
            }
        };
        handleTrackingPermission();
        return () => {
            onInstallConversionDataCanceller();
        };
    }, []);
    if (unstrippedUser._persist?.rehydrated) {
        return (React.createElement(OnboardingBackground, null,
            React.createElement(View, { style: styles.welcomeContainer },
                React.createElement(View, null,
                    React.createElement(Image, { source: require('@assets/images/WebLogo.png'), style: { width: 100, height: 100 } })),
                React.createElement(BaseText, { style: [styles.largeText, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Welcome to Stoa")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(QuotationIcon, { style: {
                        width: 28, height: 17, color: COLORS[BRAND][mode],
                    } }),
                React.createElement(View, { style: { paddingVertical: 6 } }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "The impediment to action advances action. What stands in the way becomes the way."),
                React.createElement(View, { style: styles.padding },
                    React.createElement(View, { style: styles.divider })),
                React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Marcus Aurelius")),
            React.createElement(ContinueFooter, { index: 0, nextScreenName: PITCH_SCREEN })));
    }
    return (React.createElement(OnboardingBackground, null,
        React.createElement(ActivityIndicator, { size: "large", color: COLORS[BRAND][mode] })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    unstrippedUser: state.user,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps, null)(WelcomeScreen);
