import React from 'react';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isEmpty } from 'lodash';
import { stripPersist } from '../../utils';
import OnboardingModal from './OnboardingModal';
import { setAndGoToMeditation } from '../../actions/meditationActions';
import { isUserExpired, isUserSubscribedOrFree } from '../../utils/enforcement';
import Analytics from '../../utils/analytics';
import { getMeditationsObj } from '../../selectors/playlists';
import { onboardingTheoryMeditation } from '../../selectors/meditations';
class OnboardingContainer extends React.Component {
    onboardingKey;
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.onboardingKey = `onboarding-modal-${this.props.user.id}`;
    }
    async componentDidMount() {
        const isOnboarded = await AsyncStorage.getItem(this.onboardingKey);
        if (!isOnboarded
            && !isUserExpired(this.props.user)
            && !isUserSubscribedOrFree(this.props.user)) {
            this.setState({ isOpen: true });
            Analytics.event('Open onboarding modal', {
                theory_meditation: this.props.theoryMeditation ? this.props.theoryMeditation.title : null,
            });
        }
    }
    finishOnboarding = async () => {
        await AsyncStorage.setItem(this.onboardingKey, 'true');
        this.setState({ isOpen: false });
        Analytics.event('Dismiss onboarding modal');
    };
    render() {
        return (React.createElement(OnboardingModal, { mode: this.props.mode, navigation: this.props.navigation, isOpen: this.state.isOpen, finishOnboarding: this.finishOnboarding, meditation: this.props.meditation, theoryMeditation: this.props.theoryMeditation, setAndGoToMeditation: this.props.setAndGoToMeditation }));
    }
}
const mapStateToProps = (state) => {
    const user = stripPersist(state.user);
    const meditations = getMeditationsObj(stripPersist(state.playlists));
    const theoryMeditation = !isEmpty(meditations)
        ? onboardingTheoryMeditation(meditations, user)
        : null;
    return {
        theoryMeditation,
        user,
    };
};
const mapDispatchToProps = (dispatch) => ({
    setAndGoToMeditation: (meditation, navigation) => dispatch(setAndGoToMeditation(meditation, navigation)),
});
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer);
