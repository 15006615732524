const FLAGS = {
    DOWNLOADED_MEDITATIONS_HOME: false,
    DOWNLOAD: false,
    SHARE: false,
    REMINDERS: false,
    VERSION: false,
    FIREBASE: false,
    PSEUDO_USERS: false,
    BANNERS: false,
    DEFAULT_JS_TIMERS: true,
};
export default FLAGS;
