import MeditationItem from '@components/playlist/MeditationItem';
import { getAllMeditations } from '@selectors/playlists';
import { stripPersist } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
const styles = StyleSheet.create({
    content: {
        flex: 6,
    },
    contentContainer: {
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
});
const MeditationList = ({ currentTag, meditations, playlists }) => {
    const [currentMeditations, setCurrentMeditations] = useState([]);
    useEffect(() => {
        if (currentTag) {
            const newMeditations = meditations.filter((meditation) => currentTag.meditation_ids.includes(meditation.id));
            newMeditations.sort((a, b) => (a.is_listened ? 1 : -1) - (b.is_listened ? 1 : -1));
            setCurrentMeditations(newMeditations);
        }
    }, [currentTag]);
    return (React.createElement(ScrollView, { contentContainerStyle: styles.contentContainer, style: styles.content }, currentMeditations.map((meditation) => {
        const playlist = playlists[meditation.playlist_id];
        return (React.createElement(MeditationItem, { key: `meditation-item-${meditation.id}`, meditation: meditation, playlist: playlist, index: null }));
    })));
};
const mapStateToProps = (state) => {
    const playlists = stripPersist(state.playlists);
    const meditations = getAllMeditations(playlists);
    return {
        meditations,
        playlists,
    };
};
export default connect(mapStateToProps)(MeditationList);
