import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function QuotesIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 22 19", ...otherProps },
        React.createElement(Path, { d: "M18.2683 2.47237C16.2481 0.878043 13.566 0 10.7161 0C7.86623 0 5.18417 0.878043 3.16399 2.47237C1.12366 4.08257 0 6.22957 0 8.51783C0 9.89432 0.422043 11.2615 1.22045 12.4714C1.95729 13.5881 3.01476 14.5747 4.28632 15.3338C4.22764 15.7448 3.91169 16.9474 2.21376 18.3807C2.12638 18.4545 2.09075 18.5729 2.12298 18.6827C2.1552 18.7924 2.24916 18.8728 2.36253 18.8877C2.37281 18.889 2.61975 18.9207 3.05854 18.9207C4.30971 18.9207 6.86657 18.6723 9.99227 17.0165C10.2335 17.0293 10.4766 17.0357 10.7161 17.0357C13.566 17.0357 16.248 16.1577 18.2683 14.5634C20.3086 12.9531 21.4323 10.8061 21.4323 8.51783C21.4323 6.22957 20.3086 4.08257 18.2683 2.47237Z", fill: color })));
}
QuotesIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default QuotesIcon;
