// @ts-nocheck
import React from 'react';
import { StyleSheet } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { BOTTOM_TABS, HOME_SCREEN, MEDITATION_STACK, PLAYLISTS_SCREEN, TAB_ICON_SIZE, THEORY_PLAYLISTS_SCREEN, THEORY_STACK, } from '../utils/constants';
import { stripPersist } from '../utils';
import { BRAND, COLORS, FOOTER_GRAY, BACKGROUND } from '../utils/colors';
import DrawerNavigator from './DrawerNavigator';
import MeditationNavigator from './MeditationNavigator';
import TheoryNavigator from './TheoryNavigator';
import QuotesNavigator from './QuotesNavigator';
import EntryNavigator from './EntryNavigator';
import store from '../store/store';
import { HomeIcon, MeditationIcon, TheoryIcon, QuotesIcon, JournalIcon } from '../assets/svg';
const styles = StyleSheet.create({
    tabBarStyle: {
        paddingTop: 10,
        paddingBottom: 10 + (isIphoneX() ? 10 : 0),
        height: 65 + (isIphoneX() ? 10 : 0),
        justifyContent: 'center',
        alignItems: 'center',
    },
});
const Tab = createBottomTabNavigator();
const TabsNavigator = () => {
    const { mode } = stripPersist(store.getState().colors);
    return (React.createElement(Tab.Navigator, { tabBarOptions: {
            activeTintColor: COLORS[BRAND][mode],
            inactiveTintColor: COLORS[FOOTER_GRAY][mode],
            tabStyle: { borderTopColor: COLORS[FOOTER_GRAY][mode] },
            style: [
                styles.tabBarStyle,
                {
                    backgroundColor: COLORS[BACKGROUND][mode],
                    borderTopColor: COLORS[FOOTER_GRAY][mode],
                },
            ],
        }, initialRouteName: HOME_SCREEN, headerMode: "none" },
        React.createElement(Tab.Screen, { options: {
                title: BOTTOM_TABS.home.title,
                tabBarIcon: ({ color }) => (React.createElement(HomeIcon, { style: { color, height: TAB_ICON_SIZE, width: TAB_ICON_SIZE } })),
            }, name: BOTTOM_TABS.home.name, component: DrawerNavigator }),
        React.createElement(Tab.Screen, { options: {
                title: BOTTOM_TABS.meditation.title,
                tabBarIcon: ({ color }) => (React.createElement(MeditationIcon, { style: { color, height: TAB_ICON_SIZE, width: TAB_ICON_SIZE } })),
            }, name: BOTTOM_TABS.meditation.name, component: MeditationNavigator, listeners: ({ navigation }) => ({
                tabPress: (e) => {
                    e.preventDefault();
                    navigation.navigate(MEDITATION_STACK, { screen: PLAYLISTS_SCREEN });
                },
            }) }),
        React.createElement(Tab.Screen, { options: {
                title: BOTTOM_TABS.theory.title,
                tabBarIcon: ({ color }) => (React.createElement(TheoryIcon, { style: { color, height: TAB_ICON_SIZE, width: TAB_ICON_SIZE } })),
            }, name: BOTTOM_TABS.theory.name, component: TheoryNavigator, listeners: ({ navigation }) => ({
                tabPress: (e) => {
                    e.preventDefault();
                    navigation.navigate(THEORY_STACK, { screen: THEORY_PLAYLISTS_SCREEN });
                },
            }) }),
        React.createElement(Tab.Screen, { options: {
                title: BOTTOM_TABS.quotes.title,
                tabBarIcon: ({ color }) => (React.createElement(QuotesIcon, { style: { color, height: TAB_ICON_SIZE, width: TAB_ICON_SIZE } })),
            }, name: BOTTOM_TABS.quotes.name, component: QuotesNavigator }),
        React.createElement(Tab.Screen, { options: {
                title: BOTTOM_TABS.journal.title,
                tabBarIcon: ({ color }) => (React.createElement(JournalIcon, { style: { color, height: TAB_ICON_SIZE, width: TAB_ICON_SIZE } })),
            }, name: BOTTOM_TABS.journal.name, component: EntryNavigator })));
};
export default TabsNavigator;
