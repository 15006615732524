import React from 'react';
import Analytics from '../../utils/analytics';
import FallbackComponent from './FallbackComponent';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error) {
        if (error) {
            const message = error.message || error.toString();
            Analytics.recordError(message);
        }
    }
    resetError = () => {
        this.setState({ hasError: false });
    };
    render() {
        return this.state.hasError ? (React.createElement(FallbackComponent, { resetError: this.resetError })) : (this.props.children);
    }
}
export default ErrorBoundary;
