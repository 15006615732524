import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BRAND, COLORS } from '@utils/colors';
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 30,
        justifyContent: 'flex-start',
        height: 4,
        alignItems: 'center',
    },
});
const ProgressBar = ({ percent, mode }) => {
    return (React.createElement(View, { style: styles.container },
        React.createElement(View, { style: { height: 2, width: `${percent}%`, backgroundColor: COLORS[BRAND][mode] } })));
};
export default ProgressBar;
