import { merge } from 'lodash';
import { CONNECTION_CHANGE } from '../actions/connectionActions';
const defaultState = {
    isConnected: true,
};
export const ConnectionReducer = (state = defaultState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case CONNECTION_CHANGE:
            return {
                isConnected: action.isConnected,
                lastFetchAt: action.lastFetchAt,
            };
        default:
            return newState;
    }
};
