import { RECEIVE_LISTEN_HISTORY } from '@actions/listenHistoryActions';
import { merge } from 'lodash';
import { coerceStateToDataFormat } from '../utils';
const initialState = {
    data: {},
};
export const ListenHistoryReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_LISTEN_HISTORY:
            return { data: action.listenHistory };
        default:
            return newState;
    }
};
