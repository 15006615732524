import { stripPersist } from '@utils/index';
import Analytics from '@utils/analytics';
import { fetchPost } from '@utils/request';
// @ts-nocheck
import { merge } from 'lodash';
import { host, MEDITATION_NAV_CONFIG } from '../utils/constants';
import { escapeEmail } from '../utils/email';
import { removeContinueMeditation } from './continuePlayingActions';
import { setPlaylistId } from './playlistActions';
import { receiveMeditation } from './playlistsActions';
// TODO: remove, this is identical to recieve meditation
export const RECORD_LISTEN = 'RECORD_LISTEN';
export const SET_MEDITATION = 'SET_MEDITATION';
export const setMeditation = (props) => {
    const { id, exerciseType } = props;
    return {
        type: SET_MEDITATION,
        id,
        exerciseType,
    };
};
export const recordListen = (meditation) => ({
    type: RECORD_LISTEN,
    meditation,
});
export const recordUnlisten = (meditation) => ({
    type: RECORD_LISTEN,
    meditation,
});
export function optimisticFavoriteMeditation(user, meditation) {
    return (dispatch) => {
        const newMeditation = merge({}, meditation, { is_favorite: true });
        dispatch(receiveMeditation(newMeditation));
        return fetch(`${host}/api/user_favorite_meditations?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
                meditation_id: meditation.id,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
}
export function optimisticDestroyFavoriteMeditation(user, meditation) {
    return (dispatch) => {
        const newMeditation = merge({}, meditation, { is_favorite: false });
        dispatch(receiveMeditation(newMeditation));
        return fetch(`${host}/api/user_favorite_meditations?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
                meditation_id: meditation.id,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
}
export const setAndGoToMeditation = (meditation, navigation, isTheory = false) => (dispatch) => {
    dispatch(setPlaylistId(meditation.playlist_id));
    dispatch(setMeditation({
        id: meditation.id,
        meditationType: 'meditations',
    }));
    const navigationConfig = isTheory
        ? MEDITATION_NAV_CONFIG.theory
        : MEDITATION_NAV_CONFIG.meditation;
    navigation.navigate(navigationConfig.navigator, {
        screen: navigationConfig.screen,
        meditation,
        meditationId: meditation.id,
        playlistId: meditation.playlist_id,
    });
};
export const markAsListened = (user, meditation) => async (dispatch, getState) => {
    const response = await fetchPost(user, '/api/user_meditation_listen_statuses/listen', {
        meditation_id: meditation.id.toString(),
        user_id: user.id.toString(),
    });
    if (response?.status === 200) {
        const newMeditation = merge({}, meditation, { is_listened: true });
        dispatch(receiveMeditation(newMeditation));
        if (stripPersist(getState().continuePlaying) === meditation.id) {
            dispatch(removeContinueMeditation());
        }
    }
    else {
        Analytics.recordError(Analytics.Events.FAILED_TO_RECORD_LISTEN, {
            response: JSON.stringify(response),
        });
    }
};
export const markAsUnlistened = (user, meditation) => async (dispatch) => {
    const response = await fetchPost(user, '/api/user_meditation_listen_statuses/unlisten', {
        meditation_id: meditation.id.toString(),
        user_id: user.id.toString(),
    });
    if (response?.status === 200) {
        const newMeditation = merge({}, meditation, { is_listened: false });
        dispatch(receiveMeditation(newMeditation));
    }
    else {
        Analytics.recordError(Analytics.Events.FAILED_TO_RECORD_LISTEN, { response: JSON.stringify(response) });
    }
};
