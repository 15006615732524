import React from 'react';
import { BOOK_SCREEN } from '@utils/constants';
import Analytics from '@utils/analytics';
import Row from './Row';
const BookRow = ({ book, mode, navigation }) => {
    const handleOnPress = () => {
        navigation.navigate(BOOK_SCREEN, {
            bookId: book.id,
        });
        Analytics.event(Analytics.Events.BOOK_ROW_TOUCH, { bookName: book.title, bookId: book.id });
    };
    return (React.createElement(Row, { key: book.id, title: book.title, subtitle: book.subtitle, mode: mode, imageUrl: book.image_url, handleOnPress: handleOnPress }));
};
export default BookRow;
