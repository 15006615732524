import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { createEntry, createEntryOffline } from '../../actions/entryActions';
import { ENTRY_SCREEN, ENTRY_STACK } from '../../utils/constants';
import TextButton from '../baseComponents/textButton';
import { stripPersist } from '../../utils';
const CreateEntryButton = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        return () => setIsLoading(false);
    }, []);
    const createEntryWithOffline = () => {
        setIsLoading(true);
        if (props.isConnected) {
            props.createEntry(props.user, () => {
                setIsLoading(false);
                props.navigation.navigate(ENTRY_STACK, { screen: ENTRY_SCREEN });
            });
        }
        else {
            props.createEntryOffline(props.user, () => {
                setIsLoading(false);
                props.navigation.navigate(ENTRY_STACK, { screen: ENTRY_SCREEN });
            });
        }
        props.recordClick();
    };
    const debouncedCreateEntry = debounce(createEntryWithOffline, 50);
    return (React.createElement(TextButton, { buttonStyle: props.buttonStyle || {}, buttonTextStyle: props.buttonTextStyle || {}, isLoading: isLoading, onPress: () => {
            setIsLoading(false);
            debouncedCreateEntry();
        }, title: props.title || 'New Entry' }));
};
const mapStateToProps = (state) => {
    return {
        isConnected: state.connection.isConnected,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    createEntry: (user, callback) => dispatch(createEntry(user, callback)),
    createEntryOffline: (user, callback) => dispatch(createEntryOffline(user, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateEntryButton);
