import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import { QuotationIcon } from '@assets/svg';
import Header from '@components/baseComponents/header';
import { COLORS, LIGHT_GRAY, BRAND, WHITE, SECONDARY_TEXT, } from '../../utils/colors';
import { REVIEWS_SCREEN } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
        textAlign: 'center',
    },
    quotesContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        marginVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    padding: {
        paddingVertical: 12,
    },
    divider: {
        width: 34,
        backgroundColor: WHITE,
        opacity: 0.3,
        height: 1,
    },
    authorText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
    },
    break: {
        height: 24,
    },
});
const PhilosophyScreen = ({ mode, navigation, }) => {
    useEffect(() => {
        Analytics.screenHit('Welcome', 'PhilosophyScreen');
    }, []);
    useHandleLoggedInOrLoadedUser();
    return (React.createElement(OnboardingBackground, null,
        React.createElement(Header, { onPressCallback: () => navigation.goBack(), title: "Philosophy", mode: mode, color: WHITE }),
        React.createElement(View, { style: styles.quotesContainer },
            React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
            React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Stoa is an app for practicing the philosophy of Stoicism through theory and practice.")),
        React.createElement(View, { style: styles.quotesContainer },
            React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
            React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "The theory Stoa provides a philosophical framework for self-improvement and happiness, and practice is the means by which you transform yourself.")),
        React.createElement(View, { style: styles.break }),
        React.createElement(View, { style: styles.quotesContainer },
            React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
            React.createElement(QuotationIcon, { style: {
                    width: 28, height: 17, color: COLORS[BRAND][mode],
                } }),
            React.createElement(View, { style: { paddingVertical: 6 } }),
            React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "We suffer more often in imagination than in reality."),
            React.createElement(View, { style: styles.padding },
                React.createElement(View, { style: styles.divider })),
            React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Seneca")),
        React.createElement(ContinueFooter, { nextScreenName: REVIEWS_SCREEN, index: 2 })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(PhilosophyScreen);
