import React, { useState } from 'react';
import { stripPersist } from '@utils/index';
import { BACKDROP, COLORS, GRAYS, MODAL_QUOTE_BACKGROUND, QUOTE_TEXT, SUBTITLE_TEXT, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { Modal, View, StyleSheet, Pressable, } from 'react-native';
import { useStore } from 'react-redux';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { vs, s } from 'react-native-size-matters';
import Analytics from '@utils/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PartialRoutinesKey } from '@utils/asyncKeys';
import { merge } from 'lodash';
import TextButton from '../baseComponents/textButton';
import BaseText from '../baseComponents/baseText';
const styles = StyleSheet.create({
    authorText: {
        fontSize: Fonts.size.s,
        padding: 8,
        fontWeight: 'bold',
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 20,
        borderTopWidth: 1,
    },
    button: {
        alignSelf: 'center',
    },
    container: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
    },
    contentContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        marginHorizontal: s(18),
    },
    quoteContainer: {
        padding: 30,
        justifyContent: 'center',
        alignContent: 'center',
    },
    title: {
        fontSize: Fonts.size.l,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        textAlign: 'center',
    },
    description: {
        fontSize: Fonts.size.m,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        textAlign: 'center',
    },
    skip: {
        fontSize: Fonts.size.m,
        textDecorationLine: 'underline',
        textAlign: 'center',
    },
    skipContainer: {
        marginTop: vs(4),
        paddingBottom: vs(24),
    },
});
const UserRoutinesModal = ({ visible, user, handleUpdateUser, toggleOptIn, }) => {
    const store = useStore();
    const storeState = store.getState();
    const { mode } = stripPersist(storeState.colors);
    const [type, setType] = useState('opt-in');
    const description = type === 'opt-in'
        ? ' We’ve been working on this for a few months now and are starting to roll it out. If you opt-in, you’ll be given the new experience for about a month and then asked about any feedback you may have. \n\n You can opt out at any time.'
        : 'You can opt out at any time in the Account section. Let us know if you have any feedback at stoa@stoameditation.com';
    const title = type === 'opt-in'
        ? 'You’ve been selected to try out a new feature: Stoa Routines'
        : 'You’re now trying Stoa routines.';
    const handleOptIn = () => {
        setType('opted-in');
        const newUser = merge({}, user, { is_beta: true });
        handleUpdateUser(newUser);
        Analytics.event(Analytics.Events.ROUTINES_OPT_IN, {
            userId: user.id,
        });
    };
    const handleSkipOptIn = async () => {
        toggleOptIn(false);
        Analytics.event(Analytics.Events.ROUTINES_OPT_OUT, {
            userId: user.id,
        });
        await AsyncStorage.setItem(PartialRoutinesKey, 'no');
    };
    return (React.createElement(Modal, { visible: visible, transparent: true, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
        React.createElement(View, { style: [styles.container, { backgroundColor: COLORS[BACKDROP][mode] }] },
            React.createElement(View, { style: [
                    styles.contentContainer,
                    { backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode] },
                ] },
                React.createElement(View, { style: styles.quoteContainer },
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [styles.title, { color: COLORS[QUOTE_TEXT][mode] }] }, title)),
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [
                                styles.description,
                                {
                                    color: COLORS[QUOTE_TEXT][mode],
                                    backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode],
                                },
                            ] }, description))),
                React.createElement(View, { style: [styles.buttonContainer, { borderColor: COLORS[GRAYS][mode] }] },
                    React.createElement(TextButton, { onPress: type === 'opt-in' ? handleOptIn : () => toggleOptIn(false), title: type === 'opt-in' ? "Yes, I'm in" : 'Okay' })),
                type === 'opt-in' ? (React.createElement(Pressable, { style: styles.skipContainer, onPress: handleSkipOptIn },
                    React.createElement(BaseText, { style: [styles.skip, { color: COLORS[SUBTITLE_TEXT][mode] }] }, "Skip"))) : undefined))));
};
export default UserRoutinesModal;
