import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import FastImage from '../../utils/libraries/fastImage';
import BaseStyles from '../../utils/baseStyles';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { MEDITATION_NAV_CONFIG } from '../../utils/constants';
import { COLORS, PRIMARY_TEXT, BACKGROUND, SECONDARY_TEXT, BLUE, } from '../../utils/colors';
import { secondsToStr, hitSlop, stripPersist } from '../../utils';
import { imageToSpacesUrl } from '../../utils/request';
import { setAndGoToMeditation } from '../../actions/meditationActions';
import { setPlaylistId } from '../../actions/playlistActions';
import { CARD_IMAGE_HEIGHT } from '../../utils/styles';
import Analytics from '../../utils/analytics';
import { isMeditationRestricted, redirectIfMeditationRestricted } from '../../utils/enforcement';
import { LockIcon, PlayIcon } from '../../assets/svg';
import BaseText from './baseText';
import { Fonts } from '../../utils/fonts';
import BaseOverlay from './BaseOverlay';
const styles = StyleSheet.create({
    bgImage: {
        borderRadius: IMAGE_BORDER_RADIUS,
        height: CARD_IMAGE_HEIGHT,
        width: 136,
        backgroundColor: BLUE,
    },
    bgImageStyle: {
        opacity: 0.8,
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    titleText: {
        flexGrow: 0.6,
        fontSize: Fonts.size.s,
        fontWeight: '700',
        textAlign: 'center',
    },
    lengthText: {
        flex: 0.4,
        fontWeight: '400',
        fontSize: Fonts.size.s,
        padding: 5,
    },
});
class MeditationCarouselCard extends PureComponent {
    render() {
        if (!this.props.meditation || !this.props.playlist)
            return null;
        const navigationConfig = this.props.playlist.is_theory
            ? MEDITATION_NAV_CONFIG.theory
            : MEDITATION_NAV_CONFIG.meditation;
        const icon = isMeditationRestricted(this.props.meditation, this.props.user) ? (React.createElement(LockIcon, { style: { color: COLORS[SECONDARY_TEXT][this.props.mode], height: 20, width: 20 } })) : (React.createElement(PlayIcon, { style: { color: COLORS[SECONDARY_TEXT][this.props.mode], height: 20, width: 20 } }));
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: [BaseStyles.flexCol, { marginLeft: 5, marginRight: 5 }], onPress: () => {
                redirectIfMeditationRestricted(this.props.meditation, this.props.user, this.props.navigation, () => {
                    this.props.setAndGoToMeditation(this.props.meditation, this.props.navigation, this.props.playlist.is_theory);
                    this.props.navigation.navigate(navigationConfig.navigator, {
                        screen: navigationConfig.screen,
                        params: { isSourceHome: true },
                    });
                    Analytics.event(Analytics.Events.MEDITATION_CAROUSEL_CARD_TOUCH, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                        carousel_title: this.props.carouselTitle,
                    });
                });
            } },
            React.createElement(FastImage, { source: {
                    uri: imageToSpacesUrl(this.props.meditation.image || this.props.playlist.image),
                }, style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }], 
                // @ts-ignore
                imageStyle: styles.bgImageStyle },
                React.createElement(BaseOverlay, null),
                React.createElement(BaseText, { style: [styles.lengthText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, secondsToStr(this.props.meditation.length_sec)),
                React.createElement(View, { style: BaseStyles.flexRow }, icon)),
            React.createElement(View, { style: BaseStyles.flexRow },
                React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, this.props.meditation.title))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    setAndGoToMeditation: (meditation, navigation, isTheory) => dispatch(setAndGoToMeditation(meditation, navigation, isTheory)),
    setPlaylistId: (id) => dispatch(setPlaylistId(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeditationCarouselCard);
