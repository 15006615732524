import React from 'react';
import { stripPersist } from '@utils/index';
import { BACKDROP, COLORS, GRAYS, MODAL_QUOTE_BACKGROUND, QUOTE_TEXT, SUBTITLE_TEXT, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { Modal, View, StyleSheet, Pressable, Linking, } from 'react-native';
import { useStore } from 'react-redux';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { vs, s } from 'react-native-size-matters';
import Analytics from '@utils/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ROUTINES_FEEDBACK_URL } from '@utils/constants';
import { RoutinesFeedbackKey } from '@utils/asyncKeys';
import TextButton from '../baseComponents/textButton';
import BaseText from '../baseComponents/baseText';
const styles = StyleSheet.create({
    authorText: {
        fontSize: Fonts.size.s,
        padding: 8,
        fontWeight: 'bold',
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 20,
        borderTopWidth: 1,
    },
    button: {
        alignSelf: 'center',
    },
    container: {
        justifyContent: 'center',
        alignContent: 'center',
        flex: 1,
    },
    contentContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        marginHorizontal: s(18),
    },
    quoteContainer: {
        padding: 30,
        justifyContent: 'center',
        alignContent: 'center',
    },
    title: {
        fontSize: Fonts.size.l,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        textAlign: 'center',
    },
    description: {
        fontSize: Fonts.size.m,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        textAlign: 'center',
    },
    skip: {
        fontSize: Fonts.size.m,
        textDecorationLine: 'underline',
        textAlign: 'center',
        opacity: 0.5,
    },
    skipContainer: {
        marginTop: vs(4),
        paddingBottom: vs(24),
    },
});
const RoutineOptInFinishedModal = ({ visible, user, toggleFinishModal, }) => {
    const store = useStore();
    const storeState = store.getState();
    const { mode } = stripPersist(storeState.colors);
    const handleDone = async () => {
        toggleFinishModal(false);
        Analytics.event('Routines opt-in finished', {
            userId: user.id,
        });
        await AsyncStorage.setItem(RoutinesFeedbackKey, '3');
        Linking.openURL(ROUTINES_FEEDBACK_URL);
    };
    const handleLater = async () => {
        const count = await AsyncStorage.getItem(RoutinesFeedbackKey);
        if (count) {
            const newCount = parseInt(count, 10) + 1;
            await AsyncStorage.setItem(RoutinesFeedbackKey, newCount.toString());
        }
        else {
            await AsyncStorage.setItem(RoutinesFeedbackKey, '0');
        }
        toggleFinishModal(false);
        Analytics.event('Routines opt-in finished', {
            userId: user.id,
        });
    };
    return (React.createElement(Modal, { visible: visible, transparent: true, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
        React.createElement(View, { style: [styles.container, { backgroundColor: COLORS[BACKDROP][mode] }] },
            React.createElement(View, { style: [
                    styles.contentContainer,
                    { backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode] },
                ] },
                React.createElement(View, { style: styles.quoteContainer },
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [styles.title, { color: COLORS[QUOTE_TEXT][mode] }] }, "You have finished the current Stoa Routines.")),
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [
                                styles.description,
                                {
                                    color: COLORS[QUOTE_TEXT][mode],
                                    backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode],
                                },
                            ] }, "We\u2019re considering rolling these out completely to Stoa users. This will be a large change. Let us know how to improve the experience!"))),
                React.createElement(View, { style: [styles.buttonContainer, { borderColor: COLORS[GRAYS][mode] }] },
                    React.createElement(TextButton, { onPress: handleDone, title: "Give Feedback" })),
                React.createElement(Pressable, { style: styles.skipContainer, onPress: handleLater },
                    React.createElement(BaseText, { style: [styles.skip, { color: COLORS[SUBTITLE_TEXT][mode] }] }, "Later"))))));
};
export default RoutineOptInFinishedModal;
