import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Alert, Platform, TouchableOpacity, View, StyleSheet, } from 'react-native';
import { AppEventsLogger } from 'react-native-fbsdk-next';
import { s } from 'react-native-size-matters';
import Purchases from 'react-native-purchases';
import { DEFAULT_HORIZONTAL_PADDING, ICON_SIZE } from '@utils/styles';
import { CheckMarkIcon } from '@assets/svg';
import OnboardingBackground from '@components/onboarding_ii/OnboardingBackground';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import PurchaseService from '@services/PurchaseService';
import { ScrollView } from 'react-native-gesture-handler';
import { isEmpty } from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SubscriptionKey } from '@utils/asyncKeys';
import Header from '../baseComponents/header';
import { restoreSubscription, purchaseSubscription } from '../../actions/userActions';
import BaseStyles from '../../utils/baseStyles';
import { TERTIARY_TEXT, COLORS, BRAND, SECONDARY_TEXT, LIGHT_GRAY, TRANSPARENT, WHITE, } from '../../utils/colors';
import { TERMS_OF_USE_SCREEN, // confirm this works
PRIVACY_SCREEN, // confirm this works
CONTACT_EMAIL, // confirm this works
SUBSCRIPTION_SCREEN_II, SIGN_IN_SCREEN, HOME_SCREEN, } from '../../utils/constants';
import { hitSlop, stripPersist } from '../../utils';
import { ANNUAL_PACKAGE_TYPE, FULL_SUBSCRIPTION_ENTITLEMENT, LIFETIME_PACKAGE_TYPE, MONTHLY_PACKAGE_TYPE, } from '../../utils/subscription';
import Analytics from '../../utils/analytics';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import SubscriptionListEl from './SubscriptionListEl';
import TextButton from '../baseComponents/textButton';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
    },
    currentContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    iconContainer: {
        position: 'absolute',
        top: -10,
        right: -10,
    },
    paddingTopContainer: {
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: 10,
    },
    offerContentContainer: {
        padding: 10,
        margin: 5,
        borderRadius: IMAGE_BORDER_RADIUS,
        height: s(90),
        flexDirection: 'column',
        justifyContent: 'center',
    },
    offerTitleTextContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textAlign: 'center',
    },
    offerTrialText: {
        fontSize: Fonts.size.m,
        color: WHITE,
        opacity: 0.5,
    },
    offerTitleText: {
        color: WHITE,
    },
    offerPriceTextContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        textAlignVertical: 'bottom',
    },
    offerPriceText: {
        fontSize: Fonts.size.xl,
        fontWeight: 'bold',
        color: WHITE,
        paddingVertical: 5,
    },
    restoreSubscription: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingTop: 10,
        textAlign: 'center',
    },
    smallText: {
        fontSize: Fonts.size.s,
        padding: 10,
    },
    smallTextUnderline: {
        padding: 10,
        fontSize: Fonts.size.s,
        textDecorationLine: 'underline',
    },
    body: {
        padding: 20,
    },
    sectionContainer: {
        marginTop: 20,
    },
    purchaseButtonContainer: {
        paddingVertical: 10,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    quoteContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
        marginBottom: 20,
    },
    quoteText: {
        lineHeight: 20,
        paddingLeft: 10,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    pitchContainer: {
        flexDirection: 'column',
        paddingTop: 10,
    },
    textPadding: {
        paddingLeft: 10,
    },
    continueButton: {
        backgroundColor: 'transparent',
    },
    overlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    offerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    continueButtonsContainer: {
        position: 'absolute',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 25,
        width: '100%',
    },
});
const SubscriptionScreenV2 = (props) => {
    const [refresh, setRefresh] = useState(0);
    const { useProductPackages, useSubscriptionStatus } = PurchaseService();
    const { productPackages, selectedPackage, setSelectedPackage, } = useProductPackages();
    const { isSubscribed, } = useSubscriptionStatus({ refresh });
    useEffect(() => {
        Analytics.screenHit(SUBSCRIPTION_SCREEN_II, 'SubscriptionScreenV2');
    }, []);
    const renderOffer = (title, productPackage, lengthText, freeText = '') => {
        const { product } = productPackage;
        const isSelected = productPackage.product.identifier === selectedPackage?.product?.identifier;
        let containerStyle = { borderWidth: 3, borderColor: TRANSPARENT };
        if (isSelected) {
            containerStyle = {
                borderWidth: 3,
                borderColor: COLORS[BRAND][props.mode],
            };
        }
        return (React.createElement(TouchableOpacity, { onPress: () => {
                setSelectedPackage(productPackage);
            } },
            React.createElement(View, { style: [styles.offerContentContainer, containerStyle] },
                React.createElement(BaseOverlay, { style: styles.overlay }),
                isSelected ? (React.createElement(View, { style: styles.iconContainer },
                    React.createElement(CheckMarkIcon, { style: {
                            color: COLORS[BRAND][props.mode],
                            height: ICON_SIZE,
                            width: ICON_SIZE,
                        } }))) : null,
                React.createElement(View, { style: styles.offerPriceTextContainer },
                    React.createElement(BaseText, { style: styles.offerTitleText }, title)),
                React.createElement(View, { style: styles.offerPriceTextContainer },
                    React.createElement(BaseText, { style: styles.offerPriceText }, product.price_string),
                    React.createElement(BaseText, { style: styles.offerTitleText }, ` / ${lengthText}`)),
                freeText ? (React.createElement(View, { style: styles.offerPriceTextContainer },
                    React.createElement(BaseText, { style: styles.offerTrialText }, freeText))) : null)));
    };
    const renderOffers = () => (React.createElement(View, { style: styles.sectionContainer },
        React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "Choose your plan:"),
        React.createElement(View, { style: { paddingTop: 10 } }),
        React.createElement(View, { style: styles.offerRow },
            renderOffer('Monthly', productPackages?.[MONTHLY_PACKAGE_TYPE], 'month', 'First 7 days free'),
            renderOffer('Annual', productPackages?.[ANNUAL_PACKAGE_TYPE], 'year', 'First 7 days free')),
        renderOffer('Lifetime', productPackages?.[LIFETIME_PACKAGE_TYPE], 'once')));
    const renderPurchaseButton = () => {
        const title = (selectedPackage?.packageType === productPackages?.[ANNUAL_PACKAGE_TYPE]?.product.title
            || selectedPackage?.packageType === productPackages?.[MONTHLY_PACKAGE_TYPE]?.product.title)
            && !props.user.is_free
            ? 'Start Free Trial'
            : 'Subscribe';
        const isLoading = !selectedPackage;
        return (React.createElement(View, { style: styles.purchaseButtonContainer },
            React.createElement(TextButton, { title: title, isLoading: isLoading, onPress: handlePurchasePress })));
    };
    const renderSubscribed = () => (React.createElement(React.Fragment, null,
        React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[TERTIARY_TEXT][props.mode] }] }, "You're Subscribed!"),
        React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[TERTIARY_TEXT][props.mode] }] },
            "Manage your subscription in the",
            ' ',
            Platform.OS === 'ios' ? 'App Store' : 'Google Play Store'),
        React.createElement(React.Fragment, null,
            React.createElement(View, { style: styles.paddingTopContainer },
                React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "What's next:"),
                React.createElement(View, { style: { flexDirection: 'column' } },
                    React.createElement(SubscriptionListEl, { mode: props.mode, text: "Create an account to save progress" }),
                    React.createElement(SubscriptionListEl, { mode: props.mode, text: "Meditate on Stoic principles" }),
                    React.createElement(SubscriptionListEl, { mode: props.mode, text: "Listen to conversations with experts" }),
                    React.createElement(SubscriptionListEl, { mode: props.mode, text: "Record your progress in Stoa's Journal" }))),
            React.createElement(View, { style: styles.paddingTopContainer },
                React.createElement(View, { style: styles.quoteContainer },
                    React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                    React.createElement(BaseText, { style: [styles.quoteText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "Dwell on the beauty of life. Watch the stars, and see yourself running with them."),
                    React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, '\u2014 Marcus Aurelius'))))));
    const renderPitchBlock = () => (React.createElement(View, { style: [styles.sectionContainer, { marginTop: 0 }] },
        React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "Start a free trial for:"),
        React.createElement(View, { style: styles.pitchContainer },
            React.createElement(SubscriptionListEl, { mode: props.mode, text: "All meditations and lessons" }),
            React.createElement(SubscriptionListEl, { mode: props.mode, text: "Every conversation" }),
            React.createElement(SubscriptionListEl, { mode: props.mode, text: "Monthly live workshops" }))));
    const renderTerms = () => {
        if (Platform.OS === 'ios') {
            return (React.createElement(View, { style: [styles.paddingTopContainer, { flexDirection: 'row' }] },
                React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => props.navigation.navigate(TERMS_OF_USE_SCREEN) },
                    React.createElement(BaseText, { style: [styles.smallTextUnderline, { color: COLORS[TERTIARY_TEXT][props.mode] }] }, "Terms")),
                React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => props.navigation.navigate(PRIVACY_SCREEN) },
                    React.createElement(BaseText, { style: [styles.smallTextUnderline, { color: COLORS[TERTIARY_TEXT][props.mode] }] }, "Privacy"))));
        }
        return (React.createElement(View, { style: styles.paddingTopContainer },
            React.createElement(BaseText, { style: [styles.smallText, { color: COLORS[TERTIARY_TEXT][props.mode] }] }, "Recurring Billing. Cancel Anytime.")));
    };
    const handlePurchase = async (productPackage) => {
        try {
            const purchaseMade = await Purchases.purchasePackage(productPackage);
            const entitlementInfo = purchaseMade.purchaserInfo.entitlements.active[FULL_SUBSCRIPTION_ENTITLEMENT];
            if (typeof purchaseMade.purchaserInfo.entitlements.active[FULL_SUBSCRIPTION_ENTITLEMENT]
                !== 'undefined') {
                if (isEmpty(props.user)) {
                    AsyncStorage.setItem(SubscriptionKey, JSON.stringify({ isSubscribed: true, productIdentifier: entitlementInfo.productIdentifier }));
                }
                else {
                    props.purchaseSubscription(props.user, entitlementInfo);
                }
                setRefresh(refresh + 1);
            }
        }
        catch (e) {
            Analytics.recordError(e);
        }
    };
    const handlerestoreSubscription = async () => {
        try {
            const purchaserInfo = await Purchases.getPurchaserInfo();
            if (typeof purchaserInfo.entitlements.active.my_entitlement_identifier !== 'undefined') {
                props.restoreSubscription(props.user, purchaserInfo.entitlements.active.my_entitlement_identifier);
                setRefresh(refresh + 1);
            }
            else {
                Alert.alert('We did not find any previous purchases.', `Please contact \n${CONTACT_EMAIL} with any concerns or questions.`);
            }
        }
        catch (e) {
            Analytics.recordError(e);
        }
    };
    const handlePurchasePress = () => {
        const product = selectedPackage?.product;
        if (selectedPackage && product) {
            handlePurchase(selectedPackage);
            Analytics.event(Analytics.Events.OFFER_TOUCH, {
                product_id: product.identifier,
                product_price: product.price,
            });
            AppEventsLogger.logEvent('InitiateCheckout', {
                value: product.identifier,
            });
        }
    };
    const renderRestore = () => (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: handlerestoreSubscription, style: styles.restoreSubscription },
        React.createElement(BaseText, { style: [styles.smallTextUnderline, { color: COLORS[TERTIARY_TEXT][props.mode] }] }, "Restore")));
    const renderRestoreAndTerms = () => (React.createElement(View, { style: BaseStyles.flexRow },
        React.createElement(View, { style: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
            } },
            renderRestore(),
            renderTerms())));
    const renderSubscribe = () => (React.createElement(React.Fragment, null,
        renderPitchBlock(),
        renderOffers(),
        renderRestoreAndTerms()));
    const loadingImg = React.createElement(ActivityIndicator, { size: "small", color: COLORS[BRAND][props.mode] });
    let body;
    if (!productPackages) {
        body = (React.createElement(React.Fragment, null,
            React.createElement(Header, { onPressCallback: () => props.navigation.goBack(), isIconX: true, title: "Subscribe", mode: props.mode, color: WHITE }),
            React.createElement(View, { style: BaseStyles.flexRow }),
            React.createElement(View, { style: BaseStyles.flexRow }, loadingImg)));
    }
    else {
        body = (React.createElement(React.Fragment, null,
            React.createElement(Header, { onPressCallback: () => (props.isOnboarding ? props.navigation.goBack() : props.navigation.navigate(HOME_SCREEN)), title: "Subscribe", mode: props.mode, color: WHITE }),
            React.createElement(ScrollView, { style: styles.body }, isSubscribed ? renderSubscribed() : renderSubscribe()),
            React.createElement(View, { style: styles.continueButtonsContainer },
                isSubscribed ? null : renderPurchaseButton(),
                props.isOnboarding ? (React.createElement(TextButton, { title: "Continue", onPress: () => props.navigation.navigate(SIGN_IN_SCREEN), buttonStyle: styles.continueButton, overlay: true })) : null)));
    }
    return (React.createElement(OnboardingBackground, null, body));
};
const mapStateToProps = (state, withProps) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
    isOnboarding: withProps.route.name === SUBSCRIPTION_SCREEN_II,
});
const mapDispatchToProps = (dispatch) => ({
    purchaseSubscription: (user, purchaseMade) => dispatch(purchaseSubscription(user, purchaseMade)),
    restoreSubscription: (user, purchase) => dispatch(restoreSubscription(user, purchase)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionScreenV2);
