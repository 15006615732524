import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import PlaylistScreen from '../components/playlist/playlistScreen';
import PlaylistsScreen from '../components/playlists/playlistsScreen';
import { PLAYLISTS_SCREEN, PLAYLIST_SCREEN } from '../utils/constants';
const MeditationStack = createStackNavigator();
const MeditationNavigator = () => {
    return (React.createElement(MeditationStack.Navigator, { initialRouteName: PLAYLISTS_SCREEN, headerMode: "none", screenOptions: {
            animationEnabled: Platform.OS === 'ios',
        } },
        React.createElement(MeditationStack.Screen, { name: PLAYLISTS_SCREEN, component: PlaylistsScreen }),
        React.createElement(MeditationStack.Screen, { name: PLAYLIST_SCREEN, component: PlaylistScreen })));
};
export default MeditationNavigator;
