// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { BackHandler, View, StyleSheet, TouchableOpacity, ScrollView, Platform, } from 'react-native';
import { debounce } from 'lodash';
import { s } from 'react-native-size-matters';
import Carousel from '../../utils/libraries/carousel';
import Header from '../baseComponents/header';
import RandomPopups from '../baseComponents/RandomPopups';
import QuoteCard from './quoteCard';
import { receiveQuote, removeQuote, updateAndFetchQuotes } from '../../actions/quoteActions';
import { destroyTrackPlayer } from '../../actions/trackPlayerActions';
import { getShuffledFavoriteQuotes, getShuffledStoicQuotes } from '../../selectors/quotes';
import { stripPersist } from '../../utils';
import { COLORS, BACKGROUND, QUOTE_FILTER, QUOTE_BACKGROUND, QUOTE_TEXT } from '../../utils/colors';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import QuotesView from './quotesViewPage';
import { Fonts } from '../../utils/fonts';
const FILTER_ALL = 'FILTER_ALL';
const FILTER_FAVORITE = 'FILTER_FAVORITE';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    contentContainer: {
        flex: 1,
        paddingVertical: 10,
    },
    scrollView: {
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    filterButton: {
        padding: 10,
        borderRadius: 5,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    filters: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 5,
        padding: 5,
        marginHorizontal: 30,
        marginTop: 24,
    },
    filterText: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: Fonts.size.m,
    },
    blankSection: {
        height: 20,
    },
});
class QuotesScreen extends React.Component {
    static navigationOptions = {
        title: 'Quotes',
    };
    constructor(props) {
        super(props);
        this.state = {
            filterName: FILTER_ALL,
        };
        this.debouncedHandleFilterTouch = debounce((filterName) => this.handleFilterTouch(filterName), 1000);
    }
    async componentDidMount() {
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        Analytics.screenHit('quotes_screen', 'QuotesScreen');
        this.props.updateAndFetchQuotes(this.props.user, this.props.quotes);
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
    handleBackPress = () => {
        this.props.navigation.goBack();
        return true;
    };
    handleOnSwipe = () => {
        this.props.destroyTrackPlayer();
    };
    handleFilterTouch = (filterName) => {
        if (this.state.filterName === filterName)
            return;
        this.setState({ filterName }, this.newQuote);
        Analytics.event('Quotes Filter touch', {
            filter: filterName,
        });
    };
    renderFilterButton = (filterName, filterText) => {
        const activeStyle = [
            styles.filterButton,
            { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
        ];
        return (React.createElement(TouchableOpacity, { onPress: () => this.debouncedHandleFilterTouch(filterName), style: this.state.filterName === filterName ? activeStyle : styles.filterButton },
            React.createElement(BaseText, { style: [styles.filterText, { color: COLORS[QUOTE_TEXT][this.props.mode] }] }, filterText)));
    };
    renderFilters = () => {
        return (React.createElement(View, { style: [styles.filters, { backgroundColor: COLORS[QUOTE_FILTER][this.props.mode] }] },
            this.renderFilterButton(FILTER_ALL, 'All'),
            this.renderFilterButton(FILTER_FAVORITE, 'Favorite')));
    };
    renderCard = ({ item, index }) => {
        return (React.createElement(QuoteCard, { quoteId: item.id, snapIndex: index, snapToPrev: this.handleSnapToPrev, currentTab: this.state.filterName }));
    };
    handleSnapToPrev = () => {
        this._carousel.snapToPrev();
    };
    render() {
        return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(Header, { title: "Quotes", onPressCallback: this.handleBackPress }),
            React.createElement(View, { style: styles.contentContainer },
                React.createElement(ScrollView, { contentContainerStyle: styles.scrollView },
                    this.renderFilters(),
                    React.createElement(View, { style: styles.blankSection }),
                    Platform.OS === 'web' ? (React.createElement(QuotesView, null, (this.state.filterName === FILTER_FAVORITE
                        ? this.props.favoriteQuotes
                        : this.props.stoicMeditations).map((item) => {
                        return React.createElement(QuoteCard, { quoteId: item.id });
                    }))) : (React.createElement(Carousel, { ref: (c) => {
                            this._carousel = c;
                        }, data: this.state.filterName === FILTER_FAVORITE
                            ? this.props.favoriteQuotes
                            : this.props.stoicMeditations, renderItem: this.renderCard, sliderWidth: s(350), itemWidth: s(290), onSnapToItem: (slideIndex) => this.handleOnSwipe(slideIndex) })),
                    React.createElement(View, { style: styles.blankSection }))),
            React.createElement(RandomPopups, { navigation: this.props.navigation })));
    }
}
const mapStateToProps = (state) => {
    const quotes = stripPersist(state.quotes);
    return {
        authors: stripPersist(state.authors),
        deviceDimensions: state.dimensions.deviceDimensions,
        isConnected: state.connection.isConnected,
        mode: stripPersist(state.colors).mode,
        favoriteQuotes: getShuffledFavoriteQuotes(quotes),
        quote: stripPersist(state.quote),
        quotes,
        stoicMeditations: getShuffledStoicQuotes(quotes),
        trackPlayer: state.trackPlayer,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    receiveQuote: (quote) => dispatch(receiveQuote(quote)),
    removeQuote: (quote) => dispatch(removeQuote(quote)),
    destroyTrackPlayer: () => dispatch(destroyTrackPlayer()),
    updateAndFetchQuotes: (user, quotes) => dispatch(updateAndFetchQuotes(user, quotes)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuotesScreen);
