import React from 'react';
import BaseText from '@components/baseComponents/baseText';
import { BANNER_BACKGROUND, BANNER_TEXT, BRAND, COLORS, LIGHT_GREEN, } from '@utils/colors';
import { imageToSpacesUrl } from '@utils/request';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { Image, StyleSheet, View } from 'react-native';
import { Fonts } from '@utils/fonts';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import TextButton from '@components/baseComponents/textButton';
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: DEFAULT_HORIZONTAL_PADDING,
        paddingVertical: 20,
        flex: 1,
    },
    buttonRow: {
        paddingTop: 10,
        flexDirection: 'row',
        flex: 1,
    },
    actionButton: {
        flex: 0.5,
    },
    actionText: {
        fontSize: Fonts.size.m,
        padding: 0,
    },
    hideButton: {
        flex: 0.5,
        backgroundColor: LIGHT_GREEN,
    },
    titleText: {
        fontSize: Fonts.size.l,
    },
    descriptionText: {
        fontSize: Fonts.size.m,
    },
    textContainer: {
        paddingVertical: 10,
    },
    textWrapper: {
        paddingVertical: 5,
    },
    titleContainer: {
        paddingVertical: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
    imageWrapper: {
        justifyContent: 'center',
        flexDirection: 'row',
        alignContent: 'center',
    },
    imageStyle: {
        flex: 1,
        height: 70,
        borderRadius: IMAGE_BORDER_RADIUS,
    },
});
const Banner = ({ banner, onPress, onHide, mode, }) => {
    const bannerImage = (React.createElement(Image, { resizeMode: "contain", resizeMethod: "scale", source: { uri: imageToSpacesUrl(banner.image || '') }, style: styles.imageStyle }));
    const titleText = (React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[BANNER_TEXT][mode] }] }, banner.title));
    const descriptionText = (React.createElement(BaseText, { style: [styles.descriptionText, { color: COLORS[BANNER_TEXT][mode] }] }, banner.description));
    const ctaButton = banner.cta ? (React.createElement(TextButton, { title: banner.cta.text, buttonStyle: styles.actionButton, buttonTextStyle: styles.actionText, onPress: onPress })) : null;
    return (React.createElement(View, { style: [styles.container, { backgroundColor: COLORS[BANNER_BACKGROUND][mode] }] },
        React.createElement(View, null,
            React.createElement(View, { style: styles.imageWrapper }, bannerImage)),
        React.createElement(View, { style: styles.textWrapper },
            titleText,
            descriptionText),
        React.createElement(View, { style: styles.buttonRow },
            ctaButton,
            React.createElement(TextButton, { title: "Hide", buttonStyle: styles.hideButton, buttonTextStyle: [styles.actionText, { color: COLORS[BRAND][mode] }], onPress: onHide }))));
};
export default Banner;
