import moment from 'moment';
import { isLoggedOut } from './auth';
export const shouldFetchState = (user, isConnected) => (isLoggedOut(user)
    || !isConnected
    || !user.lastFetchAt
    || moment(user.lastFetchAt).isBefore(moment().subtract(15, 'minutes')));
export const shouldUpdateEntries = (user, isConnected) => (isLoggedOut(user)
    || !isConnected
    || !user.lastEntriesUpdateAt
    || moment(user.lastEntriesUpdateAt).isBefore(moment().subtract(60, 'minutes')));
export const isBeta = (user) => !!user.is_beta;
export const isUser3dOld = (user) => moment(user.created_at).add(3, 'days').isBefore(moment());
