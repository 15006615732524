import { isEqual, merge } from 'lodash';
import { getFavoriteQuotes } from '../selectors/quotes';
import { host } from '../utils/constants';
import { escapeEmail } from '../utils/email';
export const FETCH_QUOTES = 'FETCH_QUOTES';
export const REQUEST_QUOTES = 'REQUEST_QUOTES';
export const RECEIVE_QUOTES = 'RECEIVE_QUOTES';
export const RECEIVE_QUOTE = 'RECEIVE_QUOTE';
export const REMOVE_QUOTE = 'REMOVE_QUOTE';
export const receiveQuotes = (quotes) => {
    return {
        type: RECEIVE_QUOTES,
        loading: false,
        quotes,
    };
};
export const requestQuotes = () => {
    return {
        type: REQUEST_QUOTES,
        loading: true,
    };
};
export const receiveQuote = (quote) => {
    return {
        type: RECEIVE_QUOTE,
        quote,
    };
};
export const removeQuote = () => {
    return {
        type: REMOVE_QUOTE,
    };
};
export function updateAndFetchQuotes(user, quotes = {}) {
    return function (dispatch) {
        const favoriteQuotes = getFavoriteQuotes(quotes);
        const favoriteQuoteIds = Object.keys(favoriteQuotes);
        dispatch(requestQuotes());
        return fetch(`${host}/v2/quotes?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ favorite_quote_ids: favoriteQuoteIds }),
        })
            .then((response) => response.json())
            .then((newQuotes) => {
            if (!isEqual(quotes, newQuotes)) {
                dispatch(receiveQuotes(newQuotes));
            }
            return newQuotes;
        })
            .catch((error) => console.log(error));
    };
}
export function optimisticallyFavoriteQuote(user, quote) {
    return function (dispatch) {
        const newQuote = merge({}, quote, { is_favorite: true });
        dispatch(receiveQuote(newQuote));
        return fetch(`${host}/api/quotes/favorite_quote?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
                quote_id: newQuote.id,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
}
export function optimisticallyDestroyFavoriteQuote(user, quote) {
    return function (dispatch) {
        const newQuote = merge({}, quote, { is_favorite: false });
        dispatch(receiveQuote(newQuote));
        return fetch(`${host}/api/quotes/favorite_quote?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
                quote_id: newQuote.id,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
}
