import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, BackHandler, FlatList, StyleSheet, View, } from 'react-native';
import { debounce, filter, flatten, maxBy, minBy, first, last, clone, isEmpty, merge, some, isEqual, } from 'lodash';
import { getAuthorName, stripPersist } from '@utils/index';
import { fetchDelete, fetchGet, fetchPost } from '@utils/request';
import { connect } from 'react-redux';
import Header from '@components/baseComponents/header';
import { BACKGROUND, BRAND, COLORS } from '@utils/colors';
import Analytics from '@utils/analytics';
import { ICON_SIZE } from '@utils/styles';
import BaseStyles from '@utils/baseStyles';
import ProgressBar from './ProgressBar';
import Sentence from './Sentence';
import { getSentencesArr } from './utils';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    scrollView: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    content: {
        paddingHorizontal: 30,
    },
});
const getSentenceFromChapter = (chapter, sentenceId) => {
    if (!chapter)
        return null;
    const sections = Object.values(chapter.sections);
    const sentences = flatten(sections.map((section) => Object.values(section.sentences)));
    return first(sentences.filter((s) => s.id === sentenceId));
};
const fetchHighlights = async (user, sentenceIds) => {
    const queryString = sentenceIds.map((id) => `sentence_ids[]=${id}`).join('&');
    const highlights = await fetchGet(user, '/api/highlights', queryString);
    return highlights;
};
const Chapter = ({ user, route, navigation, mode, authors, }) => {
    const { book: initBook, chapterId, bookId, highlight, } = route.params;
    const [book, setBook] = useState(initBook);
    const [chapter, setChapter] = useState(null);
    const [headerTitle, setHeaderTitle] = useState(book?.title || '');
    const [progressPercent, setProgressPercent] = useState(0);
    const [highlightedSentences, setHighlightedSentences] = useState([]);
    const [highlightMenuIsOpen, setHighlightMenuIsOpen] = useState(false);
    const [firstHighlightedSentence, setFirstHighlightedSentence] = useState(null);
    const [savedHighlights, setSavedHighlights] = useState({});
    const [itemHeights] = useState([]);
    const debounceScrollAnalytics = debounce(() => {
        Analytics.event(Analytics.Events.CHAPTER_SCROLL);
    }, 1000 * 15);
    const sentencesRef = useRef(null);
    const fetchAndSetChapter = async (id) => {
        const fetchedChapter = await fetchGet(user, `/api/chapters/${id}`);
        if (fetchedChapter) {
            setChapter(fetchedChapter);
            if (highlight && fetchedChapter) {
                const index = getSentencesArr(fetchedChapter)?.findIndex((v) => v.text === highlight);
                if (index && index > 0) {
                    setTimeout(() => {
                        sentencesRef.current?.scrollToIndex({ index, animated: true, viewPosition: 0.5 });
                    }, 200);
                }
            }
            const title = fetchedChapter.title || book?.title;
            setHeaderTitle(`${title} - ${fetchedChapter.index + 1}`);
        }
        else {
            Analytics.recordError(Analytics.Events.UNABLE_TO_FIND_CHAPTER, { chapterId });
        }
    };
    useEffect(() => {
        if (book)
            return;
        const fetch = async () => {
            const fetchedBook = await fetchGet(user, `/api/books/${bookId}`);
            setBook(fetchedBook);
        };
        fetch();
    }, []);
    useEffect(() => {
        if (highlightedSentences) {
            const newFirst = first(highlightedSentences) || null;
            if (newFirst?.id !== firstHighlightedSentence?.id) {
                setFirstHighlightedSentence(newFirst);
            }
        }
    }, [highlightedSentences]);
    useEffect(() => {
        if (!firstHighlightedSentence) {
            setHighlightMenuIsOpen(false);
        }
        else if (!isEmpty(highlightedSentences) && !highlightMenuIsOpen) {
            setHighlightMenuIsOpen(true);
        }
        else if (isEmpty(highlightedSentences) && highlightMenuIsOpen) {
            setHighlightMenuIsOpen(false);
        }
    }, [highlightedSentences, firstHighlightedSentence]);
    useEffect(() => {
        fetchAndSetChapter(chapterId);
        Analytics.event(Analytics.Events.VIEW_CHAPTER, {
            book_id: book?.id,
            book_title: book?.title,
            chapter_id: chapterId,
        });
        const goBack = () => {
            navigation.goBack();
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', goBack);
        return () => {
            BackHandler.addEventListener('hardwareBackPress', goBack);
        };
    }, []);
    useEffect(() => {
        const fetchHighlightsAsync = async () => {
            if (!chapter?.sections)
                return;
            const chapterSections = Object.values(chapter?.sections);
            const sentenceIds = flatten(chapterSections.map((section) => Object.values(section.sentences).map((sentence) => sentence.id)));
            const fetchedHighlights = await fetchHighlights(user, sentenceIds);
            setSavedHighlights(fetchedHighlights);
        };
        fetchHighlightsAsync();
    }, [chapter]);
    const createHighlight = async (sentenceIds) => {
        const response = await fetchPost(user, '/api/highlights', {
            sentence_ids: sentenceIds,
        });
        if (response?.status === 200) {
            const fetchedHighlight = await response.json();
            const filteredSavedHighlights = {};
            Object.entries(savedHighlights)
                .filter(([, ids]) => flatten(Object.values(fetchedHighlight)).filter((highlightId) => ids.includes(highlightId)).length === 0)
                .forEach((arr) => {
                const [id, ids] = arr;
                filteredSavedHighlights[id] = ids;
            });
            const newSavedHighlights = merge({}, filteredSavedHighlights, fetchedHighlight);
            setSavedHighlights(newSavedHighlights);
            setHighlightedSentences([]);
            return fetchedHighlight;
        }
        return null;
    };
    const deleteHighlight = async (highlightId) => {
        const response = await fetchDelete(user, `/api/highlights/${highlightId}`);
        if (response?.status === 200) {
            const newSavedHighlights = merge({}, savedHighlights);
            delete newSavedHighlights[highlightId];
            setSavedHighlights(newSavedHighlights);
            resetHighlights();
            return newSavedHighlights;
        }
    };
    const handleBackPress = () => {
        navigation.goBack();
    };
    const resetHighlights = () => {
        setHighlightedSentences([]);
    };
    const debounceResetHighlight = debounce(resetHighlights, 100);
    const handleScroll = (e) => {
        debounceResetHighlight();
        debounceScrollAnalytics();
        const { contentSize, layoutMeasurement, contentOffset } = e.nativeEvent;
        let percent = (contentOffset.y / (Math.floor(contentSize.height) - Math.floor(layoutMeasurement.height))) * 100;
        percent = percent > 100 ? 100 : Math.abs(percent);
        setProgressPercent(percent);
    };
    const handleSentencePress = (sentence) => {
        let newHighlightedSentences = clone(highlightedSentences);
        if (first(newHighlightedSentences)?.id === sentence.id
            || last(newHighlightedSentences)?.id === sentence.id) {
            const sentenceIndex = newHighlightedSentences
                .map((s) => s.id)
                .findIndex((id) => id === sentence.id);
            newHighlightedSentences = newHighlightedSentences.slice(0, sentenceIndex);
        }
        else if (isEmpty(newHighlightedSentences)) {
            newHighlightedSentences = [sentence];
        }
        else {
            if (newHighlightedSentences.length === 0)
                return;
            const min = first(newHighlightedSentences)?.index || 0;
            const max = last(newHighlightedSentences)?.index || 0;
            if (!(Math.abs(sentence.index - min) === 1 || Math.abs(sentence.index - max) === 1)) {
                newHighlightedSentences = [sentence];
            }
            else if (sentence.index > min) {
                newHighlightedSentences.push(sentence);
            }
            else {
                newHighlightedSentences.unshift(sentence);
            }
        }
        const matchingSavedHighlights = [];
        Object.entries(savedHighlights).forEach(([savedHighlightId, savedSentenceIds]) => {
            if (some(savedSentenceIds, (id) => newHighlightedSentences.map((s) => s.id).includes(id))) {
                matchingSavedHighlights.push(savedHighlightId);
            }
        });
        if (matchingSavedHighlights.length === 1) {
            const newSavedHighlightId = matchingSavedHighlights[0];
            const newSavedHighlightSentences = savedHighlights[newSavedHighlightId];
            newSavedHighlightSentences.forEach((id) => {
                if (!newHighlightedSentences.map((s) => s.id).includes(id)) {
                    const savedSentence = getSentenceFromChapter(chapter, id);
                    if (savedSentence) {
                        newHighlightedSentences.push(savedSentence);
                    }
                }
            });
            newHighlightedSentences.sort((a, b) => a.index - b.index);
        }
        setHighlightedSentences(newHighlightedSentences);
    };
    const handleScrollEndDrag = (e) => {
        if (!chapter || !book)
            return;
        const { contentSize, layoutMeasurement, contentOffset } = e.nativeEvent;
        if (contentOffset.y <= -50) {
            const chapters = Object.entries(book.chapters).map(([, c]) => c);
            const previousChapter = maxBy(filter(chapters, (c) => c.index < chapter.index), (c) => c.index);
            if (previousChapter && previousChapter?.id !== chapter.id) {
                setChapter(null);
                fetchAndSetChapter(previousChapter.id);
                setProgressPercent(0);
            }
        }
        else if (Math.round(contentSize.height) + 50
            <= Math.round(layoutMeasurement.height + contentOffset.y)) {
            const chapters = Object.entries(book.chapters).map(([, c]) => c);
            const nextChapter = minBy(filter(chapters, (c) => c.index > chapter.index), (c) => c.index);
            if (nextChapter && nextChapter?.id !== chapter.id) {
                setChapter(null);
                fetchAndSetChapter(nextChapter.id);
                setProgressPercent(0);
            }
        }
    };
    const getItemLayout = (data, index) => {
        const length = itemHeights[index] || 0;
        const offset = itemHeights.slice(0, index).reduce((a, c) => a + c, 0);
        return { length, offset, index };
    };
    const renderList = () => {
        if (!chapter)
            return null;
        const { sections } = chapter;
        const sectionsArr = Object.entries(sections)
            .sort(([, a], [, b]) => a.index - b.index)
            .map((arr) => arr[1]);
        const sentencesArr = flatten(sectionsArr.map((section) => {
            const { sentences } = section;
            return Object.values(sentences).sort((a, b) => a.index - b.index);
        })).map((sentence, i) => ({
            id: sentence.id,
            index: i,
            text: sentence.text,
            key: sentence.id.toString(),
            bookTitle: book?.title,
            authorName: getAuthorName(authors, book?.author_id),
        }));
        let findLastHighlight = false;
        let isLastHighlightSentence = false;
        return (React.createElement(FlatList, { style: styles.content, ref: sentencesRef, data: sentencesArr, getItemLayout: getItemLayout, renderItem: ({ item, index }) => {
                const hasHighlightMenu = firstHighlightedSentence?.id === item.id;
                const handleCreateHighlight = hasHighlightMenu
                    ? () => createHighlight(highlightedSentences.map((s) => s.id))
                    : undefined;
                const highlightedSentenceIds = highlightedSentences.map((sentence) => sentence.id);
                highlightedSentenceIds.sort();
                const savedHighlightArr = first(Object.entries(savedHighlights).filter(([, sentenceIds]) => {
                    sentenceIds.sort();
                    return isEqual(sentenceIds, highlightedSentenceIds);
                }));
                const savedHighlightId = savedHighlightArr ? first(savedHighlightArr) : null;
                const handleDeleteHighlight = hasHighlightMenu && savedHighlightId
                    ? () => deleteHighlight(savedHighlightId)
                    : undefined;
                const isFirst = item.index === 0;
                if (isFirst && hasHighlightMenu) {
                    findLastHighlight = true;
                }
                const hasFirstSentence = highlightedSentences.filter((val) => val.id === sentencesArr[0].id);
                if (findLastHighlight && hasFirstSentence.length > 0) {
                    isLastHighlightSentence = true;
                    findLastHighlight = false;
                }
                else {
                    isLastHighlightSentence = false;
                }
                return (React.createElement(View, { onLayout: (event) => {
                        const { height } = event.nativeEvent.layout;
                        itemHeights[index] = height;
                    } },
                    React.createElement(Sentence, { sentence: item, highlightedSentences: highlightedSentences, savedHighlights: savedHighlights, handleSentencePress: handleSentencePress, mode: mode, hasHighlightMenu: hasHighlightMenu, handleCreateHighlight: handleCreateHighlight, handleDeleteHighlight: handleDeleteHighlight, isLastHighlightSentence: isLastHighlightSentence })));
            }, onScroll: handleScroll, onScrollEndDrag: handleScrollEndDrag, extraData: { firstHighlightedSentence } }));
    };
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        React.createElement(Header, { title: headerTitle, onPressCallback: handleBackPress, mode: mode }),
        React.createElement(ProgressBar, { percent: progressPercent, mode: mode }),
        !chapter ? (React.createElement(View, { style: BaseStyles.flexRow },
            React.createElement(ActivityIndicator, { color: COLORS[BRAND][mode], size: ICON_SIZE }))) : (renderList())));
};
const mapStateToProps = (state) => ({
    deviceDimensions: state.dimensions.deviceDimensions,
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
    authors: stripPersist(state.authors),
});
export default connect(mapStateToProps)(Chapter);
