import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import BaseText from '@components/baseComponents/baseText';
import TextButton from '@components/baseComponents/textButton';
import Analytics from '@utils/analytics';
import BaseStyles from '@utils/baseStyles';
import { BLACK, BRAND, COLORS, PRIMARY_TEXT, QUOTE_BACKGROUND, SECONDARY_BUTTON, WHITE } from '@utils/colors';
import { DARK } from '@utils/constants';
import { checkPermissionAndAlertIfNone } from '@utils/firebase';
import moment from 'moment';
import DateTimePicker from 'react-native-modal-datetime-picker';
import { updateUserNotification, destroyUserNotification } from '@actions/userActions';
import { connect } from 'react-redux';
import { Fonts } from '@utils/fonts';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { hitSlop, stripPersist } from '../../utils';
const styles = StyleSheet.create({
    center: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        marginVertical: '3%',
    },
    dayCircle: {
        width: 25,
        borderRadius: 25,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
    },
    dayText: {
        fontSize: Fonts.size.l,
    },
    daysRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10,
    },
    daysContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
    daysTitleText: {
        fontSize: Fonts.size.m,
    },
});
const DEFAULT_DATE_STR = 'SMTWTFS';
const ReminderButtons = (props) => {
    const [isTimePicker, setIsTimePicker] = useState(false);
    const [dayStr, setDayStr] = useState(props.user.meditation_reminder_enabled_days || DEFAULT_DATE_STR);
    const updateUserMeditationReminderAt = async (time) => {
        const meditationReminderAt = time ? JSON.stringify(time) : null;
        if (meditationReminderAt) {
            const notification = { notify_at: meditationReminderAt, enabled_days: dayStr };
            await props.updateUserNotification(props.user, notification);
        }
        else {
            await props.destroyUserNotification(props.user);
        }
    };
    const removeReminders = async () => {
        await updateUserMeditationReminderAt(null);
        Analytics.event(Analytics.Events.ACCOUNT_REMOVE_REMINDER);
    };
    const handleSetReminderPress = async () => {
        setIsTimePicker(true);
        Analytics.event(Analytics.Events.ACCOUNT_SET_REMINDER);
        await checkPermissionAndAlertIfNone();
    };
    const defaultTime = props.user.meditation_reminder_at
        ? new Date(props.user.meditation_reminder_at)
        : new Date();
    useEffect(() => {
        if (props.user.meditation_reminder_at) {
            const notification = { notify_at: props.user.meditation_reminder_at, enabled_days: dayStr };
            props.updateUserNotification(props.user, notification);
        }
    }, [dayStr]);
    return (React.createElement(React.Fragment, null,
        props.user.meditation_reminder_at ? (React.createElement(React.Fragment, null,
            React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "You're receiving reminders at:"))) : (React.createElement(BaseText, { style: [BaseStyles.standardText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "You're not receiving reminders")),
        React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { title: props.user.meditation_reminder_at ? moment(props.user.meditation_reminder_at).format('h:mm a') : 'Add reminder', onPress: handleSetReminderPress })),
        isTimePicker ? (React.createElement(DateTimePicker, { date: defaultTime, mode: "time", isVisible: isTimePicker, onConfirm: (confirmedTime) => {
                setIsTimePicker(false);
                updateUserMeditationReminderAt(confirmedTime);
            }, onCancel: () => setIsTimePicker(false), 
            // @ts-ignore
            titleIOS: "Pick a time", headerTextIOS: "Pick a time", isDarkModeEnabled: props.mode === DARK, modalPropsIOS: { supportedOrientations: ['landscape', 'portrait'] }, textColor: props.mode === DARK ? WHITE : BLACK })) : null,
        props.user.meditation_reminder_at ? (React.createElement(View, { style: styles.daysContainer },
            React.createElement(View, null,
                React.createElement(BaseText, { style: [styles.daysTitleText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, DEFAULT_DATE_STR === dayStr ? `Send everyday` : `Send some days`)),
            React.createElement(View, { style: styles.daysRow }, DEFAULT_DATE_STR.split('').map((day, i) => {
                const selected = dayStr[i] === day;
                const color = selected ? COLORS[BRAND][props.mode] : COLORS[PRIMARY_TEXT][props.mode];
                const borderColor = selected ? COLORS[BRAND][props.mode] : COLORS[PRIMARY_TEXT][props.mode];
                return (React.createElement(TouchableOpacity, { onPress: () => {
                        const newChar = selected ? '_' : day;
                        const newDayStr = dayStr.slice(0, i) + newChar + dayStr.slice(i + 1);
                        setDayStr(newDayStr);
                    }, key: `day-${i}`, hitSlop: hitSlop },
                    React.createElement(View, { style: [styles.dayCircle, { borderColor, backgroundColor: COLORS[QUOTE_BACKGROUND][props.mode] }], key: `reminder-${day}-${i}` },
                        React.createElement(BaseText, { style: [styles.dayText, { color }] }, day))));
            })))) : null,
        props.user.meditation_reminder_at ? (React.createElement(View, { style: styles.center },
            React.createElement(TextButton, { buttonStyle: { backgroundColor: COLORS[SECONDARY_BUTTON][props.mode] }, buttonTextStyle: { color: COLORS[BRAND][props.mode] }, title: "Stop Reminders", onPress: removeReminders }))) : null));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    updateUserNotification: (user, notification) => dispatch(updateUserNotification(user, notification)),
    destroyUserNotification: (user) => dispatch(destroyUserNotification(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReminderButtons);
