import { merge } from 'lodash';
import { RECEIVE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/statsActions';
const initialState = {
    notification: false,
};
export const StatsReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_NOTIFICATION:
            return { notification: true, activity: action.activity };
        case REMOVE_NOTIFICATION:
            return { notification: false };
        default:
            return newState;
    }
};
