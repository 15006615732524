import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function InfoIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 20 20", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M9.84333 0C4.4075 0 0 4.4075 0 9.84333C0 15.2792 4.4075 19.6875 9.84333 19.6875C15.2792 19.6875 19.6875 15.2792 19.6875 9.84333C19.6875 4.4075 15.2792 0 9.84333 0ZM11.8925 15.2558C11.3858 15.4558 10.9825 15.6075 10.68 15.7125C10.3783 15.8175 10.0275 15.87 9.62833 15.87C9.015 15.87 8.5375 15.72 8.1975 15.4208C7.8575 15.1217 7.68833 14.7425 7.68833 14.2817C7.68833 14.1025 7.70083 13.9192 7.72583 13.7325C7.75167 13.5458 7.7925 13.3358 7.84833 13.1L8.4825 10.86C8.53833 10.645 8.58667 10.4408 8.625 10.2508C8.66333 10.0592 8.68167 9.88333 8.68167 9.72333C8.68167 9.43833 8.6225 9.23833 8.505 9.12583C8.38583 9.01333 8.16167 8.95833 7.8275 8.95833C7.66417 8.95833 7.49583 8.9825 7.32333 9.03333C7.1525 9.08583 7.00417 9.13333 6.8825 9.18L7.05 8.49C7.465 8.32083 7.8625 8.17583 8.24167 8.05583C8.62083 7.93417 8.97917 7.87417 9.31667 7.87417C9.92583 7.87417 10.3958 8.0225 10.7267 8.31583C11.0558 8.61 11.2217 8.9925 11.2217 9.4625C11.2217 9.56 11.21 9.73167 11.1875 9.97667C11.165 10.2225 11.1225 10.4467 11.0608 10.6525L10.43 12.8858C10.3783 13.065 10.3325 13.27 10.2908 13.4992C10.25 13.7283 10.23 13.9033 10.23 14.0208C10.23 14.3175 10.2958 14.52 10.4292 14.6275C10.5608 14.735 10.7917 14.7892 11.1183 14.7892C11.2725 14.7892 11.445 14.7617 11.64 14.7083C11.8333 14.655 11.9733 14.6075 12.0617 14.5667L11.8925 15.2558ZM10.7183 6.60083C11.1325 6.60083 11.4867 6.46417 11.7808 6.19083C12.075 5.9175 12.2225 5.58583 12.2225 5.19667C12.2225 4.80917 12.075 4.47583 11.7808 4.2C11.4875 3.92333 11.1325 3.78583 10.7183 3.78583C10.305 3.78583 9.94833 3.92333 9.65167 4.2C9.3575 4.47583 9.2075 4.80917 9.2075 5.19667C9.2075 5.585 9.35667 5.9175 9.65167 6.19083C9.94833 6.46417 10.305 6.60083 10.7183 6.60083Z", fill: color })));
}
InfoIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default InfoIcon;
