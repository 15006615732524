import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function AccountIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 18 21", ...otherProps },
        React.createElement(Path, { d: "M8.61088 10.0961C9.9979 10.0961 11.1989 9.59862 12.1803 8.61712C13.1616 7.63578 13.6591 6.43506 13.6591 5.04788C13.6591 3.66118 13.1616 2.4603 12.1801 1.47864C11.1986 0.497464 9.99774 0 8.61088 0C7.22371 0 6.02298 0.497464 5.04164 1.4788C4.06031 2.46014 3.56268 3.66102 3.56268 5.04788C3.56268 6.43506 4.06031 7.63594 5.0418 8.61728C6.0233 9.59846 7.22419 10.0961 8.61088 10.0961Z", fill: color }),
        React.createElement(Path, { d: "M17.4439 16.1165C17.4156 15.7081 17.3583 15.2626 17.2741 14.7922C17.189 14.3182 17.0795 13.8702 16.9483 13.4606C16.8129 13.0374 16.6287 12.6194 16.401 12.2188C16.1646 11.8031 15.887 11.441 15.5755 11.1431C15.2498 10.8315 14.851 10.5809 14.3898 10.3981C13.9303 10.2163 13.421 10.1242 12.8762 10.1242C12.6622 10.1242 12.4553 10.212 12.0557 10.4722C11.8098 10.6326 11.5221 10.818 11.201 11.0232C10.9265 11.1981 10.5545 11.362 10.0951 11.5104C9.64691 11.6555 9.19182 11.729 8.74264 11.729C8.29347 11.729 7.83854 11.6555 7.38985 11.5104C6.93092 11.3622 6.55898 11.1983 6.28475 11.0234C5.96669 10.8201 5.67887 10.6346 5.42925 10.472C5.03013 10.2118 4.82305 10.1241 4.6091 10.1241C4.06414 10.1241 3.55501 10.2163 3.0956 10.3983C2.63475 10.5808 2.23579 10.8313 1.90975 11.1433C1.59841 11.4414 1.32066 11.8032 1.08464 12.2188C0.857091 12.6194 0.67288 13.0372 0.537281 13.4608C0.406318 13.8703 0.296784 14.3182 0.211714 14.7922C0.127444 15.262 0.0701983 15.7076 0.0418951 16.117C0.0140716 16.518 0 16.9343 0 17.3546C0 18.4487 0.347793 19.3344 1.03363 19.9876C1.71098 20.6322 2.60725 20.9592 3.69716 20.9592H13.7891C14.879 20.9592 15.7749 20.6324 16.4525 19.9876C17.1385 19.3349 17.4862 18.449 17.4862 17.3545C17.4861 16.9322 17.4719 16.5156 17.4439 16.1165Z", fill: color })));
}
AccountIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default AccountIcon;
