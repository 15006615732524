export const TOGGLE_COLORS = 'TOGGLE_COLORS';
export const RECEIVE_DARK_MODE = 'RECEIVE_DARK_MODE';
export const RECEIVE_LIGHT_MODE = 'RECEIVE_LIGHT_MODE';
export const toggleColors = () => ({
    type: TOGGLE_COLORS,
});
export const receiveDarkMode = () => ({
    type: RECEIVE_DARK_MODE,
});
export const receiveLightMode = () => ({
    type: RECEIVE_LIGHT_MODE,
});
/*
  TODO:
    [ ] Add mode to relevant components
*/
