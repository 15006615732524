export const FETCH_PLAYLIST = 'FETCH_PLAYLIST';
export const REQUEST_PLAYLIST = 'REQUEST_PLAYLIST';
export const SET_PLAYLIST_ID = 'SET_PLAYLIST_ID';
export const requestPlaylist = (id) => {
    return {
        type: REQUEST_PLAYLIST,
        loading: true,
        id,
    };
};
export const setPlaylistId = (id) => {
    return {
        type: SET_PLAYLIST_ID,
        id,
    };
};
