import React from 'react';
import { imageToSpacesUrl } from '@utils/request';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { hitSlop } from '@utils/index';
import { BLUE, BRAND, COLORS, DARK_BACKGROUND, PRIMARY_TEXT, SECONDARY_BACKGROUND, SECONDARY_BORDER, SECONDARY_TEXT, SUBTITLE_TEXT, } from '@utils/colors';
import { DARK } from '@utils/constants';
import FastImage from '@utils/libraries/fastImage';
import { StyleSheet, View } from 'react-native';
import BaseText from '@components/baseComponents/baseText';
import { ChevronRightIcon } from '@assets/svg';
import { Fonts } from '@utils/fonts';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    card: {
        flex: 1,
        padding: 10,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    image: {
        height: 50,
        width: 50,
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: BLUE,
    },
    numeral: {
        height: 50,
        width: 50,
        borderRadius: IMAGE_BORDER_RADIUS,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContainer: {
        flex: 0.8,
        backgroundColor: 'transparent',
        paddingRight: 20,
        paddingLeft: 10,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    textAndArrowContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subtitle: {
        textShadowOffset: { width: 1, height: 1 },
        fontSize: Fonts.size.s,
        fontWeight: '400',
        flexShrink: 1,
    },
    subtitleContainer: {
        flexDirection: 'row',
    },
    title: {
        fontSize: Fonts.size.l,
        fontWeight: '400',
        paddingTop: 1,
        paddingBottom: 1,
        textShadowOffset: { width: 1, height: 1 },
    },
});
const Row = ({ handleOnPress, imageUrl, mode, title, subtitle, index }) => {
    const imageSource = imageUrl ? { uri: imageToSpacesUrl(imageUrl) } : undefined;
    return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: handleOnPress, style: [
            styles.card,
            {
                backgroundColor: COLORS[SECONDARY_BACKGROUND][mode],
                borderColor: COLORS[SECONDARY_BORDER][mode],
                borderRadius: mode === DARK ? 5 : 0,
                margin: mode === DARK ? 10 : 0,
            },
        ] },
        imageSource ? React.createElement(FastImage, { style: styles.image, source: imageSource }) : null,
        index !== undefined ? (React.createElement(View, { style: [styles.numeral, { backgroundColor: COLORS[DARK_BACKGROUND][mode] }] },
            React.createElement(BaseText, { style: { color: COLORS[SECONDARY_TEXT][mode] } }, index + 1))) : null,
        React.createElement(View, { style: styles.textAndArrowContainer },
            React.createElement(View, { style: styles.textContainer },
                React.createElement(BaseText, { style: [styles.title, { color: COLORS[PRIMARY_TEXT][mode] }] }, title),
                React.createElement(View, { style: styles.subtitleContainer },
                    React.createElement(BaseText, { style: [styles.subtitle, { color: COLORS[SUBTITLE_TEXT][mode] }] }, subtitle))),
            React.createElement(ChevronRightIcon, { style: { color: COLORS[BRAND][mode], height: 20, width: 20 } }))));
};
export default Row;
