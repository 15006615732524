import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function Forward30Icon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 276 297", ...otherProps },
        React.createElement(Path, { d: "M169.562 67.8744C148.902 60.7176 126.504 60.3764 105.726 66.9017C84.9482 73.4271 66.9103 86.4675 54.3164 104.068C41.7224 121.668 35.2509 142.881 35.8721 164.525C36.4932 186.169 44.1735 207.078 57.7616 224.119C71.3498 241.16 90.1135 253.413 111.24 259.042C132.366 264.672 154.717 263.373 174.942 255.342C195.167 247.311 212.175 232.98 223.418 214.497", stroke: color, strokeWidth: "12", fill: "transparent" }),
        React.createElement(Path, { d: "M213.698 81.77L158.869 95.3376L179.01 37.1937L213.698 81.77Z", fill: color }),
        React.createElement(Path, { d: "M111.966 165.991L108.606 160.321L121.976 148.001H100.276V141.701H131.076V147.861L118.126 159.831C121.812 159.831 125.056 161.044 127.856 163.471C130.702 165.897 132.126 169.281 132.126 173.621C132.126 177.634 130.656 181.064 127.716 183.911C124.776 186.757 120.762 188.181 115.676 188.181C110.682 188.181 106.669 186.804 103.636 184.051C100.649 181.251 99.039 177.867 98.8057 173.901L105.526 172.361C105.666 175.301 106.669 177.681 108.536 179.501C110.449 181.274 112.806 182.161 115.606 182.161C118.592 182.161 120.926 181.367 122.606 179.781C124.332 178.147 125.196 176.141 125.196 173.761C125.196 171.007 124.286 168.907 122.466 167.461C120.646 166.014 118.476 165.291 115.956 165.291C114.602 165.291 113.272 165.524 111.966 165.991ZM149.187 149.681C146.527 152.481 145.197 157.381 145.197 164.381C145.197 171.381 146.527 176.281 149.187 179.081C151.054 181.041 153.457 182.021 156.397 182.021C159.337 182.021 161.74 181.041 163.607 179.081C166.267 176.281 167.597 171.381 167.597 164.381C167.597 157.381 166.267 152.481 163.607 149.681C161.74 147.721 159.337 146.741 156.397 146.741C153.457 146.741 151.054 147.721 149.187 149.681ZM142.467 180.831C139.76 176.817 138.407 171.334 138.407 164.381C138.407 157.427 139.76 151.944 142.467 147.931C145.687 143.077 150.33 140.651 156.397 140.651C162.464 140.651 167.107 143.077 170.327 147.931C173.034 151.944 174.387 157.427 174.387 164.381C174.387 171.334 173.034 176.817 170.327 180.831C167.107 185.684 162.464 188.111 156.397 188.111C150.33 188.111 145.687 185.684 142.467 180.831Z", fill: color })));
}
Forward30Icon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default Forward30Icon;
