import React from 'react';
import { connect } from 'react-redux';
import { Share, StyleSheet, TouchableOpacity, View, } from 'react-native';
import { FavoriteIcon, FavoriteOutlineIcon, ShareIcon } from '@assets/svg';
import BaseText from '@components/baseComponents/baseText';
import { BRAND, BUTTON_BACKGROUND, COLORS, ACTIVE_BUTTON, LIGHT_GRAY, WHITE, INACTIVE_TEXT, } from '@utils/colors';
import FLAGS from '@utils/flags';
import { Fonts } from '@utils/fonts';
import { LARGE_ICON_SIZE } from '@utils/styles';
import Analytics from '@utils/analytics';
import { SAMPLE_URL } from '@utils/constants';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { stripPersist, hitSlop } from '../../utils';
import { receiveSetting } from '../../actions/settingsActions';
import { optimisticDestroyFavoriteMeditation, optimisticFavoriteMeditation, } from '../../actions/meditationActions';
const ICON_PADDING = 25;
const styles = StyleSheet.create({
    actionIconContainer: {
        width: LARGE_ICON_SIZE,
        height: LARGE_ICON_SIZE,
        borderRadius: 50,
        backgroundColor: LIGHT_GRAY,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    playbackRateText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        textAlign: 'right',
    },
    specialContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    defaultContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    lengthButtons: {
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 0,
        paddingHorizontal: 5,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
const MeditationActions = (props) => {
    if (props.hasMultipleFiles) {
        const { activeButtonStyle, inactiveButtonStyle, activeTextStyle, inactiveTextStyle, } = StyleSheet.create({
            activeButtonStyle: {
                backgroundColor: COLORS[ACTIVE_BUTTON][props.mode],
                height: 40,
                width: 100,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: IMAGE_BORDER_RADIUS,
            },
            inactiveButtonStyle: {
                color: COLORS[INACTIVE_TEXT][props.mode],
                width: 100,
                height: 40,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: IMAGE_BORDER_RADIUS,
            },
            activeTextStyle: {
                color: WHITE,
            },
            inactiveTextStyle: {
                color: COLORS[INACTIVE_TEXT][props.mode],
            },
        });
        const [tenButtonStyle, twentyButtonStyle] = props.meditationLen === '10'
            ? [activeButtonStyle, inactiveButtonStyle]
            : [inactiveButtonStyle, activeButtonStyle];
        const [tenTextStyle, twentyTextStyle] = props.meditationLen === '10'
            ? [activeTextStyle, inactiveTextStyle]
            : [inactiveTextStyle, activeTextStyle];
        return (React.createElement(View, { style: styles.specialContainer },
            React.createElement(View, { style: [styles.lengthButtons, { backgroundColor: COLORS[BUTTON_BACKGROUND][props.mode] }] },
                React.createElement(TouchableOpacity, { onPress: () => {
                        props.receiveSetting('meditationLen', '10');
                        Analytics.event(Analytics.Events.MEDITATION_LENGTH_PRESS, {
                            length: '10',
                        });
                    } },
                    React.createElement(View, { style: tenButtonStyle },
                        React.createElement(BaseText, { style: tenTextStyle }, "10"))),
                React.createElement(TouchableOpacity, { onPress: () => {
                        props.receiveSetting('meditationLen', '20');
                        Analytics.event(Analytics.Events.MEDITATION_LENGTH_PRESS, {
                            length: '20',
                        });
                    } },
                    React.createElement(View, { style: twentyButtonStyle },
                        React.createElement(BaseText, { style: twentyTextStyle }, "20"))))));
    }
    const shouldRenderFavorite = !props.isDaily;
    const containerStyle = props.isTheory || !FLAGS.SHARE || !shouldRenderFavorite
        ? styles.specialContainer
        : styles.defaultContainer;
    const PlaybackRate = props.isTheory ? (React.createElement(View, { style: { paddingRight: ICON_PADDING } },
        React.createElement(TouchableOpacity, { onPress: props.handlePlaybackRate, hitSlop: hitSlop, style: styles.actionIconContainer },
            React.createElement(BaseText, { style: [styles.playbackRateText, { color: COLORS[BRAND][props.mode] }] },
                props.playbackRate.toFixed(1),
                "x")))) : null;
    const favoriteIcon = props.meditation.is_favorite ? (React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][props.mode], height: 25, width: 25 } })) : (React.createElement(FavoriteOutlineIcon, { style: { color: COLORS[BRAND][props.mode], height: 25, width: 25 } }));
    const handleShare = () => {
        Share.share({
            message: `I've been using Stoa: a Stoic meditation app! Listen to a sample meditation here: ${`${SAMPLE_URL}${props.meditation.rand_id}`}`,
        }).catch((e) => console.log(e));
        Analytics.event(Analytics.Events.MEDITATION_SHARE, {
            source: 'MeditationScreen',
            meditation_id: props.meditation.id,
            meditation_title: props.meditation.title,
        });
    };
    const ShareButton = props.isTheory ? (React.createElement(View, { style: { paddingLeft: ICON_PADDING } },
        React.createElement(TouchableOpacity, { onPress: handleShare, style: styles.actionIconContainer },
            React.createElement(ShareIcon, { style: {
                    color: COLORS[BRAND][props.mode],
                    height: 25,
                    width: 25,
                } })))) : (React.createElement(TouchableOpacity, { onPress: handleShare, style: styles.actionIconContainer },
        React.createElement(ShareIcon, { style: {
                color: COLORS[BRAND][props.mode],
                height: 25,
                width: 25,
            } })));
    const handleFavorite = () => {
        if (props.meditation.is_favorite) {
            props.destroyFavoriteMeditation(props.user, props.meditation);
            Analytics.event(Analytics.Events.FAVORITE_MEDITATION_TOUCH, {
                meditation_id: props.meditation.id,
                meditation_title: props.meditation.title,
            });
        }
        else {
            props.favoriteMeditation(props.user, props.meditation);
            Analytics.event(Analytics.Events.REMOVE_FAVORITE_MEDITATION_TOUCH, {
                meditation_id: props.meditation.id,
                meditation_title: props.meditation.title,
            });
        }
    };
    return (React.createElement(View, { style: containerStyle },
        PlaybackRate,
        shouldRenderFavorite && (React.createElement(TouchableOpacity, { style: styles.actionIconContainer, onPress: handleFavorite }, favoriteIcon)),
        FLAGS.SHARE && ShareButton));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    receiveSetting: (key, value) => dispatch(receiveSetting(key, value)),
    destroyFavoriteMeditation: (user, meditation) => dispatch(optimisticDestroyFavoriteMeditation(user, meditation)),
    favoriteMeditation: (user, meditation) => dispatch(optimisticFavoriteMeditation(user, meditation)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeditationActions);
