import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform, } from 'react-native';
import { connect } from 'react-redux';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { COLORS, PRIMARY_TEXT, SECONDARY_ICON, BRAND, } from '../../utils/colors';
import { bigSlop, stripPersist } from '../../utils';
import { CloseIcon, MenuIcon, StatsIcon, VerticalDotsIcon, ChevronLeftIcon, } from '../../assets/svg';
import BaseText from './baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    titleText: {
        fontWeight: 'bold',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerContainer: {
        paddingVertical: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    leftContainer: {
        flex: 1,
        zIndex: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    centerContainer: {
        flex: 8,
        zIndex: 1,
        alignItems: 'center',
    },
    rightContainer: {
        flex: 1,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
});
const Header = (props) => {
    const headerColor = props.color || COLORS[PRIMARY_TEXT][props.mode];
    const { mode, isHome, hasVerticalDots, isIconX, color, } = props;
    const getRightIcon = () => {
        if (isHome) {
            return React.createElement(StatsIcon, { style: { color: COLORS[SECONDARY_ICON][mode], height: 28, width: 28 } });
        }
        if (hasVerticalDots) {
            return React.createElement(VerticalDotsIcon, { style: { color: COLORS[BRAND][mode] }, size: 28 });
        }
        return null;
    };
    const getLeftIcon = () => {
        if (isHome) {
            return React.createElement(MenuIcon, { style: { color: COLORS[SECONDARY_ICON][mode], height: 28, width: 28 } });
        }
        const iconColor = color || COLORS[PRIMARY_TEXT][mode];
        return isIconX ? (React.createElement(CloseIcon, { style: { height: 20, width: 20, color: iconColor } })) : (React.createElement(ChevronLeftIcon, { style: { height: 20, width: 20, color: iconColor } }));
    };
    const titleFontSize = props.title.length < 15
        ? Fonts.size.xxl
        : props.title.length < 25
            ? Fonts.size.xl
            : Fonts.size.l;
    return (React.createElement(View, { style: {
            paddingTop: Platform.OS === 'android' ? 22 : getStatusBarHeight() + 22,
            paddingHorizontal: '4%',
        } },
        React.createElement(View, { style: styles.headerContainer },
            React.createElement(View, { style: styles.leftContainer },
                React.createElement(TouchableOpacity, { hitSlop: bigSlop, onPress: props.onPressCallback }, getLeftIcon())),
            React.createElement(View, { style: styles.centerContainer },
                React.createElement(BaseText, { numberOfLines: 2, style: [styles.titleText, { fontSize: titleFontSize, color: headerColor }] }, props.title)),
            React.createElement(View, { style: styles.rightContainer },
                React.createElement(TouchableOpacity, { hitSlop: bigSlop, onPress: props.secondaryOnPressCallback }, getRightIcon())))));
};
Header.defaultProps = {
    color: '',
    hasVerticalDots: false,
    isHome: false,
    isIconX: false,
    secondaryOnPressCallback: () => { },
};
const mapStateToProps = (state) => ({
    deviceDimensions: state.dimensions.deviceDimensions,
    mode: stripPersist(state.colors).mode,
    stats: state.stats,
});
export default connect(mapStateToProps, null)(Header);
