// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, View, KeyboardAvoidingView, TouchableOpacity, Alert, } from 'react-native';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import Input from '../../utils/libraries/input';
import { enterEmail, enterPassword, signInUser } from '../../actions/userActions';
import TextButton from '../baseComponents/textButton';
import BaseStyles from '../../utils/baseStyles';
import Header from '../baseComponents/header';
import { COLORS, SECONDARY_TEXT, FOOTER_GRAY, LIGHT_GRAY, WHITE, } from '../../utils/colors';
import { escapeEmail, parseEmail } from '../../utils/email';
import { attachConfig, hitSlop, stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import { createPseudoUser, enterPseudoEmail, enterPseudoPassword, } from '../../actions/pseudoUserActions';
import { redirectIfLoggedIn } from '../../utils/auth';
import { isUserPseudo } from '../../utils/enforcement';
import FLAGS from '../../utils/flags';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
    },
    buttonContainer: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        padding: 20,
    },
    contentContainer: {
        flex: 1,
    },
    inputContainer: {
        flex: 1,
        padding: 20,
    },
    loginContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        flex: 1,
        borderRadius: 10,
        margin: 30,
        padding: 32,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subtitleText: {
        justifyContent: 'center',
    },
    titleText: {
        fontSize: Fonts.size.xxl,
        fontWeight: 'bold',
        justifyContent: 'center',
    },
    passwordNavButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    passwordNavContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        flex: 1,
    },
    warningMessage: {
        marginTop: 10,
    },
    inputStyle: {
        paddingLeft: 5,
        flex: 1,
    },
    overlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    underlineText: {
        textDecorationLine: 'underline',
        color: WHITE,
    },
});
const SignInScreenConfig = (isRoot) => (isRoot
    ? {
        isHeaderIconX: () => true,
        title: () => 'Sign Up',
        emailDescription: () => 'Create an account',
        screenHit() {
            Analytics.screenHit('sign_in_screen_root', 'SignInScreen');
        },
        async sendLoginEmail() {
            this.setState({ isLoading: true });
            const response = await enterPseudoEmail(parseEmail(this.state.email.toLowerCase()));
            if (response && response.status === 200) {
                this.setState({ isEmail: false, isLoading: false });
                Analytics.event(Analytics.Events.SEND_PSEUDO_EMAIL_SUCCESS);
            }
            else {
                this.setState({
                    isLoading: false,
                    warningMessage: 'Something went wrong. Please try again.',
                });
                Analytics.recordError(Analytics.Events.SEND_PSEUDO_EMAIL_FAIL);
            }
        },
        resendLoginEmail() { },
        redirectIfLoggedIn: () => { },
        handlePasswordSubmit() {
            this.setState({ isLoading: true }, async () => {
                const password = this.state.password.trim();
                if (this.validPassword(password)) {
                    Analytics.event(Analytics.Events.VALID_PSUEDO_PASSWORD_SUBMIT, {
                        email: parseEmail(this.state.email.toLowerCase()),
                        user_id: this.props.user.id,
                    });
                    const response = await this.props.enterPseudoPassword({
                        email: parseEmail(this.state.email.toLowerCase()),
                        password,
                    });
                    if (response.status !== 200) {
                        Analytics.event(Analytics.Events.UNSUCCESSFUL_PSUEDO_PASSWORD_SUBMIT, { status: response.status });
                        this.passwordInput && this.passwordInput?.shake && this.passwordInput?.shake();
                        this.setState({ isLoading: false, warningMessage: 'Invalid email or password' });
                    }
                    else {
                        Analytics.event(Analytics.Events.SUCCESSFUL_PSUEDO_PASSWORD_SUBMIT);
                        this.setState({ isLoading: false });
                    }
                }
                else {
                    Analytics.event(Analytics.Events.INVALID_PSEUDO_PASSWORD_SUBMIT, {
                        email: parseEmail(this.state.email.toLowerCase()),
                    });
                    this.setState({
                        isLoading: false,
                        isPasswordValid: false,
                        warningMessage: this.getInvalidPasswordMessage(password),
                    });
                }
            });
        },
    }
    : {
        isHeaderIconX: () => false,
        title: () => 'Sign In',
        emailDescription: () => 'Create an account or login',
        screenHit() {
            Analytics.screenHit('sign_in_screen', 'SignInScreen');
        },
        sendLoginEmail() {
            enterEmail(parseEmail(this.state.email.toLowerCase())).then((response) => {
                console.log(response);
                if (response && response.status === 200) {
                    this.setState({ isEmail: false, isLoading: false });
                    Analytics.event(Analytics.Events.SEND_LOGIN_EMAIL_SUCCESS);
                }
                else {
                    this.setState({
                        isLoading: false,
                        warningMessage: 'Something went wrong. Please try again.',
                    });
                    Analytics.recordError(Analytics.Events.SEND_LOGIN_EMAIL_FAIL);
                }
            });
        },
        resendLoginEmail() {
            enterEmail(parseEmail(this.state.email.toLowerCase())).then((response) => {
                if (response.status === 200) {
                    this.setState({ isEmail: false, isLoading: false, didResendLoginEmail: true });
                    Analytics.event(Analytics.Events.RESEND_LOGIN_EMAIL_SUCCESS);
                }
                else {
                    this.setState({
                        isLoading: false,
                        warningMessage: 'Something went wrong. Please try again.',
                    });
                    Analytics.event(Analytics.Events.RESEND_LOGIN_EMAIL_FAIL);
                }
            });
        },
        handlePasswordSubmit() {
            this.setState({ isLoading: true }, () => {
                const password = this.state.password.trim();
                if (this.validPassword(password)) {
                    Analytics.event(Analytics.Events.VALID_PASSWORD_SUBMIT, {
                        email: parseEmail(this.state.email.toLowerCase()),
                    });
                    this.props.enterPassword({
                        email: parseEmail(this.state.email.toLowerCase()),
                        password,
                        isPasswordValid: true,
                    }, this.props.navigation, () => this.setState({ isLoading: false }));
                }
                else {
                    Analytics.event(Analytics.Events.INVALID_PASSWORD_SUBMIT, {
                        email: parseEmail(this.state.email.toLowerCase()),
                    });
                    this.setState({
                        isLoading: false,
                        isPasswordValid: false,
                        warningMessage: this.getInvalidPasswordMessage(password),
                    });
                }
            });
        },
    });
const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class SignInScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoading: false,
            isEmailValid: true,
            isPasswordValid: true,
            isEmail: true,
            warningMessage: null,
            blockedEmails: [],
        };
        attachConfig(SignInScreenConfig(this.props.isRoot), this);
    }
    componentDidMount() {
        if (!this.props.isRoot || (this.props.user && !isUserPseudo(this.props.user))) {
            redirectIfLoggedIn(this.props.user, this.props.navigation);
        }
        this.screenHit();
    }
    componentDidUpdate() {
        if (!this.props.isRoot || (this.props.user && !isUserPseudo(this.props.user))) {
            redirectIfLoggedIn(this.props.user, this.props.navigation);
        }
        // bad taste
        if (this.props.user.authStatus === 'failed' && this.state.isLoading) {
            this.passwordInput && this.passwordInput?.shake && this.passwordInput?.shake();
            this.setState({ isLoading: false, warningMessage: 'Invalid email or password' });
        }
    }
    handleBackPress = () => {
        if (this.state.isEmail) {
            this.props.navigation.goBack(null);
            Analytics.event(Analytics.Events.SIGNUP_BACKPRESS, { source: 'email' });
        }
        else {
            this.setState({ isEmail: true, warningMessage: null });
            Analytics.event(Analytics.Events.SIGNUP_BACKPRESS, { source: 'password' });
        }
    };
    validateEmail = (email) => EMAIL_RE.test(email);
    getInvalidPasswordMessage = (password) => {
        if (!password) {
            return 'Please enter the login code we emailed you';
        }
        if (password.length !== 6) {
            return 'Please enter the six-digit code we emailed you';
        }
        if (!/^\d+$/.test(password)) {
            return 'Please enter the six numbers we emailed you';
        }
        return 'Please enter the login code we emailed you';
    };
    validPassword = (password) => password && /^\d+$/.test(password) && password.length === 6;
    handleEmailSubmit = () => {
        this.setState({ isLoading: true }, () => {
            if (!this.validateEmail(this.state.email)) {
                this.emailInput && this.emailInput.shake && this.emailInput?.shake();
                this.setState({ isLoading: false, warningMessage: 'Invalid email' });
                Analytics.event(Analytics.Events.INVALID_EMAIL_SUBMIT, {
                    email: this.state.email.toLowerCase(),
                });
            }
            else {
                this.sendLoginEmail();
                this.setState({ isLoading: false, warningMessage: null });
                Analytics.event(Analytics.Events.VALID_EMAIL_SUBMIT, {
                    email: parseEmail(this.state.email.toLowerCase()),
                });
            }
        });
    };
    handlePsuedoCreate = async () => {
        const response = await createPseudoUser();
        if (response && response.status === 409) {
            Alert.alert('Please log in', 'An account already exists for this device.');
        }
        else if (response && response.status === 200) {
            const user = await response.json();
            this.props.signInUser(user);
        }
        else {
            Alert.alert('Something went wrong');
        }
    };
    handlePseudoUser = async () => {
        Alert.alert('Are you sure?', 'Create an account to track progress across devices', [
            {
                text: 'I want to try it first',
                onPress: this.handlePsuedoCreate,
                style: 'cancel',
            },
            {
                text: 'OK',
                onPress: this.focusEmail,
            },
        ]);
    };
    focusEmail = () => {
        this.emailInput && this.emailInput.focus();
    };
    renderPasswordNavContainer = () => {
        const ResendConfig = this.state.didResendLoginEmail
            ? {
                onPress: () => { },
                text: 'Sending',
                style: styles.passwordNavButtonInactive,
            }
            : {
                onPress: () => {
                    this.setState({ isLoading: true });
                    this.resendLoginEmail();
                },
                text: 'Resend email',
                textStyle: styles.underlineText,
            };
        return !this.state.isEmail ? (React.createElement(View, { style: styles.passwordNavContainer },
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.passwordNavButton, onPress: () => this.setState({ isEmail: true, warningMessage: null }) },
                React.createElement(BaseText, { style: styles.underlineText }, "Re-enter email")),
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.passwordNavButton, onPress: ResendConfig.onPress },
                React.createElement(BaseText, { style: ResendConfig.textStyle }, ResendConfig.text)))) : null;
    };
    onChangeEmail = (emailInput) => {
        const email = escapeEmail(emailInput);
        if (this.state.blockedEmails
            && this.state.blockedEmails.filter((blockedEmail) => email.includes(`\@${blockedEmail}`))
                .length > 0
            && !this.state.shownBlockedEmailAlert) {
            Alert.alert('Temporary Notice', `Please be aware that Stoa is having difficulty delivering email to ${this.state.blockedEmails.join(', ')} email addresses. We recommend trying another email address.`);
            this.setState({ shownBlockedEmailAlert: true });
        }
        this.setState({ email });
    };
    handlePasswordOnChange = (password) => {
        this.setState({ password });
        if (password.length === 6) {
            this.handlePasswordSubmit();
        }
        else {
            this.setState({});
        }
    };
    makeAnAccountLater = () => ((!this.state.isEmail || this.props.isRoot || !FLAGS.PSEUDO_USERS) ? null : (React.createElement(View, { style: styles.passwordNavContainer },
        React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.passwordNavButton, onPress: () => this.handlePseudoUser() },
            React.createElement(BaseText, { style: styles.underlineText }, "Make an account later")))));
    render() {
        const { isLoading, email, password, } = this.state;
        return (React.createElement(OnboardingBackground, { mode: this.props.mode },
            React.createElement(Header, { title: this.title(), onPressCallback: this.handleBackPress, isIconX: this.isHeaderIconX(), color: WHITE }),
            React.createElement(ScrollView, { style: { flex: 1, width: this.props.deviceDimensions.deviceWidth }, keyboardShouldPersistTaps: "always" },
                React.createElement(KeyboardAvoidingView, { behavior: "padding" },
                    React.createElement(View, { style: styles.formContainer },
                        React.createElement(BaseOverlay, { style: styles.overlay }),
                        React.createElement(View, null,
                            React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, this.state.isEmail ? 'What\'s your email?' : 'Enter your password')),
                        React.createElement(View, null,
                            React.createElement(BaseText, { style: [styles.subtitleText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, this.state.isEmail
                                ? this.emailDescription()
                                : 'We sent a login code to your email')),
                        this.state.isEmail ? (React.createElement(Input, { value: parseEmail(email), keyboardAppearance: "light", autoFocus: true, autoCapitalize: "none", autoCorrect: false, keyboardType: "email-address", returnKeyType: "next", inputStyle: [styles.inputStyle, { color: COLORS[SECONDARY_TEXT][this.props.mode] }], placeholder: "Email", placeholderTextColor: COLORS[FOOTER_GRAY][this.props.mode], containerStyle: styles.inputContainer, ref: (input) => (this.emailInput = input), onChangeText: (changedEmail) => this.onChangeEmail(changedEmail), onSubmitEditing: this.handleEmailSubmit, testID: "email-input" })) : (React.createElement(Input, { value: password, keyboardAppearance: "light", keyboardType: "number-pad", autoCapitalize: "none", autoCorrect: false, secureTextEntry: false, returnKeyType: this.state.isEmail ? 'next' : 'done', blurOnSubmit: true, containerStyle: styles.inputContainer, inputStyle: [styles.inputStyle, { color: COLORS[SECONDARY_TEXT][this.props.mode] }], placeholder: "Login Code", placeholderTextColor: COLORS[FOOTER_GRAY][this.props.mode], ref: (input) => (this.passwordInput = input), onChangeText: this.handlePasswordOnChange, onSubmitEditing: this.handlePasswordSubmit, testID: "password-input" })),
                        React.createElement(View, { styles: styles.buttonContainer },
                            React.createElement(TextButton, { title: this.state.isEmail ? 'Continue' : 'Log In', onPress: this.state.isEmail ? this.handleEmailSubmit : this.handlePasswordSubmit, isLoading: isLoading, isDisabled: isLoading, testID: "signup-continue-button" })),
                        this.state.warningMessage ? (React.createElement(View, { style: styles.warningMessage },
                            React.createElement(BaseText, { style: [
                                    BaseStyles.standardText,
                                    { color: COLORS[SECONDARY_TEXT][this.props.mode] },
                                ] }, `${this.state.warningMessage}`))) : null)),
                this.renderPasswordNavContainer(),
                this.makeAnAccountLater())));
    }
}
const mapStatetoProps = (state) => ({
    deviceDimensions: state.dimensions.deviceDimensions,
    isConnected: state.connection.isConnected,
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    enterPassword: (user) => dispatch(enterPassword(user)),
    enterPseudoPassword: async (user) => dispatch(enterPseudoPassword(user)),
    signInUser: (user) => dispatch(signInUser(user)),
});
export default connect(mapStatetoProps, mapDispatchToProps)(SignInScreen);
