import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import FastImage from 'react-native-fast-image';
import { SUBTITLE_TEXT, COLORS, PRIMARY_TEXT, BRAND, SECONDARY_BACKGROUND, SECONDARY_BORDER, BLUE, } from '../../utils/colors';
import { DARK } from '../../utils/constants';
import { hitSlop } from '../../utils';
import { filepathToSpacesUrl } from '../../utils/request';
import { ChevronRightIcon } from '../../assets/svg';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    playlistCard: {
        flex: 1,
        padding: 10,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    playlistImage: {
        height: 50,
        width: 50,
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: BLUE,
    },
    playlistTextContainer: {
        flex: 0.8,
        backgroundColor: 'transparent',
        paddingRight: 20,
        paddingLeft: 10,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    playlistTextAndArrowContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subtitle: {
        textShadowOffset: { width: 1, height: 1 },
        fontSize: Fonts.size.s,
        fontWeight: '400',
        flexShrink: 1,
    },
    subtitleContainer: {
        flexDirection: 'row',
    },
    title: {
        fontSize: Fonts.size.l,
        fontWeight: '400',
        paddingTop: 1,
        paddingBottom: 1,
        textShadowOffset: { width: 1, height: 1 },
    },
});
const PlaylistItem = ({ playlist, onPress, mode, isConnected = true, }) => {
    const imageSource = isConnected && playlist.image ? { uri: filepathToSpacesUrl(`${playlist.image}.png`) } : {};
    const opacityStyle = playlist.is_listened
        ? { opacity: 0.6 }
        : {};
    return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, key: playlist.id, onPress: onPress, style: [
            styles.playlistCard,
            {
                backgroundColor: COLORS[SECONDARY_BACKGROUND][mode],
                borderColor: COLORS[SECONDARY_BORDER][mode],
                borderRadius: mode === DARK ? 5 : 0,
                margin: mode === DARK ? 10 : 0,
            },
        ] },
        React.createElement(FastImage, { style: [styles.playlistImage, opacityStyle], source: imageSource }),
        React.createElement(View, { style: styles.playlistTextAndArrowContainer },
            React.createElement(View, { style: styles.playlistTextContainer },
                React.createElement(BaseText, { style: [styles.title, { color: COLORS[PRIMARY_TEXT][mode] }, opacityStyle] }, playlist.title),
                React.createElement(View, { style: styles.subtitleContainer },
                    React.createElement(BaseText, { style: [styles.subtitle, { color: COLORS[SUBTITLE_TEXT][mode] }, opacityStyle] }, playlist.subtitle))),
            React.createElement(ChevronRightIcon, { style: {
                    color: COLORS[BRAND][mode], height: 20, width: 20, ...opacityStyle,
                } }))));
};
export default PlaylistItem;
