import * as React from 'react';
import Svg, { Circle } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function VerticalDotsIcon(props) {
    const { style, size = 20, ...otherProps } = props;
    const { color } = style || {};
    const unit = size / 10;
    const height = size;
    const width = size / 2;
    const topPosition = height - unit;
    const middlePosition = height / 2;
    const bottomPosition = unit;
    return (React.createElement(Svg, { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none", ...otherProps },
        React.createElement(Circle, { cx: unit, cy: topPosition, r: unit, transform: `rotate(-90 ${unit} ${topPosition})`, fill: color }),
        React.createElement(Circle, { cx: unit, cy: middlePosition, r: unit, transform: `rotate(-90 ${unit} ${middlePosition})`, fill: color }),
        React.createElement(Circle, { cx: unit, cy: bottomPosition, r: unit, transform: `rotate(-90 ${unit} ${bottomPosition})`, fill: color })));
}
VerticalDotsIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default VerticalDotsIcon;
