import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import PlaylistsScreen from '../components/playlists/playlistsScreen';
import PlaylistScreen from '../components/playlist/playlistScreen';
import { THEORY_PLAYLISTS_SCREEN, THEORY_PLAYLIST_SCREEN } from '../utils/constants';
const TheoryStack = createStackNavigator();
const TheoryPlaylistsScreen = (props) => React.createElement(PlaylistsScreen, { isTheory: true, ...props });
const TheoryPlaylistScreen = (props) => React.createElement(PlaylistScreen, { isTheory: true, ...props });
const TheoryNavigator = () => {
    return (React.createElement(TheoryStack.Navigator, { initialRouteName: THEORY_PLAYLISTS_SCREEN, headerMode: "none", screenOptions: { animationEnabled: Platform.OS === 'ios' } },
        React.createElement(TheoryStack.Screen, { name: THEORY_PLAYLISTS_SCREEN, component: TheoryPlaylistsScreen }),
        React.createElement(TheoryStack.Screen, { name: THEORY_PLAYLIST_SCREEN, component: TheoryPlaylistScreen })));
};
export default TheoryNavigator;
