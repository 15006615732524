import React from 'react';
import { connect } from 'react-redux';
import { BackHandler, StyleSheet, TouchableOpacity, View, Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import Header from '../baseComponents/header';
import { HOME_SCREEN, TERMS_OF_USE_SCREEN, PRIVACY_SCREEN } from '../../utils/constants';
import { COLORS, PRIMARY_TEXT, BACKGROUND } from '../../utils/colors';
import { stripPersist, hitSlop } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
    },
    body: {
        marginTop: 30,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
    },
    footer: {
        marginTop: 20,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    medText: {
        marginBottom: 10,
    },
    medDecText: {
        marginBottom: 10,
        textDecorationLine: 'underline',
    },
    headerContainer: {
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
        width: '100%',
    },
});
class AboutScreen extends React.Component {
    static navigationOptions = {
        title: 'About',
    };
    componentDidMount() {
        Analytics.screenHit('about_screen', 'AboutScreen');
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
    handleBackPress = () => {
        this.props.navigation.navigate(HOME_SCREEN);
        Analytics.event(Analytics.Events.ABOUT_BACKPRESS, {});
        return true;
    };
    render() {
        const medTextStyle = [styles.medText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }];
        return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(Header, { onPressCallback: this.handleBackPress, title: "About", mode: this.props.mode }),
            React.createElement(ScrollView, null,
                React.createElement(View, { style: styles.body },
                    React.createElement(BaseText, { style: medTextStyle }, "Stoa is an app combining the philosophies of Stoicism and mindfulness practice."),
                    React.createElement(BaseText, { style: medTextStyle }, "It is built to support practical philosophical exercises that help people live better lives."),
                    React.createElement(BaseText, { style: medTextStyle }, "Stoa is for everyone interested in Stoicism or meditation."),
                    React.createElement(BaseText, { style: medTextStyle }, "It supports a journal, guided meditation exercises, and contains hundreds of quotes."),
                    React.createElement(BaseText, { style: medTextStyle }, "Use Stoa to improve your focus, resilience, and virtue."),
                    React.createElement(BaseText, { style: medTextStyle }, "After the free trial, you may subscribe to unlock all features. If you truly cannot afford the subscription fee, but would like to continue using Stoa, get in touch with us."),
                    React.createElement(BaseText, { style: medTextStyle, 
                        // @ts-ignore
                        onPress: () => Linking.openURL('https://ancientlyre.com/') }, "Michael Levy graciously let us use his music as an intro to the Stoa Conversations.")),
                React.createElement(View, { style: styles.footer },
                    React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => this.props.navigation.navigate(TERMS_OF_USE_SCREEN) },
                        React.createElement(BaseText, { style: [styles.medDecText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Terms of Use")),
                    React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => this.props.navigation.navigate(PRIVACY_SCREEN) },
                        React.createElement(BaseText, { style: [styles.medDecText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Privacy Policy"))))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps, null)(AboutScreen);
