import RNFS from './libraries/fs';
import { getAllMeditations } from '../selectors/playlists';
import { filepathToSpacesUrl } from './request';
import { idObjToArr } from './index';
import Analytics from './analytics';
import FileSystem from './libraries/FileSystem';
const callback = (downloadProgress) => {
    const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    console.log('Download progress: ', progress);
};
export const downloadMeditation = async (meditation, successCallback) => {
    const url = filepathToSpacesUrl(meditation.filepath);
    const destination = `file:///${RNFS.DocumentDirectoryPath}/${meditation.filepath}`;
    const downloadResumable = FileSystem.createDownloadResumable(url, destination, {}, callback);
    try {
        await downloadResumable.downloadAsync();
        Analytics.event(Analytics.Events.DOWNLOADED_MEDITATION_SUCCESS);
        successCallback && successCallback();
    }
    catch (e) {
        Analytics.recordError(e);
    }
};
export const removeMeditationDownload = async (meditation) => {
    const localFilepath = `${RNFS.DocumentDirectoryPath}/${meditation.filepath}`;
    const exists = await RNFS.exists(localFilepath);
    if (exists) {
        await RNFS.unlink(localFilepath);
    }
};
export const downloadPlaylist = async (playlist) => {
    const { meditations } = playlist;
    const meditationArr = idObjToArr(meditations);
    const results = await Promise.all(meditationArr.map((meditation) => {
        const result = downloadMeditation(meditation);
        return result;
    }));
    return results;
};
export const removePlaylistDownload = async (playlist) => {
    const { meditations } = playlist;
    const meditationArr = idObjToArr(meditations);
    const results = await Promise.all(meditationArr.map(async (meditation) => {
        const result = await removeMeditationDownload(meditation);
        return result;
    }));
    return results;
};
export const removeAllPlaylistDownloads = async (playlists) => {
    const playlistArr = idObjToArr(playlists);
    const results = await playlistArr.map(async (playlist) => {
        const result = await removePlaylistDownload(playlist);
        return result;
    });
    const resolvedResults = await Promise.all(results);
    return resolvedResults;
};
export const isMeditationDownloaded = async (meditation) => {
    const localFilepath = `${RNFS.DocumentDirectoryPath}/${meditation.filepath}`;
    const exists = await RNFS.exists(localFilepath);
    return exists;
};
export const isPlaylistDownloaded = async (playlist) => {
    const { meditations } = playlist;
    const meditationArr = idObjToArr(meditations);
    const results = await meditationArr.map(async (meditation) => {
        const result = await isMeditationDownloaded(meditation);
        if (!result) {
            return false;
        }
        return true;
    });
    const resolvedResults = await Promise.all(results);
    return !resolvedResults.includes(false);
};
export const meditationDownloadCount = async (playlists) => {
    const meditations = getAllMeditations(playlists);
    let count = 0;
    const results = await meditations.map(async (meditation) => {
        const result = await isMeditationDownloaded(meditation);
        if (result) {
            count++;
        }
        return count;
    });
    await Promise.all(results);
    return count;
};
