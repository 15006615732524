import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function ChevronDownIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: height, height: width, viewBox: "0 0 19 12", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M0.960078 2.9281C0.811768 2.7799 0.730347 2.58276 0.730347 2.37139C0.730347 2.16002 0.811768 1.96288 0.960143 1.81462L1.53746 1.23716C1.84469 0.930276 2.34435 0.930276 2.65104 1.23709L9.23658 7.82263H9.48648L16.0793 1.22983C16.2278 1.08146 16.4249 1 16.6359 1C16.8473 1 17.0444 1.08144 17.1929 1.22975L17.7702 1.80733C17.9186 1.95572 18 2.15276 18 2.36408C18 2.57545 17.9186 2.7726 17.7702 2.92084L9.92082 10.7705C9.77206 10.9191 9.57404 11.0005 9.36157 11C9.14902 11.0005 8.95113 10.9192 8.80248 10.7706L0.960078 2.9281Z", fill: color })));
}
ChevronDownIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default ChevronDownIcon;
