import React from 'react';
import { COLORS, BANNER_GRAY, GRAYS, BLACK, } from '@utils/colors';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { StyleSheet, View, } from 'react-native';
import { stripPersist } from '@utils/index';
import { CheckIcon } from '@assets/svg';
import BaseText from '@components/baseComponents/baseText';
import { connect, useStore } from 'react-redux';
import QuoteModal from '@components/baseComponents/QuoteModal';
import { setAndGoToMeditation } from '@actions/meditationActions';
import { useNavigation } from '@react-navigation/native';
import { Fonts } from '../../utils/fonts';
import TextButton from '../baseComponents/textButton';
const styles = StyleSheet.create({
    actionIconContainer: {
        paddingRight: 10,
    },
    actionTextContainer: {
        padding: 10,
        flexWrap: 'wrap',
    },
    actionText: {
        fontSize: Fonts.size.m,
        fontWeight: '400',
    },
    actionTextRow: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        marginBottom: 10,
    },
    buttonContainer: {
        paddingBottom: 10,
    },
    titleText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        alignItems: 'center',
        padding: 10,
    },
    expandedContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomEndRadius: IMAGE_BORDER_RADIUS,
        borderBottomStartRadius: IMAGE_BORDER_RADIUS,
    },
    expandedText: {
        fontSize: Fonts.size.m,
        justifyContent: 'center',
        textAlign: 'center',
        padding: 10,
        opacity: 0.4,
        fontWeight: '400',
        color: BLACK,
    },
    expandedTitleText: {
        fontSize: Fonts.size.m,
        fontWeight: 'bold',
        justifyContent: 'center',
        textAlign: 'center',
        color: BLACK,
    },
});
const RoutineStep = ({ mode, step, isActive, quoteModal, handleFinish, toggleQuoteModal, }) => {
    const heightStyle = isActive ? {} : { height: 'auto' };
    return (React.createElement(View, { style: [styles.container, heightStyle] },
        React.createElement(View, null, isActive ? (step.type === 'meditation' ? (React.createElement(MeditationDetail, { step: step, handleFinish: handleFinish, mode: mode })) : step.type === 'action' ? (React.createElement(ActionDetail, { step: step, handleFinish: handleFinish, mode: mode })) : null) : null),
        quoteModal && step.type === 'quote' ? (React.createElement(QuoteModal, { quote: step.exercise, onFinish: () => {
                handleFinish(step);
                toggleQuoteModal(false);
            }, isOpen: quoteModal })) : null));
};
const MeditationDetail = ({ step, handleFinish, mode, }) => {
    const navigation = useNavigation();
    const { dispatch } = useStore();
    const meditation = step.exercise;
    const handleOnPress = () => {
        // TODO this function should not require navigation
        handleFinish(step);
        dispatch(setAndGoToMeditation(meditation, navigation));
    };
    return (React.createElement(View, { style: [styles.expandedContainer, { backgroundColor: COLORS[BANNER_GRAY][mode] }] },
        React.createElement(View, null,
            React.createElement(BaseText, { style: styles.expandedTitleText }, step.title),
            React.createElement(BaseText, { style: styles.expandedText }, step.description)),
        React.createElement(View, { style: styles.buttonContainer },
            React.createElement(TextButton, { title: "Listen", onPress: handleOnPress }))));
};
const ActionDetail = ({ handleFinish, step, mode, }) => {
    const action = step.exercise;
    return (React.createElement(View, { style: [styles.expandedContainer, { backgroundColor: COLORS[BANNER_GRAY][mode] }] },
        React.createElement(View, null,
            React.createElement(BaseText, { style: styles.expandedTitleText }, action.title),
            React.createElement(View, { style: styles.actionTextContainer }, action.description.split('\n').map((sentence, i) => (React.createElement(View, { style: styles.actionTextRow, key: i },
                React.createElement(View, { style: styles.actionIconContainer },
                    React.createElement(CheckIcon, { style: {
                            height: 14, width: 14, color: COLORS[GRAYS][mode], marginRight: 10,
                        } })),
                React.createElement(BaseText, { style: [styles.actionText, { paddingRight: 12 }] }, sentence)))))),
        React.createElement(View, { style: styles.buttonContainer },
            React.createElement(TextButton, { title: "I Did It", onPress: () => handleFinish(step) }))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(RoutineStep);
