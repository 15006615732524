import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { Fonts } from '@utils/fonts';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ChevronRightIcon } from '@assets/svg';
import moment from 'moment';
import { SHARE_SCREEN } from '@utils/constants';
import Analytics from '@utils/analytics';
import BaseText from '../baseComponents/baseText';
import { stripPersist } from '../../utils';
import SectionTitle from '../baseComponents/sectionTitle';
import { COLORS, BLUE, GREEN, SECONDARY_TEXT, } from '../../utils/colors';
const styles = StyleSheet.create({
    card: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'row',
        height: 70,
        width: '100%',
        maxWidth: 450,
        backgroundColor: BLUE,
    },
    textContainer: {
        flex: 0.8,
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    titleText: {
        fontSize: Fonts.size.xl,
        fontWeight: 'bold',
        alignItems: 'center',
    },
    arrow: {
        flex: 0.2,
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        borderTopRightRadius: IMAGE_BORDER_RADIUS,
        borderBottomRightRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: GREEN,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
        paddingBottom: 20,
    },
});
const ShareStoaHomeSection = (props) => {
    const shouldRender = () => {
        if (moment(props.user.created_at).isBefore(moment().subtract(7, 'days')))
            return false;
        const creationDay = moment(props.user.created_at).day();
        const day = moment().day();
        if (Math.abs(creationDay - day) % 3 !== 0)
            return false;
        return true;
    };
    if (!shouldRender())
        return null;
    Analytics.event(Analytics.Events.RENDER_SHARE_STOA_HOME);
    return (React.createElement(View, null,
        React.createElement(SectionTitle, { title: "Give a Free Month of Stoa" }),
        React.createElement(TouchableOpacity, { style: styles.wrapper, onPress: () => props.navigation.navigate(SHARE_SCREEN) },
            React.createElement(View, { style: styles.card },
                React.createElement(View, { style: styles.textContainer },
                    React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "Share Stoa")),
                React.createElement(View, { style: styles.arrow },
                    React.createElement(ChevronRightIcon, { style: { color: COLORS[SECONDARY_TEXT][props.mode], height: 20, width: 20 } }))))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps)(ShareStoaHomeSection);
