import { PACKAGE_TYPE } from 'react-native-purchases';
export const FULL_SUBSCRIPTION_ENTITLEMENT = 'full_subscription';
export const MONTHLY_PACKAGE_TYPE = 'MONTHLY';
export const ANNUAL_PACKAGE_TYPE = 'ANNUAL';
export const LIFETIME_PACKAGE_TYPE = 'LIFETIME';
export const DEFAULT_OFFERINGS_WITH_TRIAL_ID = 'default_with_trial';
const MONTHLY_DEV_ID = 'dev.monthly';
const YEARLY_DEV_ID = 'dev.yearly';
const LIFETIME_DEV_ID = 'dev.lifetime';
export const DevPackages = {
    [MONTHLY_PACKAGE_TYPE]: {
        product: {
            price: 9.99,
            price_string: '$9.99',
            currency_code: '$',
            identifier: MONTHLY_DEV_ID,
            introPrice: null,
            description: 'Monthly',
            title: 'Monthly',
            intro_price: null,
            intro_price_string: null,
            intro_price_period: null,
            intro_price_cycles: null,
            intro_price_period_unit: null,
            intro_price_period_number_of_units: null,
            discounts: null,
        },
        identifier: MONTHLY_DEV_ID,
        offeringIdentifier: MONTHLY_DEV_ID,
        packageType: PACKAGE_TYPE.MONTHLY,
    },
    [ANNUAL_PACKAGE_TYPE]: {
        product: {
            price: 69.99,
            price_string: '$69.99',
            currency_code: '$',
            identifier: YEARLY_DEV_ID,
            introPrice: null,
            description: 'Annual',
            title: 'Annual',
            intro_price: null,
            intro_price_string: null,
            intro_price_period: null,
            intro_price_cycles: null,
            intro_price_period_unit: null,
            intro_price_period_number_of_units: null,
            discounts: null,
        },
        identifier: YEARLY_DEV_ID,
        offeringIdentifier: YEARLY_DEV_ID,
        packageType: PACKAGE_TYPE.ANNUAL,
    },
    [LIFETIME_PACKAGE_TYPE]: {
        product: {
            price: 249.99,
            price_string: '$249.99',
            currency_code: '$',
            identifier: LIFETIME_DEV_ID,
            introPrice: null,
            description: 'Annual',
            title: 'Annual',
            intro_price: null,
            intro_price_string: null,
            intro_price_period: null,
            intro_price_cycles: null,
            intro_price_period_unit: null,
            intro_price_period_number_of_units: null,
            discounts: null,
        },
        identifier: LIFETIME_DEV_ID,
        offeringIdentifier: LIFETIME_DEV_ID,
        packageType: PACKAGE_TYPE.LIFETIME,
    },
};
export const getPurchaseMetadata = (entitlementInfo) => ({
    product_id: entitlementInfo.productIdentifier,
    name: entitlementInfo.identifier,
});
