import React from 'react';
import { connect } from 'react-redux';
import { Alert, TouchableOpacity, View, StyleSheet, } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import FastImage from '../../utils/libraries/fastImage';
import { imageToSpacesUrl } from '../../utils/request';
import romanize from '../../utils/numerals';
import { secondsToStr, hitSlop, stripPersist, bigSlop, } from '../../utils';
import { setAndGoToMeditation } from '../../actions/meditationActions';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { WHITE, COLORS, BRAND, PRIMARY_TEXT, LIGHT_BORDER, BLUE, } from '../../utils/colors';
import Analytics from '../../utils/analytics';
import { isMeditationRestricted, redirectIfMeditationRestricted } from '../../utils/enforcement';
import { LockIcon, VerticalDotsIcon } from '../../assets/svg';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    playIcon: {
        marginLeft: 2,
        justifyContent: 'center',
    },
    lengthText: {
        textAlign: 'left',
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: 'row',
        fontSize: Fonts.size.s,
        justifyContent: 'center',
        alignContent: 'center',
        textAlignVertical: 'center',
    },
    theoryText: {
        flex: 4,
        flexDirection: 'column',
    },
    meditationDescriptionText: {
        paddingLeft: 10,
        fontWeight: '400',
        fontSize: Fonts.size.s,
        opacity: 0.8,
    },
    titleText: {
        paddingLeft: 10,
        flex: 4,
        fontWeight: '400',
        fontSize: Fonts.size.l,
        justifyContent: 'flex-start',
    },
    numeral: {
        justifyContent: 'flex-start',
        flex: 1,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    descriptionText: {
        color: WHITE,
        fontWeight: '400',
        fontSize: Fonts.size.m,
        textAlign: 'center',
    },
    meditation: {
        paddingRight: 20,
        paddingLeft: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingTop: 10,
        paddingBottom: 10,
    },
    meditationImage: {
        height: 41,
        width: 41,
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: BLUE,
    },
});
const MeditationItem = ({ meditation, mode, user, openModal, dispatchedSetAndGoToMeditation, playlist, index, isConnected, }) => {
    const navigation = useNavigation();
    const isTheory = playlist.is_theory;
    const opacityStyle = meditation.is_listened
        ? { opacity: 0.6 }
        : isMeditationRestricted(meditation, user)
            ? {
                opacity: 0.4,
            }
            : {};
    const handleMeditationPress = () => {
        if (!isConnected && !meditation.is_downloaded) {
            Alert.alert("You're currently disconnected. Download meditations in order to play them while you're offline.");
        }
        else {
            redirectIfMeditationRestricted(meditation, user, navigation, () => {
                dispatchedSetAndGoToMeditation(meditation, navigation, isTheory);
                Analytics.event(Analytics.Events.PLAYLIST_MEDITATION_TOUCH, {
                    meditation_id: meditation.id,
                    meditation_title: meditation.title,
                });
            });
        }
    };
    const titleText = (React.createElement(View, { style: styles.theoryText },
        React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[PRIMARY_TEXT][mode] }, opacityStyle] }, meditation.title),
        React.createElement(BaseText, { style: [
                styles.meditationDescriptionText,
                { color: COLORS[PRIMARY_TEXT][mode] },
                opacityStyle,
            ] }, meditation.description)));
    const icon = isMeditationRestricted(meditation, user) ? (React.createElement(TouchableOpacity, { hitSlop: bigSlop, onPress: () => handleMeditationPress, style: styles.playIcon },
        React.createElement(LockIcon, { height: 20, width: 20, style: { color: COLORS[BRAND][mode] } }))) : openModal ? (React.createElement(TouchableOpacity, { hitSlop: bigSlop, onPress: () => openModal(meditation.id), style: styles.playIcon },
        React.createElement(VerticalDotsIcon, { style: { color: COLORS[BRAND][mode] }, size: 20 }))) : null;
    const numeral = isTheory || index === null ? null : (React.createElement(BaseText, { style: [styles.numeral, { color: COLORS[PRIMARY_TEXT][mode] }, opacityStyle] }, romanize(index + 1)));
    return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => handleMeditationPress() },
        React.createElement(View, { style: [styles.meditation, { borderBottomColor: COLORS[LIGHT_BORDER][mode] }] },
            numeral,
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(meditation.image || playlist.image) }, style: [styles.meditationImage, opacityStyle] }),
            titleText,
            React.createElement(BaseText, { style: [styles.lengthText, { color: COLORS[PRIMARY_TEXT][mode] }, opacityStyle] }, secondsToStr(meditation.length_sec)),
            icon)));
};
const mapStateToProps = (state) => ({
    connection: state.connection,
    isConnected: state.connection.isConnected,
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    dispatchedSetAndGoToMeditation: (meditation, navigation, isTheory) => dispatch(setAndGoToMeditation(meditation, navigation, isTheory)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeditationItem);
