/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, BackHandler, InteractionManager, Platform, StyleSheet, TextInput, View, TouchableOpacity, ScrollView, } from 'react-native';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import { receiveNotification } from '../../actions/statsActions';
import { receiveQuote, removeQuote, updateAndFetchQuotes } from '../../actions/quoteActions';
import Header from '../baseComponents/header';
import { stripPersist, formatAuthorNameFromQuote } from '../../utils';
import { fetchAuthors } from '../../actions/authorActions';
import { fetchEntries, receiveEntry, updateEntry } from '../../actions/entryActions';
import { QUOTE_BACKGROUND, QUOTE_TEXT, COLORS, PRIMARY_TEXT, BACKGROUND, BRAND, } from '../../utils/colors';
import TextButton from '../baseComponents/textButton';
import { ENTRIES_SCREEN } from '../../utils/constants';
import BaseStyles from '../../utils/baseStyles';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    bgImage: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    contentContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    author: {
        fontSize: Fonts.size.s,
    },
    scrollWrapper: {
        flex: 1,
    },
    journalText: {
        flex: 1,
        marginTop: 10,
        marginHorizontal: 30,
        padding: 14,
        borderRadius: 10,
        textAlignVertical: 'top',
        fontSize: Fonts.size.m,
        minWidth: 400,
    },
    journalContainer: {
        marginTop: 10,
        borderRadius: 10,
        flex: 1,
        width: '100%',
    },
    loadingContainer: {
        width: '100%',
        justifyContent: 'center',
    },
    quoteContainer: {
        marginTop: 10,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
        flex: 1,
    },
    quoteText: {
        fontSize: Fonts.size.m,
        backgroundColor: 'rgba(0,0,0,0)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    quoteTextContainer: {
        width: '100%',
        borderRadius: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    sectionTitleContainer: {
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
        paddingTop: 25,
        justifyContent: 'center',
    },
    sectionHideTitleContainer: {
        width: '100%',
        paddingTop: 25,
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
    },
    scrollContainer: {
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        flexDirection: 'column',
    },
});
class EntryScreen extends React.Component {
    static navigationOptions = {
        title: 'Entry',
    };
    debouncedUpdateEntry;
    scrollResponder;
    textInput;
    iosScrollView;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasQuote: props.entry ? !!props.entry.quote_id : false,
            isFocused: false,
            shouldShowQuote: true,
            entry: { created_at: '', id: 0, content: '', user_id: props.user.id },
        };
        this.debouncedUpdateEntry = this.debounceUpdateEntry();
    }
    async componentDidMount() {
        await InteractionManager.runAfterInteractions(() => {
            Analytics.screenHit('entry_screen', 'EntryScreen');
            BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
            if (Object.keys(this.props.entries).length === 0) {
                this.props.fetchEntries(this.props.user).then(() => {
                    this.setState({
                        entry: this.props.entries[this.props.entryId],
                    }, this.updateAndFetchQuotes);
                });
            }
            else {
                this.setState({
                    entry: this.props.entries[this.props.entryId],
                }, this.updateAndFetchQuotes);
            }
        });
        await this.ensureAuthorsLoaded();
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
    debounceUpdateEntry = () => {
        if (this.props.isConnected) {
            return debounce(this.props.updateEntry, 1000);
        }
        return debounce(this.props.updateEntryOffline, 1000);
    };
    updateAndFetchQuotes = () => {
        if (Object.keys(this.props.quotes).length === 0) {
            // if there are no quotes, fetch them
            this.props.updateAndFetchQuotes(this.props.user, this.props.quotes).then(() => {
                this.setQuote(() => this.setState({ isLoading: false }));
            });
        }
        else {
            this.setQuote(() => this.setState({ isLoading: false })); // set a quote if there is none
        }
    };
    randomQuote = () => {
        const quoteLength = Object.keys(this.props.quotes).length - 1;
        if (quoteLength < 1)
            return;
        const randomIdx = Math.round(Math.random() * quoteLength);
        const quoteId = Object.keys(this.props.quotes)[randomIdx];
        const quote = this.props.quotes[quoteId];
        const { entry } = this.state;
        entry.quote_id = quote.id;
        this.props.receiveQuote(quote);
        this.setState({ entry, isLoading: false }, () => {
            Analytics.event(Analytics.Events.RANDOM_QUOTE, { quote_id: quote.id });
            this.debouncedUpdateEntry(this.props.user, entry);
        });
        return quote;
    };
    // If entry has a quote, update local state, otherwise take the quote in state, else get a random quote and update local state
    setQuote = (callback) => {
        if (this.state.entry.quote_id) {
            const quote = this.props.quotes[this.state.entry.quote_id];
            if (quote)
                this.props.receiveQuote(quote);
            callback(); // Todo: this should be called after receiveQuote finishes
        }
        else if (this.props.quote) {
            const { entry } = this.state;
            entry.quote_id = this.props.quote.id;
            this.setState({ entry, isLoading: false }, () => {
                this.debouncedUpdateEntry(this.props.user, entry);
            });
        }
        else {
            this.randomQuote();
        }
    };
    handleBackPress = () => {
        // replace the current route with a new one so that component will unmount when navigating back.
        this.props.navigation.replace(ENTRIES_SCREEN);
        this.props.navigation.pop();
        Analytics.event(Analytics.Events.ENTRY_BACKPRESS, {
            content_length: this.state.entry.content?.length || 0,
            entry_id: this.props.entryId,
        });
        return true;
    };
    renderQuote = () => {
        if (this.state.entry && this.state.entry.quote_id && this.state.shouldShowQuote) {
            const quote = this.props.quotes[this.state.entry.quote_id];
            if (!quote)
                return null;
            const authorName = formatAuthorNameFromQuote(this.props.authors, quote);
            return (React.createElement(View, { style: styles.quoteContainer },
                React.createElement(View, { style: [
                        styles.quoteTextContainer,
                        { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
                    ] },
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [styles.quoteText, { color: COLORS[QUOTE_TEXT][this.props.mode] }] }, quote.text)),
                    authorName && (React.createElement(View, { style: { flexDirection: 'row', justifyContent: 'flex-end' } },
                        React.createElement(BaseText, { style: [styles.author, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] },
                            ' ',
                            `\u2014 ${authorName}`)))),
                React.createElement(View, null,
                    React.createElement(TextButton, { onPress: this.randomQuote, title: "New Quote" }))));
        }
    };
    // Edge case: larger content w/ no new line
    handleContentSizeChange = (w, h) => {
        if (Platform.OS !== 'ios' || !this.scrollResponder || !this.textInput) {
            // Android auto scrolls
            return;
        }
        const scrollResponder = this.iosScrollView.getScrollResponder();
        scrollResponder.scrollResponderScrollTo({
            x: 0,
            scrolLHeight: h,
            animated: true,
        });
    };
    renderTextField = () => {
        if (!this.state.entry) {
            return null;
        }
        return (React.createElement(TextInput, { multiline: true, numberOfLines: 8, onEndEditing: () => this.setState({ isFocused: false }), onChangeText: (text) => {
                const { entry } = this.state;
                entry.content = text;
                this.setState({ entry }, () => {
                    this.debouncedUpdateEntry(this.props.user, entry);
                    if (this.state.entry &&
                        moment(this.state.entry.created_at).isSame(moment(), 'day') &&
                        !this.props.stats.notification) {
                        // If the entry was created today
                        this.props.statsNotification();
                    }
                });
            }, 
            // @ts-ignore
            onContentSizeChange: this.handleContentSizeChange, placeholder: "Begin writing here", ref: (el) => (this.textInput = el), style: [
                styles.journalText,
                {
                    color: COLORS[PRIMARY_TEXT][this.props.mode],
                    backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode],
                },
            ], value: this.state.entry.content }));
    };
    formatDate = (dateString) => {
        return (dateString ? new Date(dateString) : new Date())
            .toLocaleDateString()
            .split('/')
            .slice(0, 2)
            .join('/');
    };
    async ensureAuthorsLoaded() {
        if (isEqual(this.props.authors, {})) {
            await this.props.fetchAuthors(this.props.user, this.props.authors);
        }
    }
    renderSectionTitle = (title) => {
        return (React.createElement(View, { style: styles.sectionTitleContainer },
            React.createElement(BaseText, { style: [BaseStyles.sectionTitle, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, title)));
    };
    renderHideSectionTitle = (title) => {
        return (React.createElement(View, { style: styles.sectionHideTitleContainer },
            React.createElement(BaseText, { style: { color: COLORS[PRIMARY_TEXT][this.props.mode] } }, title),
            this.state.shouldShowQuote ? (React.createElement(TouchableOpacity, { onPress: () => this.setState({ shouldShowQuote: false }) },
                React.createElement(BaseText, { style: { color: COLORS[BRAND][this.props.mode] } }, "Hide"))) : (React.createElement(TouchableOpacity, { onPress: () => this.setState({ shouldShowQuote: true }) },
                React.createElement(BaseText, { style: { color: COLORS[BRAND][this.props.mode] } }, "Show")))));
    };
    render() {
        return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(Header, { onPressCallback: this.handleBackPress, title: this.state.entry ? this.formatDate(this.state.entry.created_at) : '', mode: this.props.mode }),
            React.createElement(ScrollView, { contentContainerStyle: styles.contentContainer, 
                // @ts-ignore
                keyboardOffset: 100 },
                this.state.isLoading && (React.createElement(View, { style: styles.loadingContainer },
                    React.createElement(ActivityIndicator, { size: "large", color: COLORS[BRAND][this.props.mode] }))),
                !this.state.isLoading && this.props.quote && this.props.quote
                    ? this.renderSectionTitle('Your inspiration')
                    : null,
                !this.state.isLoading && this.props.quote ? this.renderQuote() : null,
                !this.state.isLoading && this.renderSectionTitle('Your thoughts'),
                !this.state.isLoading && this.renderTextField())));
    }
}
const mapStateToProps = (state) => {
    return {
        authors: stripPersist(state.authors),
        deviceDimensions: state.dimensions.deviceDimensions,
        entries: stripPersist(state.entries),
        entryId: state.entry.entryId,
        isConnected: state.connection.isConnected,
        mode: stripPersist(state.colors).mode,
        quote: stripPersist(state.quote),
        quotes: stripPersist(state.quotes),
        stats: state.stats,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    receiveEntry: (entry) => dispatch(receiveEntry(entry)),
    receiveQuote: (quote) => dispatch(receiveQuote(quote)),
    removeQuote: () => dispatch(removeQuote()),
    fetchAuthors: (user, authors) => dispatch(fetchAuthors(user, authors)),
    updateAndFetchQuotes: (user, quotes) => dispatch(updateAndFetchQuotes(user, quotes)),
    fetchEntries: (user) => dispatch(fetchEntries(user)),
    statsNotification: () => dispatch(receiveNotification('entries')),
    updateEntry: (user, entry) => dispatch(updateEntry(user, entry)),
    updateEntryOffline: (user, entry) => dispatch(updateEntry(user, entry)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EntryScreen);
