import React from 'react';
import { StyleSheet, View, Share, TouchableOpacity, Platform, } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { optimisticallyDestroyFavoriteQuote, optimisticallyFavoriteQuote, } from '@actions/quoteActions';
import { BLACK, LIGHT_GRAY, GREEN, PRIMARY_TEXT, BACKGROUND, COLORS, QUOTE_BACKGROUND, BRAND, QUOTE_TEXT, LIGHTEST_GRAY, MODAL_QUOTE_BACKGROUND, } from '../../utils/colors';
import TextButton from './textButton';
import { formatAuthorNameFromQuote, hitSlop, stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from './baseText';
import { SAMPLE_URL } from '../../utils/constants';
import { FavoriteIcon, FavoriteOutlineIcon, ShareIcon } from '../../assets/svg';
import { optimisticDestroyFavoriteMeditation, optimisticFavoriteMeditation, } from '../../actions/meditationActions';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    buttonContainer: {
        paddingTop: 20,
    },
    createEntryButton: {
        backgroundColor: LIGHT_GRAY,
        zIndex: 92,
        width: '98%',
        alignSelf: 'center',
    },
    createEntryButtonText: {
        color: GREEN,
    },
    container: {
        zIndex: 99,
        position: 'absolute',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
    },
    modalContainer: {
        width: Platform.OS === 'web' ? '50%' : '92%',
        borderRadius: 25,
        position: 'absolute',
        alignItems: 'center',
        alignSelf: 'center',
        zIndex: 70,
        padding: 12,
        marginRight: 15,
        marginLeft: 15,
    },
    contentContainer: {
        margin: 12,
    },
    overlay: {
        flex: 1,
        position: 'absolute',
        opacity: 0.4,
        backgroundColor: BLACK,
        zIndex: 60,
    },
    title: {
        textAlign: 'center',
        fontSize: Fonts.size.xxxl,
        fontWeight: 'bold',
    },
    titleContainer: {
        borderTopRightRadius: 25,
        borderTopLeftRadius: 25,
        backgroundColor: BLACK,
        zIndex: 70,
        justifyContent: 'center',
        alignContent: 'center',
        height: 75,
    },
    quoteTextContainer: {
        borderRadius: 10,
        padding: 14,
        marginBottom: 12,
    },
    quoteText: {
        fontSize: Fonts.size.m,
        backgroundColor: BLACK,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
    },
    author: {
        fontSize: Fonts.size.s,
        padding: 8,
        fontWeight: 'bold',
    },
    finishButtonStyle: {
        width: '98%',
        alignSelf: 'center',
    },
    row: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        maxHeight: 55,
        borderBottomColor: LIGHTEST_GRAY,
        borderBottomWidth: 1,
        padding: 10,
    },
    iconContainer: {
        flex: 1,
    },
    rowText: {
        fontSize: Fonts.size.l,
        flex: 5,
    },
});
const Modal = (props) => {
    if (props.isModalOpen) {
        const deviceDimensionStyles = {
            height: props.deviceDimensions.deviceHeight,
            width: props.deviceDimensions.deviceWidth,
        };
        const handleShare = () => {
            Share.share({
                message: `I've been using Stoa: a Stoic meditation app! Listen to a sample meditation here: ${`${SAMPLE_URL}${props.meditation.rand_id}`}`,
            }).catch((e) => console.log(e));
            Analytics.event(Analytics.Events.SHARE_MEDITATION, { source: 'Meditation End Modal' });
        };
        const renderFavoriteQuote = () => {
            if (isEmpty(props.quote))
                return null;
            const config = {
                favorite: {
                    text: 'Favorite Quote',
                    icon: (React.createElement(FavoriteOutlineIcon, { style: { color: COLORS[BRAND][props.mode], width: 20, height: 20 } })),
                    onPress: () => {
                        Analytics.event(Analytics.Events.FAVORITE_QUOTE_MEDITATION_MODAL, {
                            meditation_id: props.meditation.id,
                            meditation_title: props.meditation.title,
                            quote_id: props.quote.id,
                        });
                        props.favoriteQuote(props.user, props.quote);
                    },
                },
                remove: {
                    text: 'Unfavorite Quote',
                    icon: (React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][props.mode], width: 20, height: 20 } })),
                    onPress: () => {
                        Analytics.event(Analytics.Events.REMOVE_FAVORITE_QUOTE_MEDITATION_MODAL, {
                            meditation_id: props.meditation.id,
                            meditation_title: props.meditation.title,
                            quote_id: props.quote.id,
                        });
                        props.destroyFavoriteQuote(props.user, props.quote);
                    },
                },
            };
            const configured = props.quote.is_favorite ? config.remove : config.favorite;
            return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.row, onPress: configured.onPress },
                React.createElement(View, { style: styles.iconContainer }, configured.icon),
                React.createElement(BaseText, { style: [styles.rowText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, configured.text)));
        };
        const renderFavorite = () => {
            if (props?.playlist?.is_daily)
                return null;
            const config = {
                favorite: {
                    text: 'Favorite Meditation',
                    icon: (React.createElement(FavoriteOutlineIcon, { style: { color: COLORS[BRAND][props.mode], width: 20, height: 20 } })),
                    onPress: () => {
                        Analytics.event(Analytics.Events.FAVORITE_MEDITATION_END_MODAL, {
                            meditation_id: props.meditation.id,
                            meditation_title: props.meditation.title,
                        });
                        props.favoriteMeditation(props.user, props.meditation);
                    },
                },
                remove: {
                    text: 'Unfavorite Meditation',
                    icon: (React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][props.mode], width: 20, height: 20 } })),
                    onPress: () => {
                        Analytics.event(Analytics.Events.REMOVE_FAVORITE_MEDITATION_END_MODAL, {
                            meditation_id: props.meditation.id,
                            meditation_title: props.meditation.title,
                        });
                        props.destroyFavoriteMeditation(props.user, props.meditation);
                    },
                },
            };
            const configured = props.meditation.is_favorite ? config.remove : config.favorite;
            return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.row, onPress: configured.onPress },
                React.createElement(View, { style: styles.iconContainer }, configured.icon),
                React.createElement(BaseText, { style: [styles.rowText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, configured.text)));
        };
        const authorName = formatAuthorNameFromQuote(props.authors, props.quote);
        return (React.createElement(View, { style: [
                styles.container,
                deviceDimensionStyles,
                { backgroundColor: COLORS[BACKGROUND][props.mode] },
            ] },
            React.createElement(View, { style: [styles.overlay, deviceDimensionStyles], 
                // @ts-ignore
                onPress: props.closeModal }),
            React.createElement(View, { style: [styles.modalContainer, { backgroundColor: COLORS[QUOTE_BACKGROUND][props.mode] }] },
                props.title ? (React.createElement(View, { style: styles.titleContainer },
                    React.createElement(BaseText, { style: [styles.title, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, props.title))) : null,
                React.createElement(View, { style: styles.contentContainer },
                    props.quote ? (React.createElement(View, { style: [
                            styles.quoteTextContainer,
                            { backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][props.mode] },
                        ] },
                        React.createElement(View, null,
                            React.createElement(BaseText, { style: [
                                    styles.quoteText,
                                    {
                                        color: COLORS[QUOTE_TEXT][props.mode],
                                        backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][props.mode],
                                    },
                                ], selectable: true }, props.quote.text)),
                        authorName ? (React.createElement(View, null,
                            React.createElement(BaseText, { style: [styles.author, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, ` \u2014 ${authorName}`))) : null)) : null,
                    renderFavoriteQuote(),
                    renderFavorite(),
                    React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.row, onPress: handleShare },
                        React.createElement(View, { style: styles.iconContainer },
                            React.createElement(ShareIcon, { style: { color: COLORS[BRAND][props.mode], width: 20, height: 20 } })),
                        React.createElement(BaseText, { style: [styles.rowText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "Share")),
                    React.createElement(View, { style: styles.buttonContainer },
                        React.createElement(TextButton, { onPress: props.closeModal, title: props.buttonText, buttonStyle: styles.finishButtonStyle }))))));
    }
    // @ts-ignore
    return null;
};
const mapStateToProps = (state) => ({
    deviceDimensions: state.dimensions.deviceDimensions,
    mode: stripPersist(state.colors).mode,
    authors: stripPersist(state.authors),
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    destroyFavoriteMeditation: (user, meditation) => dispatch(optimisticDestroyFavoriteMeditation(user, meditation)),
    favoriteMeditation: (user, meditation) => dispatch(optimisticFavoriteMeditation(user, meditation)),
    favoriteQuote: (user, quote) => dispatch(optimisticallyFavoriteQuote(user, quote)),
    destroyFavoriteQuote: (user, quote) => dispatch(optimisticallyDestroyFavoriteQuote(user, quote)),
});
Modal.defaultProps = {
    title: '',
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
