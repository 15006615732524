import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Rate, { AndroidMarket } from 'react-native-rate';
import moment from 'moment';
import { LastRatingAskKey, LastSubscriptionAskKey } from '@utils/asyncKeys';
import { reviewAsk } from '../../actions/userActions';
import { APPLE_APP_ID, SUBSCRIPTION_SCREEN_HOME } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
const AskText = [
    "Thanks for using Stoa. Please write a review if you're able. Stoa is a run by a small team and each review matters.",
    'If you find Stoa useful, please leave a review.',
    'Do you love Stoa? Help us out with a review. Each review matters.',
];
export const getAskTextIndex = () => Math.floor((AskText.length - 1) * Math.random());
class RandomPopups extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        askTextIndex: '',
    };
    async componentDidMount() {
        this.checkSubscriptionAsk();
        this.checkRatingAsk();
    }
    shouldRatingAsk = () => {
        const likelihood = this.props.stats.notification ? 0.25 : 0.05; // higher probability after completing meditation
        const isLikelyEnough = Math.random() < likelihood;
        const isAsk = this.props.user.review_asks < 5
            && isLikelyEnough
            && !moment(this.props.user.created_at).isSame(moment(), 'day');
        return isAsk;
    };
    sameDay = (timeStr) => timeStr && moment(timeStr).isSame(moment(), 'day');
    async checkNotAsk() {
        const lastRatingAsk = await AsyncStorage.getItem('last-rating-ask');
        const lastSubscriptionAsk = await AsyncStorage.getItem('last-subscription-ask');
        const onboardingKey = `onboarding-modal-${this.props.user.id}`;
        const didOnboard = await AsyncStorage.getItem(onboardingKey);
        return this.sameDay(lastRatingAsk) || this.sameDay(lastSubscriptionAsk) || !didOnboard;
    }
    async checkRatingAsk() {
        const shouldNotAsk = await this.checkNotAsk();
        if (!shouldNotAsk) {
            const shouldAsk = this.shouldRatingAsk();
            if (shouldAsk) {
                Analytics.event(Analytics.Events.RATING_POPUP);
                this.renderAsk();
                AsyncStorage.setItem(LastRatingAskKey, moment().format());
            }
        }
    }
    async checkSubscriptionAsk() {
        const shouldNotAsk = await this.checkNotAsk();
        if (!shouldNotAsk) {
            const shouldAsk = this.shouldRatingAsk() && !this.props.user.is_subscribed && !this.props.user.is_pseudo;
            if (shouldAsk) {
                Analytics.event(Analytics.Events.SUBSCRIPTION_POPUP);
                this.props.navigation.navigate(SUBSCRIPTION_SCREEN_HOME);
                AsyncStorage.setItem(LastSubscriptionAskKey, moment().format());
            }
        }
    }
    renderAsk = () => {
        Analytics.event(Analytics.Events.RATING_SNACKBAR);
        this.props.reviewAsk(this.props.user);
        Alert.alert('Review', AskText[getAskTextIndex()], [
            { text: 'No Thanks', onPress: () => console.log('OK Pressed') },
            {
                text: 'OK',
                onPress: () => {
                    const options = {
                        AppleAppID: APPLE_APP_ID,
                        GooglePackageName: 'stoameditation.stoa',
                        OtherAndroidURL: 'https://play.google.com/store/apps/details?id=stoameditation.stoa',
                        preferredAndroidMarket: AndroidMarket.Google,
                        preferInApp: true,
                        openAppStoreIfInAppFails: true,
                        fallbackPlatformURL: 'stoameditation.com',
                    };
                    Rate.rate(options, (success) => {
                        if (success) {
                            // this technically only tells us if the user successfully went to the Review Page. Whether they actually did anything, we do not know.
                            // Todo track text in google analytics
                            Analytics.event(Analytics.Events.RATING_SNACKBAR_SUCCESS, { ask_text_idx: this.props.askTextIndex });
                        }
                    });
                },
            },
        ]);
    };
    render() {
        return null;
    }
}
const mapStateToProps = (state) => ({
    stats: state.stats,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    reviewAsk: (user) => dispatch(reviewAsk(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RandomPopups);
