import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { stripPersist } from '@utils/index';
import { fetchGet, imageToSpacesUrl } from '@utils/request';
import { Fonts } from '@utils/fonts';
import { StyleSheet, View, ScrollView, BackHandler, } from 'react-native';
import { BACKGROUND, BLACK, BLUE, COLORS, SECONDARY_TEXT, WHITE, } from '@utils/colors';
import BaseText from '@components/baseComponents/baseText';
import Header from '@components/baseComponents/header';
import { getDeviceOrientation } from '@actions/dimensionsActions';
import { ORIENTATION } from '@utils/constants';
import Analytics from '@utils/analytics';
import FastImage from '@utils/libraries/fastImage';
import AnalyticsEvents from '@utils/AnalyticsEvents';
import ChapterRow from './ChapterRow';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    titleText: {
        paddingLeft: 10,
        flex: 4,
        fontWeight: '400',
        fontSize: Fonts.size.l,
        justifyContent: 'flex-start',
    },
    numeral: {
        justifyContent: 'flex-start',
        flex: 1,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    descriptionText: {
        color: WHITE,
        fontWeight: '400',
        fontSize: Fonts.size.m,
        textAlign: 'center',
    },
    content: {
        flex: 1,
    },
    contentContainer: {
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    description: {
        paddingTop: 5,
    },
    headerImage: {
        zIndex: 1,
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        flex: 1,
        backgroundColor: BLUE,
    },
    headerOverlay: {
        opacity: 0.4,
        backgroundColor: BLACK,
        flex: 1,
        zIndex: 10,
        width: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
    },
    headerContent: {
        zIndex: 50,
        top: 0,
        left: 0,
    },
    headerImageStyle: {
        opacity: 0.8,
        zIndex: 1,
    },
    headerView: {
        justifyContent: 'space-between',
        paddingHorizontal: 30,
        flex: 1,
    },
});
const LANDSCAPE_HEADER_DIVISOR = 1.7;
const PORTRAIT_HEADER_DIVISOR = 2.5;
const Book = ({ navigation, route, user, mode, deviceDimensions, }) => {
    const { bookId } = route.params;
    const [book, setBook] = useState(null);
    useEffect(() => {
        const fetch = async () => {
            const fetchedBook = await fetchGet(user, `/api/books/${bookId}`);
            if (fetchedBook) {
                setBook(fetchedBook);
            }
            else {
                Analytics.recordError(AnalyticsEvents.UNABLE_TO_LOAD_BOOK, { bookId });
            }
        };
        fetch();
        Analytics.event(Analytics.Events.VIEW_BOOKSCREEN, {
            book_id: book?.id,
            bookTitle: book?.title,
        });
        const goBack = () => {
            navigation.goBack();
            return true;
        };
        BackHandler.addEventListener('hardwareBackPress', goBack);
        return () => {
            BackHandler.addEventListener('hardwareBackPress', goBack);
        };
    }, []);
    const renderChapters = () => {
        if (!book)
            return null;
        const { chapters } = book;
        return Object.entries(chapters)
            .sort(([, a], [, b]) => a.index - b.index)
            .map(([, chapter], i) => (React.createElement(ChapterRow, { book: book, chapter: chapter, navigation: navigation, mode: mode, index: chapter.index, key: i })));
    };
    // Move
    const { deviceHeight, deviceWidth } = deviceDimensions;
    const orientation = getDeviceOrientation();
    const divider = orientation === ORIENTATION.PORTRAIT ? PORTRAIT_HEADER_DIVISOR : LANDSCAPE_HEADER_DIVISOR;
    const headerDimensions = {
        height: deviceHeight / divider,
        width: deviceWidth,
    };
    const handleBackPress = () => {
        navigation.goBack();
    };
    const renderHeader = () => {
        if (!book)
            return null;
        const descriptionArr = book.description.split('.');
        return (React.createElement(View, { style: [
                { flexDirection: 'column', zIndex: 1, backgroundColor: COLORS[BACKGROUND][mode] },
                headerDimensions,
            ] },
            React.createElement(View, { style: [styles.headerOverlay, headerDimensions] }),
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(book.image_url) }, style: [styles.headerImage, headerDimensions], 
                // @ts-ignore
                imageStyle: styles.headerImageStyle }),
            React.createElement(View, { style: [styles.headerContent, headerDimensions] },
                React.createElement(Header, { title: book.title, onPressCallback: handleBackPress, color: COLORS[SECONDARY_TEXT][mode], mode: mode }),
                React.createElement(View, { style: styles.headerView },
                    React.createElement(ScrollView, { contentContainerStyle: [styles.description] }, descriptionArr.map((sentence, i) => (React.createElement(BaseText, { key: i, style: [styles.descriptionText, { color: COLORS[SECONDARY_TEXT][mode] }] }, `${sentence}${i === descriptionArr.length - 1 ? '' : '.'}`))))))));
    };
    if (!book)
        return React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] });
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        renderHeader(),
        React.createElement(ScrollView, { style: styles.content, contentContainerStyle: styles.contentContainer }, renderChapters())));
};
const mapStateToProps = (state) => ({
    deviceDimensions: state.dimensions.deviceDimensions,
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps)(Book);
