import { fetchListenHistory } from '@actions/listenHistoryActions';
import { setAndGoToMeditation } from '@actions/meditationActions';
import BaseText from '@components/baseComponents/baseText';
import { getMeditationsObj } from '@selectors/playlists';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, FlatList, StyleSheet, View } from 'react-native';
import FastImage from '@utils/libraries/fastImage';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { imageToSpacesUrl } from '../../utils/request';
import { Fonts } from '../../utils/fonts';
import { BLUE, COLORS, PRIMARY_TEXT, BRAND } from '../../utils/colors';
import { stripPersist } from '../../utils';
const styles = StyleSheet.create({
    image: {
        height: 41,
        width: 41,
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: BLUE,
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        maxHeight: 70,
        minHeight: 50,
    },
    itemTextContainer: {
        paddingLeft: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        width: '80%',
    },
    meditationTitle: {
        fontSize: Fonts.size.m,
    },
    date: {
        fontSize: Fonts.size.s,
    },
    length: {
        fontSize: Fonts.size.s,
    },
});
const History = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const asyncFetch = async () => {
            setIsLoading(true);
            /*
            - Todo: fetch by limit, not date
            */
            await props.fetchListenHistory(props.user, props.fetchParams);
            setIsLoading(false);
        };
        asyncFetch();
    }, [props.fetchParams]);
    const renderItem = ({ item }) => {
        const { meditationTitle, image, meditationId, createdAt, lengthSec } = item;
        const meditation = props.meditations[meditationId];
        const dateStr = moment(createdAt).format('M-D-Y');
        const lengthStr = `${(lengthSec / 60).toFixed(2).split('.')[0]}:${lengthSec % 60}`;
        return (React.createElement(TouchableOpacity, { onPress: () => {
                if (meditation) {
                    props.setAndGoToMeditation(meditation, props.navigation);
                }
                else {
                    Alert.alert('Meditation is no longer available.');
                }
            }, style: styles.itemContainer, key: createdAt },
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(image) }, style: styles.image }),
            React.createElement(View, { style: styles.itemTextContainer },
                React.createElement(View, null,
                    React.createElement(BaseText, { style: [styles.date, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, dateStr),
                    React.createElement(BaseText, { style: [styles.meditationTitle, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, meditationTitle)),
                React.createElement(View, { style: {} },
                    React.createElement(BaseText, { style: [styles.length, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, lengthStr)))));
    };
    return (React.createElement(View, null,
        React.createElement(FlatList, { data: props.listenHistory, renderItem: renderItem }),
        isLoading && React.createElement(ActivityIndicator, { size: "large", color: COLORS[BRAND][props.mode] })));
};
const mapStateToProps = (state) => {
    const meditations = getMeditationsObj(stripPersist(state.playlists));
    return {
        listenHistory: stripPersist(state.listenHistory),
        meditations,
        mode: stripPersist(state.colors).mode,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchListenHistory: (user, params) => dispatch(fetchListenHistory(user, params)),
        setAndGoToMeditation: (meditation, navigation) => dispatch(setAndGoToMeditation(meditation, navigation)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(History);
