import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { fetchGet } from '@utils/request';
import { stripPersist } from '@utils/index';
import { ScrollView } from 'react-native-gesture-handler';
import Header from '@components/baseComponents/header';
import Analytics from '@utils/analytics';
import { BOOK_SCREEN, HIGHLIGHTS_SCREEN, QUOTES_SCREEN } from '@utils/constants';
import { BACKGROUND, COLORS } from '@utils/colors';
import { isEmpty } from 'lodash';
import BookRow from './BookRow';
import Row from './Row';
const styles = StyleSheet.create({
    title: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    bg: {
        flex: 1,
    },
    scrollView: {
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
});
const Books = ({ navigation, user, mode }) => {
    const [books, setBooks] = useState({});
    useEffect(() => {
        const fetch = async () => {
            const fetchedBooks = await fetchGet(user, '/api/books');
            setBooks(fetchedBooks || {});
        };
        fetch();
    }, []);
    const renderHighlights = () => {
        const handleOnPress = () => {
            navigation.navigate(HIGHLIGHTS_SCREEN);
            Analytics.event(Analytics.Events.PRESSED_HIGHLIGHTS, {
                screen: BOOK_SCREEN,
            });
        };
        return (React.createElement(Row, { key: "highlights", title: "Highlights", subtitle: "View your highlights here.", mode: mode, handleOnPress: handleOnPress, imageUrl: "highlights" }));
    };
    const renderQuotes = () => {
        const handleOnPress = () => {
            navigation.navigate(QUOTES_SCREEN);
            Analytics.event(Analytics.Events.PRESSED_QUOTES, {
                screen: BOOK_SCREEN,
            });
        };
        return (React.createElement(Row, { key: "quotes", title: "Quotes", subtitle: "Read selected quotes from Stoa exercises.", mode: mode, handleOnPress: handleOnPress, imageUrl: "statue" }));
    };
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        React.createElement(Header, { mode: mode, title: "Read", onPressCallback: () => navigation.goBack() }),
        React.createElement(ScrollView, { contentContainerStyle: styles.scrollView },
            renderQuotes(),
            renderHighlights(),
            isEmpty(books)
                ? null
                : Object.entries(books).map(([, book]) => {
                    if (!book)
                        return null;
                    return React.createElement(BookRow, { book: book, mode: mode, navigation: navigation, key: book.id });
                }))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps)(Books);
