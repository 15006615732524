import { merge, cloneDeep } from 'lodash';
import { RECEIVE_MEDITATION, RECEIVE_PLAYLIST, RECEIVE_PLAYLISTS, } from '../actions/playlistsActions';
import { RECORD_LISTEN } from '../actions/meditationActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {
    data: {},
};
const PlaylistsReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    let playlist;
    switch (action.type) {
        case RECEIVE_PLAYLISTS:
            return {
                data: action.playlists,
            };
        case RECEIVE_PLAYLIST:
            playlist = action.playlist;
            newState.data[playlist.id] = playlist;
            return newState;
        case RECORD_LISTEN:
            playlist = newState.data[action.meditation.playlist_id];
            if (!playlist)
                return newState;
            playlist.meditations[action.meditation.id] = action.meditation;
            newState.data[playlist.id] = cloneDeep(playlist);
            return newState;
        case RECEIVE_MEDITATION:
            playlist = newState.data[action.meditation.playlist_id];
            if (!playlist)
                return newState;
            playlist.meditations[action.meditation.id] = action.meditation;
            if (!action.meditation.is_listened) {
                playlist.is_listened = false;
            }
            newState.data[playlist.id] = cloneDeep(playlist);
            return newState;
        default:
            return newState;
    }
};
export default PlaylistsReducer;
