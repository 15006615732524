import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import WelcomeScreen from '@components/onboarding_ii/WelcomeScreen';
import PitchScreen from '@components/onboarding_ii/PitchScreen';
import PhilosophyScreen from '@components/onboarding_ii/PhilosophyScreen';
import ReviewsScreen from '@components/onboarding_ii/ReviewsScreen';
import QuizIScreen from '@components/onboarding_ii/QuizIScreen';
import QuizIIScreen from '@components/onboarding_ii/QuizIIScreen';
import SubscriptionScreenV2 from '@components/subscription/SubscriptionScreenV2';
import SignInScreen from '@components/onboarding_ii/SignInScreen';
import PrivacyScreen from '@components/about/privacyScreen';
import TermsOfUseScreen from '@components/about/termsOfUseScreen';
import ReminderScreen from '../components/onboarding/reminderScreen';
import SignupScreen from '../components/auth/signupScreen';
import { SIGNUP_SCREEN, REMINDER_SCREEN, WELCOME_SCREEN, PITCH_SCREEN, PHILOSOPHY_SCREEN, REVIEWS_SCREEN, QUIZ_I_SCREEN, QUIZ_II_SCREEN, SIGN_IN_SCREEN, SUBSCRIPTION_SCREEN_II, TERMS_OF_USE_SCREEN, PRIVACY_SCREEN, } from '../utils/constants';
const AuthStack = createStackNavigator();
const AuthNavigator = () => (React.createElement(AuthStack.Navigator, { initialRouteName: WELCOME_SCREEN, headerMode: "none", screenOptions: { animationEnabled: Platform.OS === 'ios' } },
    React.createElement(AuthStack.Screen, { name: WELCOME_SCREEN, component: WelcomeScreen }),
    React.createElement(AuthStack.Screen, { name: PITCH_SCREEN, component: PitchScreen }),
    React.createElement(AuthStack.Screen, { name: PHILOSOPHY_SCREEN, component: PhilosophyScreen }),
    React.createElement(AuthStack.Screen, { name: REVIEWS_SCREEN, component: ReviewsScreen }),
    React.createElement(AuthStack.Screen, { name: QUIZ_I_SCREEN, component: QuizIScreen }),
    React.createElement(AuthStack.Screen, { name: QUIZ_II_SCREEN, component: QuizIIScreen }),
    React.createElement(AuthStack.Screen, { name: SUBSCRIPTION_SCREEN_II, component: SubscriptionScreenV2 }),
    React.createElement(AuthStack.Screen, { name: SIGN_IN_SCREEN, component: SignInScreen }),
    React.createElement(AuthStack.Screen, { name: TERMS_OF_USE_SCREEN, component: TermsOfUseScreen }),
    React.createElement(AuthStack.Screen, { name: PRIVACY_SCREEN, component: PrivacyScreen }),
    React.createElement(AuthStack.Screen, { name: REMINDER_SCREEN, component: ReminderScreen }),
    React.createElement(AuthStack.Screen, { name: SIGNUP_SCREEN, component: SignupScreen })));
export default AuthNavigator;
