import { merge } from 'lodash';
import { SET_MEDITATION } from '../actions/meditationActions';
const initialState = {
    id: '',
};
const MeditationReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case SET_MEDITATION:
            return {
                id: action.id,
                type: action.meditationType,
            };
        default:
            return newState;
    }
};
export default MeditationReducer;
