import React from 'react';
import { imageToSpacesUrl } from '@utils/request';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { DEFAULT_IMAGE } from '@utils/constants';
import MeditationLink from './MeditationLink';
import MeditationProgress from './MeditationProgress';
import MeditationControls from './MeditationControls';
import MeditationActions from './MeditationActions';
const styles = StyleSheet.create({
    meditationBgImage: {
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    meditationBg: {
        flex: 1,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    playerContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        alignContent: 'center',
        flexDirection: 'column',
    },
    playerContainerRow: {
        paddingTop: 10,
        width: '100%',
    },
    linkContainer: {
        flex: 0.2,
        justifyContent: 'center',
    },
    landScapeView: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginHorizontal: 32,
        marginVertical: 12,
        alignSelf: 'center',
    },
    landScapePlayer: {
        marginHorizontal: '3%',
        marginVertical: 12,
        alignSelf: 'center',
    },
});
const MeditationContentLandscape = ({ mode, meditation, handleForwardPress, incrementPlaybackRate, playbackRate, prevIsPlaying, handleSliderValueChange, handleSlidingStart, handleSlidingComplete, isLoadingAudio, isPlaying, isSliding, togglePlay, initiatedBySlider, playbackPosition, sliderPosition, handleReplayPress, meditationHasMultipleFiles, meditationLen, playlist, }) => (React.createElement(View, { style: styles.landScapeView },
    React.createElement(ImageBackground, { source: {
            uri: imageToSpacesUrl(meditation.image || playlist?.image || DEFAULT_IMAGE),
        }, imageStyle: styles.meditationBgImage, style: (styles.meditationBg, { height: 260, width: 260 }) }),
    React.createElement(View, { style: [styles.playerContainer, styles.landScapePlayer] },
        React.createElement(MeditationLink, { meditation: meditation, mode: mode }),
        React.createElement(View, { style: styles.playerContainerRow },
            React.createElement(MeditationProgress, { handleSliderValueChange: handleSliderValueChange, handleSlidingComplete: handleSlidingComplete, handleSlidingStart: handleSlidingStart, length_sec: meditation.length_sec, mode: mode, playbackPosition: playbackPosition, sliderPosition: sliderPosition })),
        React.createElement(View, { style: styles.playerContainerRow },
            React.createElement(MeditationControls, { handleForwardPress: handleForwardPress, handleReplayPress: handleReplayPress, initiatedBySlider: initiatedBySlider, isLoading: isLoadingAudio, isPlaying: isPlaying, isSliding: isSliding, mode: mode, rewindLength: meditation.length_sec > 60 * 15 ? '30' : '10', toggleAudioPlayback: togglePlay, wasPlaying: prevIsPlaying })),
        React.createElement(View, { style: styles.playerContainerRow },
            React.createElement(MeditationActions, { handlePlaybackRate: incrementPlaybackRate, hasMultipleFiles: meditationHasMultipleFiles, isDaily: !!playlist?.is_daily, isTheory: meditation.is_theory || !!playlist?.is_theory, meditationLen: meditationLen, playbackRate: playbackRate, meditation: meditation })))));
export default MeditationContentLandscape;
