import React, { Component } from 'react';
import { Modal, Share, View, TouchableOpacity, StyleSheet, ActivityIndicator, Pressable, } from 'react-native';
import { connect } from 'react-redux';
import { hitSlop, stripPersist } from '../../utils';
import { PRIMARY_TEXT, LIGHT_GRAY, QUOTE_BACKGROUND, COLORS, BRAND, LIGHTEST_GRAY, GRAYS, } from '../../utils/colors';
import TextButton from './textButton';
import { SAMPLE_URL } from '../../utils/constants';
import { MODAL_RADIUS } from '../../utils/styles';
import { downloadAndUpdateMeditation, removeDownloadAndUpdateMeditation, } from '../../actions/playlistsActions';
import { markAsListened, markAsUnlistened, optimisticDestroyFavoriteMeditation, optimisticFavoriteMeditation, } from '../../actions/meditationActions';
import Analytics from '../../utils/analytics';
import { TrashIcon, ShareIcon, FavoriteOutlineIcon, FavoriteIcon, DownloadIcon, CheckMarkIcon, } from '../../assets/svg';
import BaseText from './baseText';
import BaseStyles from '../../utils/baseStyles';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    container: {
        zIndex: 99,
        position: 'absolute',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        top: 0,
        left: 0,
        flex: 1,
    },
    modalContainer: {
        backgroundColor: LIGHT_GRAY,
        marginTop: 120,
        borderRadius: MODAL_RADIUS,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 70,
        padding: 20,
        marginRight: 15,
        marginLeft: 15,
    },
    row: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        maxHeight: 55,
        borderBottomColor: LIGHTEST_GRAY,
        borderBottomWidth: 1,
        padding: 10,
    },
    buttonRow: {
        flex: 2,
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: 24,
        padding: 24,
    },
    rowText: {
        fontSize: Fonts.size.l,
        flex: 5,
    },
    iconContainer: {
        flex: 1,
    },
});
export const Row = ({ onPress, icon, text, mode, }) => (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.row, onPress: onPress },
    React.createElement(View, { style: styles.iconContainer }, icon),
    React.createElement(BaseText, { style: [styles.rowText, { color: COLORS[PRIMARY_TEXT][mode] }] }, text)));
class MeditationModal extends Component {
    handleShare = () => {
        Share.share({
            message: `I've been using Stoa: a Stoic meditation app. Listen to a sample meditation here: ${`${SAMPLE_URL}${this.props.meditation.rand_id}`}`,
        }).catch((e) => console.log(e));
        Analytics.event(Analytics.Events.SHARE_MEDITATION, { source: 'Meditation Modal' });
    };
    renderDownload = () => {
        const config = {
            download: {
                text: 'Download',
                icon: (React.createElement(DownloadIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: async () => {
                    this.props.downloadAndUpdateMeditation(this.props.meditation);
                    Analytics.event(Analytics.Events.DOWNLOAD_MEDITATION_MODAL_TOUCH, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                },
            },
            remove: {
                text: 'Remove Download',
                icon: (React.createElement(TrashIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: async () => {
                    this.props.removeDownloadAndUpdateMeditation(this.props.meditation);
                    Analytics.event(Analytics.Events.REMOVE_MEDITATION_MODAL_TOUCH, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                },
            },
            downloading: {
                text: 'Downloading',
                icon: (React.createElement(ActivityIndicator, { color: COLORS[BRAND][this.props.mode], size: 20, style: { width: 20, height: 20 } })),
                onPress: () => { },
            },
        };
        const configured = this.props.meditation.is_downloading
            ? config.downloading
            : this.props.meditation.is_downloaded
                ? config.remove
                : config.download;
        return (React.createElement(Row, { onPress: configured.onPress, text: configured.text, icon: configured.icon, mode: this.props.mode }));
    };
    renderFavorite = () => {
        if (this.props.playlist?.is_daily)
            return null;
        const config = {
            favorite: {
                text: 'Favorite',
                icon: (React.createElement(FavoriteOutlineIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    Analytics.event(Analytics.Events.FAVORITE_MEDITATION_MODAL, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                    this.props.favoriteMeditation(this.props.user, this.props.meditation);
                },
            },
            remove: {
                text: 'Remove from Favorites',
                icon: (React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    Analytics.event(Analytics.Events.REMOVE_FAVORITE_MEDITATION_MODAL, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                    this.props.destroyFavoriteMeditation(this.props.user, this.props.meditation);
                },
            },
        };
        const configured = this.props.meditation.is_favorite ? config.remove : config.favorite;
        return (React.createElement(Row, { onPress: configured.onPress, text: configured.text, icon: configured.icon, mode: this.props.mode }));
    };
    renderMarkAsListened = () => {
        const config = {
            listen: {
                text: 'Mark as Listened',
                icon: (React.createElement(CheckMarkIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    Analytics.event(Analytics.Events.MARK_AS_LISTENED_MEDITATION_MODAL, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                    this.props.markAsListened(this.props.user, this.props.meditation);
                },
            },
            unlisten: {
                text: 'Mark as Unlistened',
                icon: (React.createElement(CheckMarkIcon, { style: { color: COLORS[GRAYS][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    Analytics.event(Analytics.Events.MARK_AS_UNLISTENED_MEDITATION_MODAL, {
                        meditation_id: this.props.meditation.id,
                        meditation_title: this.props.meditation.title,
                    });
                    this.props.markAsUnlistened(this.props.user, this.props.meditation);
                },
            },
        };
        const configured = this.props.meditation.is_listened ? config.unlisten : config.listen;
        return (React.createElement(Row, { onPress: configured.onPress, text: configured.text, icon: configured.icon, mode: this.props.mode }));
    };
    renderShare = () => {
        const icon = (React.createElement(ShareIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } }));
        return React.createElement(Row, { onPress: this.handleShare, text: "Share", icon: icon, mode: this.props.mode });
    };
    renderModal = () => (React.createElement(View, { style: [
            styles.modalContainer,
            { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
        ] },
        React.createElement(View, null,
            this.renderFavorite(),
            this.renderMarkAsListened(),
            this.renderShare(),
            this.renderDownload()),
        React.createElement(View, { style: styles.buttonRow },
            React.createElement(TextButton, { title: "Close", onPress: this.props.closeModal }))));
    render() {
        return (React.createElement(Modal, { visible: !!(this.props.isOpen && this.props.meditation), transparent: true, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
            this.renderModal(),
            React.createElement(Pressable, { onPress: this.props.closeModal, style: BaseStyles.overlay })));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    downloadAndUpdateMeditation: (meditation) => dispatch(downloadAndUpdateMeditation(meditation)),
    removeDownloadAndUpdateMeditation: (meditation) => dispatch(removeDownloadAndUpdateMeditation(meditation)),
    destroyFavoriteMeditation: (user, meditation) => dispatch(optimisticDestroyFavoriteMeditation(user, meditation)),
    favoriteMeditation: (user, meditation) => dispatch(optimisticFavoriteMeditation(user, meditation)),
    markAsListened: (user, meditation) => {
        dispatch(markAsListened(user, meditation));
    },
    markAsUnlistened: (user, meditation) => {
        dispatch(markAsUnlistened(user, meditation));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MeditationModal));
