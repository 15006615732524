import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { getMeditationsFromPlaylist, INTRO_IDS } from '@selectors/playlists';
import { COLORS, BRAND } from '../../utils/colors';
import MeditationHomeCard from '../baseComponents/meditationHomeCard';
import SectionTitle from '../baseComponents/sectionTitle';
import OfflineMessage from '../baseComponents/offlineMessage';
import { stripPersist } from '../../utils';
import BaseStyles from '../../utils/baseStyles';
import Analytics from '../../utils/analytics';
// Web export not working?
const isFirstMeditation = (playlist, meditation) => {
    const meditations = getMeditationsFromPlaylist(playlist);
    return meditations[0]?.id === meditation.id;
};
const styles = StyleSheet.create({
    textContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    meditationCard: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
const MeditationHomeSection = (props) => {
    useEffect(() => {
        if (props.playlist && props.meditation) {
            Analytics.event('View Next Meditation', {
                is_daily: props.playlist.is_daily,
                meditation_id: props.meditation.id,
            });
        }
    }, []);
    if (props.meditation === null) {
        return null;
    }
    if (props.continuePlayingId && props.continuePlayingId === props.meditation.id) {
        return null;
    }
    if (!props.isConnected) {
        return React.createElement(OfflineMessage, null);
    }
    if (props.meditation === undefined || !props.playlist) {
        return (React.createElement(View, null,
            React.createElement(SectionTitle, { title: "Current Meditation" }),
            React.createElement(View, { style: styles.meditationCard },
                React.createElement(View, { style: styles.textContainer },
                    React.createElement(ActivityIndicator, { size: "small", color: COLORS[BRAND][props.mode] })))));
    }
    let sectionTitle = 'Current Meditation';
    if (INTRO_IDS.includes(props.meditation.playlist_id)
        && isFirstMeditation(props.playlist, props.meditation)) {
        sectionTitle = 'Start Here';
    }
    else if (props.playlist.is_daily) {
        sectionTitle = 'Daily Meditation';
    }
    else if (isFirstMeditation(props.playlist, props.meditation)) {
        sectionTitle = `Start ${props.playlist.title} Series`;
    }
    else {
        sectionTitle = `Next in ${props.playlist.title} Series`;
    }
    return (React.createElement(View, { style: BaseStyles.homeSectionContainer },
        React.createElement(SectionTitle, { title: sectionTitle }),
        React.createElement(MeditationHomeCard, { meditation: props.meditation, playlist: props.playlist, navigation: props.navigation, onPressAnalytics: () => Analytics.event(Analytics.Events.MEDITATION_HOME_CARD_TOUCH, {
                meditation_id: props.meditation.id,
                meditation_title: props.meditation.title,
            }) })));
};
const mapStateToProps = (state) => ({
    continuePlayingId: stripPersist(state.continuePlaying),
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps)(MeditationHomeSection);
