import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import EntriesScreen from '../components/entries/entriesScreen';
import EntryScreen from '../components/entries/entryScreen';
import { ENTRIES_SCREEN, ENTRY_SCREEN } from '../utils/constants';
const EntryStack = createStackNavigator();
const EntryNavigator = () => {
    return (React.createElement(EntryStack.Navigator, { initialRouteName: ENTRIES_SCREEN, headerMode: "none", screenOptions: { animationEnabled: Platform.OS === 'ios' } },
        React.createElement(EntryStack.Screen, { name: ENTRIES_SCREEN, component: EntriesScreen }),
        React.createElement(EntryStack.Screen, { name: ENTRY_SCREEN, component: EntryScreen })));
};
export default EntryNavigator;
