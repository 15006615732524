// @ts-nocheck
import { Platform } from 'react-native';
import AppleHealthKit from 'rn-apple-healthkit';
export const healthKitPermissions = () => {
    if (Platform.OS === 'ios') {
        const PERMS = AppleHealthKit.Constants.Permissions;
        const healthKitOptions = {
            permissions: {
                read: [],
                write: [PERMS.MindfulSession],
            },
        };
        AppleHealthKit.initHealthKit(healthKitOptions, (err) => {
            if (err) {
                console.log(err);
            }
        });
    }
};
export const saveMindfulSession = (startDate, endDate) => {
    AppleHealthKit.saveMindfulSession({
        startDate,
        endDate,
    }, (err, res) => {
        if (err)
            return;
    });
};
