// @ts-nocheck
import React, { PureComponent } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import store from './store/store';
import AppAuthContainer from './navigation/AppAuthContainer';
import ErrorBoundary from './components/baseComponents/ErrorBoundary';
import { COLORS, BRAND, BACKGROUND, PRIMARY_TEXT, SECONDARY_BORDER, SECONDARY_BACKGROUND, } from './utils/colors';
import { DARK } from './utils/constants';
import Analytics from './utils/analytics';
Analytics.start();
class App extends PureComponent {
    render() {
        const { mode } = store.getState().colors;
        return (React.createElement(Provider, { store: store },
            React.createElement(NavigationContainer, { theme: NavigationTheme(mode) },
                React.createElement(ErrorBoundary, null,
                    React.createElement(AppAuthContainer, null)))));
    }
}
const NavigationTheme = (mode) => {
    return {
        dark: mode === DARK,
        colors: {
            primary: COLORS[BRAND][mode],
            card: COLORS[SECONDARY_BACKGROUND][mode],
            background: COLORS[BACKGROUND][mode],
            text: COLORS[PRIMARY_TEXT][mode],
            border: COLORS[SECONDARY_BORDER][mode],
        },
    };
};
export default App;
