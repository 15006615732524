import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function ChevronRightIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 9 15", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M1.78659 0.345005C1.6608 0.21911 1.49345 0.149994 1.31403 0.149994C1.1346 0.149994 0.967252 0.21911 0.841402 0.34506L0.351218 0.835122C0.0907159 1.09592 0.0907159 1.52006 0.351158 1.7804L5.94138 7.37062V7.58276L0.344997 13.1791C0.219051 13.3052 0.149902 13.4725 0.149902 13.6517C0.149902 13.831 0.219032 13.9984 0.344929 14.1245L0.835212 14.6145C0.961174 14.7405 1.12844 14.8096 1.30782 14.8096C1.48724 14.8096 1.6546 14.7405 1.78043 14.6145L8.44376 7.95145C8.56984 7.82518 8.63893 7.65708 8.63853 7.47672C8.63893 7.2963 8.56992 7.12831 8.44384 7.00213L1.78659 0.345005Z", fill: color })));
}
ChevronRightIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default ChevronRightIcon;
