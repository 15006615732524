import { flatten } from 'lodash';
export const getSentencesArr = (chapter) => {
    if (!chapter) {
        return;
    }
    const sectionsArr = Object.entries(chapter?.sections)
        .sort((a, b) => a.index - b.index)
        .map((arr) => arr[1]);
    const sentencesArr = flatten(sectionsArr.map((section) => {
        return Object.values(section?.sentences).sort((a, b) => a.index - b.index);
    })).map((sentence, i) => ({
        id: sentence.id,
        index: i,
        text: sentence.text,
        key: sentence.id.toString(),
    }));
    return sentencesArr;
};
