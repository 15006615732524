import { host } from './constants';
import { escapeEmail } from './email';
export const filepathToSpacesUrl = (uri) => {
    return `https://stoic-app.sfo2.cdn.digitaloceanspaces.com/${uri}`;
};
export const imageToSpacesUrl = (uri) => {
    if (!uri)
        return '';
    const newUri = uri.includes('.png') ? uri : `${uri}.png`;
    return filepathToSpacesUrl(newUri);
};
export const fetchGet = (user, url, queryString) => {
    return fetch(`${host}${url}/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}${queryString ? `&${queryString}` : ''}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        redirect: 'follow',
        referrer: 'no-referrer', // no-referrer, *client
    })
        .then((response) => {
        return response.json();
    })
        .catch((error) => {
        console.log(error);
    });
};
export const fetchPost = (user, url, data = {}) => {
    return fetch(`${host}${url}/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).catch((e) => console.log(e));
};
export const fetchPatch = (user, url, data = {}) => {
    return fetch(`${host}${url}/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).catch((e) => console.log(e));
};
export const fetchDelete = (user, url) => {
    return fetchWithMethod(user, url, 'DELETE');
};
const fetchWithMethod = async (user, url, method, data = {}) => {
    return fetch(`${host}${url}/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).catch((e) => console.log(e));
};
export const getMeditationText = (textFilepath) => {
    return fetch(filepathToSpacesUrl(textFilepath), {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'text/markdown; charset=utf-8',
        },
    })
        .then((response) => {
        return response.text();
    })
        .catch((error) => {
        console.log(error);
    });
};
