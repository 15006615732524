import React from 'react';
import { Modal, View, StyleSheet, TouchableOpacity, } from 'react-native';
import BaseStyles from '../../utils/baseStyles';
import { BACKGROUND, BRAND, COLORS, GRAY, PRIMARY_TEXT, QUOTE_BACKGROUND, WHITE, } from '../../utils/colors';
import { MODAL_RADIUS } from '../../utils/styles';
import { QuotationIcon } from '../../assets/svg';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import BaseText from '../baseComponents/baseText';
import TextButton from '../baseComponents/textButton';
import Analytics from '../../utils/analytics';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: WHITE,
        paddingTop: 70,
        borderRadius: MODAL_RADIUS,
        alignItems: 'center',
        zIndex: 70,
        padding: 20,
        paddingRight: 15,
        paddingLeft: 15,
    },
    headerText: {
        fontSize: Fonts.size.xxl,
    },
    quoteContainer: {
        marginTop: 40,
        paddingTop: 20,
        paddingBottom: 20,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    iconContainer: {
        marginBottom: 20,
    },
    quoteText: {
        marginRight: 30,
        marginLeft: 30,
        textAlign: 'center',
        fontWeight: '400',
        fontSize: Fonts.size.s,
        lineHeight: 20,
    },
    bar: {
        width: 30,
        borderWidth: 1,
        marginTop: 20,
        marginBottom: 20,
        borderColor: GRAY,
    },
    authorText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: Fonts.size.s,
    },
    buttonStyle: {
        width: 300,
    },
    buttonsContainer: {
        padding: 40,
        justifyContent: 'center',
        alignContent: 'center',
    },
    laterText: {
        textDecorationLine: 'underline',
        fontSize: Fonts.size.m,
        fontWeight: '400',
        color: GRAY,
    },
});
const OnboardingModal = (props) => {
    if (!props.meditation)
        return null;
    return (React.createElement(Modal, { visible: props.isOpen, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
        React.createElement(View, { style: [styles.modalContainer, { backgroundColor: COLORS[BACKGROUND][props.mode] }] },
            React.createElement(View, null,
                React.createElement(BaseText, { style: [styles.headerText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "Welcome to Stoa")),
            React.createElement(View, { style: [styles.quoteContainer, { backgroundColor: COLORS[QUOTE_BACKGROUND][props.mode] }] },
                React.createElement(View, { style: styles.iconContainer },
                    React.createElement(QuotationIcon, { style: { height: 25, width: 25, color: COLORS[BRAND][props.mode] } })),
                React.createElement(BaseText, { style: [styles.quoteText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "You have power over your mind - not outside events. Realize this and you will find strength."),
                React.createElement(View, null),
                React.createElement(View, { style: styles.bar }),
                React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "Marcus Aurelius")),
            React.createElement(View, { style: styles.buttonsContainer },
                props.meditation?.id ? (React.createElement(TextButton, { title: "Start Meditating", buttonStyle: styles.buttonStyle, onPress: () => {
                        props.finishOnboarding();
                        if (props.meditation) {
                            props.setAndGoToMeditation(props.meditation, props.navigation);
                            Analytics.event('Tap Start Meditating', {
                                meditation_id: props.meditation.id,
                            });
                        }
                    } })) : null,
                props.theoryMeditation?.id ? (React.createElement(View, { style: { paddingTop: 20 } },
                    React.createElement(TextButton, { title: `Learn about Stoic ${props.theoryMeditation.title}`, buttonStyle: styles.buttonStyle, onPress: () => {
                            props.finishOnboarding();
                            props.setAndGoToMeditation(props.theoryMeditation, props.navigation);
                            Analytics.event('Tap Start Theory Meditation', {
                                meditation_id: props.theoryMeditation.id,
                            });
                        } }))) : null),
            React.createElement(TouchableOpacity, { onPress: props.finishOnboarding },
                React.createElement(BaseText, { style: styles.laterText }, "Listen later \u2013 I want to explore"))),
        React.createElement(View, { style: BaseStyles.overlay })));
};
export default OnboardingModal;
