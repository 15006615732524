import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { stripPersist } from '@utils/index';
import { fetchDelete, fetchGet } from '@utils/request';
import { StyleSheet, View, BackHandler } from 'react-native';
import { BACKGROUND, COLORS } from '@utils/colors';
import Header from '@components/baseComponents/header';
import Carousel from 'react-native-snap-carousel';
import { s } from 'react-native-size-matters';
import Highlight from './Highlight';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    contentContainer: {
        flex: 1,
        paddingVertical: 10,
    },
});
const HighlightsScreen = ({ navigation, user, mode }) => {
    const [highlights, setHighlights] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            const fetchedHighlights = await fetchGet(user, `/api/highlights/${user.id}/full`);
            if (fetchedHighlights) {
                const result = Object.keys(fetchedHighlights).map((key) => ({
                    ...fetchedHighlights[key],
                }));
                setHighlights(result);
            }
        };
        fetch();
        BackHandler.addEventListener('hardwareBackPress', () => {
            navigation.goBack();
            return true;
        });
        return () => {
            BackHandler.addEventListener('hardwareBackPress', () => {
                navigation.goBack();
                return true;
            });
        };
    }, []);
    const handleDeleteHighlight = async (highlightId) => {
        try {
            const response = await fetchDelete(user, `/api/highlights/${highlightId}`);
            if (response?.status === 200) {
                const newArr = highlights.filter((val) => val.id !== highlightId);
                setHighlights(newArr);
            }
        }
        catch (error) {
            console.log('Error deleting highlight');
        }
    };
    const renderHighlight = ({ item }) => (React.createElement(Highlight, { mode: mode, highlight: item, navigation: navigation, handleDeleteHighlight: handleDeleteHighlight }));
    const renderHighlights = () => (React.createElement(Carousel, { data: highlights, renderItem: renderHighlight, sliderWidth: s(350), itemWidth: s(290) }));
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        React.createElement(Header, { title: "Highlights", onPressCallback: () => navigation.goBack(), mode: mode }),
        React.createElement(View, { style: styles.contentContainer }, renderHighlights())));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps)(HighlightsScreen);
