import equal from 'fast-deep-equal';
import { host } from '../utils/constants';
import { escapeEmail } from '../utils/email';
export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const REQUEST_AUTHORS = 'REQUEST_AUTHORS';
export const RECEIVE_AUTHORS = 'RECEIVE_AUTHORS';
export const receiveAuthors = (authors) => {
    return {
        type: RECEIVE_AUTHORS,
        loading: false,
        authors,
    };
};
export const requestAuthors = () => {
    return {
        type: REQUEST_AUTHORS,
        loading: true,
    };
};
// Add user email and token as request headers: https://github.com/gonzalo-bulnes/simple_token_authentication
export function fetchAuthors(user, authors) {
    return function (dispatch) {
        dispatch(requestAuthors());
        return fetch(`${host}/api/authors/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`)
            .then((response) => response.json())
            .then((newAuthors) => {
            if (!equal(authors, newAuthors)) {
                dispatch(receiveAuthors(newAuthors));
            }
        })
            .catch((error) => console.log(error));
    };
}
