import * as React from 'react';
import Svg, { Rect } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function MenuIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 25 8", ...otherProps },
        React.createElement(Rect, { width: "25", height: "1.8", rx: "0.5", fill: color }),
        React.createElement(Rect, { y: "6", width: "16", height: "1.8", rx: "0.5", fill: color })));
}
MenuIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default MenuIcon;
