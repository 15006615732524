import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function LockIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 14 18", ...otherProps },
        React.createElement(Path, { d: "M12.0625 6.75H11.5V4.5C11.5 2.01819 9.48181 0 7 0C4.51819 0 2.5 2.01819 2.5 4.5V6.75H1.9375C1.00751 6.75 0.25 7.50668 0.25 8.4375V16.3125C0.25 17.2433 1.00751 18 1.9375 18H12.0625C12.9925 18 13.75 17.2433 13.75 16.3125V8.4375C13.75 7.50668 12.9925 6.75 12.0625 6.75ZM4.00005 4.5C4.00005 2.84546 5.34546 1.50005 7 1.50005C8.65454 1.50005 9.99995 2.84546 9.99995 4.5V6.75H4.00005V4.5Z", fill: color })));
}
LockIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default LockIcon;
