import React from 'react';
import BaseText from '@components/baseComponents/baseText';
import { BRAND, COLORS, JUNGLE_GREEN, PRIMARY_TEXT, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { flatten } from 'lodash';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import HighlightMenu from './HighlightMenu';
const styles = StyleSheet.create({
    text: {
        fontSize: Fonts.size.m,
        fontWeight: '100',
        paddingTop: 5,
        paddingBottom: 15,
        lineHeight: Fonts.size.m * 1.5,
        flex: 1,
    },
});
const Sentence = ({ highlightedSentences, mode, sentence, handleSentencePress, hasHighlightMenu, handleCreateHighlight, handleDeleteHighlight, savedHighlights, isLastHighlightSentence, }) => {
    const additionalStyle = {
        color: COLORS[PRIMARY_TEXT][mode],
    };
    const savedHighlightSentences = flatten(Object.values(savedHighlights));
    if (highlightedSentences.map((highlight) => highlight.id).includes(sentence.id)) {
        additionalStyle.backgroundColor = COLORS[BRAND][mode];
    }
    else if (savedHighlightSentences.includes(sentence.id)) {
        additionalStyle.backgroundColor = JUNGLE_GREEN;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(View, { style: {
                position: 'absolute',
                zIndex: 99,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            } },
            React.createElement(HighlightMenu, { isOpen: hasHighlightMenu, handleCreateHighlight: handleCreateHighlight, highlightedSentences: highlightedSentences, handleDeleteHighlight: handleDeleteHighlight, isLastHighlightSentence: isLastHighlightSentence })),
        React.createElement(TouchableOpacity, { onPress: () => handleSentencePress(sentence) },
            React.createElement(BaseText, { style: [styles.text, additionalStyle], key: sentence.index }, sentence.text.trim()))));
};
export default Sentence;
