import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, TouchableOpacity, View, } from 'react-native';
import { s } from 'react-native-size-matters';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import Header from '@components/baseComponents/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { QuizIAnswerKey } from '@utils/asyncKeys';
import BeginnerIcon from '@assets/svg/levels/BeginnerIcon';
import IntermediateIcon from '@assets/svg/levels/IntermediateIcon';
import AdvancedIcon from '@assets/svg/levels/AdvancedIcon';
import { COLORS, LIGHT_GRAY, BRAND, SECONDARY_TEXT, WHITE, } from '../../utils/colors';
import { QUIZ_II_SCREEN, SIGN_IN_SCREEN, } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    quotesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
        marginBottom: 20,
        height: 60,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    authorText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
    },
    container: {
        paddingTop: s(50),
        paddingBottom: s(100),
    },
});
export const BEGINNER_ANSWER = 'Beginner';
export const INTERMEDIATE_ANSWER = 'Intermediate';
export const ADVANCED_ANSWER = 'Advanced';
const LEVELS = [
    BEGINNER_ANSWER,
    INTERMEDIATE_ANSWER,
    ADVANCED_ANSWER,
];
const ICONS = [
    BeginnerIcon,
    IntermediateIcon,
    AdvancedIcon,
];
const QuizIScreen = ({ mode, navigation, }) => {
    useEffect(() => {
        Analytics.screenHit('Welcome', 'QuizIScreen');
    }, []);
    const [selected, setSelected] = useState(null);
    useHandleLoggedInOrLoadedUser();
    const handleTouch = async (answer) => {
        setSelected(answer);
        await AsyncStorage.setItem(QuizIAnswerKey, answer);
        navigation.navigate(QUIZ_II_SCREEN);
    };
    return (React.createElement(OnboardingBackground, null,
        React.createElement(Header, { onPressCallback: () => navigation.goBack(), title: "How familiar are you with Stoicism?", mode: mode, color: WHITE }),
        React.createElement(View, { style: styles.container }, LEVELS.map((level, i) => {
            const LevelIcon = ICONS[i];
            const selectedStyle = selected === level ? { backgroundColor: COLORS[BRAND][mode], opacity: 0.8 } : null;
            return (React.createElement(TouchableOpacity, { onPress: () => handleTouch(level), key: level },
                React.createElement(View, { style: styles.quotesContainer },
                    React.createElement(BaseOverlay, { style: [styles.quoteOverlay, selectedStyle] }),
                    React.createElement(View, null),
                    React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, level),
                    React.createElement(LevelIcon, null))));
        })),
        React.createElement(ContinueFooter, { nextScreenName: QUIZ_II_SCREEN, index: 4, skipScreenName: SIGN_IN_SCREEN, mode: mode })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(QuizIScreen);
