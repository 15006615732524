import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { fetchAuthors } from '@actions/authorActions';
const useAuthors = ({ authors, user }) => {
    const store = useStore();
    useEffect(() => {
        if (Object.keys(authors).length === 0) {
            const { dispatch } = store;
            dispatch(fetchAuthors(user, authors));
        }
    }, [Object.keys(authors).length]);
};
export default useAuthors;
