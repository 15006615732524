import BaseText from '@components/baseComponents/baseText';
import { hitSlop } from '@utils/index';
import { LIGHT_GRAY } from '@utils/colors';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import React from 'react';
import { StyleSheet, View, TouchableOpacity, Share, } from 'react-native';
const styles = StyleSheet.create({
    container: {
        position: 'relative',
        top: -14,
        zIndex: 99,
        justifyContent: 'center',
        flexDirection: 'row',
        borderRadius: IMAGE_BORDER_RADIUS,
        padding: 2,
        width: '50%',
    },
    actionContainer: {
        paddingHorizontal: 10,
    },
});
const HighlightMenu = ({ isOpen, handleDeleteHighlight, highlightedSentences, handleCreateHighlight, isLastHighlightSentence = false, }) => {
    if (!isOpen)
        return null;
    const handleShare = () => {
        const { authorName, bookTitle } = highlightedSentences[0];
        Share.share({
            message: `${highlightedSentences.map((s) => s.text).join(' ')}\n\n${authorName ? `\u2014 ${authorName}\n` : ''}${bookTitle ? `${bookTitle}\n` : ''}\nstoameditation.com`,
        });
    };
    const additionalStyle = isLastHighlightSentence ? { top: undefined, bottom: -15 } : {};
    return (React.createElement(View, { style: [styles.container, { backgroundColor: LIGHT_GRAY }, additionalStyle] },
        React.createElement(View, { style: styles.actionContainer }, handleDeleteHighlight ? (React.createElement(TouchableOpacity, { onPress: handleDeleteHighlight, hitSlop: hitSlop },
            React.createElement(BaseText, null, "Remove"))) : (React.createElement(TouchableOpacity, { onPress: handleCreateHighlight, hitSlop: hitSlop },
            React.createElement(BaseText, null, "Save")))),
        React.createElement(View, { style: styles.actionContainer },
            React.createElement(TouchableOpacity, { onPress: handleShare },
                React.createElement(BaseText, null, "Share")))));
};
export default HighlightMenu;
