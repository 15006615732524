import React from 'react';
import { Dimensions, Platform, View, StyleSheet } from 'react-native';
import { BLUE, LIGHT_GRAY } from '../../utils/colors';
import BaseText from './baseText';
const { height: deviceHeight, width: deviceWidth } = Dimensions.get('window');
const styles = StyleSheet.create({
    meditationTitle: {
        color: BLUE,
        textShadowOffset: { width: 1, height: 1 },
        justifyContent: 'center',
        textAlign: 'center',
    },
    meditationCard: {
        marginTop: 50,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 4,
        marginLeft: 4,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: deviceWidth / 24,
        backgroundColor: LIGHT_GRAY,
        ...Platform.select({
            ios: {
            // shadowColor: 'rgba(0,0,0,0.5)',
            // shadowOffset: { height: 1, width: 1 },
            // shadowOpacity: .8,
            // shadowRadius: 1,
            },
            android: {
                elevation: 1,
            },
        }),
    },
    textContainer: {
        width: '90%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
});
export default () => {
    return (React.createElement(View, { style: styles.meditationCard },
        React.createElement(View, { style: styles.textContainer },
            React.createElement(BaseText, { style: styles.meditationTitle }, "You're currently offline"),
            React.createElement(BaseText, { style: styles.meditationTitle }, "You'll only be able to access downloaded content"))));
};
