import * as React from 'react';
import { WHITE } from '@utils/colors';
import Svg, { Path } from 'react-native-svg';
const AnxietyIcon = (props) => {
    const { style, ...otherProps } = props;
    const { height = 24, width = 28, color = WHITE } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 24 28", fill: "none", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M11.0003 0.0629262C6.49566 0.645096 2.96039 3.53251 2.21136 7.24126C2.02455 8.16624 2.00054 8.87845 2.10663 10.3485C2.27047 12.6192 2.08629 13.2343 0.780568 14.7781C0.116926 15.5626 0 15.7509 0 16.0351C0 16.3754 0.181973 16.6512 0.604964 16.9521C1.82249 17.8183 2.06559 18.3469 2.29515 20.628C2.45091 22.1757 2.63178 22.6988 3.13924 23.069C3.56933 23.3828 3.9462 23.456 4.92791 23.4163C5.40101 23.3972 6.11885 23.3494 6.5231 23.3102C7.66535 23.1993 8.36084 23.298 8.88275 23.6449C9.28816 23.9144 9.65817 24.9259 10.0472 26.8279C10.201 27.5802 10.2549 27.7445 10.3859 27.8612L10.5418 28H15.5024H20.463L20.6133 27.8486C20.8153 27.6452 20.8086 27.3867 20.5801 26.5819C20.3727 25.851 20.02 24.1754 19.8983 23.3424C19.8538 23.037 19.8206 22.4402 19.8248 22.0161C19.8374 20.7323 20.1498 19.7763 20.8729 18.8082C22.5305 16.5891 23.512 14.5442 23.8558 12.5936C24.3224 9.94612 23.6354 6.74508 22.1041 4.43176C21.5719 3.62775 20.4304 2.48272 19.6298 1.94991C17.6348 0.622086 15.2987 -0.0191218 12.5274 0.000433992C11.92 0.0047523 11.2328 0.0328831 11.0003 0.0629262ZM14.3769 3.53294C16.7723 4.0586 18.7103 5.67531 19.6303 7.91522C21.087 11.4627 19.5606 15.5395 16.1311 17.261C14.4762 18.0917 12.4479 18.2423 10.6875 17.6652C8.15853 16.8362 6.29795 14.7037 5.7791 12.0392C5.65146 11.3839 5.63853 10.1493 5.75246 9.49295C6.29758 6.35193 8.7469 3.95002 11.9143 3.45033C12.4278 3.36933 13.8486 3.41696 14.3769 3.53294ZM12.3008 6.96532C12.0483 7.09968 11.8395 7.36303 11.7576 7.65032C11.6776 7.93145 12.0363 12.2168 12.16 12.4578C12.3456 12.8192 12.8173 12.9466 13.211 12.7416C13.5346 12.573 13.574 12.3923 13.7451 10.2888C13.9458 7.82207 13.9461 7.69505 13.7514 7.36057C13.4751 6.88555 12.7977 6.70097 12.3008 6.96532ZM12.4553 13.6182C12.1062 13.8389 12.04 14.3897 12.3297 14.6631C12.8013 15.108 13.5134 14.8083 13.4968 14.1719C13.4836 13.6628 12.8867 13.3455 12.4553 13.6182Z", fill: color })));
};
export default AnxietyIcon;
