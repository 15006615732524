// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ScrollView, View, ImageBackground, Share, } from 'react-native';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import SubscriptionListEl from '@components/subscription/SubscriptionListEl';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ShareLinkKey } from '@utils/asyncKeys';
import { imageToSpacesUrl } from '@utils/request';
import { getDeviceOrientation } from '@actions/dimensionsActions';
import Header from '../baseComponents/header';
import BaseStyles from '../../utils/baseStyles';
import { COLORS, BACKGROUND, PRIMARY_TEXT } from '../../utils/colors';
import { HOME_SCREEN, ORIENTATION } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import TextButton from '../baseComponents/textButton';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = {
    bgImage: {
        flex: 1,
    },
    body: {
        padding: 20,
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
    },
    sectionContainer: {
        marginTop: 20,
    },
    quoteContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'column',
        marginLeft: 30,
        padding: 10,
    },
    quoteText: {
        lineHeight: 20,
        paddingLeft: 10,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    pitchContainer: {
        flexDirection: 'column',
        paddingTop: 10,
        padding: 10,
    },
    textPadding: {
        paddingLeft: 10,
    },
    meditationBgImage: {
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    meditationBg: {
        flex: 1,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
    },
};
const SHARE_LINK = 'https://share.stoameditation.com/share';
const ShareScreen = (props) => {
    const [shareableLink, setShareableLink] = useState(null);
    const handleBackPress = () => {
        props.navigation.navigate(HOME_SCREEN);
        Analytics.event(Analytics.Events.SUBSCRIPTION_BACKPRESS, {});
    };
    useEffect(() => {
        const buildLink = async () => {
            let link = await AsyncStorage.getItem(ShareLinkKey);
            if (!link) {
                link = await dynamicLinks().buildLink({
                    link: `${SHARE_LINK}?user_id=${props.user.id}`,
                    domainUriPrefix: 'https://share.stoameditation.com',
                    analytics: {
                        campaign: 'home',
                    },
                });
            }
            setShareableLink(link);
        };
        buildLink();
    }, []);
    const handleShare = () => {
        Analytics.event(Analytics.Events.SHARE_SCREEN_TOUCH);
        Share.share({
            message: `Join me on Stoa. Download the app using this link and receive a free month: ${shareableLink}`,
        }).catch((e) => console.log(e));
    };
    const orientation = getDeviceOrientation();
    const { deviceHeight, deviceWidth } = props.deviceDimensions;
    const size = orientation === ORIENTATION.PORTRAIT
        ? Math.floor((deviceHeight * 0.25 + deviceWidth * 0.8) / 2)
        : 100;
    return (React.createElement(View, { style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][props.mode] }] },
        React.createElement(Header, { onPressCallback: handleBackPress, title: "Share" }),
        React.createElement(ScrollView, { currentContainerStyle: styles.currentContainer },
            React.createElement(View, { style: styles.body },
                React.createElement(View, { style: styles.sectionContainer },
                    React.createElement(ImageBackground, { source: {
                            uri: imageToSpacesUrl('stock_9'),
                        }, imageStyle: styles.meditationBgImage, style: (styles.meditationBg, { height: size, width: size }) })),
                React.createElement(View, { style: styles.sectionContainer },
                    React.createElement(BaseText, { style: [BaseStyles.sectionHeaderText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "Give a free month of Stoa:"),
                    React.createElement(View, { style: styles.pitchContainer },
                        React.createElement(SubscriptionListEl, { mode: props.mode, text: "Share the benefits of philosophy with anyone in your life.", textStyle: { opacity: 0.7, color: COLORS[PRIMARY_TEXT][props.mode] } }),
                        React.createElement(SubscriptionListEl, { mode: props.mode, text: "Whether they\u2019re seeking tranquility, resilience, or virtue, Stoicism could be exceptionally useful for them.", textStyle: { opacity: 0.7, color: COLORS[PRIMARY_TEXT][props.mode] } }),
                        React.createElement(SubscriptionListEl, { mode: props.mode, text: "Share your link with anyone in order for them to receive a free month.", textStyle: { opacity: 0.7, color: COLORS[PRIMARY_TEXT][props.mode] } }))),
                React.createElement(View, { style: styles.sectionContainer },
                    React.createElement(TextButton, { title: "Give A Free Month", onPress: handleShare, isLoading: !shareableLink }))))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
    deviceDimensions: state.dimensions.deviceDimensions,
});
export default connect(mapStateToProps)(ShareScreen);
