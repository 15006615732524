import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import ShareScreen from '@components/share/ShareScreen';
import HistoryScreen from '@components/stats/HistoryScreen';
import MessagesScreen from '@components/messages/MessagesScreen';
import MeditationScreenV2 from '@components/meditation/MeditationScreenV2';
import SubscriptionScreenV2 from '@components/subscription/SubscriptionScreenV2';
import TabsNavigator from './TabsNavigator';
import { HOME_SCREEN, STATS_SCREEN, ABOUT_SCREEN, TERMS_OF_USE_SCREEN, PRIVACY_SCREEN, ACCOUNT_SCREEN, THEORY_MEDITATION_SCREEN, MEDITATION_SCREEN, ROOT_SIGNUP_SCREEN, SHARE_SCREEN, MESSAGES_SCREEN, SUBSCRIPTION_SCREEN_HOME, } from '../utils/constants';
import AboutScreen from '../components/about/aboutScreen';
import TermsOfUseScreen from '../components/about/termsOfUseScreen';
import PrivacyScreen from '../components/about/privacyScreen';
import AccountScreen from '../components/account/accountScreen';
import SignupScreen from '../components/auth/signupScreen';
const RootSignupScreen = (props) => React.createElement(SignupScreen, { isRoot: true, ...props });
const RootStack = createStackNavigator();
const RootNavigator = () => (React.createElement(RootStack.Navigator, { headerMode: "none", initialRouteName: HOME_SCREEN, screenOptions: { animationEnabled: Platform.OS === 'ios' } },
    React.createElement(RootStack.Screen, { name: HOME_SCREEN, component: TabsNavigator }),
    React.createElement(RootStack.Screen, { name: SUBSCRIPTION_SCREEN_HOME, component: SubscriptionScreenV2 }),
    React.createElement(RootStack.Screen, { name: SHARE_SCREEN, component: ShareScreen }),
    React.createElement(RootStack.Screen, { name: MESSAGES_SCREEN, component: MessagesScreen }),
    React.createElement(RootStack.Screen, { name: STATS_SCREEN, component: HistoryScreen }),
    React.createElement(RootStack.Screen, { name: ABOUT_SCREEN, component: AboutScreen }),
    React.createElement(RootStack.Screen, { name: TERMS_OF_USE_SCREEN, component: TermsOfUseScreen }),
    React.createElement(RootStack.Screen, { name: PRIVACY_SCREEN, component: PrivacyScreen }),
    React.createElement(RootStack.Screen, { name: ACCOUNT_SCREEN, component: AccountScreen }),
    React.createElement(RootStack.Screen, { name: THEORY_MEDITATION_SCREEN, component: MeditationScreenV2 }),
    React.createElement(RootStack.Screen, { name: MEDITATION_SCREEN, component: MeditationScreenV2 }),
    React.createElement(RootStack.Screen, { name: ROOT_SIGNUP_SCREEN, component: RootSignupScreen })));
export default RootNavigator;
