// Export dummy helper methods
export async function checkPermission() {
    return null;
}
export async function registerApp() {
    return null;
}
export async function getToken() {
    return null;
}
export async function requestPermission() {
    return null;
}
export const createNotificationListeners = async () => {
    return null;
};
export const getAndSetToken = () => {
    return null;
};
export const checkPermissionAndAlertIfNone = () => { };
