import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import { rootReducer } from '../reducers/rootReducer';
import { IS_DEV } from '../utils/constants';
const configureAndPersistStore = (preloadedState = {}) => {
    const store = IS_DEV
        ? createStore(rootReducer, preloadedState, applyMiddleware(thunk, logger))
        : createStore(rootReducer, preloadedState, applyMiddleware(thunk));
    const persistor = persistStore(store);
    return { store, persistor };
};
export default configureAndPersistStore;
