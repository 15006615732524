// @ts-nocheck
import DeviceInfo from 'react-native-device-info';
import Bugsnag from '../utils/libraries/bugsnag';
import { host } from '../utils/constants';
import { getRubyTimezoneOffset } from '../utils';
import { handleEncryptionKey } from './userActions';
export const createPseudoUser = () => fetch(`${host}/v2/pseudo_users`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        user: {
            device_id: DeviceInfo.getUniqueId(),
        },
    }),
})
    .then((response) => response)
    .catch((error) => console.log(error));
export const enterPseudoEmail = (email) => fetch(`${host}/v2/pseudo_users/email`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        user: {
            email,
            timezone_offset_hours: getRubyTimezoneOffset(),
            device_id: DeviceInfo.getUniqueId(),
        },
    }),
})
    .then((response) => response)
    .catch((error) => console.log(error));
export const enterPseudoPassword = ({ email, password }) => async (dispatch) => {
    return fetch(`${host}/v2/pseudo_users/password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                email,
                password,
                device_id: DeviceInfo.getUniqueId(),
            },
        }),
    }).then(async (response) => {
        if (response.status === 200) {
            await response.json().then((user) => {
                user.lastFetchAt = null;
                handleEncryptionKey(user, dispatch);
            });
        }
        else {
            Bugsnag.notify(new Error('Psuedo InputFail'), (event) => {
                event.severity = 'info';
                event.context = 'input';
                event.addMetadata('response', {
                    status: response.status,
                    text: response.statusText,
                    body: response.body,
                });
                event.addMetadata('message', {
                    message: password,
                    email,
                });
            });
        }
        return response;
    });
};
