const ENV = {
    dev: {
        host: 'http://localhost:3000',
    },
    prod: {
        host: 'https://stoameditation.com',
    },
};
export const IS_DEV = __DEV__;
export const { host } = ENV.prod;
export const SAMPLE_URL = 'https://stoameditation.com/sample/';
export const APPLE_APP_ID = '1446345567';
// navigation
export const HOME_STACK = 'Stoa: Home Stack';
export const MEDITATION_STACK = 'Stoa: Meditation Stack';
export const THEORY_STACK = 'Stoa: Theory Stack';
export const READ_STACK = 'Stoa: Read Stack';
export const ENTRY_STACK = 'Stoa: Journal Stack';
export const AUTH_STACK = 'Stoa: Auth Stack';
export const HOME_SCREEN = 'Stoa: Home';
export const QUOTES_SCREEN = 'Stoa: Quotes';
export const HIGHLIGHTS_SCREEN = 'Stoa: Highlights';
export const PLAYLISTS_SCREEN = 'Stoa: Meditations';
export const PLAYLIST_SCREEN = 'Stoa: Program';
export const MEDITATION_SCREEN = 'Stoa: Meditation';
export const THEORY_MEDITATION_SCREEN = 'Stoa: Theory';
export const THEORY_PLAYLISTS_SCREEN = 'Stoa: Theory Program';
export const THEORY_PLAYLIST_SCREEN = 'Stoa: Theory Programs';
export const ENTRIES_SCREEN = 'Stoa: Journal';
export const ENTRY_SCREEN = 'Stoa: Journal Entry';
export const WELCOME_SCREEN = 'Stoa: Welcome';
export const REMINDER_SCREEN = 'Stoa: Reminders';
export const SIGNUP_SCREEN = 'Stoa: Signup';
export const ROOT_SIGNUP_SCREEN = 'Stoa: Create an Account';
export const ABOUT_SCREEN = 'Stoa: About';
export const ACCOUNT_SCREEN = 'Stoa: Account';
export const MESSAGES_SCREEN = 'Stoa: Messages';
export const PRIVACY_SCREEN = 'Stoa: Privacy';
export const SHARE_SCREEN = 'Stoa: Share';
export const STATS_SCREEN = 'Stoa: Stats';
export const TERMS_OF_USE_SCREEN = 'Stoa: Terms';
export const BOOK_SCREEN = 'Stoa: Book';
export const CHAPTER_SCREEN = 'Stoa: Chapter';
export const DRAWER_MENU = 'Stoa: Drawer';
export const PITCH_SCREEN = 'PITCH_SCREEN';
export const PHILOSOPHY_SCREEN = 'PHILOSOPHY_SCREEN';
export const REVIEWS_SCREEN = 'REVIEWS_SCREEN';
export const QUIZ_I_SCREEN = 'QUIZ_I_SCREEN';
export const QUIZ_II_SCREEN = 'QUIZ_II_SCREEN';
export const SIGN_IN_SCREEN = 'SIGN_IN_SCREEN';
export const SUBSCRIPTION_SCREEN_II = 'SUBSCRIPTION_SCREEN_II';
export const SUBSCRIPTION_SCREEN_HOME = 'SUBSCRIPTION_SCREEN_HOME';
export const BOTTOM_TABS = {
    home: { name: HOME_SCREEN, title: 'Home', icon: 'home' },
    meditation: { name: MEDITATION_STACK, title: 'Meditate', icon: 'circle' },
    theory: { name: THEORY_STACK, title: 'Learn', icon: 'book-open' },
    quotes: { name: READ_STACK, title: 'Read', icon: 'quote-right' },
    journal: { name: ENTRY_STACK, title: 'Journal', icon: 'pen' },
};
export const TAB_ICON_SIZE = 20;
// navigation config
export const MEDITATION_NAV_CONFIG = {
    theory: {
        navigator: THEORY_MEDITATION_SCREEN,
        screen: null,
    },
    meditation: {
        navigator: MEDITATION_SCREEN,
        screen: null,
    },
};
export const PLAYLIST_NAV_CONFIG = {
    theory: {
        navigator: THEORY_STACK,
        screen: THEORY_PLAYLIST_SCREEN,
    },
    meditation: {
        navigator: MEDITATION_STACK,
        screen: PLAYLIST_SCREEN,
    },
};
export const PLAYLISTS_NAV_CONFIG = {
    theory: {
        navigator: THEORY_STACK,
        screen: THEORY_PLAYLISTS_SCREEN,
    },
    meditation: {
        navigator: MEDITATION_STACK,
        screen: PLAYLISTS_SCREEN,
    },
};
// colors
export const LIGHT = 'LIGHT';
export const DARK = 'DARK';
// auth
export const SUCCESS_STATUS = 'success';
export const FAILED_STATUS = 'failed';
export const LOADING_STATUS = 'loading';
// Device Orientation
export const ORIENTATION = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
};
// URLS
export const STOA_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=stoameditation.stoa';
export const STOA_APP_STORE_URL = 'https://apps.apple.com/us/app/stoa-stoic-meditation-diary/id1446345567';
export const ROUTINES_FEEDBACK_URL = 'https://forms.gle/Xmago5352ijr9MqX7';
export const CONTACT_EMAIL = 'stoa@stoameditation.com';
export const DEFAULT_IMAGE = 'icon';
export const WPM = 250;
