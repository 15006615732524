import { useState, useEffect } from 'react';
import { DATE_AVAILABLE_AT_FMT } from '@utils/index';
import { fetchGet, fetchPost } from '@utils/request';
import moment from 'moment';
import { isEmpty } from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PartialRoutinesKey } from '@utils/asyncKeys';
import { isUser3dOld } from '@utils/user';
const setNextStep = (routine) => {
    const newRoutine = { ...routine };
    let foundNext;
    Object.entries(newRoutine.steps).forEach(([, step]) => {
        if (!foundNext && !step.is_finished) {
            foundNext = true;
            newRoutine.steps[step.id] = { ...step, ...{ is_next: true } };
        }
    });
    return newRoutine;
};
const useRoutine = ({ user }) => {
    const [routine, setRoutine] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeIndex, setActiveIndex] = useState([]);
    const [quoteModal, setQuoteModal] = useState(false);
    const [optIn, setOptIn] = useState(false);
    const [allRoutinesFinished, setAllRoutinesFinished] = useState(false);
    useEffect(() => {
        const fetchRoutine = async () => {
            const dateStr = moment().format(DATE_AVAILABLE_AT_FMT);
            const result = await fetchGet(user, '/api/routines/next', `date_available_at=${dateStr}`);
            if (isEmpty(result)) {
                setError(404);
            }
            else {
                setError(null);
                setRoutine(setNextStep(result));
                getActiveIndex(setNextStep(result));
            }
            setIsLoading(false);
        };
        fetchRoutine();
    }, []);
    useEffect(() => {
        const checkUserRoutinesOptIn = async () => {
            const routinesOptAsked = await AsyncStorage.getItem(PartialRoutinesKey);
            if (user.is_beta
                && isUser3dOld(user)
                && routinesOptAsked === 'no'
                && Math.random() <= 0.2) {
                setOptIn(true);
                await AsyncStorage.setItem(PartialRoutinesKey, 'yes');
            }
            else {
                await AsyncStorage.setItem(PartialRoutinesKey, 'yes');
            }
        };
        checkUserRoutinesOptIn();
    }, []);
    const getActiveIndex = (val) => {
        // Added timeout to show animation properly.
        setTimeout(() => {
            const stepsArray = Object.keys(val?.steps).map((key) => ({
                ...val?.steps[key],
            }));
            for (const step of stepsArray) {
                if (!step.is_finished) {
                    setActiveIndex([stepsArray.indexOf(step)]);
                    if (stepsArray[stepsArray.indexOf(step)].type === 'quote')
                        toggleQuoteModal(true);
                    return;
                }
                setActiveIndex([]);
            }
        }, 400);
    };
    const toggleQuoteModal = (val) => {
        setQuoteModal(val);
    };
    const finishRoutineStep = (routineStep) => {
        if (!routine)
            return;
        const newRoutineStep = { ...routineStep, ...{ is_finished: true, is_next: false } };
        const newRoutine = { ...routine };
        newRoutine.steps[newRoutineStep.id] = newRoutineStep;
        setRoutine(setNextStep(newRoutine));
        getActiveIndex(setNextStep(newRoutine));
        fetchPost(user, '/api/user_routine_steps', {
            user_id: user.id,
            routine_step_id: routineStep.id,
        });
        const newSteps = Object.values(newRoutine.steps);
        const allStepsFinished = newSteps.filter((step) => !step.is_finished).length === 0;
        if (allStepsFinished) {
            finishRoutine();
            setAllRoutinesFinished(true);
        }
    };
    const toggleOptIn = (val) => {
        setOptIn(val || !optIn);
    };
    const toggleFinishModal = (val) => {
        setAllRoutinesFinished(val || !allRoutinesFinished);
    };
    const finishRoutine = () => {
        if (!routine)
            return;
        const newRoutine = { ...routine, is_finished: true };
        setRoutine(newRoutine);
        setActiveIndex([]);
        fetchPost(user, '/api/user_routines', {
            user_id: user.id,
            routine_step_id: routine.id,
        });
    };
    return {
        error,
        routine,
        isLoading,
        finishRoutineStep,
        activeIndex,
        setActiveIndex,
        quoteModal,
        toggleQuoteModal,
        optIn,
        toggleOptIn,
        allRoutinesFinished,
        toggleFinishModal,
    };
};
export default useRoutine;
