import React from 'react';
import { connect } from 'react-redux';
import { Alert, ActivityIndicator, InteractionManager, StyleSheet, TouchableOpacity, View, Switch, } from 'react-native';
import { receiveSetting } from '@actions/settingsActions';
import { isPlaylistRestricted } from '@utils/enforcement';
import FastImage from '../../utils/libraries/fastImage';
import { DownloadIcon, LockIcon, TrashIcon } from '../../assets/svg';
import TextButton from '../baseComponents/textButton';
import { imageToSpacesUrl } from '../../utils/request';
import { hitSlop, stripPersist } from '../../utils';
import { internetChecker } from '../../actions/connectionActions';
import { downloadPlaylistAndUpdate, removePlaylistDownloadAndUpdate, isPlaylistDownloadedAndUpdate, } from '../../actions/playlistsActions';
import Header from '../baseComponents/header';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { ORIENTATION } from '../../utils/constants';
import { WHITE, BLACK, COLORS, SECONDARY_TEXT, BRAND, BLUE, LIGHT_GRAY, BACKGROUND, } from '../../utils/colors';
import { getDeviceOrientation } from '../../actions/dimensionsActions';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    autoplayContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    autoplayText: {
        color: WHITE,
        fontWeight: 'bold',
        fontSize: Fonts.size.m,
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 22,
    },
    downloadButton: {
        borderRadius: IMAGE_BORDER_RADIUS,
        padding: 5,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: 35,
    },
    downloadText: {
        fontSize: 10,
        fontWeight: '400',
    },
    description: {
        marginTop: 22,
    },
    descriptionText: {
        color: WHITE,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    headerContainer: {
        flexDirection: 'column',
        zIndex: 1,
    },
    headerImage: {
        zIndex: 1,
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        flex: 1,
        backgroundColor: BLUE,
    },
    headerOverlay: {
        opacity: 0.4,
        backgroundColor: BLACK,
        flex: 1,
        zIndex: 10,
        width: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
    },
    headerContent: {
        position: 'absolute',
        zIndex: 50,
        top: 0,
        left: 0,
        flex: 1,
    },
    headerImageStyle: {
        flex: 1,
        opacity: 0.8,
        zIndex: 1,
    },
    headerView: {
        paddingHorizontal: 30,
    },
});
const LANDSCAPE_HEADER_DIVISOR = 1.7;
const PORTRAIT_HEADER_DIVISOR = 2.5;
class PlaylistHeader extends React.Component {
    static navigationOptions = {
        title: 'Playlist',
    };
    constructor(props) {
        super(props);
        this.state = { isDownloading: false };
    }
    componentDidMount() {
        InteractionManager.runAfterInteractions(() => {
            if (this.props.playlist.is_downloaded === undefined
                || this.props.playlist.is_downloaded === null) {
                this.props.isPlaylistDownloadedAndUpdate(this.props.playlist);
            }
            this.props.internetChecker(this.props.connection);
        });
    }
    renderAutoplay = () => {
        const { autoplay, mode } = this.props;
        return (React.createElement(View, { style: styles.autoplayContainer },
            React.createElement(BaseText, { style: styles.autoplayText }, "Autoplay"),
            React.createElement(Switch, { trackColor: { false: LIGHT_GRAY, true: BLUE }, thumbColor: autoplay ? COLORS[BRAND][mode] : COLORS[BACKGROUND][mode], ios_backgroundColor: LIGHT_GRAY, onValueChange: () => {
                    this.props.receiveSetting('autoplay', !autoplay);
                    Analytics.event(Analytics.Events.AUTOPLAY_TOGGLE, {
                        on: !autoplay,
                    });
                }, value: autoplay })));
    };
    renderAction = () => {
        const { playlist } = this.props;
        if (playlist.is_theory) {
            return this.renderAutoplay();
        }
        return this.renderDownload();
    };
    asyncDownloadingAction = async (action) => {
        this.setState({ isDownloading: true });
        await action(this.props.playlist);
        this.setState({ isDownloading: false });
    };
    downloadMeditations = async () => {
        if (!this.props.isConnected) {
            Alert.alert('You are Offline', 'You need to be online to download and play meditations', [
                { text: 'OK' },
            ]);
            return;
        }
        Alert.alert('Please wait a few moments', 'Downloading each meditation may take a few minutes.\n'
            + 'Downloaded meditations appear at the bottom of the homescreen.\n'
            + 'If you ever want to remove all downloads, go to the Account screen.', [{ text: 'OK' }]);
        this.asyncDownloadingAction(this.props.downloadPlaylistAndUpdate);
        Analytics.event(Analytics.Events.DOWNLOAD_MEDITATIONS, {
            playlist_id: this.props.playlist.id,
            playlist_title: this.props.playlist.title,
        });
    };
    removeMeditationDownloads = async () => {
        await this.asyncDownloadingAction(this.props.removePlaylistDownloadAndUpdate);
        Analytics.event(Analytics.Events.REMOVE_MEDITATIONS, {
            playlist_id: this.props.playlist.id,
            playlist_title: this.props.playlist.title,
        });
    };
    renderDownload = () => {
        if (isPlaylistRestricted(this.props.playlist, this.props.user)) {
            return (React.createElement(View, { style: styles.downloadButton },
                React.createElement(LockIcon, { style: { color: WHITE, width: 25, height: 25 } })));
        }
        if (this.state.isDownloading) {
            return (React.createElement(TouchableOpacity, { style: styles.downloadButton },
                React.createElement(ActivityIndicator, { size: "small", color: COLORS[BRAND][this.props.mode] })));
        }
        if (this.props.playlist.is_downloaded) {
            return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.downloadButton, onPress: this.removeMeditationDownloads },
                React.createElement(TrashIcon, { style: { color: WHITE, width: 25, height: 25 } })));
        }
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.downloadButton, onPress: this.downloadMeditations },
            React.createElement(DownloadIcon, { style: { height: 25, width: 25, color: WHITE } })));
    };
    render() {
        const { deviceHeight, deviceWidth } = this.props.deviceDimensions;
        const orientation = getDeviceOrientation();
        const divider = orientation === ORIENTATION.PORTRAIT ? PORTRAIT_HEADER_DIVISOR : LANDSCAPE_HEADER_DIVISOR;
        const headerDimensions = {
            height: deviceHeight / divider,
            width: deviceWidth,
        };
        return (React.createElement(View, { style: [{ flexDirection: 'column', zIndex: 1 }, headerDimensions] },
            React.createElement(View, { style: [styles.headerOverlay, headerDimensions] }),
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(this.props.playlist.image) }, style: [styles.headerImage, headerDimensions], 
                // @ts-ignore
                imageStyle: styles.headerImageStyle }),
            React.createElement(View, { style: [styles.headerContent, headerDimensions] },
                React.createElement(Header, { title: this.props.playlist.title, onPressCallback: this.props.handleBackPress, secondaryOnPressCallback: this.props.openModal, hasVerticalDots: !!this.props.playlist.is_theory, color: COLORS[SECONDARY_TEXT][this.props.mode], mode: this.props.mode }),
                React.createElement(View, { style: styles.headerView },
                    React.createElement(View, { style: styles.description },
                        React.createElement(BaseText, { style: [styles.descriptionText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, this.props.playlist.description)),
                    React.createElement(View, { style: styles.buttonsContainer },
                        React.createElement(TextButton, { title: "Play", onPress: this.props.playNextMeditation, buttonStyle: { flex: 0.9 } }),
                        this.renderAction())))));
    }
}
const mapStateToProps = (state) => {
    const playlist = stripPersist(state.playlists)[state.playlist.id];
    const user = stripPersist(state.user);
    return {
        autoplay: state.settings.autoplay,
        connection: state.connection,
        deviceDimensions: state.dimensions.deviceDimensions,
        isConnected: state.connection.isConnected,
        mode: stripPersist(state.colors).mode,
        playlist,
        user,
    };
};
const mapDispatchToProps = (dispatch) => ({
    internetChecker: (connection) => dispatch(internetChecker(connection)),
    isPlaylistDownloadedAndUpdate: (playlist) => dispatch(isPlaylistDownloadedAndUpdate(playlist)),
    removePlaylistDownloadAndUpdate: (playlist) => dispatch(removePlaylistDownloadAndUpdate(playlist)),
    downloadPlaylistAndUpdate: (playlist) => dispatch(downloadPlaylistAndUpdate(playlist)),
    receiveSetting: (key, value) => dispatch(receiveSetting(key, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistHeader);
