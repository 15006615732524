import { receiveQuote, updateAndFetchQuotes } from '@actions/quoteActions';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
const QuoteService = () => {
    const useFetchAndUpdateQuotes = ({ quotes, user }) => {
        const store = useStore();
        useEffect(() => {
            if (Object.keys(quotes).length === 0) {
                const { dispatch } = store;
                dispatch(updateAndFetchQuotes(user, quotes));
            }
        }, [Object.keys(quotes).length]);
    };
    const useMeditationQuote = ({ meditation, quotes, quote, }) => {
        const store = useStore();
        useEffect(() => {
            if (meditation.quote_id && meditation?.quote_id !== quote?.id) {
                const newQuote = quotes[meditation.quote_id];
                if (newQuote) {
                    const { dispatch } = store;
                    dispatch(receiveQuote(newQuote));
                }
            }
        }, [meditation?.id, quote?.id, Object.keys(quotes).length]);
    };
    return { useFetchAndUpdateQuotes, useMeditationQuote };
};
export default QuoteService;
