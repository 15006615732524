import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function EnvelopIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 143", ...otherProps },
        React.createElement(Path, { d: "M192.857 0H7.14294L100 76.4857L193.907 0.214286C193.563 0.11384 193.212 0.0422712 192.857 0Z", fill: color }),
        React.createElement(Path, { d: "M104.507 91.2568C101.875 93.4118 98.0888 93.4118 95.4571 91.2568L0 12.614V135.714C0 139.659 3.19796 142.857 7.14299 142.857H192.857C196.802 142.857 200 139.659 200 135.714V13.6712L104.507 91.2568Z", fill: color }),
        React.createElement(Path, { d: "M35.2479 14.244L25.892 16.4047L29.4697 6.5749L35.2479 14.244Z", fill: color })));
}
EnvelopIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default EnvelopIcon;
