import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function ChevronLeftIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 9 15", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M7.21341 0.345035C7.3392 0.21914 7.50655 0.150024 7.68597 0.150024C7.8654 0.150024 8.03275 0.21914 8.1586 0.34509L8.64878 0.835152C8.90928 1.09595 8.90928 1.52009 8.64884 1.78043L3.05862 7.37065V7.58279L8.655 13.1792C8.78095 13.3052 8.8501 13.4725 8.8501 13.6517C8.8501 13.8311 8.78097 13.9984 8.65507 14.1245L8.16479 14.6146C8.03883 14.7405 7.87156 14.8096 7.69218 14.8096C7.51276 14.8096 7.3454 14.7405 7.21957 14.6146L0.55624 7.95148C0.430164 7.82521 0.36107 7.65711 0.361466 7.47676C0.36107 7.29633 0.430077 7.12834 0.556155 7.00216L7.21341 0.345035Z", fill: color })));
}
ChevronLeftIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default ChevronLeftIcon;
