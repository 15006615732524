import { HOME_SCREEN, SUCCESS_STATUS } from './constants';
export const isLoggedIn = (user) => user && user.authStatus === SUCCESS_STATUS && user.id; // Refactor
export const isLoggedOut = (user) => !user || user.authStatus !== SUCCESS_STATUS;
export const redirectIfLoggedIn = (user, navigation) => {
    if (user && isLoggedIn(user))
        navigation.navigate(HOME_SCREEN);
};
export const redirectIfLoggedOut = (user = null, navigation) => {
    if (isLoggedOut(user))
        navigation.navigate(HOME_SCREEN);
};
