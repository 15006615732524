import { escapeEmail } from '../utils/email';
import { host } from '../utils/constants';
export const RECEIVE_LISTEN_HISTORY = 'RECEIVE_LISTEN_HISTORY';
export const CLEAR_LISTEN_HISTORY = 'CLEAR_LISTEN_HISTORY';
export const receiveListenHistory = (listenHistory) => ({
    type: RECEIVE_LISTEN_HISTORY,
    listenHistory,
});
export const clearListenHistory = () => ({
    type: CLEAR_LISTEN_HISTORY,
});
export const fetchListenHistory = (user, params = {}) => {
    return (dispatch) => {
        return fetch(`${host}/api/user_meditations/listens?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}&start_time=${params.startTime || ''}&end_time=${params.endTime || ''}&limit=${params.limit || ''}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((listenHistory) => {
            const parsedListenHistory = listenHistory.map((lh) => {
                return {
                    createdAt: new Date(lh.created_at),
                    meditationId: lh.meditation_id,
                    meditationTitle: lh.meditation_title,
                    image: lh.image,
                    lengthSec: lh.length_sec,
                };
            });
            dispatch(receiveListenHistory(parsedListenHistory));
        })
            .catch((error) => console.log(error));
    };
};
