import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function GearIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 21 21", ...otherProps },
        React.createElement(Path, { d: "M20.6722 9.18684C20.5032 9.08073 19.1787 8.28732 18.6523 8.04652L18.0001 6.47185C18.1939 5.95115 18.5641 4.49047 18.622 4.23723C18.6756 4.00246 18.6049 3.75661 18.4346 3.58632L17.4146 2.56638C17.2444 2.39608 16.9984 2.32529 16.7637 2.37894C16.5717 2.42287 15.0728 2.79853 14.5291 3.0009L12.9544 2.34863C12.7239 1.84496 11.9542 0.551857 11.8141 0.328773C11.686 0.124803 11.4621 0.000976562 11.2213 0.000976562H9.77874C9.5379 0.000976562 9.31395 0.124803 9.1859 0.328773C9.07979 0.497799 8.28639 1.82223 8.04558 2.34863L6.47091 3.0009C5.95022 2.8071 4.48953 2.43685 4.2363 2.37894C4.00153 2.32533 3.75568 2.39613 3.58538 2.56638L2.5654 3.58636C2.39511 3.75665 2.32431 4.0025 2.37796 4.23728C2.42189 4.42931 2.79755 5.92819 2.99992 6.47189L2.34765 8.04656C1.84398 8.27699 0.550881 9.04677 0.327797 9.18688C0.123826 9.31489 0 9.53883 0 9.77968V11.2222C0 11.463 0.123826 11.687 0.327797 11.815C0.496822 11.9211 1.82126 12.7145 2.34765 12.9554L2.99992 14.53C2.80612 15.0507 2.43588 16.5114 2.37796 16.7646C2.32436 16.9994 2.39511 17.2453 2.5654 17.4156L3.58538 18.4355C3.75568 18.6058 4.00161 18.6767 4.2363 18.623C4.42833 18.579 5.92721 18.2034 6.47091 18.001L8.04558 18.6533C8.27601 19.157 9.04579 20.4501 9.1859 20.6731C9.31395 20.8771 9.5379 21.0009 9.77874 21.0009H11.2213C11.4621 21.0009 11.686 20.8771 11.8141 20.6731C11.9202 20.5041 12.7136 19.1797 12.9544 18.6533L14.5291 18.001C15.0498 18.1948 16.5105 18.5651 16.7637 18.623C16.9986 18.6767 17.2444 18.6058 17.4146 18.4355L18.4346 17.4156C18.6049 17.2453 18.6757 16.9994 18.622 16.7646C18.5781 16.5726 18.2024 15.0737 18.0001 14.53L18.6523 12.9554C19.156 12.7249 20.4491 11.9551 20.6722 11.815C20.8762 11.687 21 11.463 21 11.2222V9.77968C21 9.53883 20.8762 9.31489 20.6722 9.18684ZM10.5 15.0785C7.97594 15.0785 5.92245 13.025 5.92245 10.5009C5.92245 7.97687 7.97594 5.92343 10.5 5.92343C13.0241 5.92343 15.0775 7.97692 15.0775 10.501C15.0775 13.025 13.0241 15.0785 10.5 15.0785Z", fill: color })));
}
GearIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default GearIcon;
