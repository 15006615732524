import { BLACK } from '@utils/colors';
import React from 'react';
import { View, StyleSheet, } from 'react-native';
const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.3,
        backgroundColor: BLACK,
    },
});
const defaultProps = {
    style: {},
};
const BaseOverlay = (props) => (React.createElement(View, { style: [styles.overlay, props.style] }));
BaseOverlay.defaultProps = defaultProps;
export default BaseOverlay;
