import { Dimensions } from 'react-native';
import { s } from 'react-native-size-matters';
export const BLUR_TEXT_COLOR = 'white'; // no more opacity btn
export const MODAL_RADIUS = 10;
export const BUTTON_RADIUS = 10;
export const DEFAULT_HORIZONTAL_PADDING = 30;
export const CARD_IMAGE_HEIGHT = 155;
export const SCREEN_HEIGHT = Dimensions.get('screen').height;
export const SCREEN_WIDTH = Dimensions.get('screen').height;
export const ICON_SIZE = 25;
export const LARGE_ICON_SIZE = 55;
export const TOP_BLANK_HEIGHT = s(60);
