// @ts-nocheck
import { merge } from 'lodash';
import SimpleCrypto from 'simple-crypto-js';
import utf8 from 'utf8';
import uuid from 'uuid';
import equal from 'fast-deep-equal';
import { host } from '../utils/constants';
import { escapeEmail } from '../utils/email';
export const FETCH_ENTRIES = 'FETCH_ENTRIES';
export const RECIEVE_ENTRIES = 'RECIEVE_ENTRIES';
export const RECIEVE_ENTRY = 'RECIEVE_ENTRY';
export const SET_ENTRY = 'SET_ENTRY';
export const REMOVE_ENTRY = 'REMOVE_ENTRY';
export const recieveEntries = (entries) => ({
    type: RECIEVE_ENTRIES,
    entries,
});
export const receiveEntry = (entry) => ({
    type: RECIEVE_ENTRY,
    entry,
});
export const removeEntry = (entryId) => ({
    type: REMOVE_ENTRY,
    entryId,
});
export const setEntry = (entryId) => ({
    type: SET_ENTRY,
    entryId,
});
export const createEntry = (user, callback) => {
    return function (dispatch) {
        return fetch(`${host}/api/entries/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.id,
            }),
        })
            .then((response) => response.json())
            .then((entry) => {
            dispatch(receiveEntry(entry));
            dispatch(setEntry(entry.id));
        })
            .then(() => callback())
            .catch((error) => console.log(error));
    };
};
export const createEntryOffline = (user, callback) => {
    return function (dispatch) {
        const entry = {
            user_id: user.id,
            created_at: new Date().toISOString(),
            id: uuid.v4(),
        };
        dispatch(receiveEntry(entry));
        dispatch(setEntry(entry.id));
        callback();
    };
};
export const fetchEntries = (user) => {
    return function (dispatch) {
        return fetch(`${host}/api/entries/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}&user_id=${user.id}`)
            .then((response) => response.json())
            .then((entries) => dispatch(recieveEntries(decryptEntries(user, entries))))
            .catch((error) => console.log(error));
    };
};
export const updateEntryOffline = (user, entry) => {
    return function (dispatch) {
        const shallowEncryptedEntry = shallowEncryptEntry(user, entry, entry.content);
        dispatch(receiveEntry(shallowEncryptedEntry));
    };
};
export const updateEntry = (user, entry) => {
    return function (dispatch) {
        const shallowEncryptedEntry = shallowEncryptEntry(user, entry, entry.content);
        dispatch(receiveEntry(shallowEncryptedEntry)); // optimistic dispatch
        const encryptedEntry = encryptEntry(user, entry);
        // TODO: hacky, refactor entry flow
        if (encryptedEntry.id === undefined || encryptEntry.id === 0) {
            encryptedEntry.id = uuid.v4();
        }
        return fetch(`${host}/api/entries/${entry.id}?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(encryptedEntry),
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
};
export const deleteEntry = (user, entryId) => {
    return function (dispatch) {
        dispatch(removeEntry(entryId)); // optimistic dispatch
        return fetch(`${host}/api/entries/${entryId}?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .catch((error) => console.log(error));
    };
};
// Sync BE with local copies of entries, then sync local
export const updateEntries = (user, entries) => {
    return (dispatch) => {
        if (!entries) {
            return;
        }
        const entryObjs = Object.keys(entries)
            .filter((entryId) => entryId !== '_persist')
            .map((entryId) => encryptEntry(user, entries[entryId]));
        return fetch(`${host}/api/entries/mass_update_ii?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ entries: entryObjs }), // body data type must match "Content-Type" header
        })
            .then((response) => response.json())
            .then((newEntries) => {
            if (!equal(newEntries, entries)) {
                dispatch(recieveEntries(newEntries));
            }
        })
            .catch((error) => console.log(error));
    };
};
// Currently encrypting
const encryptEntry = (user, entry) => {
    const { content } = entry;
    const encryptedEntry = shallowEncryptEntry(user, entry, content);
    // Only delete if the user isn't backing up entries on the server
    if (!user.is_entries_backup) {
        delete encryptedEntry.content;
    }
    return encryptedEntry;
};
// Doesn't remove content
const shallowEncryptEntry = (user, entry, content) => {
    const encryptedEntry = merge({}, entry);
    if (user.encryptionKey && content) {
        const simpleCrypto = new SimpleCrypto(user.encryptionKey);
        const encodedContent = utf8.encode(content);
        encryptedEntry.encrypted_content = simpleCrypto.encrypt(encodedContent);
    }
    return encryptedEntry;
};
const decryptEntries = (user, entries) => {
    if (user.encryptionKey) {
        const decryptedEntries = {};
        const simpleCrypto = new SimpleCrypto(user.encryptionKey);
        Object.keys(entries).forEach((entryId) => {
            const entry = entries[entryId];
            const decryptedEntry = merge({}, entry);
            if (entry.encrypted_content && !entry.content) {
                // Only decrypt if the entry hasn't been decrypted previously
                try {
                    const decryptedContent = simpleCrypto.decrypt(entry.encrypted_content);
                    decryptedEntry.content = utf8.decode(decryptedContent);
                }
                catch (error) {
                    decryptedEntry.content = 'CONTENT ENCRYPTED';
                }
            }
            decryptedEntries[decryptedEntry.id] = decryptedEntry;
        });
        return decryptedEntries;
    }
    return entries;
};
