import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function TrashIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 20 24", ...otherProps },
        React.createElement(Path, { d: "M9.29395 11.2941H10.7057V19.0588H9.29395V11.2941Z", fill: color }),
        React.createElement(Path, { d: "M6.47046 11.2941H7.88223V19.0588H6.47046V11.2941Z", fill: color }),
        React.createElement(Path, { d: "M12.1174 11.2941H13.5292V19.0588H12.1174V11.2941Z", fill: color }),
        React.createElement(Path, { d: "M19.1764 2.82351H13.5294V2.11763C13.5294 0.949961 12.5794 -1.52588e-05 11.4117 -1.52588e-05H8.58819C7.42047 -1.52588e-05 6.47055 0.949961 6.47055 2.11763V2.82351H0.823486V7.05881H2.29398L3.59205 21.9879C3.6481 23.1176 4.57384 24 5.70706 24H14.2928C15.426 24 16.3518 23.1176 16.4078 21.9879L17.7059 7.05881H19.1764V2.82351ZM7.88231 2.11763C7.88231 1.72841 8.19897 1.41175 8.58819 1.41175H11.4117C11.801 1.41175 12.1176 1.72841 12.1176 2.11763V2.82351H7.88231V2.11763ZM2.23525 4.23528H17.7647V5.64704H2.23525V4.23528ZM14.9981 21.9117C14.9823 22.291 14.6725 22.5882 14.2929 22.5882H5.70706C5.32739 22.5882 5.0176 22.2911 5.00179 21.9117C3.62071 6.01961 3.71516 7.10624 3.71102 7.05881H16.2889C16.2851 7.1014 16.373 6.09086 14.9981 21.9117Z", fill: color })));
}
TrashIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default TrashIcon;
