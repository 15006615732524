import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { stripPersist } from '../../utils';
import BaseStyles from '../../utils/baseStyles';
import { COLORS, PRIMARY_TEXT } from '../../utils/colors';
import BaseText from './baseText';
const defaultProps = {};
const SectionTitle = (props) => {
    const mode = useSelector((state) => stripPersist(state.colors).mode);
    return (React.createElement(View, { style: BaseStyles.flexRow },
        React.createElement(BaseText, { style: [BaseStyles.sectionTitle, { color: COLORS[PRIMARY_TEXT][mode] }] }, props.title)));
};
SectionTitle.defaultProps = defaultProps;
export default SectionTitle;
