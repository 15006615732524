import { RECEIVE_SETTING } from '@actions/settingsActions';
import { merge } from 'lodash';
const initialState = {
    meditationLen: '10',
};
const SettingsReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_SETTING:
            newState[action.key] = action.value;
            return newState;
        default:
            return newState;
    }
};
export default SettingsReducer;
