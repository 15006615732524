import * as React from 'react';
import { WHITE } from '@utils/colors';
import Svg, { Path } from 'react-native-svg';
const FireIcon = (props) => {
    const { style, ...otherProps } = props;
    const { height = 19, width = 26, color = WHITE } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 19 26", fill: "none", ...otherProps },
        React.createElement(Path, { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M11.9052 0.0443619C11.471 0.309281 10.3484 1.35833 9.76301 2.04613C8.18714 3.89771 7.15526 5.97516 6.73173 8.14908C6.5097 9.28865 6.33103 11.2369 6.33103 12.5185V12.9503L6.10856 12.8816C5.61036 12.7277 5.01202 12.2337 4.63417 11.6643C4.25864 11.0985 3.91696 10.227 3.72541 9.34663C3.53579 8.47508 3.05339 8.45535 2.20558 9.28448C1.13287 10.3336 0.386529 12.0961 0.112728 14.2268C0.00377184 15.0751 -0.0342315 16.4944 0.0344914 17.1509C0.280695 19.5031 1.20952 21.528 2.78296 23.1431C4.24537 24.6441 5.97235 25.548 8.06521 25.9078C8.78236 26.031 10.2254 26.0307 10.939 25.9071C13.0405 25.543 14.757 24.646 16.2141 23.1504C18.2185 21.093 19.1866 18.3871 18.9702 15.4472C18.8073 13.2326 18.3133 11.5678 17.2424 9.62396C16.8837 8.97303 16.3685 8.19267 15.3451 6.75049C14.8191 6.00923 14.235 5.15497 14.047 4.85211C13.1784 3.4523 12.7866 2.30744 12.6808 0.859919C12.6431 0.343762 12.6259 0.270781 12.5149 0.156809C12.3723 0.0104397 12.0562 -0.0478435 11.9052 0.0443619ZM10.6404 16.7496C10.7284 16.9096 11.0585 17.4315 11.3741 17.9094C12.0048 18.8646 12.316 19.4844 12.4704 20.0928C12.883 21.7189 12.6184 22.9843 11.6598 23.9683C11.0216 24.6233 10.3466 24.9215 9.50209 24.9215C8.65755 24.9215 7.98261 24.6233 7.34443 23.9683C6.90435 23.5166 6.66726 23.1082 6.47195 22.4656C6.27088 21.8042 6.33683 20.3531 6.59676 19.7186L6.66295 19.5571L6.7893 19.778C7.01281 20.169 7.31079 20.4702 7.63429 20.6321C8.2355 20.933 8.76343 20.8701 8.89236 20.4821C8.92486 20.3842 8.95276 20.0572 8.95429 19.7554C8.96232 18.1795 9.24801 17.1342 9.94936 16.1143L10.2076 15.7388L10.3441 16.0987C10.4191 16.2967 10.5525 16.5896 10.6404 16.7496Z", fill: color })));
};
export default FireIcon;
