import RNFS from '../utils/libraries/fs';
import TrackPlayer, { Capability } from '../utils/libraries/trackPlayer';
import { filepathToSpacesUrl, imageToSpacesUrl } from '../utils/request';
const Capabilities = [
    Capability.Play,
    Capability.Pause,
    Capability.JumpBackward,
    Capability.JumpForward,
];
// Actions
export const UPDATE_TRACK_PLAYER_STATUS = 'UPDATE_TRACK_PLAYER_STATUS';
export const INITIALIZE_TRACK_PLAYER = 'INITIALIZE_TRACK_PLAYER';
// Statuses
export const TRACK_PLAYER_STATUSES = {
    UNINITIALIZED: 'UNINITIALIZED',
    INITIALIZING: 'INITIALIZING',
    INITIALIZED: 'INITIALIZED',
    LOADING: 'LOADING',
    READY: 'READY',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
};
export const updateTrackPlayerStatus = (status) => ({
    type: UPDATE_TRACK_PLAYER_STATUS,
    status,
});
export const initializeTrackPlayer = (player) => ({
    type: INITIALIZE_TRACK_PLAYER,
    status: TRACK_PLAYER_STATUSES.INITIALIZED,
    player,
});
export const destroyTrackPlayer = () => (dispatch) => {
    if (TrackPlayer)
        TrackPlayer.destroy();
    dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.UNINITIALIZED));
};
export const resetTrackPlayer = () => (dispatch) => {
    if (TrackPlayer)
        TrackPlayer.reset();
    dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.INITIALIZED));
};
export const playTrackPlayer = (callback = () => { }) => async (dispatch) => {
    await TrackPlayer.play();
    dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.PLAYING));
    callback();
};
export const pauseTrackPlayer = (callback = () => { }) => async (dispatch) => {
    await TrackPlayer.pause();
    dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.READY));
    callback();
};
export const initTrackPlayer = () => async (dispatch) => {
    dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.INITIALIZING));
    await TrackPlayer.setupPlayer().then(() => {
        TrackPlayer.updateOptions({
            capabilities: Capabilities,
            backwardJumpInterval: 30,
            forwardJumpInterval: 30,
            notificationCapabilities: Capabilities,
            compactCapabilities: Capabilities,
            stopWithApp: true,
        });
    });
    dispatch(initializeTrackPlayer(TrackPlayer));
};
// bad taste
export const addTrackToTrackPlayer = function (user, meditation, playlist = {}) {
    return async function (dispatch) {
        dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.LOADING));
        const duration = meditation.length_sec;
        const { filepath } = meditation;
        const track = {
            artist: 'Stoa',
            id: meditation.id.toString(),
            title: meditation.title,
            duration,
            artwork: imageToSpacesUrl(meditation.image || playlist.image),
        };
        const localFilepath = `${RNFS.DocumentDirectoryPath}/${filepath}`;
        const exists = await RNFS.exists(localFilepath);
        if (exists) {
            track.url = `file:///${localFilepath}`;
        }
        else {
            track.url = filepathToSpacesUrl(filepath);
        }
        await TrackPlayer.add([track]);
        dispatch(updateTrackPlayerStatus(TRACK_PLAYER_STATUSES.READY));
    };
};
export const pause = function () {
    this.props.pauseTrackPlayer();
};
export const play = function () {
    this.props.playTrackPlayer();
};
