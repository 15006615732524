import { Dimensions } from 'react-native';
export const CHANGE_ORIENTATION = 'CHANGE_ORIENTATION';
export const NULL_ORIENTATION = 'NULL_ORIENTATION';
const PORTRAIT = 'PORTRAIT';
const LANDSCAPE = 'LANDSCAPE';
export const changeOrientation = () => {
    const { orientation, deviceDimensions } = setOrientation();
    if (orientation) {
        return {
            type: CHANGE_ORIENTATION,
            orientation,
            deviceDimensions,
        };
    }
    return {
        type: NULL_ORIENTATION,
    };
};
export const getDeviceOrientation = () => {
    const { height, width } = Dimensions.get('window');
    return height >= width ? PORTRAIT : LANDSCAPE;
};
const getDeviceDimensions = () => {
    const { height, width } = Dimensions.get('window');
    return {
        deviceHeight: height,
        deviceWidth: width,
    };
};
export const setOrientation = () => {
    const orientation = getDeviceOrientation();
    return {
        orientation,
        deviceDimensions: getDeviceDimensions(),
    };
};
