import { merge } from 'lodash';
import { SET_PLAYLIST_ID } from '../actions/playlistActions';
const initialState = {
    id: '',
};
const PlaylistReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case SET_PLAYLIST_ID:
            return { id: action.id };
        default:
            return newState;
    }
};
export default PlaylistReducer;
