// @ts-nocheck
import AsyncStorage from '@react-native-async-storage/async-storage';
import uuid from 'uuid';
import { isEmpty, merge } from 'lodash';
import { encryptedEntriesKey, MeditationGoalIdsKey, MeditationReminderAtKey, QuizIAnswerKey, QuizIIAnswerKey, ReferralLinkURLKey, SubscriptionKey, } from '@utils/asyncKeys';
import { fetchGet } from '@utils/request';
import { Alert } from 'react-native';
import Bugsnag from '../utils/libraries/bugsnag';
import { escapeEmail } from '../utils/email';
import { getPurchaseMetadata } from '../utils/subscription';
import { HOME_SCREEN, host, SUCCESS_STATUS } from '../utils/constants';
import Analytics from '../utils/analytics';
import { getRubyTimezoneOffset } from '../utils/index';
export const LOGIN = 'LOGIN';
export const RECEIVE_USER = 'RECEIVE_USER';
export const SIGN_UP = 'SIGN_UP';
export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const authLoad = () => ({
    type: AUTH_LOAD,
});
export const authFail = () => ({
    type: AUTH_FAIL,
});
export const receiveUser = (user) => ({
    type: RECEIVE_USER,
    user,
});
export const logout = () => ({
    type: LOGOUT,
});
export const restoreSubscription = (user, entitlementInfo) => {
    const purchaseMetadata = getPurchaseMetadata(entitlementInfo);
    return (dispatch) => {
        fetch(`${host}/v2/users/purchase?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user,
                receipt_data: purchaseMetadata,
            }),
        })
            .then((response) => response.json())
            .then((user) => receiveUserWrapper(user, dispatch))
            .then(() => {
            Analytics.event(Analytics.Events.RESTORE_SUBSCRIPTION);
        })
            .catch((response) => {
            Bugsnag.notify(new Error('RestorePurchaseFail'), (event) => {
                event.severity = 'info';
                event.context = 'purchase';
                event.addMetadata('response', {
                    purchaseMetadata,
                    status: response.status,
                    text: response.statusText,
                    body: response.body,
                    user: user.email,
                });
            });
        });
    };
};
const UnsubscribedAlert = () => Alert.alert('Your subscription has expired.', 'Please reach out to stoa@stoameditation.com with any questions.');
export const unsubscribeUser = (user) => (dispatch) => {
    fetch(`${host}/v2/users/unsubscribe?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                id: user.id,
            },
        }),
    })
        .then((response) => response.json())
        .then((receivedUser) => {
        if (!receivedUser.is_subscribed) {
            Analytics.event(Analytics.Events.UNSUBSCRIBE, {
                userId: receivedUser.id,
            });
            UnsubscribedAlert();
            receiveUserWrapper(receivedUser, dispatch);
        }
    });
};
export const purchaseSubscription = (user, entitlementInfo) => (dispatch) => {
    const purchaseMetadata = getPurchaseMetadata(entitlementInfo);
    fetch(`${host}/v2/users/purchase?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user,
            receipt_data: purchaseMetadata,
        }),
    })
        .then((response) => response.json())
        .then((receivedUser) => {
        receiveUserWrapper(receivedUser, dispatch);
        Analytics.revenue(purchaseMetadata);
        Analytics.event(Analytics.Events.GENERIC_PURPOSE, {
            product_id: purchaseMetadata.productId || purchaseMetadata.product_id,
        });
    })
        .catch((response) => {
        Bugsnag.notify(new Error('PurchaseFail'), (event) => {
            event.severity = 'info';
            event.context = 'purchase';
            event.addMetadata('response', {
                purchaseMetadata,
                status: response.status,
                text: response.statusText,
                body: response.body,
                user: user.email,
            });
        });
    });
};
export const enterEmail = async (email) => {
    const meditationReminderAt = await AsyncStorage.getItem(MeditationReminderAtKey);
    const meditationGoalIds = await AsyncStorage.getItem(MeditationGoalIdsKey);
    const referralLink = await AsyncStorage.getItem(ReferralLinkURLKey);
    const quizIAnswer = await AsyncStorage.getItem(QuizIAnswerKey);
    const quizIIAnswer = await AsyncStorage.getItem(QuizIIAnswerKey);
    const subscriptionInfo = await AsyncStorage.getItem(SubscriptionKey);
    const isSubscribed = subscriptionInfo?.isSubscribed;
    const productIdentifier = subscriptionInfo?.productIdentifier;
    return fetch(`${host}/v2/users`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                email,
                meditation_reminder_at: meditationReminderAt,
                timezone_offset_hours: getRubyTimezoneOffset(),
                meditation_goal_ids: JSON.parse(meditationGoalIds),
                referral_link: referralLink,
                is_subscribed: isSubscribed,
                product_id: productIdentifier,
                quiz: {
                    level: quizIAnswer,
                    problems: quizIIAnswer,
                },
            },
        }),
    }).catch((error) => console.log(error));
};
export const enterPassword = ({ email, password }, navigation, callback) => (dispatch) => {
    dispatch(authLoad);
    fetch(`${host}/users/sign_in`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                email,
                password,
            },
        }),
    })
        .then((response) => {
        if (response.status === 200) {
            response.json().then((user) => {
                handleEncryptionKey(user, dispatch);
                navigation && navigation.navigate(HOME_SCREEN);
            });
        }
        else {
            Bugsnag.notify(new Error('InputFail'), (event) => {
                event.severity = 'info';
                event.context = 'input';
                event.addMetadata('response', {
                    status: response.status,
                    text: response.statusText,
                    body: response.body,
                });
                event.addMetadata('message', {
                    message: password,
                    email,
                });
            });
            dispatch(authFail());
        }
    })
        .then(callback);
};
export const updateUser = (user) => (dispatch) => fetch(`${host}/api/users/${user.id}?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
    method: 'PATCH',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        user: {
            fcm_token: user.fcm_token,
            id: user.id,
            meditation_reminder_at: user.meditation_reminder_at,
            is_beta: user.is_beta,
        },
    }),
}).then((response) => {
    if (response.status === 200) {
        response.json().then((newUser) => {
            receiveUserWrapper(newUser, dispatch);
        });
    }
    else {
        console.log('update error');
    }
});
export const updateUserToken = (user) => {
    fetch(`${host}/api/users/${user.id}?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                id: user.id,
                fcm_token: user.fcm_token,
            },
        }),
    }).then((response) => console.log(response));
};
export const reviewAsk = (user) => (dispatch) => {
    fetch(`${host}/v2/users/review_ask?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: {
                id: user.id,
            },
        }),
    }).then((response) => {
        if (response.status === 200) {
            response.json().then((receivedUser) => {
                receiveUserWrapper(receivedUser, dispatch);
            });
        }
        else {
            Analytics.recordError(Analytics.Events.REVIEW_ASK_ERROR);
        }
    });
};
export async function handleEncryptionKey(user, dispatch) {
    const encryptedUser = merge({}, user);
    let encryptionKey = await AsyncStorage.getItem(encryptedEntriesKey(encryptedUser.id));
    if (!encryptionKey) {
        encryptionKey = uuid.v4();
        await AsyncStorage.setItem(encryptedEntriesKey(encryptedUser.id), encryptionKey);
    }
    encryptedUser.encryptionKey = encryptionKey;
    dispatch(signInUser(encryptedUser));
}
const persistedUser = (user) => {
    if (user._persist) {
        return user;
    }
    return {
        data: {
            authStatus: SUCCESS_STATUS,
            ...user,
        },
        _persist: {
            rehydrated: true,
        },
    };
};
export const signInUser = (user) => receiveUser(persistedUser(user));
const receiveUserWrapper = (user, dispatch) => {
    if (user.encryptionKey) {
        dispatch(receiveUser(persistedUser(user)));
    }
    else {
        handleEncryptionKey(user, dispatch);
    }
};
export const recordFetch = (user) => (dispatch) => {
    user.lastFetchAt = new Date();
    receiveUserWrapper(user, dispatch);
};
export const recordEntriesUpdate = (user) => (dispatch) => {
    user.lastEntriesUpdateAt = new Date();
    receiveUserWrapper(user, dispatch);
};
// Assumes persisted user
export const saveUser = (user) => {
    const userJSON = JSON.stringify(user);
    AsyncStorage.setItem('user', userJSON);
};
export const loadUser = async (dispatch) => {
    const userJSON = await AsyncStorage.getItem('user');
    if (userJSON) {
        const user = JSON.parse(userJSON);
        dispatch(receiveUser(user));
    }
    else {
        dispatch(receiveUser({
            _persist: {
                rehydrated: true,
            },
            data: {
                authStatus: AUTH_FAIL,
            },
        }));
    }
};
export const removeUser = async () => {
    await AsyncStorage.removeItem('user');
};
export const userFetchAndUpdate = (user) => async (dispatch) => {
    const userObj = await fetchGet(user, `/api/users/${user.id}`);
    if (!isEmpty(userObj)) {
        Analytics.setUser(userObj);
        if (user.is_subscribed && !userObj.is_subscribed) {
            UnsubscribedAlert();
        }
        receiveUserWrapper(userObj, dispatch);
    }
};
export const updateUserNotification = (user, notification) => (dispatch) => fetch(`${host}/api/user_notifications?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        notify_at: notification.notify_at,
        enabled_days: notification.enabled_days,
    }),
}).then((response) => {
    if (response.status === 200) {
        response.json().then((newNotification) => {
            const newUser = merge(user, {
                meditation_reminder_at: newNotification.notify_at,
                meditation_reminder_enabled_days: newNotification.enabled_days,
            });
            receiveUserWrapper(newUser, dispatch);
        });
    }
    else {
        console.log('update error');
    }
});
export const destroyUserNotification = (user) => (dispatch) => fetch(`${host}/api/user_notifications?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
    method: 'DELETE',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}).then((response) => {
    if (response.status === 200) {
        response.json().then(() => {
            const newUser = merge(user, { meditation_reminder_at: null, meditation_reminder_enabled_days: null });
            receiveUserWrapper(newUser, dispatch);
        });
    }
    else {
        console.log('update error');
    }
});
export const deleteUser = (user) => (dispatch) => fetch(`${host}/users/?user_email=${escapeEmail(user.email)}&user_token=${user.authentication_token}`, {
    method: 'DELETE',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}).then(() => {
    dispatch(logout());
    Alert.alert('Your account and its associated data has been deleted');
    Analytics.event(Analytics.Events.USER_DELETED);
});
