import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Animated, BackHandler, ScrollView, StyleSheet, View, } from 'react-native';
import { removeNotification } from '@actions/statsActions';
import { BRAND, QUOTE_BACKGROUND, SUBTITLE_TEXT, COLORS, PRIMARY_TEXT, BACKGROUND, } from '@utils/colors';
import { fetchGet } from '@utils/request';
import { DARK, HOME_SCREEN } from '@utils/constants';
import { stripPersist } from '@utils/index';
import Analytics from '@utils/analytics';
import { Fonts } from '@utils/fonts';
import BaseText from '../baseComponents/baseText';
import RandomPopups from '../baseComponents/RandomPopups';
import Header from '../baseComponents/header';
import OfflineMessage from '../baseComponents/offlineMessage';
import ListenHistory from './ListenHistory';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    contentContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: '8%',
        paddingBottom: 30,
    },
    titleText: {
        paddingLeft: 0,
        fontSize: Fonts.size.xxxl,
        fontWeight: 'bold',
        paddingVertical: 8,
    },
    statsBlock: {
        width: '100%',
        marginTop: 22,
    },
    flexRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexColumn: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
    },
    statsBlockData: {
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
    },
    numText: {
        fontSize: Fonts.size.xxl,
        fontWeight: 'bold',
    },
    labelText: {},
});
const MONTH_MILLI = 24 * 30 * 60 * 60 * 1000;
const defaultFetchParams = () => ({
    startTime: new Date(new Date().getTime() - MONTH_MILLI),
    endTime: new Date(),
});
const HistoryScreen = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [entriesStats, setEntriesStats] = useState(null);
    const [meditationStats, setMeditationStats] = useState(null);
    const [listenHistoryParams, setListenHistoryParams] = useState(defaultFetchParams());
    const backgroundColorValue = new Animated.Value(0);
    useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', handleBackPress);
        if (props.isConnected)
            fetchStats();
        Analytics.screenHit('stats_screen', 'HistoryScreen');
        _start();
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
            props.removeStatsNotification(); // remove after viewing
        };
    }, []);
    const handleBackPress = () => {
        props.navigation.navigate(HOME_SCREEN);
        Analytics.event(Analytics.Events.STATS_BACKPRESS, {});
        return true;
    };
    const _start = () => {
        if (props.stats.notification) {
            Animated.timing(backgroundColorValue, {
                toValue: 1,
                duration: 2000,
                useNativeDriver: false,
            }).start();
        }
    };
    const fetchStats = () => {
        fetchGet(props.user, `/api/stats_ii/${props.user.id}`).then((response) => {
            // check status first
            if (response?.meditation && response?.entries) {
                setIsLoading(false);
                setEntriesStats(response.entries);
                setMeditationStats(response.meditation);
            }
        });
    };
    const renderStatsBlock = (field) => {
        const outputRange = props.mode === DARK
            ? ['rgb(7,199,177)', 'rgb(9,37,46)']
            : ['rgb(7,199,177)', 'rgb(245,245,245)'];
        const backgroundColor = backgroundColorValue.interpolate({
            inputRange: [0, 1],
            outputRange,
        });
        const statsBlockDataStyle = props.stats.activity === field
            ? [styles.statsBlockData, { backgroundColor }]
            : [styles.statsBlockData, { backgroundColor: COLORS[QUOTE_BACKGROUND][props.mode] }];
        const stats = field === 'entries' ? entriesStats : meditationStats;
        if (!stats)
            return null;
        return (React.createElement(View, { style: styles.statsBlock },
            React.createElement(View, null,
                React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, field === 'entries' ? 'JOURNAL' : field.toUpperCase())),
            React.createElement(Animated.View, { style: statsBlockDataStyle },
                React.createElement(View, { style: styles.flexColumn },
                    React.createElement(BaseText, { style: [styles.labelText, { color: COLORS[SUBTITLE_TEXT][props.mode] }] },
                        field === 'meditation' ? 'Daily ' : 'Daily ',
                        "Streak"),
                    React.createElement(View, { style: styles.flexRow },
                        React.createElement(BaseText, { style: [styles.numText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, stats.streak))),
                React.createElement(View, { style: styles.flexColumn },
                    React.createElement(BaseText, { style: [styles.labelText, { color: COLORS[SUBTITLE_TEXT][props.mode] }] }, field === 'meditation' ? 'Minutes' : 'Monthly'),
                    React.createElement(BaseText, { style: [styles.numText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, field === 'meditation' ? stats.minutes : stats.monthly)),
                React.createElement(View, { style: styles.flexColumn },
                    React.createElement(BaseText, { style: [styles.labelText, { color: COLORS[SUBTITLE_TEXT][props.mode] }] }, "Total"),
                    React.createElement(BaseText, { style: [styles.numText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, stats.total)))));
    };
    const handleScrollEndDrag = (e) => {
        const { contentSize, layoutMeasurement, contentOffset } = e.nativeEvent;
        if (Math.round(contentSize.height) + 50
            <= Math.round(layoutMeasurement.height + contentOffset.y)) {
            const { endTime, startTime } = listenHistoryParams;
            if (endTime && startTime) {
                const newStartTime = new Date(startTime.getTime() - MONTH_MILLI);
                setListenHistoryParams({ startTime: new Date(newStartTime), endTime });
            }
        }
    };
    if (!props.isConnected) {
        return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][props.mode] }] },
            React.createElement(Header, { onPressCallback: handleBackPress, title: "Stats", mode: props.mode }),
            React.createElement(OfflineMessage, null)));
    }
    if (isLoading) {
        const loadingImg = React.createElement(ActivityIndicator, { size: "small", color: COLORS[BRAND][props.mode] });
        return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][props.mode] }] },
            React.createElement(Header, { onPressCallback: handleBackPress, title: "Stats", mode: props.mode }),
            loadingImg));
    }
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][props.mode] }] },
        React.createElement(Header, { onPressCallback: handleBackPress, title: "Stats", mode: props.mode }),
        React.createElement(ScrollView, { contentContainerStyle: styles.contentContainer, onScrollEndDrag: handleScrollEndDrag },
            renderStatsBlock('meditation'),
            renderStatsBlock('entries'),
            React.createElement(View, { style: styles.statsBlock },
                React.createElement(View, null,
                    React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, "HISTORY")),
                React.createElement(ListenHistory, { navigation: props.navigation, fetchParams: listenHistoryParams })),
            React.createElement(RandomPopups, { navigation: props.navigation }))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    stats: state.stats,
    user: stripPersist(state.user),
    isConnected: state.connection.isConnected,
});
const mapDispatchToProps = (dispatch) => ({
    removeStatsNotification: () => dispatch(removeNotification()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HistoryScreen);
