import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BackHandler, InteractionManager, FlatList, StyleSheet, View, } from 'react-native';
import Tags from '@components/tags/Tags';
import { TIMER_ID } from '@selectors/playlists';
import Header from '../baseComponents/header';
import RandomPopups from '../baseComponents/RandomPopups';
import { setPlaylistId } from '../../actions/playlistActions';
import { internetChecker } from '../../actions/connectionActions';
import { COLORS, BACKGROUND } from '../../utils/colors';
import { HOME_SCREEN, PLAYLIST_SCREEN, THEORY_PLAYLIST_SCREEN } from '../../utils/constants';
import { stripPersist, idObjToArr } from '../../utils';
import Analytics from '../../utils/analytics';
import PlaylistItem from './PlaylistItem';
import MeditationList from './MeditationList';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
    },
    content: {
        flex: 6,
    },
});
const PlaylistsScreen = ({ isTheory, connection, dispatchiInternetChecker, mode, dispatchSetPlaylistId, navigation, isConnected, playlists, }) => {
    const [currentTag, setCurrentTag] = useState(null);
    const navigateHome = () => {
        navigation.navigate(HOME_SCREEN);
        return true;
    };
    useEffect(() => {
        isTheory
            ? Analytics.screenHit('Theory Playlists visit', 'TheoryPlaylistsScreen')
            : Analytics.screenHit('Playlists visit', 'PlaylistsScreen');
        InteractionManager.runAfterInteractions(() => {
            dispatchiInternetChecker(connection);
            BackHandler.addEventListener('hardwareBackPress', navigateHome);
            return () => {
                BackHandler.removeEventListener('hardwareBackPress', navigateHome);
            };
        });
    }, []);
    const handleOnPlaylistPress = (playlist) => {
        dispatchSetPlaylistId(playlist.id);
        isTheory ? navigation.navigate(THEORY_PLAYLIST_SCREEN) : navigation.navigate(PLAYLIST_SCREEN);
        const properties = {
            playlist_id: playlist.id,
            playlist_title: playlist.title,
        };
        isTheory
            ? Analytics.event(Analytics.Events.THEORY_PLAYLIST_TOUCH, properties)
            : Analytics.event(Analytics.Events.PLAYLIST_TOUCH, properties);
    };
    const renderPlaylists = () => {
        const playlistsArr = idObjToArr(playlists)
            .sort((a, b) => a.index - b.index)
            .sort((a, b) => (a.is_listened && a.id !== TIMER_ID ? 1 : -1) - (b.is_listened ? 1 : -1))
            .filter((playlist) => (isTheory ? playlist.is_theory : !playlist.is_theory));
        return (React.createElement(View, { style: styles.content },
            React.createElement(FlatList, { scrollEventThrottle: 16, data: playlistsArr, keyExtractor: (item, i) => (item.id ? item.id.toString() : i.toString()), renderItem: (itemObj) => {
                    const onPress = () => handleOnPlaylistPress(itemObj.item);
                    return (React.createElement(PlaylistItem, { playlist: itemObj.item, mode: mode, isConnected: isConnected, onPress: onPress }));
                } })));
    };
    return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        React.createElement(Header, { onPressCallback: navigateHome, title: isTheory ? 'Theory' : 'Meditation', mode: mode }),
        isTheory ? null : React.createElement(Tags, { setCurrentTag: setCurrentTag, currentTag: currentTag }),
        currentTag ? React.createElement(MeditationList, { currentTag: currentTag }) : renderPlaylists(),
        React.createElement(RandomPopups, { navigation: navigation })));
};
const mapStateToProps = (state) => ({
    connection: state.connection,
    isConnected: state.connection.isConnected,
    mode: stripPersist(state.colors).mode,
    playlists: stripPersist(state.playlists),
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    dispatchiInternetChecker: (connection) => dispatch(internetChecker(connection)),
    dispatchSetPlaylistId: (id) => dispatch(setPlaylistId(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistsScreen);
