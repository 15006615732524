import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function FavoriteIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 200 192", fill: "none", ...otherProps },
        React.createElement(Path, { d: "M199.477 72.4179C198.16 68.3682 194.568 65.501 190.335 65.118L132.601 59.8765L109.784 6.45763C108.1 2.533 104.267 0 100 0C95.7338 0 91.8992 2.533 90.2253 6.45763L67.4085 59.8765L9.6652 65.118C5.43234 65.5086 1.84799 68.3758 0.523506 72.4179C-0.793349 76.4677 0.422796 80.9096 3.62414 83.7172L47.2665 121.985L34.3986 178.661C33.4571 182.828 35.0746 187.137 38.5323 189.636C40.3908 190.987 42.5744 191.661 44.7656 191.661C46.6485 191.661 48.533 191.161 50.2161 190.154L100 160.386L149.775 190.154C153.427 192.336 158.018 192.136 161.468 189.636C164.926 187.137 166.543 182.828 165.602 178.661L152.734 121.985L196.376 83.7172C199.576 80.9096 200.794 76.4768 199.477 72.4179Z", fill: color })));
}
FavoriteIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default FavoriteIcon;
