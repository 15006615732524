import { merge } from 'lodash';
import { RECEIVE_MEDITATION_GOALS } from '../actions/meditationGoalActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {};
const MeditationGoalsReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_MEDITATION_GOALS:
            return { data: action.meditationGoals };
        default:
            return newState;
    }
};
export default MeditationGoalsReducer;
