import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function StatsIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 21 21", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M19.7091 1.84086C19.7091 0.936592 18.967 0.199951 18.0546 0.199951C17.1421 0.199951 16.4 0.936592 16.4 1.84086C16.4 1.99471 16.4281 2.15097 16.4833 2.32589L16.5261 2.46158L14.1424 4.23584L14.0252 4.15815C13.7548 3.97887 13.4391 3.88177 13.1091 3.88177C12.1967 3.88177 11.4546 4.61841 11.4546 5.52268C11.4546 5.76316 11.5118 5.99875 11.6207 6.22442L11.6826 6.35282L8.99925 9.01641L8.87228 8.95607C8.64449 8.84783 8.40658 8.79086 8.16366 8.79086C7.73205 8.79086 7.32635 8.95833 7.01819 9.25301L6.91676 9.34999L4.83412 8.31642L4.86728 8.05701L4.87275 7.97722C4.87275 7.07296 4.13065 6.33631 3.21821 6.33631C2.30576 6.33631 1.56366 7.07296 1.56366 7.97722C1.56366 8.88149 2.30576 9.61813 3.21821 9.61813C3.64981 9.61813 4.05551 9.45066 4.36368 9.15599L4.4651 9.059L6.54775 10.0926L6.51459 10.352L6.50911 10.4318C6.50911 11.336 7.25121 12.0727 8.16366 12.0727C9.07611 12.0727 9.8182 11.336 9.8182 10.4318C9.8182 10.1913 9.76095 9.9557 9.65212 9.73003L9.5902 9.60163L12.2735 6.93804L12.4005 6.99837C12.6283 7.10661 12.8662 7.16359 13.1091 7.16359C14.0216 7.16359 14.7637 6.42695 14.7637 5.52268C14.7637 5.36883 14.7356 5.21257 14.6804 5.03765L14.6375 4.90196L17.0213 3.1277L17.1385 3.20539C17.4089 3.38467 17.7246 3.48177 18.0546 3.48177C18.967 3.48177 19.7091 2.74513 19.7091 1.84086Z", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M19.1133 6.77267H16.6523C16.5876 6.77267 16.5371 6.82275 16.5371 6.88292V20.2795H14.3066V10.5444C14.3066 10.4842 14.2562 10.4342 14.1914 10.4342H11.7305C11.6657 10.4342 11.6152 10.4842 11.6152 10.5444V20.2795H9.38477V15.4264C9.38477 15.3662 9.33428 15.3161 9.26953 15.3161H6.80859C6.74385 15.3161 6.69336 15.3662 6.69336 15.4264V20.2795H4.46289V12.9854C4.46289 12.9252 4.4124 12.8751 4.34766 12.8751H1.88672C1.82197 12.8751 1.77148 12.9252 1.77148 12.9854V20.2795H0.615234C0.550489 20.2795 0.5 20.3295 0.5 20.3897C0.5 20.4499 0.550489 20.4999 0.615234 20.4999H20.3848C20.4495 20.4999 20.5 20.4499 20.5 20.3897C20.5 20.3295 20.4495 20.2795 20.3848 20.2795H19.2285V6.88292C19.2285 6.82275 19.178 6.77267 19.1133 6.77267Z", fill: color })));
}
StatsIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default StatsIcon;
