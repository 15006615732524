import { fetchGet } from '../utils/request';
export const RECEIVE_MEDITATION_GOALS = 'RECEIVE_MEDITATION_GOALS';
export const receiveMeditationGoals = (meditationGoals) => ({
    type: RECEIVE_MEDITATION_GOALS,
    meditationGoals,
});
export const fetchMeditationGoals = () => {
    return function (dispatch) {
        fetchGet({}, '/api/meditation_goals')
            .then((goals) => {
            dispatch(receiveMeditationGoals(goals));
        })
            .catch((error) => console.log(error));
    };
};
