import React from 'react';
import { connect } from 'react-redux';
import { BackHandler, InteractionManager, ScrollView, StyleSheet, View, Alert } from 'react-native';
import PlaylistModal from '@components/baseComponents/PlaylistModal';
import { stripPersist, idObjToArr } from '../../utils';
import { internetChecker } from '../../actions/connectionActions';
import { setAndGoToMeditation } from '../../actions/meditationActions';
import { PLAYLISTS_NAV_CONFIG } from '../../utils/constants';
import { COLORS, BACKGROUND } from '../../utils/colors';
import MeditationModal from '../baseComponents/meditationModal';
import PlaylistHeader from './PlaylistHeader';
import Analytics from '../../utils/analytics';
import { redirectIfMeditationRestricted } from '../../utils/enforcement';
import MeditationItem from './MeditationItem';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    meditationsContent: {
        flex: 1,
    },
    meditationsContentContainer: {
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
});
class PlaylistScreen extends React.Component {
    static navigationOptions = {
        title: 'Playlist',
    };
    removeDownloadedMeditations;
    constructor(props) {
        super(props);
        this.state = { isModalOpen: false, playlistModalIsOpen: false };
    }
    componentDidMount() {
        InteractionManager.runAfterInteractions(() => {
            const properties = {
                playlist_id: this.props.playlist.id,
                playlist_title: this.props.playlist.title,
            };
            this.props.isTheory
                ? Analytics.screenHit('TheoryPlaylist', 'TheoryPlaylistScreen', properties)
                : Analytics.screenHit('Playlist', 'PlaylistScreen', properties);
            BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        });
    }
    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }
    handleBackPress = () => {
        const navigationConfig = this.props.isTheory
            ? PLAYLISTS_NAV_CONFIG.theory
            : PLAYLISTS_NAV_CONFIG.meditation;
        this.props.navigation.navigate(navigationConfig.navigator, {
            screen: navigationConfig.screen,
        });
        this.props.isTheory
            ? Analytics.event(Analytics.Events.THEORY_PLAYLIST_BACKPRESS, {})
            : Analytics.event(Analytics.Events.PLAYLIST_BACKPRESS, {});
        return true;
    };
    handleMeditationTouch = (meditation) => {
        if (!this.props.isConnected && !meditation.is_downloaded) {
            Alert.alert("You're currently disconnected. Download meditations in order to play them while you're offline.");
        }
        else {
            redirectIfMeditationRestricted(meditation, this.props.user, this.props.navigation, () => {
                this.props.setAndGoToMeditation(meditation, this.props.navigation, this.props.isTheory);
                Analytics.event(Analytics.Events.PLAYLIST_MEDITATION_TOUCH, {
                    meditation_id: meditation.id,
                    meditation_title: meditation.title,
                });
            });
        }
    };
    playNextMeditation = () => {
        let meditation = this.props.meditations.filter((val) => !val.is_listened)[0];
        let idx;
        if (meditation) {
            idx = this.props.meditations.map((val) => val.id).indexOf(meditation.id);
        }
        else {
            idx = Math.round(Math.random() * (this.props.meditations.length - 1));
            meditation = this.props.meditations[idx];
        }
        this.handleMeditationTouch(meditation);
    };
    getModalMeditation = () => {
        if (!this.state.meditationId)
            return null;
        const meditation = this.props.playlist.meditations[this.state.meditationId];
        return meditation;
    };
    closeModal = () => {
        this.setState({ isModalOpen: false }, () => this.setState({ meditationId: undefined }));
    };
    openModal = (meditationId) => {
        this.setState({ meditationId }, () => this.setState({ isModalOpen: true }));
        Analytics.event(Analytics.Events.PLAYLIST_MODAL_TOUCH, {
            meditation_id: meditationId,
        });
    };
    renderModal = () => {
        const meditation = this.getModalMeditation();
        if (meditation) {
            return (React.createElement(MeditationModal, { playlist: this.props.playlist, meditation: meditation, isOpen: this.state.isModalOpen, closeModal: () => this.setState({ isModalOpen: false }) }));
        }
        return null;
    };
    render() {
        const { playlistModalIsOpen } = this.state;
        return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
            React.createElement(PlaylistHeader, { handleBackPress: this.handleBackPress, playNextMeditation: this.playNextMeditation, openModal: () => this.setState({ playlistModalIsOpen: true }) }),
            React.createElement(ScrollView, { style: styles.meditationsContent, contentContainerStyle: styles.meditationsContentContainer }, this.props.meditations.map((meditation, i) => (React.createElement(MeditationItem, { meditation: meditation, playlist: this.props.playlist, index: i, key: `meditation-item-${i}`, openModal: () => this.openModal(meditation.id) })))),
            this.renderModal(),
            React.createElement(PlaylistModal, { isOpen: playlistModalIsOpen, closeModal: () => this.setState({ playlistModalIsOpen: false }) })));
    }
}
const mapStateToProps = (state) => {
    const playlist = stripPersist(state.playlists)[state.playlist.id];
    const meditations = idObjToArr(playlist.meditations);
    return {
        connection: state.connection,
        isConnected: state.connection.isConnected,
        deviceDimensions: state.dimensions.deviceDimensions,
        meditations,
        mode: stripPersist(state.colors).mode,
        playlist,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    internetChecker: (connection) => dispatch(internetChecker(connection)),
    setAndGoToMeditation: (meditation, navigation, isTheory) => dispatch(setAndGoToMeditation(meditation, navigation, isTheory)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistScreen);
