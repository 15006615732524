import React, { useEffect, useState } from 'react';
import { Linking, } from 'react-native';
import { fetchGet } from '@utils/request';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BannerHistoryKey } from '@utils/asyncKeys';
import Analytics from '@utils/analytics';
import FLAGS from '@utils/flags';
import { isEmpty } from 'lodash';
import Banner from './Banner';
const Banners = ({ mode, navigation, user }) => {
    const [banners, setBanners] = useState([]);
    const getBannerHistory = async () => {
        const workshopJSON = await AsyncStorage.getItem(BannerHistoryKey(user.id));
        let bannerHistory = {};
        if (workshopJSON) {
            bannerHistory = JSON.parse(workshopJSON);
        }
        return bannerHistory;
    };
    useEffect(() => {
        const asyncSetBanners = async () => {
            const bannerHistory = await getBannerHistory();
            try {
                const fetchedBanners = await fetchGet(user, '/api/banners');
                fetchedBanners?.forEach((banner) => {
                    if (!bannerHistory[banner.id]) {
                        fetchedBanners.push(banner);
                    }
                });
                setBanners(fetchedBanners);
            }
            catch (e) {
                Analytics.recordError(Analytics.Events.FAILED_TO_FETCH_BANNERS);
            }
        };
        asyncSetBanners();
    }, []);
    const handleHideBanner = async (bannerId) => {
        try {
            const bannerHistory = await getBannerHistory();
            bannerHistory[bannerId] = 'hidden';
            await AsyncStorage.setItem(BannerHistoryKey(user.id), JSON.stringify(bannerHistory));
            const newBanners = banners?.filter((banner) => banner.id !== bannerId);
            setBanners(newBanners);
            Analytics.event(Analytics.Events.HIDE_BANNER, {
                bannerId,
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    const handleOnPress = (banner) => {
        if (isEmpty(banner.cta))
            return;
        if (banner.cta?.url) {
            Linking.openURL(banner.cta.url);
        }
        else if (banner.cta?.screen) {
            navigation.navigate(banner.cta?.screen);
        }
        Analytics.event(Analytics.Events.BANNER_ACTION, {
            bannerId: banner.id,
        });
    };
    if (isEmpty(banners))
        return null;
    const banner = banners[0];
    if (!banner || !FLAGS.BANNERS)
        return null;
    return (React.createElement(Banner, { banner: banner, mode: mode, onPress: () => handleOnPress(banner), onHide: () => handleHideBanner(banner.id) }));
};
export default Banners;
