// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, Linking } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { CONTACT_EMAIL } from '@utils/constants';
import { BACKGROUND, COLORS, PRIMARY_TEXT } from '../../utils/colors';
import TextButton from './textButton';
import { stripPersist } from '../../utils';
import BaseStyles from '../../utils/baseStyles';
import BaseText from './baseText';
import Analytics from '../../utils/analytics';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        top: 0,
        left: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    text: {
        fontSize: Fonts.size.l,
        textAlign: 'center',
        padding: 10,
    },
});
class FallbackComponent extends React.PureComponent {
    componentDidMount() {
        Analytics.event(Analytics.Events.VIEW_ERROR_BOUNDARY);
    }
    render() {
        return (React.createElement(SafeAreaProvider, null,
            React.createElement(View, { style: [styles.container, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
                React.createElement(View, { style: BaseStyles.flexCol },
                    React.createElement(View, { style: BaseStyles.flexCol }),
                    React.createElement(View, { style: BaseStyles.flexCol },
                        React.createElement(BaseText, { style: [styles.text, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Sorry something went wrong."),
                        React.createElement(BaseText, { style: [styles.text, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, "Please contact us if the problem continues.")),
                    React.createElement(View, { style: BaseStyles.flexCol },
                        React.createElement(TextButton, { title: "Try again", onPress: this.props.resetError })),
                    React.createElement(View, { style: BaseStyles.flexCol },
                        React.createElement(TextButton, { title: "Contact", onPress: () => Linking.openURL(`mailto:${CONTACT_EMAIL}`) }))))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(FallbackComponent);
