import { RECEIVE_CONTINUE_MEDITATION, REMOVE_CONTINUE_MEDITATION, } from '@actions/continuePlayingActions';
import { merge } from 'lodash';
import { coerceStateToDataFormat } from '../utils';
const initialState = {};
export const ContinuePlayingReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_CONTINUE_MEDITATION:
            return { data: action.meditationId };
        case REMOVE_CONTINUE_MEDITATION:
            return {};
        default:
            return newState;
    }
};
