import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, ScrollView, StyleSheet, View, TouchableOpacity, Animated, AppState, } from 'react-native';
import FLAGS from '@utils/flags';
import { MeditationIcon, MenuIcon, StatsIcon } from '@assets/svg';
import { s } from 'react-native-size-matters';
import { fetchListenHistory } from '@actions/listenHistoryActions';
import { TOP_BLANK_HEIGHT } from '@utils/styles';
import moment from 'moment';
import { isBeta } from '@utils/user';
import appsFlyer from 'react-native-appsflyer';
import { requestTrackingPermission } from 'react-native-tracking-transparency';
import PurchaseService from '@services/PurchaseService';
import { Settings } from 'react-native-fbsdk-next';
import { RoutinesFeedbackKey } from '@utils/asyncKeys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { fetchPlaylists, updateAllPlaylistsDownloadStatus } from '../actions/playlistsActions';
import { receiveQuote } from '../actions/quoteActions';
import { uploadListens } from '../actions/listensActions';
import { setAndGoToMeditation } from '../actions/meditationActions';
import { updateUserToken, recordFetch, userFetchAndUpdate, updateUser, } from '../actions/userActions';
import { internetChecker } from '../actions/connectionActions';
import MeditationHomeSection from './home/MeditationHomeSection';
import PlaylistCard from './baseComponents/playlistCard';
import QuoteBox from './quotes/quoteBox';
import RandomPopups from './baseComponents/RandomPopups';
import { stripPersist } from '../utils';
import { getDailyMeditation, getDownloadedMeditations, getEveningMeditations, getFavoriteMeditations, getHomeScreenPlaylists, getListenAgain, getMorningMeditations, getNextMeditation, getRecommendedConversations, getSuggestedMeditationsFromOnboarding, INTRO_IDS, playlistsToSortedArray, } from '../selectors/playlists';
import Analytics from '../utils/analytics';
import { BRAND, COLORS, BACKGROUND, SECONDARY_ICON, } from '../utils/colors';
import { createNotificationListeners, getAndSetToken } from '../utils/firebase';
import { isLoggedOut, isLoggedIn } from '../utils/auth';
import SectionTitle from './baseComponents/sectionTitle';
import Carousel from '../utils/libraries/carousel';
import { STATS_SCREEN, AUTH_STACK, BOTTOM_TABS, } from '../utils/constants';
import MeditationCarousel from './baseComponents/meditationCarousel';
import BaseStyles from '../utils/baseStyles';
import OnboardingContainer from './onboarding/OnboardingContainer';
import Banners from './home/Banners';
import ShareStoaHomeSection from './share/ShareStoaHomeSection';
import ContinuePlayingSection from './home/ContinuePlayingSection';
import DailyRoutineManager from './routines/RoutineManager';
import { useProductMessages } from './messages/MessagesScreen';
import UserRoutinesModal from './routines/UserRoutinesModal';
import useRoutine from './routines/useRoutines';
import RoutineOptInFinishedModal from './routines/RoutineOptInFinishedModal';
const styles = StyleSheet.create({
    bg: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    content: {
        flex: 10,
    },
    contentContainer: {
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    topGradient: {
        top: 60,
        height: 20,
    },
    bottomGradient: {
        bottom: 0,
        height: 40,
    },
    menuNotification: {
        position: 'absolute',
        top: -1,
        left: -5,
    },
});
const HomeScreen = (props) => {
    const { useUpdateSubscriptionStatus } = PurchaseService();
    useUpdateSubscriptionStatus({
        user: props.user,
    });
    const [hasScrolledToHeader, setHasScrolledToHeader] = useState(false);
    const [day, setDay] = useState(moment().format('y-D'));
    const [nextMeditation, setNextMeditation] = useState(null);
    const [suggestedMeditations, setSuggestedMeditations] = useState();
    const [seenCount, setSeenCount] = useState(0);
    const { optIn, toggleOptIn, allRoutinesFinished, toggleFinishModal, } = useRoutine({ user: props.user });
    const { productMessages } = useProductMessages({ user: props.user });
    const menuAnim = useRef(new Animated.Value(20)).current;
    // TODO move out
    const handleMount = async () => {
        createNotificationListeners(props, handleNotification);
        const count = await AsyncStorage.getItem(RoutinesFeedbackKey);
        setSeenCount(parseInt(count || '0', 10));
        if (props.user.id && props.isConnected) {
            if (!props.user.lastFetchAt
                || moment(props.user.lastFetchAt).isBefore(moment().subtract(1, 'minutes'))) {
                props.userFetchAndUpdate(props.user);
                await props.fetchListenHistory(props.user, { limit: 10 });
                await props.fetchPlaylists(props.user, props.playlists);
                await props.recordFetch(props.user);
                props.updateAllPlaylistsDownloadStatus(props.playlists);
            }
        }
    };
    useEffect(() => {
        const getNextMeditationAsync = async () => {
            const meditation = await getNextMeditation(props.playlists, props.listenHistory);
            setNextMeditation(meditation);
        };
        getNextMeditationAsync();
    }, [Object.keys(props.playlists).length, props.listenHistory.length]);
    useEffect(() => {
        if (isLoggedOut(props.user)) {
            props.navigation.navigate(AUTH_STACK);
            return;
        }
        Analytics.screenHit && Analytics.screenHit('home_screen', 'HomeScreen');
        handleMount();
        getAndSetToken(props.user, updateUserToken);
        props.internetChecker(props.connection);
        // TODO: Need to find better way to get updated state of connection
        setTimeout(() => {
            if (props.isConnected) {
                props.uploadListens(props.user, props.meditationIds || []);
            }
        }, 1000);
    }, []);
    useEffect(() => {
        handleMount();
    }, [props.user.id, day]);
    // TODO: move when onboarding redesigned
    useEffect(() => {
        const handleTrackingPermission = async () => {
            const trackingStatus = await requestTrackingPermission();
            if (!(trackingStatus === 'authorized' || trackingStatus === 'unavailable')) {
                appsFlyer.anonymizeUser(true);
                Settings.setAdvertiserTrackingEnabled(true);
            }
        };
        handleTrackingPermission();
    }, []);
    useEffect(() => {
        const handleNewDay = (nextAppState) => {
            const newDay = moment().format('y-D');
            if (nextAppState === 'active' && newDay !== day) {
                setDay(newDay);
            }
        };
        AppState.addEventListener('change', handleNewDay);
        return () => {
            AppState.removeEventListener('change', handleNewDay);
        };
    }, []);
    useEffect(() => {
        const getSuggestedMeditations = async () => {
            const newSuggestedMeditations = await getSuggestedMeditationsFromOnboarding(props.playlists);
            setSuggestedMeditations(newSuggestedMeditations);
        };
        getSuggestedMeditations();
    }, [Object.keys(props.playlists).length]);
    const handleNotification = (data) => {
        if (data.type === 'quote' && data.id !== undefined) {
            props.navigation.navigate(BOTTOM_TABS.quotes.name, { quoteId: data.id });
            const quote = props.quotes[data.id];
            if (quote) {
                props.receiveQuote(quote);
            }
            return;
        }
        if (!nextMeditation)
            return;
        props.setAndGoToMeditation(nextMeditation, props.navigation);
        Analytics.event(Analytics.Events.GO_TO_NEXT_MEDITATION, {
            meditation_title: nextMeditation.title,
            meditation_id: nextMeditation.id,
            playlist_id: nextMeditation.playlist_id,
        });
    };
    const getTimedMeditationConfig = (isEvening) => (isEvening
        ? {
            title: 'Evening Routines',
            meditations: getEveningMeditations(props.playlists),
        }
        : {
            title: 'Morning Routines',
            meditations: getMorningMeditations(props.playlists),
        });
    const renderNextMeditation = () => {
        if (!nextMeditation)
            return null;
        return (React.createElement(MeditationHomeSection, { isConnected: props.isConnected, meditation: nextMeditation, playlist: nextMeditation ? props.playlists[nextMeditation.playlist_id] : null, navigation: props.navigation }));
    };
    const renderTimedMeditation = () => {
        if (nextMeditation?.playlist_id && INTRO_IDS.includes(nextMeditation?.playlist_id)) {
            return null;
        }
        const isEvening = new Date().getHours() > 12;
        const config = getTimedMeditationConfig(isEvening);
        return (React.createElement(MeditationCarousel, { meditations: config.meditations, playlists: props.playlists, navigation: props.navigation, deviceWidth: props.deviceDimensions.deviceWidth, title: config.title }));
    };
    const renderPlaylists = () => {
        const renderItem = ({ item, index }) => {
            const isCurrent = nextMeditation && nextMeditation.playlist_id === item.id;
            return (React.createElement(PlaylistCard, { description: isCurrent ? 'Current' : item.is_new ? 'New' : '', key: `${index}-${item.id}`, navigation: props.navigation, playlist: item, isTheory: true }));
        };
        return (React.createElement(View, { style: BaseStyles.homeSectionContainer },
            React.createElement(SectionTitle, { title: "Learn Stoic Theory" }),
            React.createElement(Carousel, { activeSlideAlignment: "start", data: props.homeScreenPlaylists, renderItem: renderItem, sliderWidth: props.deviceDimensions.deviceWidth, itemWidth: 263, containerCustomStyle: BaseStyles.homeCarousel })));
    };
    const renderQuote = () => (React.createElement(View, { style: BaseStyles.homeSectionContainer },
        React.createElement(SectionTitle, { title: "Quote" }),
        React.createElement(QuoteBox, null)));
    const renderDailyMeditation = () => {
        if (nextMeditation?.playlist_id && INTRO_IDS.includes(nextMeditation?.playlist_id)) {
            return null;
        }
        if (!props.dailyMeditation) {
            return null;
        }
        return (React.createElement(MeditationHomeSection, { isConnected: props.isConnected, meditation: props.dailyMeditation, playlist: props.dailyMeditation ? props.playlists[props.dailyMeditation.playlist_id] : null, navigation: props.navigation }));
    };
    const renderFavoriteOrMostRecent = () => {
        const config = {
            favorite: {
                title: 'Favorite Meditations',
                meditations: props.favoriteMeditations,
            },
            recent: {
                title: 'Listen Again',
                meditations: props.listenAgain,
            },
        };
        const configured = props.favoriteMeditations.length > 0 ? config.favorite : config.recent;
        return (React.createElement(MeditationCarousel, { meditations: configured.meditations, playlists: props.playlists, navigation: props.navigation, deviceWidth: props.deviceDimensions.deviceWidth, title: configured.title }));
    };
    const renderDownloadedMeditations = () => {
        const { downloadedMeditations } = props;
        if (!FLAGS.DOWNLOADED_MEDITATIONS_HOME)
            return null;
        return (React.createElement(MeditationCarousel, { meditations: downloadedMeditations, playlists: props.playlists, navigation: props.navigation, deviceWidth: props.deviceDimensions.deviceWidth, title: "Downloaded Meditations" }));
    };
    const renderMenu = () => (React.createElement(Animated.View, { style: {
            top: 0,
            left: 0,
            paddingLeft: menuAnim,
            paddingTop: s(30),
            position: 'absolute',
            zIndex: 20,
        } },
        React.createElement(TouchableOpacity, { onPress: () => {
                props.navigation.openDrawer();
                Analytics.event(Analytics.Events.MENU_TOUCH, { source: 'Home' });
            } },
            React.createElement(MenuIcon, { style: {
                    color: COLORS[SECONDARY_ICON][props.mode], height: 28, width: 28, zIndex: 10,
                } }),
            productMessages.length ? (React.createElement(View, { style: styles.menuNotification },
                React.createElement(MeditationIcon, { style: { height: 15, width: 15, color: COLORS[BRAND][props.mode] } }))) : null)));
    const renderStats = () => (React.createElement(Animated.View, { style: {
            top: 0,
            right: 0,
            paddingRight: menuAnim,
            paddingTop: s(30),
            position: 'absolute',
            zIndex: 10,
        } },
        React.createElement(TouchableOpacity, { onPress: () => {
                props.navigation.navigate(STATS_SCREEN);
                Analytics.event(Analytics.Events.STATS_TOUCH, { source: 'Home' });
            } },
            React.createElement(StatsIcon, { style: { color: COLORS[SECONDARY_ICON][props.mode], height: 28, width: 28 } }))));
    const handleScroll = (e) => {
        if (e.nativeEvent.contentOffset.y > TOP_BLANK_HEIGHT - 25 && !hasScrolledToHeader) {
            Animated.timing(menuAnim, {
                toValue: 5,
                duration: 1000,
                useNativeDriver: false,
            }).start();
            setHasScrolledToHeader(true);
        }
        else if (e.nativeEvent.contentOffset.y < TOP_BLANK_HEIGHT - 25 && hasScrolledToHeader) {
            Animated.timing(menuAnim, {
                toValue: 20,
                duration: 1000,
                useNativeDriver: false,
            }).start();
            setHasScrolledToHeader(false);
        }
    };
    if (isLoggedIn(props.user)) {
        return (React.createElement(View, { style: [styles.bg, { backgroundColor: COLORS[BACKGROUND][props.mode] }], testID: "homescreen-container" },
            renderMenu(),
            renderStats(),
            React.createElement(ScrollView, { style: styles.content, contentContainerStyle: styles.contentContainer, onScroll: handleScroll, scrollEventThrottle: 3 },
                React.createElement(View, { style: { height: TOP_BLANK_HEIGHT } }),
                React.createElement(ContinuePlayingSection, { navigation: props.navigation }),
                isBeta(props.user) ? (React.createElement(React.Fragment, null,
                    React.createElement(Banners, { mode: props.mode, navigation: props.navigation, user: props.user }),
                    React.createElement(DailyRoutineManager, null),
                    renderFavoriteOrMostRecent(),
                    renderDownloadedMeditations())) : (React.createElement(React.Fragment, null,
                    renderNextMeditation(),
                    React.createElement(Banners, { mode: props.mode, navigation: props.navigation, user: props.user }),
                    React.createElement(ShareStoaHomeSection, { navigation: props.navigation }),
                    renderFavoriteOrMostRecent(),
                    suggestedMeditations ? (React.createElement(MeditationCarousel, { meditations: suggestedMeditations, playlists: props.playlists, navigation: props.navigation, deviceWidth: props.deviceDimensions.deviceWidth, title: "Suggested Meditations" })) : suggestedMeditations === null ? renderTimedMeditation() : null,
                    renderDailyMeditation(),
                    renderPlaylists(),
                    React.createElement(MeditationCarousel, { meditations: props.conversations, playlists: props.playlists, navigation: props.navigation, deviceWidth: props.deviceDimensions.deviceWidth, title: "Recent Conversations" }),
                    renderDownloadedMeditations(),
                    renderQuote()))),
            React.createElement(RandomPopups, { navigation: props.navigation }),
            React.createElement(OnboardingContainer, { mode: props.mode, navigation: props.navigation, meditation: nextMeditation }),
            React.createElement(UserRoutinesModal, { visible: optIn, toggleOptIn: toggleOptIn, user: props.user, handleUpdateUser: props.updateUser }),
            React.createElement(RoutineOptInFinishedModal, { visible: allRoutinesFinished && seenCount < 3, user: props.user, toggleFinishModal: toggleFinishModal })));
    }
    return (React.createElement(View, { style: [BaseStyles.flexCol, { backgroundColor: COLORS[BACKGROUND][props.mode] }], testID: "homescreen-container" },
        React.createElement(ActivityIndicator, { color: COLORS[BRAND][props.mode], size: "large" })));
};
const mapStateToProps = (state) => {
    const listenHistory = stripPersist(state.listenHistory);
    const playlists = stripPersist(state.playlists);
    const playlistsArr = playlistsToSortedArray(playlists);
    const homeScreenPlaylists = getHomeScreenPlaylists(playlists);
    const conversations = getRecommendedConversations(playlists);
    const listenAgain = getListenAgain(playlists);
    const favoriteMeditations = getFavoriteMeditations(playlists);
    const downloadedMeditations = getDownloadedMeditations(playlists);
    const { deviceDimensions } = state.dimensions;
    const dailyMeditation = getDailyMeditation(playlists);
    const quotes = stripPersist(state.quotes);
    return {
        connection: state.connection,
        conversations,
        dailyMeditation,
        deviceDimensions,
        downloadedMeditations,
        favoriteMeditations,
        homeScreenPlaylists,
        isConnected: state.connection.isConnected,
        listenAgain,
        listenHistory,
        meditationIds: state.listens.meditationIds,
        mode: stripPersist(state.colors).mode,
        quotes,
        playlists,
        playlistsArr,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchListenHistory: (user, params) => dispatch(fetchListenHistory(user, params)),
    fetchPlaylists: (user, playlists) => dispatch(fetchPlaylists(user, playlists)),
    internetChecker: (connection) => dispatch(internetChecker(connection)),
    receiveQuote: (quote) => dispatch(receiveQuote(quote)),
    recordFetch: (user) => dispatch(recordFetch(user)),
    setAndGoToMeditation: (meditation, navigation) => dispatch(setAndGoToMeditation(meditation, navigation)),
    uploadListens: (user, meditationIds) => dispatch(uploadListens(user, meditationIds)),
    userFetchAndUpdate: (user) => {
        dispatch(userFetchAndUpdate(user));
    },
    updateAllPlaylistsDownloadStatus: (playlists) => dispatch(updateAllPlaylistsDownloadStatus(playlists)),
    updateUser: (user) => dispatch(updateUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
