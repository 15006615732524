// @ts-nocheck
import React from 'react';
import { Linking, ScrollView, StyleSheet, Switch, TouchableOpacity, View, SafeAreaView, Alert, } from 'react-native';
import { connect } from 'react-redux';
import Clipboard from '@react-native-clipboard/clipboard';
import FastImage from '../utils/libraries/fastImage';
import TextButton from './baseComponents/textButton';
import { hitSlop, stripPersist } from '../utils';
import { toggleColors } from '../actions/colorsActions';
import { SUBSCRIPTION_SCREEN_HOME, ACCOUNT_SCREEN, STATS_SCREEN, ABOUT_SCREEN, LIGHT, DARK, ORIENTATION, CONTACT_EMAIL, MESSAGES_SCREEN, } from '../utils/constants';
import { COLORS, BACKGROUND, PRIMARY_TEXT, SECONDARY_TEXT, SECONDARY_ICON, SECONDARY_BACKGROUND, BLUE, BLACK, BRAND, } from '../utils/colors';
import { SubscriptionBackground } from '../assets';
import BaseStyles from '../utils/baseStyles';
import Analytics from '../utils/analytics';
import { getDeviceOrientation } from '../actions/dimensionsActions';
import { CloseIcon, MeditationIcon } from '../assets/svg';
import BaseText from './baseComponents/baseText';
import { Fonts } from '../utils/fonts';
import { useProductMessages } from './messages/MessagesScreen';
const styles = StyleSheet.create({
    blankItem: {
        marginLeft: 20,
        width: 10,
    },
    container: {
        flex: 1,
    },
    headerContainer: {
        flex: 2.5,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: '6%',
    },
    closeContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        paddingLeft: 15,
    },
    sectionBreak: {
        flex: 2,
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemsContainer: {
        paddingTop: 15,
        paddingHorizontal: 15,
        flex: 8,
    },
    itemText: {
        fontSize: Fonts.size.xl,
        paddingLeft: 15,
    },
    itemIcon: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        padding: 10,
    },
    subscriptionContainer: {
        flex: 4,
        paddingLeft: 15,
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingBottom: 10,
        backgroundColor: BLUE,
    },
    subscriptionTitleText: {
        fontWeight: 'bold',
        fontSize: Fonts.size.xxxl,
        paddingTop: 10,
    },
    subscriptionBodyText: {
        fontSize: Fonts.size.s,
        fontWeight: '400',
        paddingRight: 15,
        lineHeight: 20,
    },
    subscriptionButtonContainer: {
        paddingRight: 15,
    },
    subscriptionButton: {
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.8,
        backgroundColor: BLACK,
    },
});
const SUBSCRIPTION_TEXT = {
    true: {
        body1: 'You are Subscribed to Stoa.',
    },
    false: {
        body1: 'Subscribe to Stoa to unlock all the content.',
    },
};
const DrawerMenu = (props) => {
    const { productMessages } = useProductMessages({ user: props.user });
    const renderItem = (title, onPress) => (React.createElement(React.Fragment, null,
        React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.itemContainer, onPress: onPress },
            React.createElement(BaseText, { style: [styles.itemText, { color: COLORS[PRIMARY_TEXT][props.mode] }] }, title),
            title === 'Messages' && productMessages?.length ? (React.createElement(View, { style: styles.itemIcon },
                React.createElement(MeditationIcon, { style: { height: 15, width: 15, color: COLORS[BRAND][props.mode] } }))) : null)));
    const renderSubscriptionSection = () => (React.createElement(FastImage, { source: SubscriptionBackground, style: styles.subscriptionContainer },
        React.createElement(View, { style: styles.overlay }),
        React.createElement(View, null,
            React.createElement(BaseText, { style: [styles.subscriptionTitleText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, "Account"),
            React.createElement(BaseText, { style: [styles.subscriptionBodyText, { color: COLORS[SECONDARY_TEXT][props.mode] }] }, SUBSCRIPTION_TEXT[!!props.user.is_subscribed].body1)),
        props.user.is_subscribed ? null : (React.createElement(View, { style: styles.subscriptionButtonContainer },
            React.createElement(TextButton, { buttonStyle: styles.subscriptionButton, title: "Learn More", onPress: () => props.navigation.navigate(SUBSCRIPTION_SCREEN_HOME) })))));
    const orientation = getDeviceOrientation();
    return (React.createElement(SafeAreaView, { style: [
            styles.container,
            {
                backgroundColor: COLORS[BACKGROUND][props.mode],
            },
        ] },
        React.createElement(ScrollView, { contentContainerStyle: { height: orientation === ORIENTATION.PORTRAIT ? '100%' : 'auto' } },
            React.createElement(View, { style: styles.headerContainer },
                React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => props.navigation.closeDrawer(), style: styles.closeContainer },
                    React.createElement(CloseIcon, { style: { color: COLORS[SECONDARY_ICON][props.mode], height: 16, width: 16 } })),
                React.createElement(View, { style: BaseStyles.flexRow },
                    React.createElement(BaseText, { style: { color: COLORS[PRIMARY_TEXT][props.mode], marginRight: 8 } }, "Dark Mode"),
                    React.createElement(Switch, { trackColor: { false: COLORS[BACKGROUND][DARK], true: COLORS[BACKGROUND][LIGHT] }, ios_backgroundColor: COLORS[SECONDARY_BACKGROUND][props.mode], onValueChange: () => {
                            Analytics.event('Toggle Colors', {
                                new_mode: props.mode === DARK ? LIGHT : DARK,
                            });
                            props.toggleColors();
                        }, value: props.mode === DARK }),
                    React.createElement(View, { style: styles.blankItem }))),
            renderSubscriptionSection(),
            React.createElement(View, { style: styles.itemsContainer },
                renderItem('Messages', () => {
                    props.navigation.navigate(MESSAGES_SCREEN);
                    Analytics.event(Analytics.Events.DRAWER_MESSAGES_TOUCH);
                }),
                renderItem('Stats', () => {
                    props.navigation.navigate(STATS_SCREEN);
                    Analytics.event(Analytics.Events.DRAWER_STATS_TOUCH);
                }),
                renderItem('Account', () => {
                    props.navigation.navigate(ACCOUNT_SCREEN);
                    Analytics.event(Analytics.Events.DRAWER_ACCOUNT_TOUCH);
                }),
                renderItem('Contact', () => {
                    Linking.openURL(`mailto:${CONTACT_EMAIL}`).catch(() => {
                        Alert.alert('Unable to open email client.', `Contact us at ${CONTACT_EMAIL}.`, [
                            {
                                text: 'Copy Email',
                                onPress: () => Clipboard.setString(CONTACT_EMAIL),
                            },
                            { text: 'Close', onPress: () => { }, style: 'cancel' },
                        ], { cancelable: false });
                    });
                    Analytics.event(Analytics.Events.DRAWER_CONTACT_TOUCH);
                }),
                renderItem('About', () => {
                    props.navigation.navigate(ABOUT_SCREEN);
                    Analytics.event(Analytics.Events.DRAWER_ABOUT_TOUCH);
                })),
            React.createElement(View, { style: styles.sectionBreak }))));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    toggleColors: () => dispatch(toggleColors()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DrawerMenu);
