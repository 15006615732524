export const BannerHistoryKey = (userId) => `${userId}-banner-history`;
export const ProductMessagesKey = (userId) => `${userId}-product-messages`;
export const encryptedEntriesKey = (userId) => `${userId}-entries-key`;
export const meditationPositionKey = (userId, meditationId) => (`${userId}-meditation-position-${meditationId}`);
export const MeditationReminderAtKey = 'meditation_reminder_at';
export const MeditationGoalIdsKey = 'meditation_goal_ids';
export const ReferralLinkURLKey = 'referral_link_url';
export const LastRatingAskKey = 'last-rating-ask';
export const LastSubscriptionAskKey = 'last-subscription-ask';
export const FCMTokenKey = 'fcmToken';
export const ShareLinkKey = 'ShareLinkKey';
export const ContinuePlayingKey = 'ContinuePlayingKey';
export const QuizIAnswerKey = 'QuizIAnswerKey';
export const QuizIIAnswerKey = 'QuizIIAnswerKey';
export const SubscriptionKey = 'SubscriptionKey';
export const PartialRoutinesKey = 'PartialRoutinesKey';
export const RoutinesFeedbackKey = 'RoutinesFeedbackKey';
