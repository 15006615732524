import React from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, TouchableOpacity, View, ActivityIndicator, } from 'react-native';
import BaseStyles from '@utils/baseStyles';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { COLORS, BRAND, SECONDARY_TEXT, LIGHT_GRAY, } from '../../utils/colors';
import { hitSlop, stripPersist } from '../../utils';
import { BUTTON_RADIUS } from '../../utils/styles';
import BaseText from './baseText';
import BaseOverlay from './BaseOverlay';
const styles = StyleSheet.create({
    button: {
        borderRadius: BUTTON_RADIUS,
        height: 50,
        width: 200,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerStyle: {
        paddingRight: 10,
        paddingLeft: 10,
    },
    overlay: {
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
        borderRadius: IMAGE_BORDER_RADIUS,
    },
});
const defaultProps = {
    isDisabled: false,
    onPress: () => { },
    buttonStyle: {},
    testID: undefined,
    isLoading: false,
    buttonTextStyle: {},
    overlay: false,
};
const TextButton = (props) => {
    const mode = useSelector((state) => stripPersist(state.colors).mode);
    const titleEl = typeof props.title === 'string' ? (React.createElement(BaseText, { style: [
            BaseStyles.buttonText,
            { color: COLORS[SECONDARY_TEXT][mode] },
            props.buttonTextStyle,
        ] }, props.title)) : (props.title);
    return (React.createElement(TouchableOpacity, { disabled: props.isDisabled, hitSlop: hitSlop, onPress: props.onPress, style: [styles.button, { backgroundColor: COLORS[BRAND][mode] }, props.buttonStyle], testID: props.testID },
        props.overlay ? React.createElement(BaseOverlay, { style: styles.overlay }) : null,
        props.isLoading ? (React.createElement(ActivityIndicator, { color: COLORS[SECONDARY_TEXT][mode], size: "small" })) : (React.createElement(View, { style: [styles.button, props.buttonStyle] }, titleEl))));
};
TextButton.defaultProps = defaultProps;
export default TextButton;
