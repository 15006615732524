import React from 'react';
import BaseText from '@components/baseComponents/baseText';
import { BRAND, COLORS, DARK_BORDER, PRIMARY_TEXT, QUOTE_BACKGROUND, QUOTE_TEXT, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { SCREEN_HEIGHT } from '@utils/styles';
import { Share, StyleSheet, View } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { vs } from 'react-native-size-matters';
import { ShareIcon, TrashIcon } from '@assets/svg';
import { hitSlop } from '@utils/index';
import { CHAPTER_SCREEN } from '@utils/constants';
const styles = StyleSheet.create({
    quotesContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        marginTop: 20,
        maxHeight: Math.floor(SCREEN_HEIGHT / 2),
    },
    quoteText: {
        textAlign: 'center',
        fontWeight: '400',
        fontSize: Fonts.size.m,
        lineHeight: vs(16),
    },
    bookText: {
        textAlign: 'center',
        fontSize: Fonts.size.s,
    },
    authorText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: Fonts.size.s,
    },
    hyphenText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    quotesContentContainer: {
        padding: 20,
    },
    quotesActionsContainer: {
        height: 60,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
});
const Highlight = ({ highlight, mode, navigation, handleDeleteHighlight, }) => {
    const handleShare = () => {
        Share.share({
            message: `${highlight.text}\n\n\u2014 ${highlight.author_name}\n${highlight.book_title}\n\nfrom stoameditation.com`,
        }).catch((e) => console.log(e));
    };
    const handleDelete = () => {
        handleDeleteHighlight(highlight.id);
    };
    const handleNav = () => {
        navigation.navigate(CHAPTER_SCREEN, {
            chapterId: highlight.chapter_id,
            bookId: highlight.book_id,
            highlight: highlight.text,
        });
    };
    return (React.createElement(View, { key: highlight.id.toString(), style: [styles.quotesContainer, { backgroundColor: COLORS[QUOTE_BACKGROUND][mode] }] },
        React.createElement(ScrollView, { contentContainerStyle: styles.quotesContentContainer, showsVerticalScrollIndicator: false },
            React.createElement(BaseText, { style: [styles.quoteText, { color: COLORS[QUOTE_TEXT][mode] }] }, highlight.text),
            React.createElement(BaseText, { style: [styles.hyphenText, { color: COLORS[BRAND][mode] }] }, '\u2014'),
            React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[PRIMARY_TEXT][mode] }] }, highlight.author_name),
            React.createElement(BaseText, { style: [styles.bookText, { color: COLORS[PRIMARY_TEXT][mode] }] }, highlight.book_title)),
        React.createElement(View, { style: [styles.quotesActionsContainer, { borderTopColor: COLORS[DARK_BORDER][mode] }] },
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: handleNav },
                React.createElement(BaseText, { style: { color: COLORS[BRAND][mode] } }, "Read")),
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: handleDelete },
                React.createElement(TrashIcon, { style: { color: COLORS[BRAND][mode], height: 20, width: 20 } })),
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: handleShare },
                React.createElement(ShareIcon, { style: { color: COLORS[BRAND][mode], height: 20, width: 20 } })))));
};
export default Highlight;
