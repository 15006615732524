import { merge } from 'lodash';
import isDarkMode from '../utils/libraries/darkMode';
import { TOGGLE_COLORS, RECEIVE_DARK_MODE, RECEIVE_LIGHT_MODE } from '../actions/colorsActions';
import { LIGHT, DARK } from '../utils/constants';
import { coerceStateToDataFormat } from '../utils';
const defaultState = {
    data: {
        mode: isDarkMode() ? DARK : LIGHT,
    },
};
export const ColorsReducer = (state = defaultState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state, defaultState));
    switch (action.type) {
        case TOGGLE_COLORS:
            return {
                data: {
                    mode: newState?.data?.mode === LIGHT ? DARK : LIGHT,
                },
            };
        case RECEIVE_DARK_MODE:
            return {
                data: {
                    mode: DARK,
                },
            };
        case RECEIVE_LIGHT_MODE:
            return {
                data: {
                    mode: LIGHT,
                },
            };
        default:
            return newState;
    }
};
