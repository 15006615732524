import React from 'react';
import TextButton from '@components/baseComponents/textButton';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { COLORS, SECONDARY_TEXT, WHITE, } from '@utils/colors';
import BaseText from '@components/baseComponents/baseText';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { hitSlop } from '@utils/index';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    dotsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dotContainer: {
        paddingTop: 20,
        paddingBottom: 10,
        paddingHorizontal: 10,
    },
    dot: {
        backgroundColor: WHITE,
        opacity: 0.3,
        height: 8,
        width: 8,
        borderRadius: 4,
    },
    dotProgressContainer: {
        paddingTop: 20,
        paddingBottom: 10,
        position: 'absolute',
    },
    dotProgress: {
        position: 'relative',
        top: 0,
        left: -41,
        height: 8,
        width: 8,
        backgroundColor: WHITE,
        borderRadius: 8,
    },
    container: {
        position: 'absolute',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 25,
        width: '100%',
    },
    skip: {
        padding: 10,
    },
    skipContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 10,
    },
    skipText: {
        textDecorationLine: 'underline',
    },
});
const ContinueFooter = ({ index, nextScreenName, skipScreenName, mode, }) => {
    const navigation = useNavigation();
    let dotWidth;
    switch (index) {
        case 1:
            dotWidth = 18;
            break;
        case 2:
            dotWidth = 36;
            break;
        case 3:
            dotWidth = 46;
            break;
        case 4:
            dotWidth = 64;
            break;
        case 5:
            dotWidth = 72;
            break;
        case 6:
            dotWidth = 90;
            break;
        case 7:
            dotWidth = 100;
            break;
        default:
            dotWidth = 8;
    }
    const dotLeft = -47 + (dotWidth / 2);
    return (React.createElement(View, { style: styles.container },
        React.createElement(View, { style: styles.dotsContainer },
            React.createElement(View, { style: styles.dotProgressContainer },
                React.createElement(View, { style: [styles.dotProgress, { width: dotWidth, left: dotLeft }] })),
            React.createElement(View, { style: styles.dotContainer },
                React.createElement(View, { style: styles.dot })),
            React.createElement(View, { style: styles.dotContainer },
                React.createElement(View, { style: styles.dot })),
            React.createElement(View, { style: styles.dotContainer },
                React.createElement(View, { style: styles.dot })),
            React.createElement(View, { style: styles.dotContainer },
                React.createElement(View, { style: styles.dot }))),
        skipScreenName && mode ? (React.createElement(View, { style: styles.skipContainer },
            React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: () => navigation.navigate(skipScreenName) },
                React.createElement(View, { style: styles.skip },
                    React.createElement(BaseText, { style: [styles.skipText, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Skip"))))) : (React.createElement(View, { style: styles.buttonContainer },
            React.createElement(TextButton, { onPress: () => navigation.navigate(nextScreenName), title: "Continue", isLoading: false })))));
};
export default ContinueFooter;
