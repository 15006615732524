import { LIGHT, DARK } from './constants';
export const WHITE_SMOKE = '#f3f3f3';
// LIGHT (mostly)
export const LIGHT_GREEN = '#E3FFF0';
export const GREEN = '#07C7B1';
export const GREEN_I = '#45C1A6';
export const JUNGLE_GREEN = '#0e8073';
export const DARK_GREEN = '#126359';
export const LIGHT_BLACK = '#242424';
export const WHITE = '#ffffff';
export const BLUE = '#09252E';
export const DARK_BLUE = '#0A1A1F';
export const DARKER_BLUE = '#011116';
export const DARK_GRAY = '#E5E5E5';
export const GRAY = '#667073';
export const LIGHT_GRAY = '#EEEEEE';
export const LIGHTEST_GRAY = '#EEEDED';
export const TRANSPARENT_GRAY = 'rgb(255, 255, 255, 0.1)';
export const MODAL_BG_GRAY = 'rgb(255, 255, 255, 0.5)';
export const MEDIUM_GRAY = '#A8A8A8';
export const WHITE_GREEN = '#EFFBF8';
export const WARNING_RED = '#CC3333';
// Dark (mostly)
export const BLACKER_GREEN = '#011116';
export const BLACKISH_GREEN = '#04181F';
export const BLACK_GREEN = '#0A1A1F';
export const GREEN_II = '#07C7B1';
export const BLACK = '#000000';
export const TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.26)';
export const MODAL_BG_BLACK = 'rgba(0, 0, 0, 0.5)';
export const TRANSPARENT = 'transparent';
// COLORS key names
export const BACKGROUND = 'BACKGROUND';
export const TRANSPARENT_BACKGROUND = 'TRANSPARENT_BACKGROUND';
export const SECONDARY_BACKGROUND = 'SECONDARY_BACKGROUND';
export const SECONDARY_BORDER = 'SECONDARY_BORDER';
export const QUOTE_BACKGROUND = 'QUOTE_BACKGROUND';
export const MODAL_QUOTE_BACKGROUND = 'MODAL_QUOTE_BACKGROUND';
export const ENTRY_BACKGROUND = 'ENTRY_BACKGROUND';
export const BACKDROP = 'BACKDROP';
export const LIGHT_BORDER = 'LIGHT_BORDER';
export const DARK_BORDER = 'DARK_BORDER';
export const DARK_BACKGROUND = 'DARK_BACKGROUND';
export const GRAYS = 'GRAYS';
export const PRIMARY_TEXT = 'PRIMARY_TEXT';
export const SECONDARY_TEXT = 'SECONDARY_TEXT';
export const SUBTITLE_TEXT = 'SUBTITLE_TEXT';
export const QUOTE_TEXT = 'QUOTE_TEXT';
export const MODAL_TEXT = 'MODAL_TEXT';
export const MODAL_QUOTE_TEXT = 'MODAL_QUOTE_TEXT';
export const BRAND = 'BRAND';
export const MODAL_BACKGROUND = 'MODAL_BACKGROUND';
export const TERTIARY_BACKGROUND = 'TERTIARY_BACKGROUND';
export const TERTIARY_TEXT = 'TERTIARY_TEXT';
export const SECONDARY_BUTTON = 'SECONDARY_BUTTON';
export const SECONDARY_ICON = 'SECONDARY_ICON';
export const TERTIARY_ICON = 'TERTIARY_ICON';
export const BUTTON_BACKGROUND = 'BUTTON_BACKGROUND';
export const ACTIVE_BUTTON = 'ACTIVE_BUTTON';
export const INACTIVE_TEXT = 'INACTIVE_TEXT';
export const FOOTER_GRAY = 'FOOTER_GRAY';
export const QUOTE_FILTER = 'QUOTE_FILTER';
export const BANNER_BACKGROUND = 'BANNER_BACKGROUND';
export const BANNER_TEXT = 'BANNER_TEXT';
export const BANNER_GRAY = 'BANNER_GRAY';
/*
  [NAME]: {
    [LIGHT]: ,
    [DARK]: ,
  }
*/
export const COLORS = {
    [BRAND]: {
        [LIGHT]: GREEN_I,
        [DARK]: GREEN_II,
    },
    [PRIMARY_TEXT]: {
        [LIGHT]: BLACK,
        [DARK]: WHITE,
    },
    [SECONDARY_TEXT]: {
        [LIGHT]: WHITE,
        [DARK]: WHITE,
    },
    [TERTIARY_TEXT]: {
        [LIGHT]: GRAY,
        [DARK]: WHITE,
    },
    [SUBTITLE_TEXT]: {
        [LIGHT]: GRAY,
        [DARK]: WHITE,
    },
    [FOOTER_GRAY]: {
        [LIGHT]: GRAY,
        [DARK]: LIGHT_GRAY,
    },
    [BACKGROUND]: {
        [LIGHT]: WHITE,
        [DARK]: BLACKER_GREEN,
    },
    [TRANSPARENT_BACKGROUND]: {
        [LIGHT]: TRANSPARENT_BLACK,
        [DARK]: TRANSPARENT_BLACK,
    },
    [SECONDARY_BACKGROUND]: {
        [LIGHT]: WHITE,
        [DARK]: BLUE,
    },
    [QUOTE_FILTER]: {
        [LIGHT]: TRANSPARENT_GRAY,
        [DARK]: BLACKISH_GREEN,
    },
    [MODAL_BACKGROUND]: {
        [LIGHT]: WHITE,
        [DARK]: BLACKISH_GREEN,
    },
    [BANNER_BACKGROUND]: {
        [LIGHT]: BLACKISH_GREEN,
        [DARK]: BLUE,
    },
    [BANNER_TEXT]: {
        [LIGHT]: WHITE,
        [DARK]: LIGHT_GRAY,
    },
    [MODAL_TEXT]: {
        [LIGHT]: GRAY,
        [DARK]: MEDIUM_GRAY,
    },
    [MODAL_QUOTE_TEXT]: {
        [LIGHT]: BLACK,
        [DARK]: WHITE,
    },
    [QUOTE_TEXT]: {
        [LIGHT]: LIGHT_BLACK,
        [DARK]: LIGHT_GRAY,
    },
    [QUOTE_BACKGROUND]: {
        [LIGHT]: LIGHT_GRAY,
        [DARK]: BLUE,
    },
    [ENTRY_BACKGROUND]: {
        [LIGHT]: TRANSPARENT_GRAY,
        [DARK]: BLACK_GREEN,
    },
    [BACKDROP]: {
        [LIGHT]: MODAL_BG_GRAY,
        [DARK]: MODAL_BG_BLACK,
    },
    [SECONDARY_BORDER]: {
        [LIGHT]: LIGHTEST_GRAY,
        [DARK]: BLUE,
    },
    [SECONDARY_BUTTON]: {
        [LIGHT]: WHITE_GREEN,
        [DARK]: WHITE_GREEN,
    },
    [SECONDARY_ICON]: {
        [LIGHT]: BLACK,
        [DARK]: WHITE,
    },
    [TERTIARY_ICON]: {
        [LIGHT]: WHITE,
        [DARK]: WHITE,
    },
    [TERTIARY_BACKGROUND]: {
        [LIGHT]: WHITE,
        [DARK]: TRANSPARENT,
    },
    [DARK_BORDER]: {
        [LIGHT]: DARK_GRAY,
        [DARK]: DARK_GRAY,
    },
    [LIGHT_BORDER]: {
        [LIGHT]: LIGHT_GRAY,
        [DARK]: BLACK_GREEN,
    },
    [BUTTON_BACKGROUND]: {
        [LIGHT]: LIGHT_GREEN,
        [DARK]: BLACKISH_GREEN,
    },
    [ACTIVE_BUTTON]: {
        [LIGHT]: GREEN_I,
        [DARK]: BLUE,
    },
    [INACTIVE_TEXT]: {
        [LIGHT]: GREEN_I,
        [DARK]: MEDIUM_GRAY,
    },
    [GRAYS]: {
        [LIGHT]: MEDIUM_GRAY,
        [DARK]: GRAY,
    },
    [MODAL_QUOTE_BACKGROUND]: {
        [LIGHT]: DARK_GRAY,
        [DARK]: BLACKER_GREEN,
    },
    [DARK_BACKGROUND]: {
        [LIGHT]: BLUE,
        [DARK]: BLACKER_GREEN,
    },
    [BANNER_GRAY]: {
        [LIGHT]: WHITE_SMOKE,
        [DARK]: WHITE_SMOKE,
    },
};
