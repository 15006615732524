import React from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import { getBrand } from 'react-native-device-info';
const styles = StyleSheet.create({
    baseStyles: {
        fontFamily: Platform.OS === 'web'
            ? ''
            : getBrand() === 'OnePlus'
                ? 'Roboto-Medium'
                : 'CircularStd-Medium',
    },
});
const defaultProps = {
    style: {},
    numberOfLines: 100,
    testID: '',
    selectable: false,
};
const BaseText = (props) => {
    return (React.createElement(Text, { ...props, style: [styles.baseStyles, props.style] }, props.children));
};
BaseText.defaultProps = defaultProps;
export default BaseText;
