import { compact, startCase, toLower, isEmpty, } from 'lodash';
// Persisted reducers are nested under data { _persist: ..., data: ...}
export const stripPersist = (obj) => (obj && obj.data ? obj.data : {});
// persisted reducers are being reformatted. Currently coercing into data to manage the rollout
export const coerceStateToDataFormat = (state, defaultState = {}) => {
    if (!state.data) {
        return defaultState;
    }
    return state;
};
export const secondsToStr = (time) => {
    const hours = Math.floor(time / 60);
    const seconds = time % 60;
    return `${hours}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
export const hitSlop = {
    top: 10,
    bottom: 10,
    right: 10,
    left: 10,
};
export const bigSlop = {
    top: 20,
    bottom: 20,
    right: 20,
    left: 20,
};
export const formatAuthorNameFromQuote = (authors, quote) => {
    if (!quote || !authors)
        return '';
    const authorName = authors[quote.author_id] ? authors[quote.author_id].name : '';
    return startCase(toLower(authorName));
};
export const getAuthorName = (authors, id) => {
    if (!id || !authors)
        return '';
    const authorName = authors[id] ? authors[id].name : '';
    return startCase(toLower(authorName));
};
export function attachConfig(config, context) {
    Object.keys(config).forEach((key) => {
        context[key] = config[key].bind(context);
    });
}
export const getRubyTimezoneOffset = () => (new Date().getTimezoneOffset() / 60) * -1 // ruby timezone offset flips +/-
;
export const idObjToArr = (obj) => {
    if (isEmpty(obj))
        return [];
    return compact(Object.keys(obj).map((id) => obj[id]));
};
export const arrToIdObj = (arr) => {
    if (isEmpty(arr))
        return {};
    const obj = {};
    arr.forEach((el) => obj[el.id] = el);
    return obj;
};
export const DATE_AVAILABLE_AT_FMT = 'YYYY-MM-D';
