import { merge } from 'lodash';
import { RECEIVE_AUTHORS } from '../actions/authorActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {};
export const AuthorsReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case RECEIVE_AUTHORS:
            return { data: action.authors };
        default:
            return newState;
    }
};
