import React, { Component } from 'react';
import { Modal, View, StyleSheet, Pressable, } from 'react-native';
import { connect } from 'react-redux';
import Analytics from '@utils/analytics';
import { downloadPlaylistAndUpdate, markPlaylistAsListened, markPlaylistAsUnlistened, removePlaylistDownloadAndUpdate, } from '@actions/playlistsActions';
import { TrashIcon, DownloadIcon, CheckMarkIcon } from '../../assets/svg';
import { stripPersist } from '../../utils';
import { LIGHT_GRAY, QUOTE_BACKGROUND, COLORS, BRAND, GRAYS, } from '../../utils/colors';
import TextButton from './textButton';
import { MODAL_RADIUS } from '../../utils/styles';
import BaseStyles from '../../utils/baseStyles';
import { Row } from './meditationModal';
const styles = StyleSheet.create({
    container: {
        zIndex: 99,
        position: 'absolute',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        top: 0,
        left: 0,
        flex: 1,
    },
    modalContainer: {
        backgroundColor: LIGHT_GRAY,
        marginTop: 120,
        borderRadius: MODAL_RADIUS,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 70,
        padding: 20,
        marginRight: 15,
        marginLeft: 15,
    },
    buttonRow: {
        flex: 2,
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: 24,
        padding: 24,
    },
    iconContainer: {
        flex: 1,
    },
});
class PlaylistModal extends Component {
    handleAction = async (action) => {
        await action(this.props.playlist);
    };
    renderMarkAsListened = () => {
        const config = {
            listen: {
                text: 'Mark as Listened',
                icon: (React.createElement(CheckMarkIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    this.props.markPlaylistAsListened(this.props.user, this.props.playlist);
                    Analytics.event(Analytics.Events.MARK_AS_LISTENED_PLAYLIST, {
                        title: this.props.playlist.title,
                        id: this.props.playlist.id,
                        location: 'PlaylistModal',
                    });
                },
            },
            unlisten: {
                text: 'Mark as Unlistened',
                icon: (React.createElement(CheckMarkIcon, { style: { color: COLORS[GRAYS][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    this.props.markPlaylistAsUnlistened(this.props.user, this.props.playlist);
                    Analytics.event(Analytics.Events.MARK_AS_UNLISTENED_PLAYLIST, {
                        title: this.props.playlist.title,
                        id: this.props.playlist.id,
                        location: 'PlaylistModal',
                    });
                },
            },
        };
        const configured = this.props.playlist.is_listened ? config.unlisten : config.listen;
        return (React.createElement(Row, { text: configured.text, onPress: configured.onPress, mode: this.props.mode, icon: configured.icon }));
    };
    renderDownload = () => {
        const config = {
            download: {
                text: 'Download',
                icon: (React.createElement(DownloadIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    this.handleAction(this.props.downloadPlaylistAndUpdate);
                    Analytics.event(Analytics.Events.DOWNLOAD_PLAYLIST, {
                        title: this.props.playlist.title,
                        id: this.props.playlist.id,
                        location: 'PlaylistModal',
                    });
                },
            },
            remove: {
                text: 'Remove',
                icon: (React.createElement(TrashIcon, { style: { color: COLORS[BRAND][this.props.mode], width: 20, height: 20 } })),
                onPress: () => {
                    this.handleAction(this.props.removePlaylistDownloadAndUpdate);
                    Analytics.event(Analytics.Events.REMOVE_PLAYLIST, {
                        title: this.props.playlist.title,
                        id: this.props.playlist.id,
                        location: 'PlaylistModal',
                    });
                },
            },
        };
        const configured = this.props.playlist.is_downloaded ? config.remove : config.download;
        return (React.createElement(Row, { text: configured.text, onPress: configured.onPress, mode: this.props.mode, icon: configured.icon }));
    };
    renderModal = () => (React.createElement(View, { style: [
            styles.modalContainer,
            { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
        ] },
        React.createElement(View, null, this.renderMarkAsListened()),
        React.createElement(View, null, this.renderDownload()),
        React.createElement(View, { style: styles.buttonRow },
            React.createElement(TextButton, { title: "Close", onPress: this.props.closeModal }))));
    render() {
        return (React.createElement(Modal, { visible: !!this.props.isOpen, transparent: true, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
            this.renderModal(),
            React.createElement(Pressable, { style: [BaseStyles.overlay], onPress: this.props.closeModal })));
    }
}
const mapStateToProps = (state) => {
    const playlist = stripPersist(state.playlists)[state.playlist.id];
    return {
        mode: stripPersist(state.colors).mode,
        playlist,
        user: stripPersist(state.user),
    };
};
const mapDispatchToProps = (dispatch) => ({
    removePlaylistDownloadAndUpdate: (playlist) => dispatch(removePlaylistDownloadAndUpdate(playlist)),
    downloadPlaylistAndUpdate: (playlist) => dispatch(downloadPlaylistAndUpdate(playlist)),
    markPlaylistAsUnlistened: (user, playlist) => {
        dispatch(markPlaylistAsUnlistened(user, playlist));
    },
    markPlaylistAsListened: (user, playlist) => {
        dispatch(markPlaylistAsListened(user, playlist));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistModal);
