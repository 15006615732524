const AnalyticsEvents = {
    /** Errors */
    FAILED_TO_RECORD_LISTEN: 'Failed to record listen',
    REVIEW_ASK_ERROR: 'review_ask_error',
    SEND_PSEUDO_EMAIL_FAIL: 'Send Pseudo Email fail',
    SEND_LOGIN_EMAIL_FAIL: 'Send Login Email fail',
    UNABLE_TO_LOAD_BOOK: 'Unable to load book',
    UNABLE_TO_FIND_CHAPTER: 'Unable to find chapter',
    FAILED_TO_FETCH_BANNERS: 'Failed to fetch banners',
    LOADED_EMPTY_MEDITATION: 'Loaded empty meditation',
    /** Playlists */
    DOWNLOADED_MEDITATION: 'Downloaded Meditation',
    REMOVE_MEDITATION: 'Remove Meditation',
    /** User */
    RESTORE_SUBSCRIPTION: 'restore subscription',
    UNSUBSCRIBE: 'Unsubscribe',
    GENERIC_PURPOSE: 'generic purpose',
    USER_DELETED: 'User deleted',
    /** Drawer Menu */
    TOGGLE_COLORS: 'Toggle Colors',
    DRAWER_MESSAGES_TOUCH: 'Drawer Messages touch',
    DRAWER_STATS_TOUCH: 'Drawer Stats touch',
    DRAWER_ABOUT_TOUCH: 'Drawer About touch',
    DRAWER_CONTACT_TOUCH: 'Drawer Contact touch',
    DRAWER_ACCOUNT_TOUCH: 'Drawer Account touch',
    /** Home Screen */
    GO_TO_NEXT_MEDITATION: 'Go To Next Meditation',
    MENU_TOUCH: 'Menu touch',
    STATS_TOUCH: 'Stats touch',
    /** About */
    ABOUT_BACKPRESS: 'About Backpress',
    /** Privacy */
    PRIVACY_BACKPRESS: 'Privacy Backpress',
    /** Terms */
    TERMS_BACKPRESS: 'Terms Backpress',
    /** Account */
    ACCOUNT_REMOVE_MEDITATIONS_TOUCH: 'Account Remove Meditaitons touch',
    ROUTINES_OPT_OUT: 'Routines Opt-out',
    ACCOUNT_BACKPRESS: 'Account Backpress',
    ACCOUNT_SUBSCRIPTION_TOUCH: 'Account Subscription touch',
    PSEUDO_SIGN_IN_TOUCH: 'Pseudo Sign In touch',
    /** Reminder */
    ACCOUNT_REMOVE_REMINDER: 'Account remove reminder',
    ACCOUNT_SET_REMINDER: 'Account set reminder',
    VIEW_ERROR_BOUNDARY: 'View Error Boundary',
    MEDITATION_CAROUSEL_CARD_TOUCH: 'Meditation Carousel Card touch',
    PLAYLIST_CARD_TOUCH: 'Playlist Card touch',
    /** Sign In Screen */
    SEND_PSEUDO_EMAIL_SUCCESS: 'Send Pseudo Email success',
    VALID_PSUEDO_PASSWORD_SUBMIT: 'Valid Psuedo Password submit',
    UNSUCCESSFUL_PSUEDO_PASSWORD_SUBMIT: 'Unsuccessful Psuedo Password submit',
    SUCCESSFUL_PSUEDO_PASSWORD_SUBMIT: 'Successful Psuedo Password submit',
    INVALID_PSEUDO_PASSWORD_SUBMIT: 'Invalid Pseudo Password submit',
    SEND_LOGIN_EMAIL_SUCCESS: 'Send Login Email success',
    RESEND_LOGIN_EMAIL_SUCCESS: 'Resend Login Email success',
    RESEND_LOGIN_EMAIL_FAIL: 'Resend Login Email fail',
    VALID_PASSWORD_SUBMIT: 'Valid Password submit',
    INVALID_PASSWORD_SUBMIT: 'Invalid Password submit',
    SIGNUP_BACKPRESS: 'Signup Backpress',
    INVALID_EMAIL_SUBMIT: 'Invalid Email submit',
    VALID_EMAIL_SUBMIT: 'Valid Email submit',
    /** Meditation Modal */
    SHARE_MEDITATION: 'Share Meditation',
    DOWNLOAD_MEDITATION_MODAL_TOUCH: 'Download Meditation Modal touch',
    REMOVE_MEDITATION_MODAL_TOUCH: 'Remove Meditation Modal touch',
    FAVORITE_MEDITATION_MODAL: 'Favorite Meditation Modal',
    REMOVE_FAVORITE_MEDITATION_MODAL: 'Remove Favorite Meditation Modal',
    MARK_AS_LISTENED_MEDITATION_MODAL: 'Mark as listened Meditation Modal',
    MARK_AS_UNLISTENED_MEDITATION_MODAL: 'Mark as unlistened Meditation Modal',
    /** Modal */
    FAVORITE_QUOTE_MEDITATION_MODAL: 'Favorite Quote Meditation Modal',
    REMOVE_FAVORITE_QUOTE_MEDITATION_MODAL: 'Remove Favorite Quote Meditation Modal',
    FAVORITE_MEDITATION_END_MODAL: 'Favorite Meditation End Modal',
    REMOVE_FAVORITE_MEDITATION_END_MODAL: 'Remove Favorite Meditation End Modal',
    /** Playlist modal */
    MARK_AS_LISTENED_PLAYLIST: 'Mark as listened Playlist',
    MARK_AS_UNLISTENED_PLAYLIST: 'Mark as unlistened Playlist',
    DOWNLOAD_PLAYLIST: 'Download Playlist',
    REMOVE_PLAYLIST: 'Remove Playlist',
    /** Random Popups */
    RATING_POPUP: 'Rating popup',
    SUBSCRIPTION_POPUP: 'Subscription popup',
    RATING_SNACKBAR: 'rating_snackbar',
    RATING_SNACKBAR_SUCCESS: 'rating_snackbar_success',
    /** BOOKS */
    BOOK_ROW_TOUCH: 'Book Row touch',
    VIEW_BOOKSCREEN: 'View BookScreen',
    PRESSED_HIGHLIGHTS: 'Pressed Highlights',
    PRESSED_QUOTES: 'Pressed Quotes',
    CHAPTER_ROW_TOUCH: 'Chapter Row touch',
    VIEW_CHAPTER: 'View Chapter',
    CHAPTER_SCROLL: 'CHAPTER_SCROLL',
    /** ENTRIES */
    ENTRY_ROW_TOUCH: 'Entry Row touch',
    CREATE_ENTRY: 'create_entry',
    ENTRY_NEW_QUOTE_TOUCH: 'Entry New Quote touch',
    ENTRY_BACKPRESS: 'Entry Backpress',
    RANDOM_QUOTE: 'random_quote',
    /** BANNERS */
    BANNER_ACTION: 'Banner Action',
    HIDE_BANNER: 'Hide Banner',
    /** Continue Playing Section */
    MEDITATION_HOME_CARD_TOUCH: 'Meditation Home Card touch',
    /** Meditation  */
    MEDITATION_LENGTH_PRESS: 'Meditation Length Press',
    MEDITATION_SHARE: 'Meditation Share',
    FAVORITE_MEDITATION_TOUCH: 'Favorite Meditation touch',
    REMOVE_FAVORITE_MEDITATION_TOUCH: 'Remove Favorite Meditation touch',
    MEDITATION_REPLAY_PRESS: 'Meditation Replay Press',
    MEDITATION_FORWARD_PRESS: 'Meditation Forward Press',
    MEDITATION_BACKPRESS: 'Meditation Backpress',
    MEDITATION_END: 'Meditation end',
    MEDITATION_RECORD_LISTEN: 'Meditation record listen',
    /** Reminder Screen */
    REMINDER_WELCOME_BACKPRESS: 'Reminder Welcome Backpress',
    REMINDER_SAVE: 'Reminder save',
    REMINDERS_CONTINUE_TOUCH: 'Reminders Continue touch',
    REMOVE_MEDITATION_GOAL: 'Remove meditation goal',
    ADD_MEDITATION_GOAL: 'Add meditation goal',
    /** Playlists */
    AUTOPLAY_TOGGLE: 'Autoplay Toggle',
    DOWNLOAD_MEDITATIONS: 'Download Meditations',
    REMOVE_MEDITATIONS: 'Remove Meditations',
    THEORY_PLAYLIST_BACKPRESS: 'Theory Playlist Backpress',
    PLAYLIST_BACKPRESS: 'Playlist Backpress',
    PLAYLIST_MEDITATION_TOUCH: 'Playlist Meditation touch',
    PLAYLIST_MODAL_TOUCH: 'Playlist Modal touch',
    THEORY_PLAYLIST_TOUCH: 'Theory Playlist touch',
    PLAYLIST_TOUCH: 'Playlist touch',
    SHARE_QUOTE: 'Share Quote',
    QUOTE_FILTER_TOUCH: 'Quote Filter touch',
    ROUTINES_OPT_IN: 'Routines Opt-in',
    SUBSCRIPTION_BACKPRESS: 'Subscription Backpress',
    SHARE_SCREEN_TOUCH: 'Share Screen touch',
    RENDER_SHARE_STOA_HOME: 'Render share stoa home',
    OFFER_TOUCH: 'Offer touch',
    TAG_PRESS: 'Tag Press',
    MEDITATION_RESTORE: 'Meditation Restore',
    MEDITATION_COUNT: 'Meditation Count',
    VERSION_ALERT: 'Version alert',
    DOWNLOADED_MEDITATION_SUCCESS: 'Downloaded Meditation Success',
    USER_REJECTED_FIREBASE_PERMISSIONS: 'User rejected firebase permissions',
    STATS_BACKPRESS: 'Stats Backpress',
};
export default AnalyticsEvents;
