import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function CloseIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 15 15", ...otherProps },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M14.1626 0.346007C14.0365 0.219664 13.8688 0.150513 13.6884 0.150513C13.5084 0.150513 13.3407 0.219691 13.2145 0.346092L7.6062 5.95426L7.39408 5.95427L1.78537 0.345923C1.65954 0.219698 1.49179 0.150513 1.31148 0.150513C1.13138 0.150513 0.963644 0.219716 0.837628 0.34613L0.345961 0.837432C0.0845494 1.09884 0.0845494 1.52431 0.345962 1.78542L5.95438 7.39396L5.95437 7.60609L0.346044 13.2143C0.219758 13.3408 0.150504 13.5086 0.150504 13.6886C0.150504 13.8685 0.219758 14.0363 0.345934 14.1626L0.837672 14.6541C0.963539 14.7803 1.13144 14.8495 1.31136 14.8495C1.49157 14.8495 1.65937 14.7803 1.78542 14.654L7.39395 9.04566L7.60608 9.04567L13.2144 14.654C13.3193 14.7589 13.453 14.8247 13.5895 14.8428L13.6886 14.8495C13.8686 14.8495 14.0363 14.7803 14.1626 14.6539L14.654 14.1627C14.7802 14.0364 14.8495 13.8684 14.8495 13.6886C14.8495 13.5087 14.7802 13.3409 14.6541 13.2145L9.0459 7.60609V7.39396L14.6542 1.78546C14.7803 1.65919 14.8497 1.4913 14.8499 1.31149C14.8499 1.13143 14.7805 0.963462 14.6542 0.837446L14.1626 0.346007Z", fill: color })));
}
CloseIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default CloseIcon;
