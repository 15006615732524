import * as React from 'react';
import Svg, { Circle } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function MeditationIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 19 19", ...otherProps },
        React.createElement(Circle, { fillRule: "evenodd", clipRule: "evenodd", cx: "9.5", cy: "9.5", r: "9.5", fill: color })));
}
MeditationIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default MeditationIcon;
