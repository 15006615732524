import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import BooksScreen from '@components/books/BooksScreen';
import BookScreen from '@components/books/BookScreen';
import ChapterScreen from '@components/books/ChapterScreen';
import HighlightsScreen from '@components/books/HighlightsScreen';
import QuotesScreen from '../components/quotes/quotesScreen';
import { BOOK_SCREEN, BOTTOM_TABS, CHAPTER_SCREEN, HIGHLIGHTS_SCREEN, QUOTES_SCREEN, } from '../utils/constants';
const Stack = createStackNavigator();
const QuotesNavigator = () => {
    return (React.createElement(Stack.Navigator, { headerMode: "none" },
        React.createElement(Stack.Screen, { name: BOTTOM_TABS.quotes.name, component: BooksScreen }),
        React.createElement(Stack.Screen, { name: BOOK_SCREEN, component: BookScreen }),
        React.createElement(Stack.Screen, { name: CHAPTER_SCREEN, component: ChapterScreen }),
        React.createElement(Stack.Screen, { name: QUOTES_SCREEN, component: QuotesScreen }),
        React.createElement(Stack.Screen, { name: HIGHLIGHTS_SCREEN, component: HighlightsScreen })));
};
export default QuotesNavigator;
