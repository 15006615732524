import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthorsReducer } from './authorsReducer';
import { ColorsReducer } from './colorsReducer';
import { ConnectionReducer } from './connectionReducer';
import DimensionsReducer from './dimensionsReducer';
import EntriesReducer from './entriesReducer';
import EntryReducer from './entryReducer';
import ListensReducer from './listensReducer';
import MeditationGoalsReducer from './meditationGoalsReducer';
import MeditationReducer from './meditationReducer';
import PlaylistReducer from './playlistReducer';
import PlaylistsReducer from './playlistsReducer';
import QuoteReducer from './quoteReducer';
import QuotesReducer from './quotesReducer';
import { StatsReducer } from './statsReducer';
import TrackPlayerReducer from './trackPlayerReducer';
import UserReducer from './userReducer';
import SettingsReducer from './settingsReducer';
import { ListenHistoryReducer } from './listenHistoryReducer';
import { ContinuePlayingReducer } from './continuePlayingReducer';
import SoundReducer from './soundReducer';
const persistConfig = {
    storage: AsyncStorage,
    stateReconciler: hardSet,
};
const authorsPersistConfig = { ...persistConfig, key: 'authors' };
const colorsPersistConfig = { ...persistConfig, key: 'colors' };
const continuePlayingPersistConfig = { ...persistConfig, key: 'continuePlaying' };
const entriesPersistConfig = { ...persistConfig, key: 'entries' };
const listenHistoryPersistConfig = { ...persistConfig, key: 'listenHistory' };
const listensPersistConfig = { ...persistConfig, key: 'listens' };
const meditationGoalsConfig = { ...persistConfig, key: 'meditationGoals' };
const playlistsPersistConfig = { ...persistConfig, key: 'playlists' };
const quotePersistConfig = { ...persistConfig, key: 'quote' };
const quotesPersistConfig = { ...persistConfig, key: 'quotes' };
const settingsPersistConfig = { ...persistConfig, key: 'settings' };
const PersistedAuthorsReducer = persistReducer(authorsPersistConfig, AuthorsReducer);
const PersistedColorsReducer = persistReducer(colorsPersistConfig, ColorsReducer);
const PersistedContinuePlayingReducer = persistReducer(continuePlayingPersistConfig, ContinuePlayingReducer);
const PersistedEntriesReducer = persistReducer(entriesPersistConfig, EntriesReducer);
const PersistedListensReducer = persistReducer(listensPersistConfig, ListensReducer);
const PersistedMeditationGoalsReducer = persistReducer(meditationGoalsConfig, MeditationGoalsReducer);
const PersistedPlaylistsReducer = persistReducer(playlistsPersistConfig, PlaylistsReducer);
const PersistedQuoteReducer = persistReducer(quotePersistConfig, QuoteReducer);
const PersistedQuotesReducer = persistReducer(quotesPersistConfig, QuotesReducer);
const PersistedSettingsReducer = persistReducer(settingsPersistConfig, SettingsReducer);
const PersistedListenHistoryReducer = persistReducer(listenHistoryPersistConfig, ListenHistoryReducer);
export const rootReducer = combineReducers({
    authors: PersistedAuthorsReducer,
    colors: PersistedColorsReducer,
    continuePlaying: PersistedContinuePlayingReducer,
    connection: ConnectionReducer,
    dimensions: DimensionsReducer,
    entries: PersistedEntriesReducer,
    entry: EntryReducer,
    listens: PersistedListensReducer,
    listenHistory: PersistedListenHistoryReducer,
    meditation: MeditationReducer,
    meditationGoals: PersistedMeditationGoalsReducer,
    playlist: PlaylistReducer,
    playlists: PersistedPlaylistsReducer,
    quote: PersistedQuoteReducer,
    quotes: PersistedQuotesReducer,
    settings: PersistedSettingsReducer,
    sound: SoundReducer,
    stats: StatsReducer,
    trackPlayer: TrackPlayerReducer,
    user: UserReducer,
});
