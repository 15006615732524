import { isEqual, shuffle } from 'lodash';
import { idObjToArr } from '../utils';
export const areStoicMeditationsLoaded = (quotes) => {
    const quoteIds = Object.keys(quotes).filter((id) => quotes[id].filepath);
    return !isEqual(quoteIds, []);
};
export const getStoicMeditationsArr = (quotes) => {
    if (!quotes)
        return [];
    return idObjToArr(quotes).filter((quote) => quote.filepath);
};
export const getStoicMeditations = (quotes) => {
    const stoicMeditations = {};
    getStoicMeditationsArr(quotes).forEach((quote) => {
        stoicMeditations[quote.id] = quote;
    });
    return stoicMeditations;
};
let shuffledStoicMeditations = [];
export const getShuffledStoicQuotes = (quotes) => {
    const stoicMeditations = getStoicMeditationsArr(quotes);
    shuffledStoicMeditations = getShuffledQuotes(stoicMeditations, shuffledStoicMeditations);
    return shuffledStoicMeditations;
};
export const getStoicMeditation = (quotes) => {
    const stoicMeditationQuotes = getQuotesArr(quotes).filter((quote) => quote.filepath);
    const randomIdx = Math.round(Math.random() * stoicMeditationQuotes.length);
    const quote = stoicMeditationQuotes[randomIdx];
    return quote;
};
export const getNewRandomQuoteId = (quotes, quote) => {
    const quoteIds = Object.keys(quotes).filter((id) => !quote || parseInt(id, 10) !== quote.id);
    const randomIdx = Math.round(Math.random() * (quoteIds.length - 1));
    const quoteId = quoteIds[randomIdx];
    return quoteId;
};
export const getFavoriteQuotesArr = (quotes) => {
    return getQuotesArr(quotes).filter((quote) => quote.is_favorite);
};
export const getFavoriteQuotes = (quotes) => {
    const favoriteQuotes = {};
    getFavoriteQuotesArr(quotes).forEach((quote) => {
        favoriteQuotes[quote.id] = quote;
    });
    return favoriteQuotes;
};
let shuffledFavoriteQuotes = [];
export const getShuffledFavoriteQuotes = (quotes) => {
    const favoriteQuotes = getFavoriteQuotesArr(quotes);
    shuffledFavoriteQuotes = getShuffledQuotes(favoriteQuotes, shuffledFavoriteQuotes);
    return shuffledFavoriteQuotes;
};
export const getQuotesArr = (quotes) => {
    if (!quotes)
        return [];
    return Object.keys(quotes).map((id) => quotes[id]);
};
const getShuffledQuotes = (quoteArr, memoArr) => {
    const shuffledIds = memoArr.map((quote) => quote.id).sort();
    const quoteIds = quoteArr.map((quote) => quote.id).sort();
    if (isEqual(shuffledIds, quoteIds)) {
        return memoArr;
    }
    return shuffle(quoteArr);
};
