import React from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Share, TouchableOpacity, View, StyleSheet, } from 'react-native';
import { isEqual } from 'lodash';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import { ms, s } from 'react-native-size-matters';
import { fetchAuthors } from '../../actions/authorActions';
import { receiveQuote, removeQuote, updateAndFetchQuotes } from '../../actions/quoteActions';
import { playTrackPlayer, pauseTrackPlayer, initTrackPlayer, destroyTrackPlayer, } from '../../actions/trackPlayerActions';
import { getStoicMeditation } from '../../selectors/quotes';
import { COLORS, QUOTE_BACKGROUND, QUOTE_TEXT, PRIMARY_TEXT, BRAND, } from '../../utils/colors';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { stripPersist, hitSlop, formatAuthorNameFromQuote } from '../../utils';
import Analytics from '../../utils/analytics';
import FLAGS from '../../utils/flags';
import BaseText from '../baseComponents/baseText';
import { ShareIcon } from '../../assets/svg';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    quoteContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'column',
        width: '92%',
        padding: ms(10),
        alignSelf: 'center',
    },
    quoteText: {
        lineHeight: 20,
        paddingLeft: s(8),
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    footerText: {
        lineHeight: 20,
        paddingHorizontal: s(4),
        fontSize: Fonts.size.s,
        fontWeight: 'bold',
    },
    footer: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: s(4),
        flexDirection: 'row',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    playIconContainer: {
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
    },
    playIcon: {
        paddingLeft: s(4),
    },
});
class QuotesBox extends React.Component {
    static navigationOptions = {
        title: 'Quotes',
    };
    constructor(props) {
        super(props);
    }
    async componentDidMount() {
        await this.ensureAuthorsLoaded();
        await this.ensureQuoteLoaded();
    }
    componentDidUpdate() {
        if (isEqual(this.props.quote, {}) && Object.keys(this.props.quotes).length > 0) {
            this.setQuote(this.props.quotes);
        }
    }
    componentWillUnmount() {
        this.props.destroyTrackPlayer();
    }
    handleShare = () => {
        const authorName = formatAuthorNameFromQuote(this.props.authors, this.props.quote);
        Share.share({
            message: `${this.props.quote.text}\n\n\u2014 ${authorName}\n\n From stoameditation.com`,
        }).catch((e) => console.log(e));
        Analytics.event(Analytics.Events.SHARE_QUOTE, {
            quote_author: authorName,
            quote_text: this.props.quote.text,
            quote_id: this.props.quote.id,
        });
    };
    setQuote(quotes) {
        if (!isEqual(this.props.quote, {}) && this.props.quote.filepath)
            return;
        const quote = getStoicMeditation(quotes);
        this.props.receiveQuote(quote);
    }
    renderShare = () => {
        if (!FLAGS.SHARE)
            return null;
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: this.handleShare },
            React.createElement(ShareIcon, { style: { width: 20, height: 20, color: COLORS[BRAND][this.props.mode] } })));
    };
    async ensureAuthorsLoaded() {
        if (isEqual(this.props.authors, {})) {
            await this.props.fetchAuthors(this.props.user, this.props.authors);
        }
    }
    async ensureQuoteLoaded() {
        try {
            const quotes = await this.props.updateAndFetchQuotes(this.props.user, this.props.quotes);
            // @ts-ignore cant use void for conditions
            if (!quotes || isEqual(quotes, {})) {
                Error();
            }
            this.setQuote(quotes);
        }
        catch {
            this.setQuote(this.props.quotes);
        }
    }
    render() {
        if (isEqual(this.props.quote, {}) || isEqual(this.props.authors, {})) {
            return (React.createElement(View, { style: styles.quoteContainer },
                React.createElement(ActivityIndicator, { size: "small", color: COLORS[BRAND][this.props.mode] })));
        }
        const authorName = formatAuthorNameFromQuote(this.props.authors, this.props.quote);
        return (React.createElement(View, { style: [
                styles.quoteContainer,
                { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
            ] },
            React.createElement(BaseText, { selectable: true, style: [styles.quoteText, { color: COLORS[QUOTE_TEXT][this.props.mode] }] }, this.props.quote.text),
            React.createElement(View, { style: styles.footer },
                React.createElement(BaseText, { style: [styles.footerText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] },
                    ' ',
                    `\u2014 ${authorName}`),
                this.renderShare())));
    }
}
const mapStateToProps = (state) => {
    const quotes = stripPersist(state.quotes);
    return {
        authors: stripPersist(state.authors),
        quote: stripPersist(state.quote),
        quoteIds: getStoicMeditation(quotes),
        quotes: stripPersist(quotes),
        mode: stripPersist(state.colors).mode,
        user: stripPersist(state.user),
        trackPlayer: state.trackPlayer,
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchAuthors: (user, authors) => dispatch(fetchAuthors(user, authors)),
    updateAndFetchQuotes: (user, quotes) => dispatch(updateAndFetchQuotes(user, quotes)),
    receiveQuote: (quote) => dispatch(receiveQuote(quote)),
    removeQuote: () => dispatch(removeQuote()),
    destroyTrackPlayer: () => dispatch(destroyTrackPlayer()),
    playTrackPlayer: (callback) => dispatch(playTrackPlayer(callback)),
    pauseTrackPlayer: (callback) => dispatch(pauseTrackPlayer(callback)),
    initTrackPlayer: () => dispatch(initTrackPlayer()),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuotesBox);
