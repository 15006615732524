import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { BLACK, LIGHTEST_GRAY } from '../colors';
import { IMAGE_BORDER_RADIUS } from '../styleConstants';
const styles = StyleSheet.create({
    inputStyle: {
        padding: 10,
        backgroundColor: LIGHTEST_GRAY,
        borderRadius: IMAGE_BORDER_RADIUS,
        color: BLACK,
        flex: 1,
        width: 200,
    },
});
// Sanitized for login
const TextInputWeb = React.forwardRef((props, ref) => {
    const { inputStyle, onChangeText, onSubmitEditing, placeHolder, placeHolderTextColor, value, containerStyle, } = props;
    return (React.createElement(View, { style: containerStyle },
        React.createElement(TextInput, { placeholder: placeHolder, onSubmitEditing: onSubmitEditing, style: [inputStyle, styles.inputStyle], value: value, placeholderTextColor: placeHolderTextColor, onChangeText: onChangeText, ref: ref, autoFocus: true })));
});
export default TextInputWeb;
