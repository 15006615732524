import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { AppRegistry } from 'react-native';
import { name } from './app.json';
import App from './src/app';
const rootEl = document.getElementById('app-root');
AppRegistry.registerComponent(name, () => App);
AppRegistry.runApplication(name, {
    initialProps: {},
    rootTag: rootEl,
});
