import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
const useDimensions = () => {
    const { height: initialHeight, width: initialWidth } = Dimensions.get('window');
    const [dimensions, setDimensions] = useState({
        height: initialHeight,
        width: initialWidth,
        orientation: initialHeight >= initialWidth ? 'PORTRAIT' : 'LANDSCAPE',
    });
    const handleChangeOrientation = () => {
        const { height: newHeight, width: newWidth } = Dimensions.get('window');
        const newOrientation = newHeight >= newWidth ? 'PORTRAIT' : 'LANDSCAPE';
        setDimensions({
            height: newHeight,
            width: newWidth,
            orientation: newOrientation,
        });
    };
    useEffect(() => {
        Dimensions.addEventListener('change', handleChangeOrientation);
        return () => Dimensions.removeEventListener('change', handleChangeOrientation);
    }, []);
    return dimensions;
};
export default useDimensions;
