import { Forward10Icon, Forward30Icon, PauseIcon, PlayIcon, Replay10Icon, Replay30Icon, } from '@assets/svg';
import { BRAND, COLORS, LIGHT_GRAY, MEDIUM_GRAY, WHITE, } from '@utils/colors';
import React from 'react';
import { ICON_SIZE, LARGE_ICON_SIZE } from '@utils/styles';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { hitSlop } from '@utils/index';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
const ICON_PADDING = 25;
const ICON_SHRINK_AMOUNT = 5;
const MEDIUM_ICON_SIZE = 50;
const LARGEST_ICON_SIZE = 64;
const styles = StyleSheet.create({
    audioControls: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    audioControlsIconContainer: {
        paddingLeft: ICON_PADDING - (LARGEST_ICON_SIZE - MEDIUM_ICON_SIZE),
        paddingRight: ICON_PADDING - (LARGEST_ICON_SIZE - MEDIUM_ICON_SIZE),
    },
    majorActionIconContainer: {
        width: LARGEST_ICON_SIZE,
        height: LARGEST_ICON_SIZE,
        borderRadius: 50,
        backgroundColor: LIGHT_GRAY,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    replayForwardIcon: {
        color: MEDIUM_GRAY,
        height: LARGE_ICON_SIZE - ICON_SHRINK_AMOUNT,
        width: LARGE_ICON_SIZE - ICON_SHRINK_AMOUNT,
    },
});
const MeditationControls = (props) => {
    const ReplayIcon = props.rewindLength === '10' ? (React.createElement(Replay10Icon, { style: styles.replayForwardIcon })) : (React.createElement(Replay30Icon, { style: styles.replayForwardIcon }));
    const ForwardIcon = props.rewindLength === '10' ? (React.createElement(Forward10Icon, { style: styles.replayForwardIcon })) : (React.createElement(Forward30Icon, { style: styles.replayForwardIcon }));
    return (React.createElement(View, { style: styles.audioControls },
        React.createElement(TouchableOpacity, { style: styles.audioControlsIconContainer, hitSlop: hitSlop, onPress: props.handleReplayPress }, ReplayIcon),
        React.createElement(PlayPauseButton, { isPlaying: props.isPlaying, initiatedBySlider: props.initiatedBySlider, wasPlaying: props.wasPlaying, toggleAudioPlayback: props.toggleAudioPlayback, mode: props.mode, isLoading: props.isLoading, isSliding: props.isSliding }),
        React.createElement(TouchableOpacity, { style: styles.audioControlsIconContainer, hitSlop: hitSlop, onPress: props.handleForwardPress }, ForwardIcon)));
};
const PlayPauseButton = (props) => {
    let playPauseIcon = null;
    const pauseIcon = React.createElement(PauseIcon, { style: { color: WHITE, height: ICON_SIZE, width: ICON_SIZE } });
    const playIcon = React.createElement(PlayIcon, { style: { color: WHITE, height: ICON_SIZE, width: ICON_SIZE } });
    if (props.isLoading || props.isSliding) {
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: props.toggleAudioPlayback, style: styles.majorActionIconContainer },
            React.createElement(ActivityIndicator, { size: "large", color: COLORS[BRAND][props.mode] })));
    }
    if (props.initiatedBySlider) {
        playPauseIcon = props.wasPlaying ? pauseIcon : playIcon;
    }
    else {
        playPauseIcon = props.isPlaying ? pauseIcon : playIcon;
    }
    return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: props.toggleAudioPlayback, style: [styles.majorActionIconContainer, { backgroundColor: COLORS[BRAND][props.mode] }] }, playPauseIcon));
};
export default React.memo(MeditationControls);
