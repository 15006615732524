import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import { s } from 'react-native-size-matters';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import { FavoriteIcon } from '@assets/svg';
import Header from '@components/baseComponents/header';
import { times } from 'lodash';
import { ScrollView } from 'react-native-gesture-handler';
import useDimensions from '@services/useDimensions';
import { COLORS, LIGHT_GRAY, BRAND, WHITE, SECONDARY_TEXT, } from '../../utils/colors';
import { QUIZ_I_SCREEN } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    quotesContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 15,
        marginHorizontal: 20,
        marginBottom: 20,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        borderWidth: 2,
        borderColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    padding: {
        paddingVertical: 6,
    },
    welcomeContainer: {
        paddingTop: s(50),
        paddingBottom: s(100),
        flexDirection: 'column',
        alignItems: 'center',
    },
    divider: {
        width: 34,
        backgroundColor: WHITE,
        opacity: 0.3,
        height: 1,
    },
    authorText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
    },
    starContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
    },
});
const SCROLL_SUBTRACT = 25 + 50 + 20 + 40 + 80;
const Stars = ({ mode }) => (React.createElement(View, { style: styles.starContainer }, times(5, () => React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][mode], height: 12, width: 12 } }))));
const ReviewsScreen = ({ mode, navigation, }) => {
    useEffect(() => {
        Analytics.screenHit('Welcome', 'ReviewsScreen');
    }, []);
    const { height } = useDimensions();
    useHandleLoggedInOrLoadedUser();
    return (React.createElement(OnboardingBackground, null,
        React.createElement(Header, { onPressCallback: () => navigation.goBack(), title: "Reviews", mode: mode, color: WHITE }),
        React.createElement(View, { style: styles.padding }),
        React.createElement(View, { style: styles.padding }),
        React.createElement(ScrollView, { style: { maxHeight: height - SCROLL_SUBTRACT } },
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(Stars, { mode: mode }),
                React.createElement(View, { style: styles.padding }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "If you\u2019re interested in learning about the stoic mindset and meditation, this app is for you without a doubt.")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(Stars, { mode: mode }),
                React.createElement(View, { style: styles.padding }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "With Stoa you can really get a sense of how to take yourself out of your thoughts and get a sense of how to handle different, difficult situations.")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(Stars, { mode: mode }),
                React.createElement(View, { style: styles.padding }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "If you\u2019re new to Stoicism, the intro series alone makes this app worth a download.")),
            React.createElement(View, { style: styles.quotesContainer },
                React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                React.createElement(Stars, { mode: mode }),
                React.createElement(View, { style: styles.padding }),
                React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, "Bravo to the developers; thank you, thank you, thank you a thousand times for making this app, so people like me can improve our lives!"))),
        React.createElement(ContinueFooter, { nextScreenName: QUIZ_I_SCREEN, index: 3 })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(ReviewsScreen);
