import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, TouchableOpacity, View, } from 'react-native';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseOverlay from '@components/baseComponents/BaseOverlay';
import { s } from 'react-native-size-matters';
import useHandleLoggedInOrLoadedUser from '@services/useHandleLoggedInOrLoadedUser';
import Header from '@components/baseComponents/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { QuizIIAnswerKey } from '@utils/asyncKeys';
import AnxietyIcon from '@assets/svg/emotions/AnxietyIcon';
import IrrationalityIcon from '@assets/svg/emotions/IrrationalityIcon';
import FireIcon from '@assets/svg/emotions/FireIcon';
import ViceIcon from '@assets/svg/emotions/ViceIcon';
import MeaninglessnessIcon from '@assets/svg/emotions/MeaninglessnessIcon';
import { shuffle } from 'lodash';
import { ICON_SIZE } from '@utils/styles';
import SelectedCircle from '@assets/svg/circle/SelectedCircle';
import OutlineCircle from '@assets/svg/circle/OutlineCircle';
import GriefIcon from '@assets/svg/emotions/GriefIcon';
import { COLORS, LIGHT_GRAY, WHITE, SECONDARY_TEXT, } from '../../utils/colors';
import { SUBSCRIPTION_SCREEN_II, } from '../../utils/constants';
import { stripPersist } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { Fonts } from '../../utils/fonts';
import OnboardingBackground from './OnboardingBackground';
import ContinueFooter from './ContinueFooter';
const styles = StyleSheet.create({
    buttonContainer: {
        padding: 10,
    },
    text: {
        fontWeight: '400',
        fontSize: Fonts.size.m,
    },
    quotesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: IMAGE_BORDER_RADIUS,
        paddingVertical: 12,
        paddingHorizontal: 24,
        marginHorizontal: 20,
        marginBottom: 20,
        height: 60,
    },
    quoteOverlay: {
        borderRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: LIGHT_GRAY,
        opacity: 0.1,
    },
    welcomeContainer: {
        paddingTop: s(50),
        paddingBottom: s(100),
        flexDirection: 'column',
        alignItems: 'center',
    },
    divider: {
        width: 34,
        backgroundColor: WHITE,
        opacity: 0.3,
        height: 1,
    },
    authorText: {
        fontWeight: '400',
        fontSize: Fonts.size.s,
    },
    skipText: {
        textDecorationLine: 'underline',
    },
    container: {
        paddingTop: 20,
    },
});
export const ANXIETY_ANSWER = 'Anxiety';
export const IRRATIONALITY_ANSWER = 'Irrationality';
export const ANGER_ANSWER = 'Anger';
export const VICE_ANSWER = 'Vice';
export const MEANINGLESSNESS_ANSWER = 'Meaninglessness';
export const GRIEF_ANSWER = 'Grief';
const ANSWERS_TO_ICON = {
    Anxiety: AnxietyIcon,
    Irrationality: IrrationalityIcon,
    Anger: FireIcon,
    Vice: ViceIcon,
    Meaninglessness: MeaninglessnessIcon,
    Grief: GriefIcon,
};
const ANSWERS_SHUFFLED = shuffle(Object.keys(ANSWERS_TO_ICON));
const QuizIIScreen = ({ mode, navigation, }) => {
    const [answers, setAnswers] = useState([]);
    useEffect(() => {
        Analytics.screenHit('Welcome', 'QuizIIScreen');
    }, []);
    const handleTouch = async (answer) => {
        const newAnswers = answers.includes(answer) ? answers.filter((a) => a !== answer) : answers.concat([answer]);
        setAnswers(newAnswers);
        await AsyncStorage.setItem(QuizIIAnswerKey, `[${newAnswers.join(', ')}]`);
    };
    useHandleLoggedInOrLoadedUser();
    return (React.createElement(OnboardingBackground, null,
        React.createElement(Header, { onPressCallback: () => navigation.goBack(), title: "How familiar are you with Stoicism?", mode: mode, color: WHITE }),
        React.createElement(ScrollView, { style: styles.container }, ANSWERS_SHUFFLED.map((answer) => {
            const Icon = ANSWERS_TO_ICON[answer];
            return (React.createElement(View, { key: answer },
                React.createElement(TouchableOpacity, { onPress: () => handleTouch(answer) },
                    React.createElement(View, { style: styles.quotesContainer },
                        React.createElement(BaseOverlay, { style: styles.quoteOverlay }),
                        answers.includes(answer)
                            ? React.createElement(SelectedCircle, { style: { height: ICON_SIZE, width: ICON_SIZE, color: WHITE } })
                            : (React.createElement(OutlineCircle, { style: { height: ICON_SIZE, width: ICON_SIZE, color: WHITE } })),
                        React.createElement(BaseText, { style: [styles.text, { color: COLORS[SECONDARY_TEXT][mode] }] }, answer),
                        React.createElement(Icon, { style: { height: ICON_SIZE, width: ICON_SIZE, color: WHITE } })))));
        })),
        React.createElement(ContinueFooter, { nextScreenName: SUBSCRIPTION_SCREEN_II, index: 5 })));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
});
export default connect(mapStateToProps, null)(QuizIIScreen);
