import React, { useEffect, useState } from 'react';
import { ProductMessagesKey } from '@utils/asyncKeys';
import { stripPersist } from '@utils/index';
import { connect } from 'react-redux';
import { fetchGet } from '@utils/request';
import Header from '@components/baseComponents/header';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import BaseText from '@components/baseComponents/baseText';
import TextButton from '@components/baseComponents/textButton';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Analytics from '@utils/analytics';
import { Fonts } from '@utils/fonts';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import { BACKGROUND, COLORS, PRIMARY_TEXT, QUOTE_BACKGROUND, QUOTE_TEXT, } from '@utils/colors';
import { isEmpty } from 'lodash';
import BaseStyles from '@utils/baseStyles';
export const useProductMessages = ({ user, }) => {
    const [messages, setMessages] = useState([]);
    const [messagesHistory, setMessagesHistory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigation = useNavigation();
    const getMessagesHistory = async () => {
        const messagesHistoryJSON = await AsyncStorage.getItem(ProductMessagesKey(user.id));
        let history = {};
        if (messagesHistoryJSON) {
            history = JSON.parse(messagesHistoryJSON);
        }
        return history;
    };
    useEffect(() => {
        const getAndSet = async () => {
            const history = await getMessagesHistory();
            setMessagesHistory(history);
            setIsLoading(false);
        };
        getAndSet();
        navigation.addListener('focus', getAndSet);
        return () => navigation.removeListener('focus', getAndSet);
    }, []);
    useEffect(() => {
        const fetch = async () => {
            if (!messagesHistory)
                return;
            const fetchedMessages = await fetchGet(user, '/api/product_notifications');
            const newMessages = [];
            if (fetchedMessages?.length > 0) {
                fetchedMessages.forEach((message) => {
                    if (!messagesHistory[message.id]) {
                        newMessages.push(message);
                    }
                });
                setMessages(newMessages);
            }
        };
        if (messagesHistory) {
            fetch();
        }
    }, [messagesHistory]);
    const hideProductMessage = async (messageId) => {
        try {
            const messageHistory = await getMessagesHistory();
            messageHistory[messageId] = 'hidden';
            await AsyncStorage.setItem(ProductMessagesKey(user.id), JSON.stringify(messageHistory));
            const newMessages = messages?.filter((message) => message.id !== messageId);
            setMessages(newMessages);
            Analytics.event('Hide Message', {
                messageId,
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    return { productMessages: messages, hideProductMessage, isLoading };
};
const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 10,
    },
    container: {
        flex: 1,
    },
    notification: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: IMAGE_BORDER_RADIUS,
        marginHorizontal: DEFAULT_HORIZONTAL_PADDING,
        marginVertical: 10,
    },
    titleContainer: {
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
    },
    title: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        paddingLeft: 10,
        paddingTop: 10,
    },
    descriptionContainer: {
        padding: 10,
        justifyContent: 'center',
    },
    description: {
        fontSize: Fonts.size.m,
        fontWeight: '400',
    },
    buttonStyle: {
        height: 20,
        width: 100,
    },
});
const MessagesScreen = ({ mode, user }) => {
    const { productMessages, hideProductMessage, isLoading } = useProductMessages({ user });
    const navigator = useNavigation();
    return (React.createElement(View, { style: [styles.container, { backgroundColor: COLORS[BACKGROUND][mode] }] },
        React.createElement(Header, { title: "Messages", onPressCallback: () => navigator.goBack(), mode: mode }),
        productMessages.map((message) => (React.createElement(View, { style: [styles.notification, { backgroundColor: COLORS[QUOTE_BACKGROUND][mode] }] },
            React.createElement(View, { style: styles.titleContainer },
                React.createElement(BaseText, { style: [styles.title, { color: COLORS[PRIMARY_TEXT][mode] }] }, message.title)),
            React.createElement(View, { style: styles.descriptionContainer },
                React.createElement(BaseText, { style: [styles.description, { color: COLORS[QUOTE_TEXT][mode] }] }, message.description)),
            React.createElement(View, { style: styles.buttonContainer },
                React.createElement(TextButton, { title: "Hide", onPress: () => hideProductMessage(message.id) }))))),
        !isLoading && isEmpty(productMessages) ? (React.createElement(View, { style: BaseStyles.flexRow },
            React.createElement(BaseText, { style: [styles.description, { color: COLORS[QUOTE_TEXT][mode] }] }, "There are no new messages at this time"))) : null));
};
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
export default connect(mapStateToProps)(MessagesScreen);
