// From: https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript/41358305
export default function romanize(num) {
    if (Number.isNaN(num))
        return NaN;
    const digits = String(+num).split('');
    const key = [
        '',
        'C',
        'CC',
        'CCC',
        'CD',
        'D',
        'DC',
        'DCC',
        'DCCC',
        'CM',
        '',
        'X',
        'XX',
        'XXX',
        'XL',
        'L',
        'LX',
        'LXX',
        'LXXX',
        'XC',
        '',
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
    ];
    let roman = '';
    let i = 3;
    while (i--)
        roman = (key[+digits.pop() + i * 10] || '') + roman;
    return Array(+digits.join('') + 1).join('M') + roman;
}
