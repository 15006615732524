import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { markAsListened } from '@actions/meditationActions';
import MeditationHomeCard from '@components/baseComponents/meditationHomeCard';
import SectionTitle from '@components/baseComponents/sectionTitle';
import TextButton from '@components/baseComponents/textButton';
import { getMeditationsObj } from '@selectors/playlists';
import Analytics from '@utils/analytics';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import { meditationPositionKey } from '@utils/asyncKeys';
import BaseStyles from '@utils/baseStyles';
import { BRAND, COLORS, LIGHT_GRAY } from '@utils/colors';
import { DEFAULT_HORIZONTAL_PADDING, TOP_BLANK_HEIGHT } from '@utils/styles';
import { stripPersist } from '@utils/index';
const style = StyleSheet.create({
    bar: {
        height: 5,
        paddingVertical: 2,
    },
});
const ContinuePlayingSection = ({ dispatchMarkAsListened, mode, navigation, playlists, user, continuePlayingId, }) => {
    const [position, setPosition] = useState(0);
    useEffect(() => {
        const asyncAction = async () => {
            if (continuePlayingId) {
                const positionStr = await AsyncStorage.getItem(meditationPositionKey(user.id, continuePlayingId));
                if (positionStr) {
                    setPosition(parseInt(positionStr, 10));
                }
            }
        };
        asyncAction();
    }, [continuePlayingId]);
    if (!continuePlayingId || !playlists)
        return null;
    const meditations = getMeditationsObj(playlists);
    const meditation = meditations[continuePlayingId];
    if (!meditation)
        return null;
    const playlist = playlists[meditation.playlist_id];
    const percentage = position ? Math.floor((position / meditation.length_sec) * 100) : null;
    return (React.createElement(React.Fragment, null,
        React.createElement(View, { style: { height: TOP_BLANK_HEIGHT } }),
        React.createElement(View, { style: BaseStyles.homeSectionContainer },
            React.createElement(MeditationHomeCard, { meditation: meditation, playlist: playlist, navigation: navigation, onPressAnalytics: () => Analytics.event(Analytics.Events.MEDITATION_HOME_CARD_TOUCH, {
                    meditation_id: meditation.id,
                    meditation_title: meditation.title,
                }) }),
            percentage ? (React.createElement(View, { style: [BaseStyles.flexRow, { paddingHorizontal: DEFAULT_HORIZONTAL_PADDING }] },
                React.createElement(View, { style: [
                        style.bar,
                        {
                            backgroundColor: COLORS[BRAND][mode],
                            width: `${percentage}%`,
                            borderBottomLeftRadius: IMAGE_BORDER_RADIUS,
                            borderTopLeftRadius: IMAGE_BORDER_RADIUS,
                        },
                    ] }),
                React.createElement(View, { style: [
                        style.bar,
                        {
                            backgroundColor: LIGHT_GRAY,
                            width: `${100 - percentage}%`,
                            borderBottomRightRadius: IMAGE_BORDER_RADIUS,
                            borderTopRightRadius: IMAGE_BORDER_RADIUS,
                        },
                    ] }))) : null,
            React.createElement(SectionTitle, { title: "Finish Listening" }),
            React.createElement(View, { style: BaseStyles.flexRow },
                React.createElement(TextButton, { onPress: () => {
                        dispatchMarkAsListened(user, meditation);
                    }, title: "Mark as Listened" })))));
};
const mapStateToProps = (state) => ({
    continuePlayingId: stripPersist(state.continuePlaying),
    mode: stripPersist(state.colors).mode,
    playlists: stripPersist(state.playlists),
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    dispatchMarkAsListened: (user, meditation) => {
        dispatch(markAsListened(user, meditation));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ContinuePlayingSection);
