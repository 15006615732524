// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { TouchableOpacity, Share, View, StyleSheet, Platform, } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { vs } from 'react-native-size-matters';
import { FavoriteIcon, FavoriteOutlineIcon, ShareIcon } from '../../assets/svg';
import { optimisticallyFavoriteQuote, optimisticallyDestroyFavoriteQuote, } from '../../actions/quoteActions';
import { COLORS, QUOTE_TEXT, PRIMARY_TEXT, BRAND, QUOTE_BACKGROUND, DARK_BORDER, } from '../../utils/colors';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { stripPersist, hitSlop, formatAuthorNameFromQuote } from '../../utils';
import Analytics from '../../utils/analytics';
import BaseText from '../baseComponents/baseText';
import { SCREEN_HEIGHT } from '../../utils/styles';
import { Fonts } from '../../utils/fonts';
import { getShuffledFavoriteQuotes } from '../../selectors/quotes';
const FILTER_FAVORITE = 'FILTER_FAVORITE';
const styles = StyleSheet.create({
    quotesContainer: {
        borderRadius: IMAGE_BORDER_RADIUS,
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        marginTop: 20,
        maxHeight: Math.floor(SCREEN_HEIGHT / 2),
    },
    quoteText: {
        textAlign: 'center',
        fontWeight: '400',
        fontSize: Fonts.size.m,
        lineHeight: vs(16),
    },
    authorText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: Fonts.size.s,
    },
    hyphenText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    quotesContentContainer: {
        padding: 20,
    },
    quotesActionsContainer: {
        height: 60,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
});
class QuoteCard extends React.Component {
    static navigationOptions = {
        title: 'Quotes',
    };
    handleShare = () => {
        Analytics.screenHit('share_touch_quotes');
        const authorName = formatAuthorNameFromQuote(this.props.authors, this.props.quote);
        Share.share({
            message: `${this.props.quote.text}\n\n\u2014 ${authorName}\n\n From stoameditation.com`,
        }).catch((e) => console.log(e));
    };
    toggleFavorite = () => {
        const { quote } = this.props;
        quote.is_favorite
            ? this.props.optimisticallyDestroyFavoriteQuote(this.props.user, quote)
            : this.props.optimisticallyFavoriteQuote(this.props.user, quote);
        if (Platform.OS === 'android'
            && this.props.snapIndex === this.props.favoriteQuotes.length - 1
            && this.props.currentTab === FILTER_FAVORITE) {
            this.props.snapToPrev();
        }
    };
    render() {
        const { quote } = this.props;
        const isFavorite = quote.is_favorite;
        const authorName = formatAuthorNameFromQuote(this.props.authors, this.props.quote);
        return (React.createElement(View, { style: [
                styles.quotesContainer,
                { backgroundColor: COLORS[QUOTE_BACKGROUND][this.props.mode] },
            ] },
            React.createElement(ScrollView, { contentContainerStyle: styles.quotesContentContainer, showsVerticalScrollIndicator: false },
                React.createElement(BaseText, { style: [styles.quoteText, { color: COLORS[QUOTE_TEXT][this.props.mode] }], selectable: true }, quote.text),
                React.createElement(BaseText, { style: [styles.hyphenText, { color: COLORS[BRAND][this.props.mode] }] }, '\u2014'),
                React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[PRIMARY_TEXT][this.props.mode] }] }, authorName)),
            React.createElement(View, { style: [
                    styles.quotesActionsContainer,
                    { borderTopColor: COLORS[DARK_BORDER][this.props.mode] },
                ] },
                React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: this.toggleFavorite }, isFavorite ? (React.createElement(FavoriteIcon, { style: { color: COLORS[BRAND][this.props.mode], height: 20, width: 20 } })) : (React.createElement(FavoriteOutlineIcon, { style: { color: COLORS[BRAND][this.props.mode], height: 20, width: 20 } }))),
                React.createElement(View, null),
                React.createElement(TouchableOpacity, { hitSlop: hitSlop, onPress: this.handleShare },
                    React.createElement(ShareIcon, { style: { color: COLORS[BRAND][this.props.mode], height: 20, width: 20 } })))));
    }
}
const mapStateToProps = (state, ownProps) => {
    const quotes = stripPersist(state.quotes);
    const quote = quotes[ownProps.quoteId];
    return {
        authors: stripPersist(state.authors),
        mode: stripPersist(state.colors).mode,
        quote,
        user: stripPersist(state.user),
        favoriteQuotes: getShuffledFavoriteQuotes(quotes),
    };
};
const mapDispatchToProps = (dispatch) => ({
    optimisticallyDestroyFavoriteQuote: (user, params) => {
        dispatch(optimisticallyDestroyFavoriteQuote(user, params));
    },
    optimisticallyFavoriteQuote: (user, params) => dispatch(optimisticallyFavoriteQuote(user, params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuoteCard);
