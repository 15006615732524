import { merge } from 'lodash';
import { LOGOUT } from '../actions/userActions';
import { RECEIVE_QUOTE, REMOVE_QUOTE } from '../actions/quoteActions';
import { coerceStateToDataFormat } from '../utils';
const initialState = {};
const QuoteReducer = (state = initialState, action) => {
    const newState = merge({}, coerceStateToDataFormat(state));
    switch (action.type) {
        case REMOVE_QUOTE:
            return newState;
        case RECEIVE_QUOTE:
            return merge(newState, { data: action.quote });
        case LOGOUT:
            return newState;
        default:
            return newState;
    }
};
export default QuoteReducer;
