import { merge } from 'lodash';
import { SET_ENTRY } from '../actions/entryActions';
const initialState = {
    entryId: '',
};
const EntryReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case SET_ENTRY:
            return {
                entryId: action.entryId,
            };
        default:
            return newState;
    }
};
export default EntryReducer;
