import React from 'react';
import { stripPersist } from '@utils/index';
import { COLORS, GRAYS, MODAL_QUOTE_BACKGROUND, PRIMARY_TEXT, QUOTE_TEXT, } from '@utils/colors';
import { Fonts } from '@utils/fonts';
import { Modal, View, StyleSheet, Pressable } from 'react-native';
import { useStore } from 'react-redux';
import BaseStyles from '@utils/baseStyles';
import { IMAGE_BORDER_RADIUS } from '@utils/styleConstants';
import BaseText from './baseText';
import TextButton from './textButton';
const styles = StyleSheet.create({
    authorText: {
        fontSize: Fonts.size.s,
        padding: 8,
        fontWeight: 'bold',
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 20,
        borderTopWidth: 1,
    },
    button: {
        alignSelf: 'center',
    },
    container: {
        zIndex: 70,
        justifyContent: 'center',
        alignContent: 'center',
    },
    contentContainer: {
        marginTop: 120,
        borderRadius: IMAGE_BORDER_RADIUS,
    },
    quoteContainer: {
        padding: 30,
        justifyContent: 'center',
        alignContent: 'center',
    },
    quoteText: {
        fontSize: Fonts.size.m,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
    },
    titleText: {
        fontSize: Fonts.size.m,
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
    },
});
const QuoteModal = ({ isOpen, quote, onFinish }) => {
    const store = useStore();
    const storeState = store.getState();
    const authors = stripPersist(storeState.authors);
    const { mode } = stripPersist(storeState.colors);
    const authorName = authors[quote.id]?.name;
    return (React.createElement(Modal, { visible: isOpen, transparent: true, animationType: "slide", supportedOrientations: ['portrait', 'landscape'] },
        React.createElement(View, { style: styles.container },
            React.createElement(View, { style: [
                    styles.contentContainer,
                    { backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode] },
                ] },
                React.createElement(View, { style: styles.quoteContainer },
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[QUOTE_TEXT][mode] }] }, "Daily Quote")),
                    React.createElement(View, null,
                        React.createElement(BaseText, { style: [
                                styles.quoteText,
                                {
                                    color: COLORS[QUOTE_TEXT][mode],
                                    backgroundColor: COLORS[MODAL_QUOTE_BACKGROUND][mode],
                                },
                            ] }, quote.text)),
                    authorName ? (React.createElement(View, null,
                        React.createElement(BaseText, { style: [styles.authorText, { color: COLORS[PRIMARY_TEXT][mode] }] }, ` \u2014 ${authorName}`))) : null),
                React.createElement(View, { style: [styles.buttonContainer, { borderColor: COLORS[GRAYS][mode] }] },
                    React.createElement(TextButton, { onPress: onFinish, title: "Finish" })))),
        React.createElement(Pressable, { onPress: onFinish, style: BaseStyles.overlay })));
};
export default QuoteModal;
