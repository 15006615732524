import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { MEDIUM_GRAY } from '../../../utils/colors';
function ContactIcon(props) {
    const { style, ...otherProps } = props;
    const { height = 44, width = 44, color } = style || {};
    return (React.createElement(Svg, { width: width, height: height, viewBox: "0 0 20 19", ...otherProps },
        React.createElement(Path, { d: "M18.5781 15.0189L15.6432 12.0837C15.0587 11.5014 14.0906 11.5191 13.4857 12.1243L12.007 13.6026C11.9136 13.5511 11.8169 13.4973 11.7153 13.4403C10.7815 12.9229 9.50357 12.2138 8.15877 10.868C6.80999 9.51938 6.10022 8.23944 5.58127 7.30511C5.52651 7.20613 5.47404 7.11069 5.42223 7.02006L6.41459 6.02916L6.90248 5.54068C7.50835 4.93464 7.52506 3.96685 6.94184 3.38292L4.00694 0.447369C3.42373 -0.135735 2.45522 -0.118045 1.84935 0.487989L1.0222 1.31989L1.0448 1.34233C0.767444 1.69624 0.535677 2.10441 0.363203 2.54458C0.204214 2.96356 0.105229 3.36338 0.0599673 3.76402C-0.327567 6.97676 1.14057 9.91297 5.12489 13.8973C10.6324 19.4045 15.0708 18.9885 15.2623 18.9682C15.6793 18.9183 16.0789 18.8187 16.4851 18.6609C16.9214 18.4905 17.3293 18.2591 17.683 17.9823L17.7011 17.9983L18.5391 17.1778C19.1437 16.5719 19.1611 15.6037 18.5781 15.0189Z", fill: color })));
}
ContactIcon.defaultProps = {
    style: {
        color: MEDIUM_GRAY,
        height: 44,
        width: 44,
    },
};
export default ContactIcon;
