import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DEFAULT_HORIZONTAL_PADDING } from '@utils/styles';
import FastImage from '../../utils/libraries/fastImage';
import BaseOverlay from './BaseOverlay';
import { imageToSpacesUrl } from '../../utils/request';
import { secondsToStr, hitSlop, stripPersist } from '../../utils';
import { IMAGE_BORDER_RADIUS } from '../../utils/styleConstants';
import { GREEN, SECONDARY_TEXT, COLORS, BACKGROUND, BLUE, } from '../../utils/colors';
import { setAndGoToMeditation } from '../../actions/meditationActions';
import { isMeditationRestricted, redirectIfMeditationRestricted } from '../../utils/enforcement';
import { LockIcon, ChevronRightIcon } from '../../assets/svg';
import BaseText from './baseText';
import { Fonts } from '../../utils/fonts';
const styles = StyleSheet.create({
    bgImage: {
        borderRadius: IMAGE_BORDER_RADIUS,
        flexDirection: 'row',
        height: 70,
        width: '100%',
        maxWidth: 450,
        backgroundColor: BLUE,
    },
    bgImageImageStyle: {
        borderRadius: IMAGE_BORDER_RADIUS,
        opacity: 0.8,
    },
    textContainer: {
        flex: 0.8,
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    titleText: {
        fontSize: Fonts.size.l,
        fontWeight: 'bold',
        alignItems: 'center',
    },
    lengthText: {
        fontSize: Fonts.size.s,
        fontWeight: '400',
        alignItems: 'center',
    },
    arrow: {
        flex: 0.2,
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        borderTopRightRadius: IMAGE_BORDER_RADIUS,
        borderBottomRightRadius: IMAGE_BORDER_RADIUS,
        backgroundColor: GREEN,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
        paddingRight: DEFAULT_HORIZONTAL_PADDING,
        paddingLeft: DEFAULT_HORIZONTAL_PADDING,
    },
});
class MeditationCard extends PureComponent {
    render() {
        if (!this.props.meditation)
            return null;
        const icon = isMeditationRestricted(this.props.meditation, this.props.user) ? (React.createElement(LockIcon, { style: { color: COLORS[SECONDARY_TEXT][this.props.mode], height: 20, width: 20 } })) : (React.createElement(ChevronRightIcon, { style: { color: COLORS[SECONDARY_TEXT][this.props.mode], height: 20, width: 20 } }));
        return (React.createElement(TouchableOpacity, { hitSlop: hitSlop, style: styles.wrapper, onPress: () => {
                redirectIfMeditationRestricted(this.props.meditation, this.props.user, this.props.navigation, () => {
                    this.props.setAndGoToMeditation(this.props.meditation, this.props.navigation);
                    this.props.onPressAnalytics();
                });
            } },
            React.createElement(FastImage, { source: { uri: imageToSpacesUrl(this.props.playlist.image) }, 
                // @ts-ignore
                imageStyle: styles.bgImageImageStyle, style: [styles.bgImage, { backgroundColor: COLORS[BACKGROUND][this.props.mode] }] },
                React.createElement(BaseOverlay, null),
                React.createElement(View, { style: styles.textContainer },
                    React.createElement(BaseText, { style: [styles.titleText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, this.props.meditation.title),
                    React.createElement(BaseText, { style: [styles.lengthText, { color: COLORS[SECONDARY_TEXT][this.props.mode] }] }, secondsToStr(this.props.meditation.length_sec))),
                React.createElement(View, { style: styles.arrow }, icon))));
    }
}
const mapStateToProps = (state) => ({
    mode: stripPersist(state.colors).mode,
    user: stripPersist(state.user),
});
const mapDispatchToProps = (dispatch) => ({
    setAndGoToMeditation: (meditation, navigation) => dispatch(setAndGoToMeditation(meditation, navigation)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeditationCard);
