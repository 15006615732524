import { receiveMeditation } from '@actions/playlistsActions';
import { isMeditationDownloaded } from '@utils/download';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { getMeditationText } from '@utils/request';
const MeditationService = () => {
    const useIsMeditationDownloaded = ({ meditation }) => {
        const { dispatch } = useStore();
        useEffect(() => {
            const isMeditationDownloadedAsync = async () => {
                if (!meditation)
                    return;
                const isDownloaded = await isMeditationDownloaded(meditation);
                const newMeditation = merge({}, meditation, { is_downloaded: isDownloaded });
                dispatch(receiveMeditation(newMeditation));
            };
            isMeditationDownloadedAsync();
        }, [meditation?.id]);
    };
    const useMeditationText = ({ meditation }) => {
        const [meditationText, setMeditationText] = useState(null);
        useEffect(() => {
            const getMeditationTextAsync = async () => {
                if (!meditation || !meditation.text_filepath)
                    return;
                const text = await getMeditationText(meditation.text_filepath);
                if (text) {
                    setMeditationText(text);
                }
            };
            getMeditationTextAsync();
        }, [meditation?.id]);
        return { meditationText };
    };
    return { useIsMeditationDownloaded, useMeditationText };
};
export default MeditationService;
