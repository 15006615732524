import { merge } from 'lodash';
import { RECEIVE_SOUND, REMOVE_SOUND } from '@actions/soundActions';
const initialState = {
    sound: null,
};
const SoundReducer = (state = initialState, action) => {
    const newState = merge({}, state);
    switch (action.type) {
        case REMOVE_SOUND:
            newState.sound = null;
            return newState;
        case RECEIVE_SOUND:
            return merge(newState, { sound: action.sound });
        default:
            return newState;
    }
};
export default SoundReducer;
